import styles from './inventory.module.css';

const Inventory = () => {
    return (
        <div className={styles.inventory}>
            <div className="container">
                <div className={styles.inventory_title}>КАКОЙ ИНВЕНТАРЬ НУЖЕН<br/> ДЛЯ&nbsp;ТРЕНИРОВОК?</div>
                <div className={styles.inventory_subtitle}>Этого набора хватит на&nbsp;всю&nbsp;жизнь!</div>
                <div className={styles.inventory_block}>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/inv_1_1.png" alt="" />
                        <p>Бодибар или&nbsp;штанга<br/>весом от&nbsp;8-12&nbsp;кг</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/inv_2.png" alt="" />
                        <p>Спортивная резиновая<br/> лента с&nbsp;сопротивлением<br/> 17–54&nbsp;кг</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/inv_3.png" alt="" />
                        <p>Разборные гантели<br/> до&nbsp;15&nbsp;кг</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/inv_4.png" alt="" />
                        <p>2 утяжелителя<br/>по&nbsp;3&nbsp;кг</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inventory;