import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}>ГЛАВнАЯ распродажа ГОДА!</div>
                    <div className={styles.promo_title}>Черная<br/> пятница</div>
                    <div className={styles.promo_text}>Забери комплект из&nbsp;12&nbsp;программ с&nbsp;домашними тренировками от&nbsp;Кати Усмановой <b>с&nbsp;выгодой&nbsp;91%</b> и&nbsp;создай красивое и&nbsp;стройное тело</div>
                    <div className={styles.mob}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/promo_mob.png" alt="" />
                    </div>
                    <div className={styles.promo_btns}>
                        <div className={styles.promo_btns_text}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/arrow.svg" alt="" /> Эксклюзивное закрытое предложение<br/> для участниц предпродаж</div>
                        <div className={styles.promo_btn} onClick={scroll}>Забрать программы</div>
                    </div>
                    <div className={styles.promo_remains}>
                        <div className={styles.promo_remains_text}>Осталось мест<br/> по&nbsp;спецпредложению:</div>
                        <div className={styles.promo_remains_number}>{window.lps_settings.other.count}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promo;