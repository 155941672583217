import { useEffect, useRef, useState } from 'react';
import styles from './platform.module.css';
import './video.css'

const Platform = () => {
    const videoref = useRef();

  const [counter, setCounter] = useState(0)
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (isActive) {
      if(counter > 59) {
        const timer = setCounter(0)
        return () => clearInterval(timer);
      } else {
        const timer = setInterval(() => setCounter(counter + 0.1), 100);
        return () => clearInterval(timer);
      }
    } 
  }, [counter, isActive])

    return (
        <div className={styles.platform}>
            <div className="stretch_container">
                <div className={styles.platform_title}><span>GymTeam</span>&nbsp;— платформа<br/> для&nbsp;самостоятельного фитнеса<br/> в&nbsp;домашних условиях</div>
                {/* <div className={styles.platform_img}><img src='https://gymteam.kinescopecdn.net/img/l/2023/stretch/phone.jpg' alt="" /></div> */}
                <div className="stretch_promo_video">
                    <div className='stretch_pbg_1'><img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/promoicon_1.svg" alt="" /></div>
                    <div className='stretch_pbg_2'>Функциональный тренинг</div>
                    <div className='stretch_pbg_3'><img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/promoicon_2.svg" alt="" /></div>
                    <div className='stretch_pbg_4'>{counter > 59 ? '1' : '0'}:{counter < 10 && '0'}{counter.toFixed(0)} <span>/ 1:00</span></div>
                    <div className='stretch_pbg_5'>
                        <div className={"stretch_pbg_5_line " + (isActive ? '' : '')} style={{width: `${counter*10.25}px`}}></div>
                        <div className="stretch_pbg_5_circle"></div>
                        <div className={"stretch_pbg_5_line2 " + (isActive ? '' : '')} style={{width: `${615-counter*10.25}px`}}></div>
                    </div>
                    <div className='stretch_pbg_6'>Выпад с ротацией корпуса<br/> В течение 1 минуты без отдыха</div>
                    {/* <div className='stretch_pbg_7'>Завершить тренировку</div> */}
                    <div className='stretch_pbg_8'><img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/promoicon_3.svg" alt="" /></div>
                    {window.innerWidth < 576 ?
                    <video
                        width="830"
                        height="380"
                        controls={false}
                        preLoad="auto"
                        autoPlay="autoplay"
                        loop
                        muted
                        playsinline='playsinline'
                        ref={videoref}
                        src='https://gymteam.kinescopecdn.net/img/l/2023/gtv/gtv_promo2.mp4'
                    />
                    :
                    <video
                        width="830"
                        height="380"
                        controls={false}
                        preLoad="auto"
                        autoPlay="autoplay"
                        loop
                        muted
                        ref={videoref}
                        src='https://gymteam.kinescopecdn.net/img/l/2023/gtv/gtv_promo2.mp4'
                    />
                    }
                </div>
                <div className={styles.platform_item}>
                    <div className={styles.platform_info}>
                        <div className={styles.platform_item_title}>БЕЗЛИМИТНЫЕ ТРЕНИРОВКИ<br/> ЭКСТРА-КЛАССА</div>
                        <div className={styles.platform_item_text}>Йога, Кроссфит, Пилатес, Кардио, Силовые и&nbsp;фунциональные тренировки, Mind-Body и&nbsp;Фейс-Фитнес от&nbsp;практикующих экспертов для&nbsp;любого возраста и&nbsp;уровня подготовки.</div>
                    </div>
                    <div className={styles.platform_img1}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/mock1v2.jpg" alt="" />
                    </div>
                </div>
                <div className={styles.platform_item}>
                    <div className={styles.platform_img2}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/mock5.jpg" alt="" />
                    </div>
                    <div className={styles.platform_info}>
                        <div className={styles.platform_item_title}>ФУНКЦИОНАЛЬНОСТЬ<br/> ДЛЯ&nbsp;ЛЮБОЙ СИТУАЦИИ</div>
                        <div className={styles.platform_item_text}>Доступ без&nbsp;интернета, Трансляция на&nbsp;ТВ, Управление скоростью и&nbsp;объяснениями,  Информация об&nbsp;оборудовании и&nbsp;другие возможности для&nbsp;комфортных занятий</div>
                    </div>
                </div>
                <div className={styles.platform_item}>
                    <div className={styles.platform_info}>
                        <div className={styles.platform_item_title}>ТЕЛЕФОН, ПЛАНШЕТ, ТЕЛЕВИЗОР ИЛИ&nbsp;НОУТБУК</div>
                        <div className={styles.platform_item_text}>Мобильные приложения для&nbsp;iPhone и&nbsp;Android, Web-платформа в&nbsp;любом браузере.</div>
                    </div>
                    <div className={styles.platform_img3}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/mock4.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Platform;