import styles from './inventory.module.css';

const Inventory = () => {
    return (
        <div className={styles.inventory}>
            <div className="container">
                <div className={styles.inventory_pretitle}>В итоге через 1,5&nbsp;месяца</div>
                <div className={styles.inventory_title}>ВАМ БОЛЬШЕ НЕ&nbsp;ПОНАДОБИТСЯ<br/> <span>ДИЕТОЛОГ</span></div>
                <div className={styles.inventory_block}>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/nutrition/inv_1.png" alt="" />
                        <p>Научитесь<br/> самостоятельно<br/> <strong>составлять себе рацион</strong></p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/nutrition/inv_2.png" alt="" />
                        <p>Будете знать причины<br/> лишнего веса <strong>и&nbsp;как с&nbsp;ним бороться</strong></p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/nutrition/inv_3.png" alt="" />
                        <p><strong>Избавитесь</strong><br/> от&nbsp;пищевой<br/> зависимости</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inventory;