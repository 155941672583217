import styles from './modal.module.css'

export const Modal = () => {
    return (
        <div className={styles.modal}>
            <div className={styles.modal_block}>
                <div className={styles.modal_title}>Спасибо,<br/> ваш голос учтён!</div>
                <div className={styles.modal_subtitle}>Мы подведем итоги 13&nbsp;августа</div>
            </div>
        </div>
    );
};