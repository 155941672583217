import React from "react";
import { HStack, Icon, VStack, Modal, ModalContent, ModalBody, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { ArrowRight, Check } from "phosphor-react";

import { TextSmall } from "../Text";
import { ButtonPrimary } from "../Button";
import { ModalOverlayBlur25 } from "../Modal";
import { modalSlideFade } from "../../transitions";
import {
  BORDER_RADIUS_30,
  FONT_SIZE_20,
  FONT_SIZE_24,
  FONT_SIZE_64,
  LOGIN_FORM_CONTAINER_WIDTH,
  SPACING_10,
  SPACING_30,
} from "../../constants";

export function HasSubscriptionModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} isCentered closeOnOverlayClick onClose={onClose}>
      <ModalOverlayBlur25 />

      <ModalContent
        motionProps={modalSlideFade}
        borderRadius={BORDER_RADIUS_30}
        w={LOGIN_FORM_CONTAINER_WIDTH}
        minW={LOGIN_FORM_CONTAINER_WIDTH}
        background="white"
      >
        <ModalBody p={SPACING_30} display="flex">
          <VStack spacing={SPACING_30} align="stretch" w="100%">
            <VStack spacing={SPACING_10}>
              <Icon as={Check} fontSize={FONT_SIZE_64} />

              <TextSmall fontSize={FONT_SIZE_20} textAlign="center" lineHeight="1.25rem" fontWeight="600">
                У вас уже есть активная
                <br />
                подписка
              </TextSmall>
            </VStack>

            <LinkBox as={ButtonPrimary} color="white">
              <HStack spacing={SPACING_10}>
                <TextSmall fontSize={FONT_SIZE_20} fontWeight="600">
                  <LinkOverlay href="https://gymteam.ru/purchases">Перейти к подписке</LinkOverlay>
                </TextSmall>
                <Icon as={ArrowRight} fontSize={FONT_SIZE_24} />
              </HStack>
            </LinkBox>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
