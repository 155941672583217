import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/universalpop/logo.svg' alt="gymteam" /></div>
                <div className={styles.promo_pretitle}>Жиросжигающий интенсив Кати&nbsp;Усмановой</div>
                <div className={styles.promo_title}>УБИРАЕМ<br/> <span>ЛИШНИЙ&nbsp;ЖИР</span></div>
                <div className={styles.promo_subtitle}>Подтянутое тело с&nbsp;эффектом липосакции<br/> <strong>за&nbsp;30&nbsp;дней занятий</strong> дома или&nbsp;в&nbsp;зале</div>
                <div className={styles.promo_items}>
                    <div className={styles.promo_item1}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/icon1.png" alt="" /> <p>Для продвинутых</p></div>
                    <div className={styles.promo_item2}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/icon2.png" alt="" /> <p>30 тренировок</p></div>
                    <div className={styles.promo_item3}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/icon3.png" alt="" /> <p>С инвентарём</p></div>
                </div> 
                <div className={styles.promo_btns}>
                    <div className='l2212_removefat_btn' onClick={scroll}><p>Участвовать</p></div>
                    <div className={styles.promo_date}><p>Старт<br/> {window.lps_settings.dates.mainlands}</p></div>
                </div>
            </div>
            <div className={styles.promo_points_block}>
                <div className={styles.promo_point1}>#быстро<span>похудеть</span></div>
                <div className={styles.promo_point2}>#придать<span>рельеф</span></div>
                <div className={styles.promo_point3}>#избавиться<span>отцеллюлита</span></div>
            </div>
        </div>
    )
}

export default Promo;