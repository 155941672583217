import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className="container">
                <div className={styles.createbody_title}>СОЗДАЙТЕ ТЕЛО <span>ПО СИСТЕМЕ<br/> КАТИ УСМАНОВОЙ</span></div>
                <div className={styles.createbody_subtitle}>Продвинутая версия легендарного <strong>«Марафона красивой и&nbsp;упругой попы&nbsp;2.0»</strong><br/> для&nbsp;тех,&nbsp;кто мечтает не&nbsp;просто накачать ягодицы, а&nbsp;сделать их&nbsp;форму<br/> по-настоящему идеальной.</div>
                <div className={styles.create_pink}>Катя Усманова с&nbsp;командой GymTeam разработала программу, которая подойдет чтобы: <strong>глубоко проработать мышцы  и&nbsp;получить результат как&nbsp;у&nbsp;модели фитнес-бикини.</strong></div>
                <div className={styles.create_yellow}>Марафон построен так, чтобы&nbsp;вы&nbsp;получили результат. <strong>Всё будет зависеть от&nbsp;вашего желания!</strong></div>
            </div>
        </div>
    )
}

export default Createbody;