import { motion } from "framer-motion";
import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: 1, x: 0 }} transition={{duration: 0.6}}>
                    <div className={styles.promo_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/logo.svg" alt="" /></div>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, x: -1400 }} animate={{ opacity: 1, x: 0 }} transition={{duration: 0.6}}>
                        <div className={styles.promo_title}>Корпоративный спорт<br/> и well-being программы<br/> <span>в одном приложении</span></div>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, x: -1600 }} animate={{ opacity: 1, x: 0 }} transition={{duration: 0.6}}>
                        <div className={styles.promo_text}>Цифровое решение для повышения вовлеченности,<br/> энергии и здоровья ваших сотрудников.<br/> Тренировки в любое время и точке мира,<br/> с доступом к интернету и без.</div>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, y: 1000 }} animate={{ opacity: 1, y: 0 }} transition={{duration: 0.6}}>
                        <div className={styles.promo_btns}>
                            <div className={styles.promo_btn} onClick={scroll}>Записаться на демо</div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Promo;