import styles from './preims.module.css';

const Preims = ({user, onOpen}) => {

    return (
        <div className={styles.preims}>
                <div className={styles.title}>Что тебя ждёт <span>на&nbsp;2&nbsp;потоке</span><br/> Метода Усмановой</div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/preims_1.jpg" alt="" />
                        </div>
                        <div className={styles.item_title}>20&nbsp;домашних тренировок в&nbsp;формате &laquo;Повторяй за&nbsp;Катей&raquo;</div>
                        <div className={styles.item_text}>5&nbsp;тренировок в&nbsp;неделю по&nbsp;30&ndash;40 минут на&nbsp;похудение и&nbsp;создание стройного тела. Тебя ждёт сочетание силовых тренировок с&nbsp;пилатесом, кардио, функциональными 3D&nbsp;тренировками, укреплением внутренних мышц.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/preims_2.jpg" alt="" />
                        </div>
                        <div className={styles.item_title}>4&nbsp;тренировки на&nbsp;укрепление здоровья от&nbsp;реабилитолога</div>
                        <div className={styles.item_text}>Каждую неделю даётся по&nbsp;1&nbsp;тренировке длительностью 15&ndash;20 мин на&nbsp;укрепление мышц спины и&nbsp;шеи, избавление от&nbsp;болей в&nbsp;коленях, чтобы хорошо себя чувствовать и&nbsp;придать фигуре сбалансированный вид в&nbsp;комплексе с&nbsp;тренировками Кати.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/preims_3_1.jpg" alt="" />
                        </div>
                        <div className={styles.item_title}>Тренировки на&nbsp;омоложение лица</div>
                        <div className={styles.item_text}>Специалист по&nbsp;фейсфитнесу покажет, как улучшить состояние кожи и&nbsp;укрепить лицевые мышцы. Ты&nbsp;сможешь разгладить морщины, повысить тонус и&nbsp;придать свежий вид коже, улучшить контур лица всего за&nbsp;несколько минут в&nbsp;день.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/preims_4.jpg" alt="" />
                        </div>
                        <div className={styles.item_title}>Лекции и&nbsp;материалы от&nbsp;нутрициолога</div>
                        <div className={styles.item_text}>Уроки от&nbsp;нутрициолога помогут подобрать рацион питания для избавления от&nbsp;лишнего веса, улучшения состояния кожи и&nbsp;самочувствия. Без долгих подсчётов калорий и&nbsp;сложных продуктов, ты&nbsp;выработаешь привычку питаться полезно, вкусно и&nbsp;сбалансированно. Также мы&nbsp;предлагаем готовые рецепты блюд для твоего удобства.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/preims_5_1.jpg" alt="" />
                        </div>
                        <div className={styles.item_title}>Доступ к&nbsp;программе<br/> на&nbsp;2&nbsp;месяца</div>
                        <div className={styles.item_text}>После окончания программы, у&nbsp;тебя останется доступ к&nbsp;записям уроков, вебинарам и&nbsp;тренировкам ещё на&nbsp;2&nbsp;месяца. Сможешь пройти программу с&nbsp;начала ещё раз и&nbsp;улучшить свой результат.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/preims_6.jpg" alt="" />
                        </div>
                        <div className={styles.item_title}>Чат с&nbsp;кураторами и&nbsp;другими участниками потока</div>
                        <div className={styles.item_text}>Ты&nbsp;попадёшь в&nbsp;фитнес-комьюнити, где тебя в&nbsp;любую минуту поддержат кураторы, единомышленники и&nbsp;Катя Усманова. Сможешь задать любой вопрос по&nbsp;тренировкам, питанию и&nbsp;мотивации в&nbsp;закрытом чате, попросить совета или поддержки.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/preims_7.jpg" alt="" />
                        </div>
                        <div className={styles.item_title}>Конкурс на&nbsp;лучшие результаты до/после</div>
                        <div className={styles.item_text}>Ты&nbsp;можешь принять участие в&nbsp;конкурсе на&nbsp;лучшие результаты по&nbsp;итогу прохождения программы. Победители получат ценные призы!</div>
                    </div>
                </div>
                <div className={`${user && styles.btn_off} ${styles.btn}`} onClick={onOpen}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                    {user && styles.btn_off ? <p>Место успешно<br/> забронировано</p> : <p>Забронировать<br/> место</p> }
                </div>
            
        </div>
    )
}

export default Preims;