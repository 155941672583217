import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer, version}) => {

    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_title}>GymTeam&nbsp;&mdash; 3&nbsp;года!</div>
                <div className={styles.promo_subtitle}>Дарим вам подарок на&nbsp;наш день рождения!</div>
                <div className={styles.promo_btn} onClick={scroll}>Забрать подарок</div>
                <div className={styles.promo_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/promo.png" alt="" />
                </div>
                {version == 1 &&
                <>
                <div className={styles.promo_note}>
                    {window.lps_settings.other.active_land == 1 ? <>Только 24&nbsp;часа в&nbsp;течение 31&nbsp;октября скидки до&nbsp;50%!</> : <>Акцию продлили по&nbsp;вашим просьбам!<br/> Только 24&nbsp;часа в&nbsp;течение 01&nbsp;ноября скидки до&nbsp;50%!</>}
                </div>
                <div className={styles.promo_countdown}>
                    <Countdown date={Date.now() + date} renderer={renderer} />
                </div>
                </>
                }
                
            </div>
        </div>
    )
}

export default Promo;