import styles from './noinventory.module.css';

const Noinventory = () => {
    return (
        <div className={styles.noinventory}>
            <div className="container">
                <div className={styles.noinventory_title}>МОЖНО ЛИ ОБОЙТИСЬ<br/> БЕЗ ИНВЕНТАРЯ?</div>
                <div className={styles.noinventory_subtitle}>На&nbsp;марафоне не&nbsp;обойтись только без&nbsp;вашего желания похудеть, подтянуть<br/> фигуру и&nbsp;избавиться от&nbsp;целлюлита&nbsp;🤪 А&nbsp;инвентарь можно заменить! <span>Вот примеры:</span></div>
                <div className={styles.noinventory_block}>
                    <div className={styles.noinventory_item}>
                        <div className={styles.noinventory_info}>
                            <div className={styles.noinventory_info__title}>Приседания</div>
                            <div className={styles.noinventory_info__text}>Вместо гантелей возьмите бутылку с&nbsp;водой, подходящую вам по&nbsp;весу (2–5&nbsp;литров). Здорово, если&nbsp;у&nbsp;вас есть&nbsp;бутылки со&nbsp;средством для&nbsp;стирки. Они весят в&nbsp;среднем 2,5&nbsp;кг, и&nbsp;у&nbsp;них чаще&nbsp;всего есть ручки, которые&nbsp;делают хват удобным.</div>
                            <div className={styles.noinventory_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.noinventory_item}>
                        <div className={styles.noinventory_info}>
                            <div className={styles.noinventory_info__title}>Выпады</div>
                            <div className={styles.noinventory_info__text}>Вместо гантелей возьмите рюкзак и&nbsp;положите туда бутылки с&nbsp;водой или&nbsp;книги. Рюкзак можно надеть на&nbsp;спину или&nbsp;держать у&nbsp;груди перед&nbsp;собой, скрестив руки.</div>
                            <div className={styles.noinventory_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.noinventory_item}>
                        <div className={styles.noinventory_info}>
                            <div className={styles.noinventory_info__title}>Тяги</div>
                            <div className={styles.noinventory_info__text}>Становую тягу можно выполнять с&nbsp;бутылкой, объёмом 5&nbsp;литров. Для горизонтальной тяги (на&nbsp;спину) идеальным вариантом станут бутылки со&nbsp;средством для&nbsp;стирки.</div>
                            <div className={styles.noinventory_info__number}>3</div>
                        </div>
                    </div>
                    <div className={styles.noinventory_item}>
                        <div className={styles.noinventory_info}>
                            <div className={styles.noinventory_info__title}>Махи ногами</div>
                            <div className={styles.noinventory_info__text}>Вместо утяжелителей можно использовать бутылки, объёмом 0,5&nbsp;л, привязав их&nbsp;тканью или&nbsp;резинкой к&nbsp;ногам.</div>
                            <div className={styles.noinventory_info__number}>4</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Noinventory;