import { useState, useEffect } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import { ShowForSegment, getReferralCode, LoginWidget } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import Faq from './components/Faq';
import Footer from '../../components/Footer';

import styles from './style.module.css';
import './components/modal.css';

export const L2023_05_Referral = () => {
    const [link, setLink] = useState('')
    const [copy, setCopy] = useState(false)
    const [isModalActive, setIsModalActive] = useState(false)

    useEffect(() => {
        getLink()
    }, [])

    const getLink = () => {
        getReferralCode().then(res => {
            setLink(window.location.origin+'/?referral='+res.code)
        })
    }

    const copyFunc = () => {
        setCopy(true)
        navigator.clipboard.writeText(link)
    }
    const successFunc = () => {
        getLink()
    }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <ShowForSegment id={window.lps_settings.segments.ref_segment_1}>
                    <div className={styles.title3}>Сколько друзей уже пригласили?</div>
                    <div className={styles.number}>1</div>
                </ShowForSegment>
                <ShowForSegment id={window.lps_settings.segments.ref_segment_2}>
                    <div className={styles.title3}>Сколько друзей уже пригласили?</div>
                    <div className={styles.number}>2</div>
                </ShowForSegment>
                <ShowForSegment id={window.lps_settings.segments.ref_segment_3}>
                    <div className={styles.title3}>Сколько друзей уже пригласили?</div>
                    <div className={styles.number}>3</div>
                </ShowForSegment>
                <ShowForSegment id={window.lps_settings.segments.ref_segment_4}>
                    <div className={styles.title3}>Сколько друзей уже пригласили?</div>
                    <div className={styles.number}>>3</div>
                </ShowForSegment>
                

                <div className={styles.block_main}>
                    <div className={styles.block}>
                        <div className={styles.block_title}>Ваша реферальная ссылка:</div>
                        <div className={styles.block_input}>
                            <div>{link}</div>
                            <div style={{cursor: 'pointer'}} onClick={copyFunc}><img src="https://gymteam.kinescopecdn.net/img/l/2023/referral/copy.svg" alt="" /></div>
                        </div>
                        
                        <div className={styles.block_btn} onClick={copyFunc}>
                            <p className={`${styles.block_btn_t1} ${copy && styles.block_btn_t1_active}`}>Поделиться ссылкой</p>
                            <p className={`${styles.block_btn_t2} ${copy && styles.block_btn_t2_active}`}>Ссылка скопирована</p>
                        </div>
                    </div>
                </div>
            </Authorized>
            <Anonymous>
                <div className={styles.block_main}>
                    <div className={styles.block}>
                        <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
                            <div className={styles.modal_content_title}>Войдите или зарегистрируйтесь,<br /> чтобы продолжить:</div>
                            {/* <div className={styles.modal_content_close} onClick={() => setIsModalActive(false)}></div> */}
                            <div className="ref_form">
                                <LoginWidget onSuccess={() => successFunc()} />
                            </div>
                            <div className={styles.modal_content_other}>При входе вы соглашаетесь<br/> <a href="/legal/public">с правилами пользования сервисом</a></div>
                        </div>
                    </div>
                </div>
            </Anonymous>
          </>
        );
    }

    return (
        <>
        <DocumentTitle value='Приглашайте друзей в GymTeam и получайте бесплатные недели подписки'/>
        <DocumentDescription value='Приглашайте друзей в GymTeam и подарите им бесплатную неделю подписки! Делитесь страстью и получайте бесплатные недели за каждого зарегистрировавшегося друга' />
        <div className={styles.main}>

            <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/referral/logo.svg" alt="" /></div>
            <div className={styles.title}>БЕСПЛАТНЫЕ ТРЕНИРОВКИ НА GYMTEAM</div>
            <div className={styles.img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/referral/trainers3.jpg" alt="" /></div>
            <div className={styles.title2}>Больше друзей — больше фитнеса!</div>
            <div className={styles.subtitle}>Приглашайте друзей в GymTeam и получайте бесплатные<br/> недели подписки каждому.</div>
            <div className={styles.ul_title}>Как тренироваться бесплатно?</div>
            <ul>
                <li><span>Шаг 1:</span> Пригласите друзей, отправив им свою реферальную ссылку</li>
                <li><span>Шаг 2:</span> Каждый зарегистрировавшийся друг получает бесплатную неделю подписки</li>
                <li><span>Шаг 3:</span> После успешной регистрации друга вы также получаете бесплатную неделю</li>
            </ul>
            
            <LoginForm />
            
            <div className={styles.block2}>
                <div className={styles.block2_item}>
                    <div className={styles.block2_title}>4.9</div>
                    <div className={styles.block2_text}>оценка приложения GymTeam в&nbsp;AppStore</div>
                </div>
                <div className={styles.block2_item}>
                    <div className={styles.block2_title}>500+</div>
                    <div className={styles.block2_text}>тренировок по&nbsp;йоге, растяжке и&nbsp;кроссфиту</div>
                </div>
                <div className={styles.block2_item}>
                    <div className={styles.block2_title}>2.5 ГОДА</div>
                    <div className={styles.block2_text}>тренировок получили участники этой программы</div>
                </div>
                <div className={styles.block2_item}>
                    <div className={styles.block2_title}>20 000</div>
                    <div className={styles.block2_text}>участниц GymTeam довольны своими результатами</div>
                </div>
                <div className={styles.block2_item}>
                    <div className={styles.block2_title}>> 40</div>
                    <div className={styles.block2_text}>фитнес программ для занятий дома и&nbsp;в&nbsp;зале</div>
                </div>
            </div>
            <Faq/>
            
        </div>
        <Footer />
        </>
    );
};