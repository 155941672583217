import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Марафон точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li><strong>Новичок</strong> и&nbsp;не&nbsp;знаете, с&nbsp;чего&nbsp;начать</li>
                            <li>Хотите <strong>плоский</strong> живот</li>
                            <li><strong>Профессионал</strong> и&nbsp;хотите проработать пресс</li>
                            <li><strong>Мечтаете о рельефе,</strong> но&nbsp;он&nbsp;прячется под&nbsp;лишним весом</li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Качаете пресс дома или в зале, <strong>но&nbsp;не&nbsp;видите заметного эффекта</strong></li>
                            <li><strong>Не можете найти время на&nbsp;фитнес-клуб,</strong> не&nbsp;хотите покупать абонемент</li>
                            <li><strong>Не знаете, где&nbsp;найти мотивацию</strong> для&nbsp;домашних тренировок</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;