import {
	LoginWidget,
	ShowForSegment,
	HideForSegment,
	HideForGroup,
} from "@com.marathonium/web2-ui";
import {
	Anonymous,
	Authorized,
	fetchWithN8n2,
	setJWT,
} from "@com.marathonium/web2-utils";

import { useEffect } from "react";

// hooks

import {
	DocumentTitle,
	DocumentDescription,
} from "../../components/DocumentMeta";

import Faq from "./components/Faq";
import Promo from "./components/Promo";
import Auth from "./components/Auth";
import Start from "./components/Start";
import Points from "./components/Points";
import What from "./components/What";
import Programs from "./components/Programs";
import Footer from "../../components/Footer";
import Trainings from "./components/Trainings";

import styles from "./style.module.css";
import "./style.css";


export const L2023_09_Fatlost = () => {
	useEffect(() => {
		document.body.id = "rasprozarka_2305";
		document.body.parentElement.id = "htmlrasprozarka_2305";
	}, []);

	const scroll = () => {
		const section = document.querySelector(".scrollhere");
		section.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	const loginByAnonimous = (e) => {
		e.preventDefault();

		let usp =  new URLSearchParams(window.location.search);

		fetchWithN8n2("web.user.login_anonymous.v1", {
			platform: window["97e74e0a-d6d2-4052-a387-cd4a619f7b94"].platform,
			gateway: window["97e74e0a-d6d2-4052-a387-cd4a619f7b94"].gateway,
			utm_source: usp.get('utm_source'),
			utm_medium: usp.get('utm_medium'), 
			utm_content: usp.get('utm_content')
		}).then((j) => {
			if (j.success === true) {
				setJWT(j.token);
			}
		});

		return false;
	};

	function LoginForm() {
		return (
			<div style={{minHeight: '100vh'}}>
				<Authorized>
					<HideForGroup id={window.lps_settings.user_groups.fatlost}>
						<Promo scroll={() => scroll(".scrollhere")} />
						<Points scroll={() => scroll(".scrollhere")} />
						<What />
						<Programs />
						<Trainings scroll={() => scroll(".scrollhere")} />
					</HideForGroup>
					<Start />
					{/* <HideForGroup id={window.lps_settings.user_groups.fatlost}>
						<Faq />
					</HideForGroup> */}
					{/* <ShowForSegment id={window.lps_settings.segments.segment_fatlost}>
						<div className={styles.hasSubscribe}>
							<p>У вас уже есть активная подписка.</p>
							<a href="/">Перейти в каталог</a>
						</div>
					</ShowForSegment>
					<HideForSegment id={window.lps_settings.segments.segment_fatlost}>
                        
					</HideForSegment> */}
				</Authorized>
				<Anonymous>
					<Promo scroll={() => scroll(".scrollhere")} />
					<Points scroll={() => scroll(".scrollhere")} />
					<What />
					<Programs />
					<Trainings scroll={() => scroll(".scrollhere")} />
					<Auth />
					{/* <Faq /> */}
				</Anonymous>
			</div>
		);
	}

	return (
		<>
			<DocumentTitle value="Жиросжигающая неделя в GymTeam" />
			<DocumentDescription value="Минус лишние килограммы и сантиметры. Огненные тренировки под вашу задачу." />
			<div className="fatlost_2309">
				<LoginForm />
				<Footer />
			</div>
		</>
	);
};
