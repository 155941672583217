import styles from './logos.module.css';

const Logos = () => {
    return (
        <div className={styles.logos}>
            <div className="container">
                <div className={styles.logos_block}>
                    <div className={styles.logos_item}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/p1.png" alt="" /></div>
                    <div className={styles.logos_item}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/p2.png" alt="" /></div>
                    <div className={styles.logos_item}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/p3.png" alt="" /></div>
                    <div className={styles.logos_item}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/p4.png" alt="" /></div>
                    <div className={styles.logos_item}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/p5.png" alt="" /></div>
                    <div className={styles.logos_item}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/p6.png" alt="" /></div>
                    <div className={styles.logos_item}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/p7.png" alt="" /></div>
                    <div className={styles.logos_item}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/p8.png" alt="" /></div>
                </div>
            </div>
        </div>
    );
};

export default Logos;