import './threecomps.css';

const Threecomps = () => {
    return (
        <div className="threecomps">
            <div className="container">
            <div className="threecomps_title">3 составляющие<br/> <span>для безопасного</span><br/> восстановления после родов</div>
            <div className="threecomps_block">
                <div className="threecomps_item">
                <div className="threecomps_item-img">
                    <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/threecomps_1.png' alt="" />
                </div>
                <div className="threecomps_item-title">30 ТРЕНИРОВОК СПЕЦИАЛЬНО ДЛЯ МАМ</div>
                <ul>
                    <li>укрепление мышц тазового дна, ягодиц, пресса и&nbsp;внутренней поверхности бёдер</li>
                    <li>координация и&nbsp;баланс</li>
                    <li>мягкая растяжка</li>
                    <li>работа с&nbsp;проблемными зонами</li>
                </ul>
                </div>
                <div className="threecomps_item">
                <div className="threecomps_item-img">
                    <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/threecomps_2.png' alt="" />
                </div>
                <div className="threecomps_item-title">РЕКОМЕНДАЦИИ ПО ПИТАНИЮ</div>
                <ul>
                    <li>здоровый рацион для кормящей мамы</li>
                    <li>похудение без голодовки</li>
                    <li>расчёт необходимого потребления калорий и&nbsp;примеры суточного рациона</li>
                </ul>
                </div>
                <div className="threecomps_item">
                <div className="threecomps_item-img">
                    <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/threecomps_3.png' alt="" />
                </div>
                <div className="threecomps_item-title">ПРОФИЛАКТИКА ПОСЛЕРОДОВЫХ ПРОБЛЕМ</div>
                <ul>
                    <li>бережная работа с&nbsp;диастазом</li>
                    <li>избавление от&nbsp;болей в&nbsp;паху и&nbsp;пояснице</li>
                    <li>уменьшение растяжек и&nbsp;целлюлита</li>
                    <li>помощь в&nbsp;решении самых деликатных проблем</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Threecomps;