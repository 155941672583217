import styles from './second.module.css';

const Second = () => {

    return (
        <div className={styles.second}>
            <div className="container">
                <div className={styles.t1}>Вдохновляющие видеотренировки!</div>
                <div className={styles.block1}>
                    <div className={styles.block1_left}>
                        <div className={styles.block1_quote}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/quote.svg" alt="" />
                        </div>
                        <div className={styles.block1_title}>Я&nbsp;помогла многим приблизиться к&nbsp;мечте о&nbsp;красивой фигуре&nbsp;&mdash; научила худеть и&nbsp;укреплять тело.</div>
                        <div className={styles.block1_text}>Но&nbsp;многие до&nbsp;сих пор избегают спорта, боятся начать и&nbsp;боятся не&nbsp;справиться.<br/> Для них мы&nbsp;открыли GymTeam 31&nbsp;октября 2021 года&nbsp;&mdash; сервис, который мотивирует, помогает полюбить спорт и&nbsp;оставаться здоровыми и&nbsp;красивыми!</div>
                    </div>
                    <div className={styles.block1_right}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/second.png" alt="" />
                    </div>
                </div>

                <div className={styles.t2}>GymTeam сегодня:</div>
                <div className={styles.block2}>
                    <div className={styles.block2_item}>
                        <div className={styles.block2_item_title}>4,9</div>
                        <div className={styles.block2_item_text}>оценка приложения<br/> AppStore & PlayMarket</div>
                    </div>
                    <div className={styles.block2_item}>
                        <div className={styles.block2_item_title}>1&nbsp;место</div>
                        <div className={styles.block2_item_text}>в&nbsp;категории SportTech<br/> рейтинга SmartRating</div>
                    </div>
                    <div className={styles.block2_item}>
                        <div className={styles.block2_item_title}>4000+</div>
                        <div className={styles.block2_item_text}>5-звездных отзывов<br/> в&nbsp;мобильных<br/> приложениях</div>
                    </div>
                </div>

                <div className={styles.block3}>
                    <div className={styles.block3_item}>
                        <div className={styles.block3_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/b3_1.svg" alt="" />
                        </div>
                        <div className={styles.block3_item_title}>130&nbsp;000+</div>
                        <div className={styles.block3_item_text}>участников</div>
                    </div>
                    <div className={styles.block3_item}>
                        <div className={styles.block3_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/b3_2.svg" alt="" />
                        </div>
                        <div className={styles.block3_item_title}>79</div>
                        <div className={styles.block3_item_text}>тренировочных программ</div>
                    </div>
                    <div className={styles.block3_item}>
                        <div className={styles.block3_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/b3_3.svg" alt="" />
                        </div>
                        <div className={styles.block3_item_title}>3000+</div>
                        <div className={styles.block3_item_text}>тренировок </div>
                    </div>
                    <div className={styles.block3_item}>
                        <div className={styles.block3_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/b3_4.svg" alt="" />
                        </div>
                        <div className={styles.block3_item_title}>11</div>
                        <div className={styles.block3_item_text}>направлений фитнеса</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Second;