import styles from './promo.module.css';

const Promo = ({scroll, startDate}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/gtv/logo.svg' alt="gymteam" /></div>
                <div className={styles.promo_pretitle}>Онлайн-курс Кати&nbsp;Усмановой</div>
                <div className={styles.promo_title}><span>Питание</span></div>
                <div className={styles.promo_subtitle}>Научитесь самостоятельно составлять рацион<br/> питания, <strong>чтобы есть вкусно и быть стройной</strong></div>
                <div className={styles.promo_items}>
                    <div className={styles.promo_item1}><img src="https://gymteam.kinescopecdn.net/img/l/nutrition/icon1_1.png" alt="" /> <p>Для любого уровня</p></div>
                    <div className={styles.promo_item2}><img src="https://gymteam.kinescopecdn.net/img/l/nutrition/icon2_1.png" alt="" /> <p>45 дней</p></div>
                    <div className={styles.promo_item3}><img src="https://gymteam.kinescopecdn.net/img/l/nutrition/icon3_1.png" alt="" /> <p>Видеоуроки и задания</p></div>
                </div> 
                <div className={styles.promo_btns}>
                    <div className='l2212_nutrition_btn' onClick={scroll}><p>Участвовать</p></div>
                    <div className={styles.promo_date}><p>Старт<br/> {window.lps_settings.dates.mainlands}</p></div>
                </div>
            </div>
            <div className={styles.promo_points_block}>
                <div className={styles.promo_point1}>#быстро<span>похудеть</span></div>
                <div className={styles.promo_point2}>#наладить<span>питание</span></div>
            </div>
        </div>
    )
}

export default Promo;