import { useState } from 'react';
import styles from './faq.module.css';

const Faq = ({scroll}) => {
    const faqs = [
        {question: 'Я могу заниматься дома?', answer: 'Программа разработана для занятий дома. Максимум, что потребуется – это коврик.'},
        {question: 'Никогда не занималась спортом, мне подойдёт марафон?', answer: 'Да, тренировки в марафоне разработаны для новичков. Екатерина всё подробно рассказывает и показывает, поэтому вам надо только внимательно смотреть и повторять.'},
        {question: 'Тренировки проходят в определённое время?', answer: 'Нет, марафон подстраивается под ваш распорядок дня. Вы сами выбираете удобное время.'},
        {question: 'Cмогу ли я заниматься, если живу не в России?', answer: 'Да. Тренировки доступны в любой точке мира. Вам понадобится смартфон, планшет или компьютер с доступом в интернет. Вы также можете транслировать видео на экран своего TV.'},
        {question: 'Могу я оплатить курс картой иностранного банка?', answer: <div><p>Да, можете. Всё просто — надо заполнить форму оформления заказа актуальными данными и нажать кнопку «Оплатить картой иностранного банка».</p><p>Откроется платёжная страница. На ней вам надо проверить введенные данные и нажать кнопку «Оплатить», чтобы выбрать удобный способ оплаты.</p></div>},
        {question: 'Я жду ребёнка, мне подойдёт марафон?', answer: 'Вам не подойдёт марафон «Стройность 1.0», ведь он не адаптирован для женщин во время беременности, а мы беспокоимся о вашем благополучии и здоровье малыша.'},
        {question: 'Я нахожусь на грудном вскармливании (ГВ) ребенка, мне можно участвовать?', answer: 'Да, если нет противопоказаний по здоровью. Правильное питание и физическая активность пойдут только на пользу.'},
        {question: 'Я оплатила, как получить доступ?', answer: <div><p>Вы получите доступ к марафону в указанную на странице дату старта. В этот день на ваш e-mail придёт письмо со ссылкой на вход в личный кабинет GymTeam.</p><p>Авторизоваться на сайте или в приложении GymTeam вы сможете только по номеру телефона, который указали при оплате.</p></div>},
        {question: 'У меня возникли технические проблемы. Куда писать?', answer: <p>По техническим вопросам, связанным с работой платформы или доступом к программам, пишите в нашу службу поддержки <a href='mailto:support@gymteam.ru'>support@gymteam.ru</a>.</p>},
        {question: 'Как установить мобильное приложение GymTeam?', answer: <><p>Прямые ссылки для установки:</p><p><a href="https://apps.apple.com/us/app/id1533404428" target='_blank'>IOS App</a></p><p><a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android" target='_blank'>Android App</a></p></>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через web-сайт?', answer: <><p>Чтобы войти на платформу, необходимо пройти регистрацию по номеру мобильного телефона. ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Выполните инструкцию ниже по шагам:</p><p>Шаг 1 - Откройте веб сайт <a href="https://gymteam.ru/" target='_blank'> www.gymteam.ru</a></p><p>Шаг 2 - Укажите номер телефона в международном формате.</p><p>Шаг 3 - Нажмите на кнопку «Получить код».</p><p>Шаг 4 - Получите входящий вызов.</p><p>Шаг 5 - Введите последние 4 цифры входящего номера телефона, и нажмите на кнопку «Войти».</p></>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через мобильное приложение?', answer: <><p>ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Шаг 1 - Введите номер телефона в международном формате, затем нажмите «Подтвердить».</p><p>Шаг 2 - Подтвердите входящий вызов, кликнув на кнопку.</p><p>Шаг 3 - Запомните 4 последние цифры входящего номера телефона. ВНИМАНИЕ! Отвечать на звонок не нужно.</p><p>Шаг 4 - Введите 4 цифры в поле для ввода, далее приложение автоматически произведет вход.</p><p>Шаг 5 - Если вам не поступил входящий вызов, то нажмите на кнопку «Отправить SMS сообщение».</p><p>Шаг 6 - Получите входящее SMS сообщение с кодом для входа, и введите цифры.</p></>},
        {question: 'Как купить другие программы Екатерины Усмановой?', answer: <><p>Купить другие программы вы можете самостоятельно на сайте программ или напишите нашим менеджерам <a href='mailto:support@gymteam.ru' target='_blank'>support@gymteam.ru</a></p><p>Купить программы можно по этим ссылкам:</p><p><a href='https://gymteam.ru/l/universalpop/' target='_blank'>Универсальные тренировки на ягодицы</a><br/><a href='https://gymteam.ru/l/marafonabs' target='_blank'>Марафон Плоского живота</a><br/><a href='https://gymteam.ru/l/marafonpopa1' target='_blank'>Марафон Упругой Попы 1.0</a><br/><a href='https://gymteam.ru/l/marafonpopa2' target='_blank'>Марафон Упругой Попы 2.0</a><br/><a href='https://gymteam.ru/l/removefat' target='_blank'>Марафон Убираем лишний жир</a><br/><a href='https://gymteam.ru/l/anticellulite' target='_blank'>Антицеллюлитный марафон</a><br/><a href='https://gymteam.ru/l/fatburning' target='_blank'>Жиросжигающий курс</a><br/><a href='https://gymteam.ru/l/coursepop2' target='_blank'>Курс по создания идеальной Попы 2.0</a><br/><a href='https://gymteam.ru/l/coursepop3' target='_blank'>Курс идеальной попы 3.0</a><br/><a href='https://gymteam.ru/l/nutrition' target='_blank'>Курс Питание</a><br/><a href='https://gymteam.ru/l/pregnancy' target='_blank'>Курс для беременных</a><br/><a href='https://gymteam.ru/l/afterpregnancy' target='_blank'>Курс Восстановление после родов</a><br/><a href='https://gymteam.ru/l/coursegym/' target='_blank'>Курс для зала</a></p></>}
    ];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    return(
        <div className={styles.faq} >
          <div className="container">
            
            <div className='l2211_str1_title'>ОТВЕЧАЕМ НА ВОПРОСЫ</div>
            <div className={styles.accordion}>
              {faqs.map((faq, index) => (
                <div key={faq.answer} className={clicked == index + 1 ? styles.active : ''}>
                  <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                  <p className={styles.answer}>{faq.answer}</p>
                </div>
              ))}
            </div>
            <button className='l2211_str1_btn l2211_str1_btn_faq' onClick={scroll}><p>Всё понятно — хочу&nbsp;участвовать</p></button>
          </div>
        </div>
    );
};

export default Faq;