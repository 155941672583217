import { DocumentDescription, DocumentTitle } from '../components/DocumentMeta';
import { RedirectPrograms } from '../components/redirect/RedirectProgram';
import Author from './components/Author';
import Faq from './components/Faq';
import Footer from '../components/Footer';
import Inventory from './components/Inventory';
import Knowledge from './components/Knowledge';
import Marforme from './components/Marforme';
import Onmarafon from './components/Onmarafon';
import Promo from './components/Promo';
import Start from './components/Start';
import Studentres from './components/Studentres';
import Whaityou from './components/Whaityou';
import Platform from '../components/platform/Platform';
import Press from './components/Press';
import './style.css';

export const L2022_12_Marafon_abs = () => {
    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scrollStart = () => {
      const section = document.querySelector( '.scrollstart' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();

    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
      <RedirectPrograms />
      <DocumentTitle value='Плоский живот  — марафон Екатерины Усмановой в GymTeam'/>
      <DocumentDescription value='«Марафон плоского живота» в GymTeam — программа Екатерины Усмановой на 21 день тренировок дома или в зале. Цель: прокачать пресс, сделать животик плоским и соблазнительным.' />
            <div className='slimstomach'>
                <Promo scroll={scroll} />
                <Press/>
                <Onmarafon />
                <Whaityou />
                <Marforme />
                <Studentres scrollStart={scrollStart} />
                <Platform />
                <Knowledge />
                <Inventory />
                <Start date={date} renderer={renderer} />
                <Author />
                <Faq scroll={scroll} />
                <Footer />
            </div>
    </>
    );
};