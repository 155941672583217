import styles from './header.module.css'

const Header = ({isActive, setIsActive, setIsModalActive}) => {

    return (
        <div className={`${styles.header_block} ${isActive && styles.active}`}>
          <div className="container">
            <div className={styles.header}>
                <div className={styles.header__logo}>
                  <a href="/"><img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/gtv_logo.svg" alt="" /></a>
                </div>
                <div className={styles.header__menu}>
                    <div className={`${styles.header__menu_item} ${styles.header__login_mob}`} onClick={() => setIsModalActive(true)}>Вход</div>
                </div>
                <div className={styles.header__login}>
                  <div className={styles.header__login_btn} onClick={() => setIsModalActive(true)}>Вход</div>
                  <div className={styles.header__login_reg} onClick={() => setIsModalActive(true)}>зарегистрироваться</div>
                </div>
                <div className={`${styles.header_btn} ${isActive && styles.active}`} onClick={() => setIsActive(!isActive)}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
            </div>
          </div>
        </div>
    );
};

export default Header;