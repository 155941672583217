import { useState } from 'react';
import styles from './knows.module.css';

const Knows = () => {
    const [play, setPlay] = useState(false);
    const url = play ? "?autoplay=1" : '';

    return (
        <div className={styles.knows}>
            <div className="container">
                <div className={styles.knows_title}>ТЕЛО БУДЕТ<br/> ПРОСТО ОГОНЬ</div>
                <div className={styles.knows_subtitle}>Катя о своей новой программе и эксклюзивных условиях в GymTeam<br/> Смотрите видео</div>
                <div className={styles.knows_video}>
                    <div className={`${styles.knows_vid} ` + (play ? styles.vid_hide : '')} onClick={() => setPlay(true)}></div>
                    <iframe src={'https://kinescope.io/embed/204031789'+url} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default Knows;