import './contraindications.css';

const Contraindications = () => {
    return (
        <div className="contraindications">
            <div className="container">
            <div className="contraindications_title">ЗАНИМАЙТЕСЬ ТОЛЬКО НА ПОЛЬЗУ СЕБЕ — ОТЛОЖИТЕ<br/> ТРЕНИРОВКИ, ЕСЛИ У&nbsp;ВАС ЕСТЬ <span>противопоказания</span></div>
            <div className="contraindications_block">
                <div className="contraindications_item">
                <ul>
                    <li>Острые воспалительные процессы</li>
                    <li>Грыжа белой линии живота</li>
                    <li>Диастаз размером более 4&nbsp;см</li>
                </ul>
                </div>
                <div className="contraindications_item">
                <ul>
                    <li>Опущение органов малого таза</li>
                    <li>Варикозное расширение вен малого таза</li>
                    <li>Остеопороз любой степени</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Contraindications;