import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/universalpop/logo.svg' alt="gymteam" /></div>
                <div className={styles.promo_pretitle}>Онлайн-марафон Кати&nbsp;Усмановой</div>
                <div className={styles.promo_title}><span>АНТИ</span>ЦЕЛЛЮЛИТНЫЙ</div>
                <div className={styles.promo_subtitle}>Ровная кожа и&nbsp;<strong>подтянутое тело за&nbsp;30&nbsp;дней</strong><br/> по&nbsp;методу чемпионки фитнес-бикини</div>
                <div className={styles.promo_items}>
                    <div className={styles.promo_item1}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_anticellulite/icon1.png" alt="" /> <p>Для новичков</p></div>
                    <div className={styles.promo_item2}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_anticellulite/icon2.png" alt="" /> <p>30 тренировок</p></div>
                    <div className={styles.promo_item3}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_anticellulite/icon3.png" alt="" /> <p>С инвентарём</p></div>
                </div> 
                <div className={styles.promo_btns}>
                    <div className='l2212_anticellulite_btn' onClick={scroll}><p>Участвовать</p></div>
                    <div className={styles.promo_date}><p>Старт<br/> {window.lps_settings.dates.mainlands}</p></div>
                </div>
            </div>
            <div className={styles.promo_points_block}>
                <div className={styles.promo_point1}>#выровнять<span>кожу</span></div>
                <div className={styles.promo_point2}>#быстро<span>похудеть</span></div>
                <div className={styles.promo_point3}>#избавиться<span>отцеллюлита</span></div>
            </div>
        </div>
    )
}

export default Promo;