import styles from './promo.module.css';

const Promo = ({user, onOpen}) => {

    return (
        <div className={styles.promo}>
            <div className="container">
                {/* <div className={styles.header}>
                    <div className={styles.header_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/logo.svg" alt="" /></div>
                    <div className={styles.header_link} onClick={scroll}>Записаться на&nbsp;второй поток</div>
                </div> */}
                <div className={styles.main}>
                    <div className={styles.main_left}>
                        <div className={styles.text}>
                            <div className={styles.inf1}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/shape_1.svg" alt="" />
                                <p>Анкета предзаписи<br/> на&nbsp;2&nbsp;поток<br/> <b>Метода Усмановой</b></p>
                            </div>
                            <div className={styles.t1}>
                                <p>Забронируй место</p>
                                <p>и&nbsp;получи самые</p>
                                <p><span>выгодные условия</span></p>
                            </div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/promo_mob_2.png" alt="" />
                            {/* <div className={styles.t2}>Чтобы не&nbsp;пропустить старт 2&nbsp;потока Метода<br/> Усмановой, <b>подписывайтесь на&nbsp;наш ТГ-канал:</b><br/> там появляется самая актуальная информация</div> */}
                            <div className={`${user && styles.btn_off} ${styles.btn}`} onClick={onOpen}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                {user && styles.btn_off ? <p>Место успешно<br/> забронировано</p> : <p>Забронировать<br/> место бесплатно</p> }
                            </div>
                            {/* <a href="https://t.me/withusmanovakate" target="_blank" rel="noreferrer" className={styles.btn}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Подписаться<br/> на&nbsp;ТГ-канал</p>
                            </a> */}
                        </div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/promo_1x.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/promo_mob.png" alt="" />
                            <div className={styles.remains}>
                                <p>Осталось мест:</p>
                                <span>{window.lps_settings.other.count}</span>
                            </div>
                            <div className={`${user && styles.btn_off} ${styles.btn}`} onClick={onOpen}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                {user && styles.btn_off ? <p>Место успешно<br/> забронировано</p> : <p>Забронировать<br/> место бесплатно</p> }
                            </div>
                        </div>
                    </div>
                    <div className={styles.main_right}>
                        <div className={styles.remains}>
                            <p>Осталось мест:</p>
                            <span>{window.lps_settings.other.count}</span>
                        </div>
                        <div className={styles.date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/clock.svg" alt="" />
                            <div>
                                <p>Примерная дата старта:</p>
                                <span>17&nbsp;сентября</span>
                            </div>
                        </div>
                        {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/promo_1x_2.png" alt="" /> */}
                    </div>
                </div>

                <div className={styles.more}>
                    <div className={styles.more_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/more.png" alt="" />
                    </div>
                    <div className={styles.more_text}>
                        <p><b>Метод Усмановой&nbsp;&mdash;</b> это комплексная программа тренировок и&nbsp;питания, направленная на&nbsp;создание красивого и&nbsp;стройного тела с&nbsp;заботой о&nbsp;его здоровье в&nbsp;любом возрасте.</p>
                        <p>Почувствуй себя уверенной, красивой и&nbsp;желанной.</p>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Promo;