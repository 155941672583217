import './wegift.css';

const Wegift = () => {
    return (
        <div className="wegift">
            <div className="container">
            <div className="wegift_title">Мы дарим подарки<br/> <span>только для вас</span></div>
            <div className="wegift_subtitle">КОГДА ОСТАЛЬНЫЕ — ТОЛЬКО ДЛЯ МАЛЫША</div>
            <div className="wegift_block">
                <div className="wegift_item">
                    <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/wegift_5.jpg' alt="" />
                    <h3>ТРЕКЕР</h3>
                    <p>«Уход за телом после родов.</p> <p>Как ускорить победу над</p> <p>растяжками и целлюлитом»</p>
                </div>
                <div className="wegift_item">
                    <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/wegift_6.jpg' alt="" />
                    <h3>ГАЙД</h3>
                    <p>«5 рецептов для кормящих</p> <p>мам. Просто, быстро и можно</p> <p>накормить мужа»</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Wegift;