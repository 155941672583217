import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className={styles.createbody_weight}></div>
            <div className={styles.createbody_weight2}></div>
            <div className="container">
                <div className={styles.createbody_title}>СОЗДАЙТЕ ИДЕАЛЬНЫЕ ЯГОДИЦЫ<br/> <span>ПО&nbsp;СИСТЕМЕ КАТИ УСМАНОВОЙ</span></div>
                <div className={styles.createbody_subtitle}>Подтянутые ягодицы&nbsp;&mdash; символ женственности</div>
                <div className={styles.create_pink}>На контрасте с&nbsp;рельефной попой талия выглядит более тонкой, а&nbsp;ноги стройными. <strong>Фигура становится женственной и&nbsp;соблазнительной, но&nbsp;при&nbsp;этом спортивной.</strong></div>
                <div className={styles.create_yellow}><strong>Внутри 5&nbsp;тренировок в&nbsp;неделю не&nbsp;только на&nbsp;попу:</strong><br/> 3&nbsp;дня качаем ягодицы, 2&nbsp;дня&nbsp;— верх тела и&nbsp;пресс.</div>
            </div>
        </div>
    )
}

export default Createbody;