import { LoginWidget } from "@com.marathonium/web2-ui";
import styles from './modal.module.css';
import './modal.css';

const Gtvmodal = ({isModalActive, setIsModalActive, getLink}) => {
    const successFunc = () => {
        setIsModalActive(false)
        getLink()
    }
    return (
        <div className={`${isModalActive && styles.active} ${styles.modal}`} onClick={() => setIsModalActive(false)}>
            <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
                <div className={styles.modal_content_title}>Войдите или зарегистрируйтесь,<br /> чтобы продолжить:</div>
                <div className={styles.modal_content_close} onClick={() => setIsModalActive(false)}></div>
                <div className="gtv-modal">
                    <LoginWidget onSuccess={() => successFunc()} />
                </div>
                <div className={styles.modal_content_other}>При входе вы соглашаетесь<br/> <a href="/legal/public">с правилами пользования сервисом</a></div>
            </div>
        </div>
    )
}

export default Gtvmodal;