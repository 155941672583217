import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <>
        <div className={styles.promo}>
            <div className="stretch_container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/gtv/gtv_logo.svg' alt="gymteam" /></div>
                <div className={styles.promo_title}>19&nbsp;—&nbsp;25 июня<br/> неделя растяжки<br/> В&nbsp;GYMTEAM </div>
                <div className={styles.promo_subtitle}>Неделя бесплатных тренировок<br/> для&nbsp;улучшения гибкости и&nbsp;подвижности,<br/> расслабления и&nbsp;восстановления всего тела!</div>
                <div className={styles.promo_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/mob-tel.jpg" alt="" />
                </div>
                <div className={styles.promo_text}>Участвуйте в&nbsp;акции и&nbsp;занимайтесь бесплатно!</div>
                <div onClick={scroll} className={styles.promo_btn}>Получить неделю растяжки</div>
            </div>
        </div>
        <div className={`${styles.promo_text} ${styles.promo_text2}`}>Участвуйте в&nbsp;акции и&nbsp;занимайтесь бесплатно!</div>
        <div onClick={scroll} className={`${styles.promo_btn} ${styles.promo_btn2}`}>Получить неделю растяжки</div>
        </>
    )
}

export default Promo;