import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <>
        <div className={styles.pretitleblock}>
            <div className="container">
                <div className={styles.promo_pretitle}>ПРЕДЛОЖЕНИЕ БОЛЬШЕ НЕ&nbsp;БУДЕТ ДОСТУПНО, ЕСЛИ ВЫ&nbsp;УЙДЁТЕ СО&nbsp;СТРАНИЦЫ</div>
            </div>
        </div>
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    {/* <div className={styles.promo_pretitle}>ПРЕДЛОЖЕНИЕ ЗАКРОЕТСЯ,<br/> КАК ТОЛЬКО ВЫ&nbsp;УЙДЁТЕ СО&nbsp;СТРАНИЦЫ</div> */}
                    <div className={styles.promo_title}>ГОДОВОЙ ДОСТУП<br/> К&nbsp;СПОРТИВНЫМ<br/> ПРОГРАММАМ<br/> <span>С&nbsp;ВЫГОДОЙ ДО&nbsp;95%</span></div>
                    <div className={styles.promo_subtitle}>Заберите ВСЕ ПРОГРАММЫ по&nbsp;цене марафона!</div>
                    <div className={styles.promo_items}>
                        <ul>
                            <li>Вся коллекция программ Кати Усмановой</li>
                            <li>Подписка на&nbsp;50+&nbsp;фитнес-программ платформы GymTeam,<br/> включая годовое обновление каталога</li>
                            <li>Или всё вместе!</li>
                        </ul>
                    </div>
                    <div className={styles.promo_btns}>
                        <div className={styles.start_countdown}>
                            <p>🔥Страница закроется через:</p>
                            <div className={styles.start_countdown_timer}>
                                {/* <Countdown date={Date.now() + date} renderer={renderer} /> */}
                                <Countdown date={Date.now() + 1200000} renderer={renderer} />
                                
                            </div>
                            {/* <p>Спецпредложение для вас<br/> <span>Только на&nbsp;20&nbsp;минут</span></p> */}
                        </div>
                        <div className={styles.promo_btn} onClick={scroll}><p>забрать программы</p></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Promo;