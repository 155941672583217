import './physical.css';

const Physical = () => {
    return (
        <div className="physical">
          <div className="container">
            <div className="physical_title"><span>На курсе</span> вы&nbsp;подготовите тело<br/> к&nbsp;физическим изменениям:</div>
            <div className="physical_item">
              <div className="physical_item__left">
                <div className="physical_item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/trim_icon_1.png' alt="" />
                </div>
                <div className="physical_item-title">триместр</div>
                <p>Вы сможете адаптироваться к&nbsp;физиологическим изменениям и&nbsp;подготовиться к&nbsp;повышенным нагрузкам на&nbsp;определённые зоны тела. Также вы уменьшите проявления токсикоза за&nbsp;счёт успокаивающих и&nbsp;расслабляющих дыхательных упражнений.</p>
              </div>
              <div className="physical_item__right">
                <p>В программе:</p>
                <ul>
                  <li>Диафрагмальное дыхание и&nbsp;«дыхание в&nbsp;почки»</li>
                  <li>Повышение мобильности грудной клетки и&nbsp;тазобедренных суставов</li>
                  <li>Улучшение осанки</li>
                  <li>Профилактика болей в&nbsp;спине и&nbsp;плоскостопия</li>
                  <li>Снятие симптомов токсикоза</li>
                </ul>
              </div>
            </div>
            <div className="physical_item">
              <div className="physical_item__left">
                <div className="physical_item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/trim_icon_2.png' alt="" />
                </div>
                <div className="physical_item-title">триместр</div>
                <p>Лучший период для тренировок. Вы сможете подготовиться к&nbsp;повышенной нагрузке на&nbsp;позвоночник, включить в&nbsp;работу мышцы, улучшить самочувствие, снять напряжение и&nbsp;предупредить развитие многих осложнений: плоскостопия, диастаза и&nbsp;т. д.</p>
              </div>
              <div className="physical_item__right">
                <p>В программе:</p>
                <ul>
                  <li>Улучшение осанки</li>
                  <li>Работа с&nbsp;мышцами верхнего плечевого пояса и&nbsp;рук</li>
                  <li>Снятие напряжения в&nbsp;области плеч и&nbsp;поясницы</li>
                  <li>Профилактика плоскостопия</li>
                  <li>Повышение мобильности грудной клетки и&nbsp;тазобедренных суставов</li>
                  <li>Улучшение баланса и&nbsp;координации</li>
                </ul>
              </div>
            </div>
            <div className="physical_item">
              <div className="physical_item__left">
                <div className="physical_item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/trim_icon_3.png' alt="" />
                </div>
                <div className="physical_item-title">триместр</div>
                <p>Вы сможете подготовиться к&nbsp;родам и&nbsp;послеродовому периоду, улучшить своё самочувствие и&nbsp;справиться с&nbsp;болезненными ощущениями в&nbsp;области спины</p>
              </div>
              <div className="physical_item__right">
                <p>В программе:</p>
                <ul>
                  <li>Повышение гибкости</li>
                  <li>Дыхательные практики</li>
                  <li>Снятие напряжения, боли и&nbsp;ощущения дискомфорта</li>
                  <li>Практики для расслабления</li>
                  <li>Подготовка к&nbsp;послеродовому периоду</li>
                </ul>
              </div>
            </div>
            <div className="physical_item physical_item__pink">
              <div className="physical_item__left">
                <p>ОБЩИЕ ПРОТИВОПОКАЗАНИЯ<br/> ДЛЯ ТРЕНИРОВОК</p>
              </div>
              <div className="physical_item__right">
                <p>👉 низкий показатель гемоглобина (менее 70)</p>
                <p>👉 любые угрозы для&nbsp;плода (угроза потери беременности, риск преждевременных родов‚ и&nbsp;т. д.)</p>
                <p>👉 кровотечение во&nbsp;время беременности</p>
                <p>👉 аномальное положение шейки матки</p>
                <p>👉 разрыв плодных оболочек</p>
                <p>👉 гестоз</p>
                <p>👉 хронически повышенное артериальное давление</p>
                <p>👉 несостоятельность шейки матки</p>
                <p>👉 предлежание плаценты</p>
                <p>👉 любые другие акушерские или медицинские противопоказания</p>
                <p>Индивидуальные противопоказания следует уточнить у&nbsp;вашего лечащего врача.</p>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Physical;