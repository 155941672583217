import React, { Fragment, createContext, useContext, useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Stack,
  VStack,
  HStack,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import {
  URLSearchParamsService,
  fetchWithN8n2,
  russianPlural,
  useN8n2,
  useIsAuthorized,
  openNewTab,
  GLOBAL_CONFIG_KEY,
} from "@com.marathonium/web2-utils";
import _get from "lodash/get";

import { ButtonPrimary } from "../Button";
import { TextAccent, TextMain, TextSmall } from "../Text";
import { InputPhone } from "../InputPhone";
import { Heading3 } from "../Heading";
import { HasSubscriptionModal } from "../common/HasSubscriptionModal";
import { getUser } from "../../helpers/user";
import {
  BORDER_RADIUS_20,
  COLOR_BLACK_OPACITY_01,
  COLOR_PINK,
  SPACING_10,
  SPACING_15,
  SPACING_20,
  SPACING_30,
  SPACING_5,
  SPACING_65,
} from "../../constants";

function InputText(props) {
  return <Input height={SPACING_65} px={SPACING_20} borderRadius={BORDER_RADIUS_20} _hover={{ borderColor: null }} {...props} />;
}

function validatePhone(value) {
  const digits = value.replace(/\D+/g, "");

  if (!digits) {
    return "Введите корректный номер телефона";
  }

  return null;
}

function validateEmail(value) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return null;
  }

  return "Введите корректный адрес почты";
}

function validateRequired(errorText) {
  return value => (value ? null : errorText);
}

function getTotalPrice(offers) {
  let p = 0;
  let mp = 0;

  const shouldCalcMp = offers.some(o => parseInt(o.marketing_price) > 0);

  offers.forEach(o => {
    p += parseInt(o.price) || 0;

    if (shouldCalcMp) {
      mp += parseInt(o.marketing_price) || parseInt(o.price) || 0;
    }
  });

  return [p, mp];
}

const DataContext = createContext();

const DataContextProvider = ({ children, id, id_order }) => {
  const { loading, data, error } = useN8n2("web.form.details.v1", { id, id_order }, [id, id_order]);

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  const { offers, is_multiple, min_quantity } = data || {};

  return (
    <DataContext.Provider
      value={{
        offers,
        is_multiple,
        min_quantity,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const StateContext = createContext();

const StateContextProvider = ({ children, id, hook, onPaymentSelect, initValue, initOrderId, initUserData }) => {
  const { offers, is_multiple, min_quantity } = useContext(DataContext);

  let defaultValue = is_multiple ? [] : _get(offers, "[0].id");
  if (initValue) {
    if (Array.isArray(initValue)) {
      defaultValue = is_multiple ? initValue : _get(initValue, "[0]");
    } else {
      defaultValue = is_multiple ? [initValue] : initValue;
    }
  }

  const [offerId, setOfferId] = useState(defaultValue);
  const [isOfferSelected, setIsOfferSelected] = useState(false);
  const [offerFormError, setOfferFormError] = useState(null);
  const [offerFormDisabled, setOfferFormDisabled] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalMarketingPrice, setTotalMarketingPrice] = useState(0);
  const [paymentError, setPaymentError] = useState(null);

  const [userDataFormDisabled, setUserDataFormDisabled] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [orderId, setOrderId] = useState(initOrderId);

  function handleUserDataFormSubmit(submission) {
    setUserDataFormDisabled(true);

    const params = {
      phone: submission.phone,
      email: submission.email,
      id_form: id,
      payment_type: submission.payment_type,
      gateway: _get(window, `["${GLOBAL_CONFIG_KEY}"].gateway`),
      ...URLSearchParamsService.load(),
    };

    if (submission.name) {
      params.name = submission.name;
    }

    if (submission.surname) {
      params.surname = submission.surname;
    }

    if (is_multiple) {
      params.id_offers = offerId;
    } else {
      params.id_offer = offerId;
    }

    if (orderId) {
      params.id_order = orderId;
    }

    fetchWithN8n2("web.order_request.create.v1", params)
      .then(({ link, has_subscription, id_order }) => {
        if (id_order) {
          setOrderId(id_order);
        }

        if (has_subscription) {
          setHasActiveSubscription(true);
          return;
        }

        openNewTab(link);

        typeof onPaymentSelect === "function" && onPaymentSelect(params);
      })
      .catch(e => {
        setPaymentError(e.message || "Произошла ошибка");
      })
      .finally(() => {
        setUserDataFormDisabled(false);
      });
  }

  function getTotalPriceAndPaymentMethods() {
    return fetchWithN8n2("web.payment_method.list.v1", {
      id_offers: is_multiple ? offerId : [offerId],
      target: "sales_widget",
    }).then(({ price, marketing_price, payment_methods }) => {
      setTotalPrice(price);
      setTotalMarketingPrice(marketing_price);
      setPaymentMethods(payment_methods);
    });
  }

  function validateOfferForm() {
    return new Promise((resolve, reject) => {
      if (is_multiple) {
        const minQ = min_quantity || 1;
        if (offerId.length < minQ) {
          const errorText = `Необходимо выбрать минимум ${russianPlural(minQ, ["позицию", "позиции", "позиций"])}`;
          setOfferFormError(errorText);
          reject(new Error(errorText));
          return;
        }
      }

      resolve();
    });
  }

  function handleOfferFormSubmit() {
    setOfferFormDisabled(true);

    return validateOfferForm()
      .then(getTotalPriceAndPaymentMethods)
      .then(() => {
        setOfferFormError(null);
        setIsOfferSelected(true);
      })
      .finally(() => {
        setOfferFormDisabled(false);
      });
  }

  useEffect(() => {
    if (initValue) {
      handleOfferFormSubmit();
    }
  }, [initValue]);

  function handleOfferFormChange(e) {
    let value = e;

    if (!value) {
      value = defaultValue;
    } else if (is_multiple && !Array.isArray(value)) {
      value = [value];
    }

    let result;
    if (Array.isArray(value)) {
      result = offers.filter(o => value.includes(o.id)).map(i => i.id);
      if (!is_multiple) {
        result = _get(result, "[0]");
      }
    } else {
      result =
        _get(
          offers.find(o => o.id === value),
          "id"
        ) || defaultValue;
    }

    setOfferFormError(null);
    setOfferId(result);
  }

  function addByIndex(index) {
    if (is_multiple) {
      const selectedIndices = [];
      offers.forEach((o, idx) => {
        if (offerId.includes(o.id)) {
          selectedIndices.push(idx);
        }
      });

      if (Array.isArray(index)) {
        selectByIndex([...selectedIndices, ...index], true);
      } else {
        selectByIndex([...selectedIndices, index], true);
      }
    } else {
      selectByIndex(index, true);
    }
  }

  function selectByIndex(index, reset = false) {
    if (reset) {
      if (Array.isArray(index)) {
        handleOfferFormChange(index.map(i => _get(offers, `[${i}].id`)));
      } else {
        handleOfferFormChange(_get(offers, `[${index}].id`));
      }
      return;
    }

    addByIndex(index);
  }

  function addById(id) {
    if (is_multiple) {
      if (Array.isArray(id)) {
        handleOfferFormChange([...offerId, ...id]);
      } else {
        handleOfferFormChange([...offerId, id]);
      }
    } else {
      handleOfferFormChange(id);
    }
  }

  function selectById(id, reset = false) {
    if (reset) {
      handleOfferFormChange(id);
      return;
    }

    addById(id);
  }

  useEffect(() => {
    hook &&
      hook.__inject({
        getItems: () => [...offers],
        reset: () => {
          handleOfferFormChange(defaultValue);
          setIsOfferSelected(false);
        },
        resetOrder: () => {
          setOrderId(null);
        },
        selectByIndex,
        selectById,
        submit: handleOfferFormSubmit,
      });
  });

  const [userData, setUserData] = useState({});
  const [userDataReady, setUserDataReady] = useState(true);
  const isAuthorized = useIsAuthorized();
  useEffect(() => {
    if (initUserData) {
      setUserData(initUserData);
      return;
    }

    if (!isAuthorized) {
      setUserData({});
      return;
    }

    setUserDataReady(false);
    getUser()
      .then(setUserData)
      .finally(() => {
        setUserDataReady(true);
      });
  }, [isAuthorized, initUserData]);

  return (
    <StateContext.Provider
      value={{
        isOfferSelected,
        offerId,
        offerFormError,
        offerFormDisabled,

        handleOfferFormSubmit,
        handleOfferFormChange,
        setIsOfferSelected,

        userDataFormDisabled,
        handleUserDataFormSubmit,
        userData,
        userDataReady,
        isAuthorized,

        hasActiveSubscription,
        setHasActiveSubscription,

        paymentMethods,
        totalPrice,
        totalMarketingPrice,
        paymentError,

        setOrderId,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

function SelectOfferFormTotal({ price, marketingPrice, mode }) {
  const isForm = mode === "form";

  return (
    <Box paddingLeft={isForm ? [0, `calc(25px + 0.5rem)`] : 0}>
      <Divider backgroundColor="#666666" h="1px" />

      <Flex justifyContent="flex-end" pt={isForm ? SPACING_10 : SPACING_5}>
        <HStack fontSize={isForm ? "lg" : "md"}>
          <TextAccent fontWeight="bold">Итого:</TextAccent>

          {marketingPrice && (
            <TextAccent fontWeight="bold" textDecor="line-through" whiteSpace="nowrap" className="gt-offer__total-marketing-price">
              {marketingPrice} руб.
            </TextAccent>
          )}

          <TextAccent fontWeight="bold" whiteSpace="nowrap" className="gt-offer__total-price">
            {price} руб.
          </TextAccent>
        </HStack>
      </Flex>
    </Box>
  );
}

function SelectOfferForm({ buttonText }) {
  const { offers, is_multiple } = useContext(DataContext);
  const { offerId, offerFormError, handleOfferFormSubmit, handleOfferFormChange, offerFormDisabled } = useContext(StateContext);

  const GroupComponent = is_multiple ? CheckboxGroup : RadioGroup;
  const ItemComponent = is_multiple ? Checkbox : Radio;

  const selectedOffers = offers.filter(o => offerId.includes(o.id));
  const [totalPrice, totalMarketingPrice] = getTotalPrice(selectedOffers);

  return (
    <Box className="sales-widget__offers">
      <GroupComponent onChange={handleOfferFormChange} value={offerId} size="lg">
        <Stack maxWidth={["100%", "800px"]} spacing={[5, 2]}>
          {offers.map(i => {
            return (
              <Box className="gt-offer" key={i.id}>
                <ItemComponent
                  display="grid"
                  gridTemplateColumns="25px 1fr"
                  alignItems="flex-start"
                  value={i.id}
                  disabled={offerFormDisabled}
                >
                  <Stack
                    direction={["column", "row"]}
                    justify={["flex-start", "space-between"]}
                    alignItems={["flex-start", "baseline"]}
                    spacing={[0, 5]}
                    position="relative"
                    top="-4px"
                  >
                    <VStack align="flex-start" spacing={0}>
                      <TextMain as="span" className="gt-offer__title">
                        {i.title}
                      </TextMain>
                      {i.description && <TextSmall color="#999999">{i.description}</TextSmall>}
                    </VStack>
                    <HStack>
                      {i.marketing_price && (
                        <TextMain textDecor="line-through" whiteSpace="nowrap" className="gt-offer__marketing-price">
                          {i.marketing_price}
                        </TextMain>
                      )}
                      <TextMain whiteSpace="nowrap" className="gt-offer__price">
                        {i.price}
                      </TextMain>
                    </HStack>
                  </Stack>
                </ItemComponent>
              </Box>
            );
          })}

          {is_multiple && <SelectOfferFormTotal price={totalPrice} marketingPrice={totalMarketingPrice} mode="form" />}
        </Stack>
      </GroupComponent>

      {offerFormError && (
        <TextSmall color={COLOR_PINK} textAlign="center">
          {offerFormError}
        </TextSmall>
      )}

      <VStack spacing={0} minW="300px">
        <ButtonPrimary w="100%" onClick={handleOfferFormSubmit} size="lg" disabled={offerFormDisabled}>
          <TextAccent>{buttonText}</TextAccent>
        </ButtonPrimary>
      </VStack>
    </Box>
  );
}

function SelectedOffer({ readOnly }) {
  const { offers, is_multiple } = useContext(DataContext);
  const { offerId, setIsOfferSelected, totalPrice, totalMarketingPrice, setOrderId } = useContext(StateContext);

  const hasMultiplePos = is_multiple && offers.length > 1;
  const selectedOffers = offers.filter(o => offerId.includes(o.id));

  const onChange = () => {
    setIsOfferSelected(false);
    setOrderId(null);
  };

  return (
    <VStack w="100%" maxW="500px" bgColor={COLOR_BLACK_OPACITY_01} p="10px" spacing={SPACING_5} className="sales-widget__selected-offers">
      {selectedOffers.map(offer => {
        return (
          <HStack key={offer.id} align="center" justify="space-between" w="100%">
            <VStack align="flex-start" spacing={0}>
              <TextAccent fontWeight="bold" lineHeight="1.2em">
                {offer.title}
              </TextAccent>
              {offer.description && (
                <TextAccent fontSize="sm" color="#999999">
                  {offer.description}
                </TextAccent>
              )}
            </VStack>

            <VStack align="flex-end" spacing={0}>
              <HStack>
                {offer.marketing_price && (
                  <TextAccent textDecor="line-through" whiteSpace="nowrap" fontWeight="bold">
                    {offer.marketing_price}
                  </TextAccent>
                )}
                <TextAccent whiteSpace="nowrap" fontWeight="bold">
                  {offer.price}
                </TextAccent>
              </HStack>

              {!hasMultiplePos && !readOnly && (
                <TextAccent onClick={onChange} cursor="pointer" textDecor="underline" color="#999999" fontSize="sm">
                  изменить
                </TextAccent>
              )}
            </VStack>
          </HStack>
        );
      })}

      {hasMultiplePos && !readOnly && (
        <Box mt={2} width="100%">
          <SelectOfferFormTotal price={totalPrice} marketingPrice={totalMarketingPrice} />

          <TextAccent onClick={onChange} cursor="pointer" textDecor="underline" color="#666666" fontSize="sm" float="right">
            изменить
          </TextAccent>
        </Box>
      )}
    </VStack>
  );
}

function checkMandatory(fieldName, preset) {
  if (preset === "only_phone") {
    return fieldName === "phone";
  }

  return true;
}

function UserDataForm({ mandatoryFields }) {
  const { paymentMethods, paymentError } = useContext(StateContext);

  const { userDataFormDisabled, handleUserDataFormSubmit, userData, userDataReady } = useContext(StateContext);

  if (!userDataReady) {
    return null;
  }

  return (
    <VStack pb="30px" px={["15px", 0]} w="100%" maxW="500px" className="sales-widget__user-form">
      <Box w="100%">
        <Formik
          initialValues={{
            phone: userData.phone || "",
            email: userData.email || "",
            payment_type: null,
            name: userData.name || "",
            surname: userData.surname || "",
          }}
          onSubmit={handleUserDataFormSubmit}
        >
          {({ setFieldValue, submitForm, values }) => (
            <Box as={Form} w="100%">
              <VStack alignItems="stretch" spacing={5} w="100%">
                <VStack alignItems="stretch">
                  <Field
                    name="phone"
                    validate={
                      checkMandatory("phone", mandatoryFields) ? (userDataFormDisabled || !!userData.phone ? null : validatePhone) : null
                    }
                    className="sales-widget__field-phone"
                  >
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.phone && form.touched.phone}
                        isDisabled={userDataFormDisabled || !!userData.phone}
                      >
                        <FormLabel textAlign="center">Ваш номер телефона:</FormLabel>
                        <InputPhone inputProps={field} formikProps={form} />
                        <FormErrorMessage justifyContent="center">{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field
                    name="email"
                    validate={
                      checkMandatory("email", mandatoryFields) ? (userDataFormDisabled || !!userData.email ? null : validateEmail) : null
                    }
                    className="sales-widget__field-email"
                  >
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                        isDisabled={userDataFormDisabled || !!userData.email}
                      >
                        <FormLabel textAlign="center">Электронная почта:</FormLabel>
                        <InputText {...field} type="email" textAlign="center" />
                        <FormErrorMessage justifyContent="center">{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <VStack alignItems="stretch" spacing={0} className="sales-widget__name-surname">
                    <FormLabel textAlign="center" opacity={userDataFormDisabled ? 0.4 : 1}>
                      Представьтесь, пожалуйста:
                    </FormLabel>
                    <HStack spacing={SPACING_15} align="flex-start">
                      <Field
                        name="name"
                        validate={checkMandatory("name", mandatoryFields) ? validateRequired("Введите имя") : null}
                        className="sales-widget__field-name"
                      >
                        {({ field, form }) => (
                          <FormControl isInvalid={form.errors.name && form.touched.name} isDisabled={userDataFormDisabled}>
                            <InputText {...field} placeholder="Ваше имя..." _placeholder={{ color: "#999999" }} />
                            <FormErrorMessage justifyContent="center">{form.errors.name}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name="surname"
                        validate={checkMandatory("surname", mandatoryFields) ? validateRequired("Введите фамилию") : null}
                        className="sales-widget__field-surname"
                      >
                        {({ field, form }) => (
                          <FormControl isInvalid={form.errors.surname && form.touched.surname} isDisabled={userDataFormDisabled}>
                            <InputText {...field} placeholder="Фамилия..." _placeholder={{ color: "#999999" }} />
                            <FormErrorMessage justifyContent="center">{form.errors.surname}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </HStack>
                  </VStack>
                </VStack>

                {paymentError && (
                  <TextSmall color={COLOR_PINK} textAlign="center">
                    {paymentError}
                  </TextSmall>
                )}

                <SimpleGrid columns={[1, 2]} justifyContent="center" w="100%" spacing={SPACING_15}>
                  {paymentMethods.map(pm => {
                    return (
                      <ButtonPrimary
                        key={pm.type}
                        type="button"
                        size="lg"
                        isLoading={userDataFormDisabled && values.payment_type === pm.type}
                        isDisabled={pm.disabled || userDataFormDisabled}
                        height={pm.title ? "65px" : null}
                        onClick={async () => {
                          await setFieldValue("payment_type", pm.type);
                          submitForm();
                        }}
                        color={pm.color || "#ffffff"}
                        bgColor={pm.bg_color || COLOR_PINK}
                        _active={{ opacity: 0.5 }}
                        _hover={{ opacity: 0.75 }}
                      >
                        <VStack spacing={0}>
                          <TextAccent>{pm.title}</TextAccent>
                          <TextSmall>{pm.subtitle}</TextSmall>
                        </VStack>
                      </ButtonPrimary>
                    );
                  })}
                </SimpleGrid>
              </VStack>
            </Box>
          )}
        </Formik>
      </Box>
    </VStack>
  );
}

function SalesWidgetView({ title, buttonText, initValue, mandatoryFields }) {
  const { isOfferSelected, hasActiveSubscription, setHasActiveSubscription } = useContext(StateContext);

  return (
    <Fragment>
      <HasSubscriptionModal
        isOpen={hasActiveSubscription}
        onClose={() => {
          setHasActiveSubscription(false);
        }}
      />

      <VStack spacing={SPACING_30}>
        {title && <Heading3 className="sales-widget__title">{title}</Heading3>}
        {isOfferSelected && <SelectedOffer readOnly={initValue} />}
        {!isOfferSelected && !initValue && <SelectOfferForm buttonText={buttonText} />}
        {isOfferSelected && <UserDataForm mandatoryFields={mandatoryFields} />}
      </VStack>
    </Fragment>
  );
}

const DEFAULT_TITLE = "Выберите вариант участия:";
const DEFAULT_BUTTON_TEXT = "Продолжить";

export function SalesWidget({
  id,
  title = DEFAULT_TITLE,
  buttonText = DEFAULT_BUTTON_TEXT,
  hook,
  onPaymentSelect,
  initValue,
  orderId,
  initUserData,
  mandatoryFields,
}) {
  return (
    <DataContextProvider id={id} id_order={orderId}>
      <StateContextProvider
        id={id}
        hook={hook}
        onPaymentSelect={onPaymentSelect}
        initValue={initValue}
        initOrderId={orderId}
        initUserData={initUserData}
      >
        <SalesWidgetView title={title} buttonText={buttonText} initValue={initValue} mandatoryFields={mandatoryFields} />
      </StateContextProvider>
    </DataContextProvider>
  );
}
