import styles from './author.module.css';

const Author = () => {
    return (
        <div className={styles.author}>
            <div className="container">
                <div className={styles.author_title}>Автор марафонов и&nbsp;всех тренировок</div>
                <div className={styles.author_subtitle}>ЕКАТЕРИНА<br/> УСМАНОВА</div>
                <div className={styles.author_info}>
                    <p>Помогает исполнять <strong>ВАШИ мечты о&nbsp;стройной фигуре</strong>&nbsp;— скинуть до&nbsp;5&nbsp;кг за&nbsp;месяц, подтянуть все&nbsp;проблемные зоны и&nbsp;сделать тело более&nbsp;рельефным.</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/slimstomach/author.jpg" alt="" />
                    <p><strong>С&nbsp;ней всё реально, ведь она:</strong></p>
                    <div className={styles.author_block}>
                        <div className={styles.author_item}>
                            <ul>
                                <li>Создатель платформы <strong>GymTeam</strong></li>
                                <li>Вице-чемпионка мира <strong>и&nbsp;чемпионка России</strong> по&nbsp;фитнес-бикини</li>
                                <li>Мама, которая всего <strong>за&nbsp;100&nbsp;дней</strong> после первых родов <strong>похудела на&nbsp;20&nbsp;кг</strong> и&nbsp;вернулась в&nbsp;прежнюю форму</li>
                            </ul>
                        </div>
                        <div className={styles.author_item}>
                            <ul>
                                <li><strong>Чемпионка России и&nbsp;мира</strong> по&nbsp;жиму лёжа</li>
                                <li>Автор первых в&nbsp;России масштабных <strong>марафонов стройности</strong></li>
                                <li>Профессиональный фитнес-тренер с&nbsp;опытом <strong>более&nbsp;15&nbsp;лет</strong></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Author;