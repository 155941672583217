import { useLayoutEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import styles from './about.module.css';

const About = () => {
    const [isMobile, setIsMobile] = useState(null)
      
    useLayoutEffect(() => {
        function updateSize() {
            window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false)
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.about}>
            <div className="container">
                <div className={styles.about_title}>О&nbsp;платформе gymteam</div>
                <div className={styles.about_subtitle}>Каждый сотрудник вне&nbsp;зависимости от&nbsp;пола, возраста<br/> и&nbsp;физической подготовки найдёт для&nbsp;себя тренировку!</div>

                {!isMobile 
                ?
                <div className={styles.about_block}>
                    <div className={styles.about_item}>
                        <div className={styles.about_info_1}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about1.png" alt="" /></div>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about2.png" alt="" /></div>
                        </div>
                        <div className={styles.about_text}>Более 20&nbsp;направлений спорта</div>
                    </div>
                    <div className={styles.about_item}>
                        <div className={styles.about_info_2}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about3.png" alt="" /></div>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about4.png" alt="" /></div>
                        </div>
                        <div className={styles.about_text}>1500&nbsp;тренировок от&nbsp;10&nbsp;до&nbsp;90&nbsp;минут</div>
                    </div>
                    <div className={styles.about_item}>
                        <div className={styles.about_info_3}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about5_2.png" alt="" /></div>
                        </div>
                        <div className={styles.about_text}>Избранные тренеры с&nbsp;российским и&nbsp;зарубежным образованием. Опытом работы от&nbsp;10&nbsp;лет</div>
                    </div>
                    <div className={styles.about_item}>
                        <div className={styles.about_info_4}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about6.png" alt="" /></div>
                        </div>
                        <div className={styles.about_text}>Возможность организовывать фитнесс-челенджи для&nbsp;развития корпоративного духа</div>
                    </div>
                    <div className={styles.about_item}>
                        <div className={styles.about_info_5}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about7.png" alt="" /></div>
                        </div>
                        <div className={styles.about_text}>Тренировки на&nbsp;smart&nbsp;TV, мобильных&nbsp;телефонах, планшетах и&nbsp;компьютерах</div>
                    </div>
                    <div className={styles.about_item}>
                        <div className={styles.about_info_6}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about8.png" alt="" /></div>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about9.png" alt="" /></div>
                        </div>
                        <div className={styles.about_text}>Возможность организовывать фитнесс-челенджи для&nbsp;развития корпоративного духа</div>
                    </div>
                    <div className={styles.about_item}>
                        <div className={styles.about_info_7}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about10.png" alt="" /></div>
                        </div>
                        <div className={styles.about_text}>Доступ из&nbsp;любой точки мира<br/> и&nbsp;даже без&nbsp;интернета</div>
                    </div>
                    <div className={styles.about_item}>
                        <div className={styles.about_info_8}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about11.png" alt="" /></div>
                        </div>
                        <div className={styles.about_text}>Возможность брендирования</div>
                    </div>
                </div>
                :
                <div>
                <Swiper
                    speed={800}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                        
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
                    modules={[Navigation]}
                    className={styles.swiper}
                >
                    <SwiperSlide className={styles.slide}>
                        <div className={styles.about_item}>
                            <div className={styles.about_info_1}>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about1.png" alt="" /></div>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about2.png" alt="" /></div>
                            </div>
                            <div className={styles.about_text}>Более 20&nbsp;направлений спорта</div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <div className={styles.about_item}>
                            <div className={styles.about_info_2}>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about3.png" alt="" /></div>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about4.png" alt="" /></div>
                            </div>
                            <div className={styles.about_text}>1500&nbsp;тренировок от&nbsp;10&nbsp;до&nbsp;90&nbsp;минут</div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <div className={styles.about_item}>
                            <div className={styles.about_info_3}>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about5_2.png" alt="" /></div>
                            </div>
                            <div className={styles.about_text}>Избранные тренеры с&nbsp;российским и&nbsp;зарубежным образованием. Опытом работы от&nbsp;10&nbsp;лет</div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <div className={styles.about_item}>
                            <div className={styles.about_info_4}>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about6.png" alt="" /></div>
                            </div>
                            <div className={styles.about_text}>Возможность организовывать фитнесс-челенджи для&nbsp;развития корпоративного духа</div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <div className={styles.about_item}>
                            <div className={styles.about_info_5}>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about7.png" alt="" /></div>
                            </div>
                            <div className={styles.about_text}>Тренировки на&nbsp;smart&nbsp;TV, мобильных&nbsp;телефонах, планшетах и&nbsp;компьютерах</div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <div className={styles.about_item}>
                            <div className={styles.about_info_6}>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about8.png" alt="" /></div>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about9.png" alt="" /></div>
                            </div>
                            <div className={styles.about_text}>Возможность организовывать фитнесс-челенджи для&nbsp;развития корпоративного духа</div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <div className={styles.about_item}>
                            <div className={styles.about_info_7}>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about10.png" alt="" /></div>
                            </div>
                            <div className={styles.about_text}>Доступ из&nbsp;любой точки мира<br/> и&nbsp;даже без&nbsp;интернета</div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <div className={styles.about_item}>
                            <div className={styles.about_info_8}>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/about11.png" alt="" /></div>
                            </div>
                            <div className={styles.about_text}>Возможность брендирования</div>
                        </div>
                    </SwiperSlide>
                </Swiper>

                <div className={`swiper_corporate_nav ${styles.slider_nav}`}>
                    <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/arrow.png" alt="" />
                    </div>
                    <div ref={navigationNextRef} className={styles.slider_btn_next}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/arrow.png" alt="" />
                    </div>
                </div>

                </div>
                }
            </div>
        </div>
    );
};

export default About;