import styles from './prizes.module.css';

const Already = () => {
    return (
        // <div className={styles.prizes_already}>
        //     <div className={styles.prizes_already_title}>Вы уже забрали свой приз</div>
        //     <div className={styles.prizes_already_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prizes.png" alt="" /></div>
        // </div>
        <div className={styles.prizes_hide}>
            <div className={styles.prizes_hide_top}>
                <div className={styles.pretitle}>Черная пятница</div>
                <div className={styles.prizes_already_title}>Извините,<br/> вам недоступен<br/> розыгрыш призов</div>
                {/* <div className={styles.prizes_hide_title}>Вас ждёт гарантированный приз!<br/> Скорее выбирайте один из&nbsp;боксов<br/> и&nbsp;узнайте, что достанется<br/> именно вам.</div> */}
            </div>
            <div className={styles.prizes_hide_bottom}>
                <div className={styles.prizes_hide_info}>
                    <div className={styles.prizes_hide_info_number}>№1</div>
                    <div className={styles.prizes_hide_info_text}>Если вы&nbsp;купили комплект программ и&nbsp;уже использовали свой шанс, то&nbsp;откройте почту&nbsp;&mdash; там вы&nbsp;найдёте письмо с&nbsp;информацией о&nbsp;подарке.</div>
                </div>
                <div className={styles.prizes_hide_info}>
                    <div className={styles.prizes_hide_info_number}>№2</div>
                    <div className={styles.prizes_hide_info_text}>Если вы&nbsp;ещё не&nbsp;купили фитнес-программы Кати Усмановой, но&nbsp;хотите участвовать в&nbsp;розыгрыше призов, то&nbsp;нажмите кнопку ниже, чтобы участвовать.</div>
                </div>
                <div className={styles.prizes_hide_btns}>
                    {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/eight.png" alt="" /> */}
                    <a href="/l/blackfriday2024" className={styles.prizes_hide__btn}>Забрать набор</a>
                </div>
                
            </div>
        </div>
    )
}

export default Already;