import styles from './reserve.module.css';

const Reserve = ({user, onOpen}) => {
    return (
        <div className={styles.preims}>
            <div className="container">
                <div className={styles.block}>
                    <div className={styles.left}>
                        <div className={styles.title}>
                            <p>Забронируй место</p>
                            <p><span>прямо сейчас&nbsp;&mdash;</span></p>
                        </div>
                        <div className={styles.text}>это гарантия самых выгодных<br/> условий на&nbsp;открытии продаж</div>
                        <div className={`${user && styles.btn_off} ${styles.btn}`} onClick={onOpen}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            {user && styles.btn_off ? <p>Место успешно<br/> забронировано</p> : <p>Записаться</p> }
                        </div>
                    </div>
                    <div className={styles.right}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/reserv.png" alt="" />
                        <div className={styles.remains}>
                            <p>Осталось мест:</p>
                            <span>{window.lps_settings.other.count}</span>
                        </div>
                        <div className={`${user && styles.btn_off} ${styles.btn}`} onClick={onOpen}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            {user && styles.btn_off ? <p>Место успешно<br/> забронировано</p> : <p>Записаться</p> }
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default Reserve;