import React, { useEffect} from 'react';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './Promo';
import Programs from './Programs';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_08_Allprogramms = () => {

    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    
    return (
      <>
        <DocumentTitle value='Фитнес-программы Кати Усмановой - любой курс или марафон под вашу цель'/>
        <DocumentDescription value='Авторские программы для дома и зала Кати Усмановой под разные цели и для любого уровня подготовки' />
        <Promo scroll={scroll} />
        <Programs />
        <Footer version={7} isColor={'#F5ECFF'} />
      </>
    );
};