import Countdown from 'react-countdown';
import styles from './block4.module.css';

const Block4 = ({scroll, renderer, date}) => {
    return (
        <div className={styles.block4}>
            <div className="container">
               {/* <div className={styles.b1}>
                    <div className={styles.b1_1}>Выиграй Apple Watch SE!</div>
                    <div className={styles.b1_2}>4&nbsp;июня в&nbsp;12:00 МСК</div>
                    <div className={styles.b1_3}>разыграем Apple&nbsp;Watch&nbsp;SE среди<br/> всех купивших любую программу!</div>
                    <div className={styles.b1_mob}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/4/prize.png" alt="" /></div>
                    <div className={styles.b1_4}>Но&nbsp;и&nbsp;это ещё не&nbsp;всё!</div>
                    <div className={styles.b1_5}>Каждый день мы&nbsp;рандомно<br/> выбираем и&nbsp;<span>дарим по&nbsp;10&nbsp;000&nbsp;₽</span><br/> двум купившим программы</div>
               </div>

               <div className={styles.t1}>Как проходит розыгрыш<br/> денежных призов?</div>
               <div className={styles.b2}>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/b2_1.png" alt="" />
                        <p>Ты&nbsp;оформляешь покупку<br/> любой программы по&nbsp;специальному предложению</p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/b2_2.png" alt="" />
                        <p>Каждый день мы&nbsp;выбираем<br/> 2-х победителей с&nbsp;помощью<br/> генератора случайных чисел</p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/b2_3.png" alt="" />
                        <p>В&nbsp;течение 24&nbsp;часов<br/> с&nbsp;победителем связывается<br/> наш менеджер и&nbsp;сообщает,<br/> как получить 10&nbsp;000&nbsp;₽</p>
                    </div>
               </div> */}

               <div className={styles.sticky}>
                    <div className={styles.sticky_left}>
                        <div className={styles.sticky_sticky}>
                            {/* <div className={styles.s1}>Суперподарков много не&nbsp;бывает!</div> */}
                            {/* <div className={styles.s4}>
                                <p>Бонус №2</p>
                                <span><em>7&nbsp;990&nbsp;₽.</em> БЕСПЛАТНО</span>
                            </div> */}
                            <div className={styles.s2}>ДАРИМ 10&nbsp;РЕАЛЬНО<br/> <span>ПОЛЕЗНЫХ ГАЙДОВ</span></div>
                            <div className={styles.s3}>При покупке комплекта программ<br/> вы&nbsp;получаете 10&nbsp;мегаполезных гайдов<br/> для поддержания результата</div>
                            {/* <div className={styles.promo_btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Получить программы и&nbsp;бонусы</p>
                            </div>
                            <div className={styles.promo_countdown}>
                                <p>Успей забрать любые программы<br/> под свою цель и&nbsp;получить ВСЕ бонусы:</p>
                                <div className={styles.promo_countdown_timer}>
                                    <Countdown date={Date.now() + 900000} renderer={renderer} />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className={styles.sticky_right}>
                        <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Как избежать травм:<br/> что нужно знать<br/> о&nbsp;правильной технике</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_1.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Мини-курс </span>
                            <p>Простые привычки,<br/> которые помогут<br/> поддерживать форму</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_2.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>сборник </span>
                            <p>Книга рецептов<br/> Кати Усмановой</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_3.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Как начать день,<br/> чтобы сжигать больше<br/> калорий</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_4.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Инструкция </span>
                            <p>Как создать тонкую<br/> талию</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_5.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Как запустить активное<br/> жиросжигание</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_6.png" alt="" />
                        </div>
                        {/* <div className={styles.sticky_item}>
                            <span>Путеводитель </span>
                            <p>Прохождение программ<br/> на&nbsp;год</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_7.png" alt="" />
                        </div> */}
                        <div className={styles.sticky_item}>
                            <span>Руководство </span>
                            <p>Всё про планку</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_8.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Чек-лист </span>
                            <p>Секреты фитнес-режима<br/> для занятых женщин</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_9.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Как восстановиться<br/> после перерыва<br/> в&nbsp;тренировках</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_10.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Инструкция </span>
                            <p>Источники энергии:<br/> как всегда быть<br/> на&nbsp;пике энергии</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_11.png" alt="" />
                        </div>
                    </div>
                    {/* <div className={styles.st_mob}>
                        <div className={styles.promo_btn} onClick={scroll}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Выбрать программы</p>
                        </div>
                        <div className={styles.promo_countdown}>
                            <p>Успей забрать любые программы<br/> под свою цель и&nbsp;получить ВСЕ бонусы:</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + 900000} renderer={renderer} />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Block4;