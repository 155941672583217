import styles from './points.module.css';

const Points = () => {
    return (
        <div className={styles.points}>
            <div className="container">
                <div className={styles.points_title}><span>Цели программы</span><br/> с&nbsp;эффектом липосакции<br/> за&nbsp;30&nbsp;дней</div>
                <div className={styles.points_block}>
                    <div className={styles.points_item}>
                        <div className={styles.points_info}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/points_1.png" alt="" />
                            <p><strong>Подтяжка кожи</strong> бедер, рук<br/> и&nbsp;живота</p>
                        </div>
                    </div>
                    <div className={styles.points_item}>
                        <div className={styles.points_info}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/points_2.png" alt="" />
                            <p><strong>Скульптурирование тела:</strong><br/> убираем жир оттуда,<br/> где&nbsp;он не&nbsp;нужен</p>
                        </div>
                    </div>
                    <div className={styles.points_item}>
                        <div className={styles.points_info}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/points_3.png" alt="" />
                            <p>Сжигание <strong>минимум<br/> 1&nbsp;размера</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Points;