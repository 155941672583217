import styles from './trainings.module.css';

const Trainings = ({scroll}) => {
    return (
        <div className={styles.trainings}>
            <div className="container">
                <div className={styles.trainings_title}>Как мы будем подбирать<br/> для&nbsp;вас тренировки?</div>
                <div className={styles.trainings_subtitle}>Вы получаете индивидуальное решение</div>
                <div className={styles.trainings_block}>
                    <div className={styles.trainings_item}>
                        <div className={styles.trainings_number}>1</div>
                        <div className={styles.trainings_text}>Вы заполняете анкету</div>
                    </div>
                    <div className={styles.trainings_item}>
                        <div className={styles.trainings_number}>2</div>
                        <div className={styles.trainings_text}>Наш специалист изучает ваши ответы и&nbsp;создаёт индивидуальную программу тренировок на&nbsp;7&nbsp;дней на&nbsp;основе анкеты</div>
                    </div>
                    <div className={styles.trainings_item}>
                        <div className={styles.trainings_number}>3</div>
                        <div className={styles.trainings_text}>Вы получаете свою программу и&nbsp;приступаете к&nbsp;тренировкам</div>
                    </div>
                </div>
                <div className={styles.trainings_info}>
                    <div className={styles.trainings_info_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/training.png" alt="" />
                    </div>
                    <div className={styles.trainings_info_text}>Мы понимаем, что&nbsp;каждый человек уникален, поэтому наш подход основан на&nbsp;учете ваших целей, физической подготовки, предпочтений и&nbsp;ограничений.</div>
                </div>
                <div className={styles.trainings_title2}>Готовы принять вызов?</div>
                <div className={styles.trainings_btn} onClick={scroll}>Принять вызов</div>
            </div>
        </div>
    );
};

export default Trainings;