import { useCallback, useEffect, useRef, useState } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react'
import {Lazy, Navigation} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import styles from './studentres.module.css';

const Studentres = () => {
    const [count, setCount] = useState(15)
    const [mobV, setMobV] = useState(false)
    const [items, setItems] = useState([
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/5.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/6.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/7.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/8.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/9.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/10.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/11.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/12.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/13.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/14.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/15.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/16.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/17.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/18.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/19.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/20.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/21.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/22.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/23.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/24.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/25.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/26.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/27.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/28.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/29.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/30.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/31.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/32.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/33.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/34.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/35.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/36.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/37.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/38.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/39.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/40.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/41.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/42.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/43.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/44.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/45.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/46.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/47.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/48.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/49.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/50.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/51.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/52.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/53.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/54.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/55.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/56.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/57.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/58.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/59.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/60.jpg'}
    ]);

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const getItems = useCallback(() => {
        return items.map((item, index) => {
            if(index+1 <= count) {
            return (
                <a key={item.src} className={styles.gallery_item} data-src={item.src}>
                    <img src={item.src} alt={item.src} loading="lazy" />
                </a>
            );
            }
        });
    }, [count]);

    const showMore = () => {
        if(count > 60) {
            setCount(15)
            const section = document.querySelector( '.scrollstart' );
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        } else {
            setCount(count + 15)
        }
    }
      
        useEffect(() => {
          function handleResize() {
            window.innerWidth < 1200 ? setMobV(true) : setMobV(false)
          }
          handleResize();
          window.addEventListener("resize", handleResize);
          return () => window.removeEventListener("resize", handleResize);
        }, []);
    
    return (
        <div className={styles.studentres + ' scrollstart'}>
            <div className="container">
                <div className={styles.studentres_title}>Результаты наших учениц&nbsp;—</div>
                <div className={styles.studentres_subtitle}>закономерность и&nbsp;доказательство эффективности,<br/> ведь <span>80% довольны своими «До/После»</span></div>
                {!mobV ? 
            <>
                <LightGallery
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    mode="lg-fade"
                    elementClassNames="gallery_block__perezagruzka_2302"
                >
                {getItems()}
                </LightGallery>
                <button className={styles.studentres_btn} onClick={showMore}><p>{count>60 ? 'Скрыть изображения' : 'Показать еще'}</p></button>
            </>
            : 
            <>
                <Swiper 
                    lazy={true}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    loop
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                        
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
                    slidesPerView={1}
                    modules={[Navigation, Lazy]}
                >
                    <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}></div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}></div>
                    </div>
                    {items.map(item => 
                    <SwiperSlide key={item.src}>
                        <img data-src={item.src} alt="" className='swiper-lazy' />
                    </SwiperSlide>
                    )}
                </Swiper>
            </>
            }
            </div>
        </div>
    )
}

export default Studentres;