import React from "react";
import { Text as CUText } from "@chakra-ui/react";
import { FONT_SIZE_16, FONT_SIZE_20 } from "../../constants/styles";

function TextBase(props) {
  return <CUText whiteSpace="break-spaces" {...props} />;
}

export function TextMain(props) {
  return <TextBase fontSize={FONT_SIZE_20} fontWeight="300" {...props} />;
}

export function TextAccent(props) {
  return <TextMain fontWeight="500" {...props} />;
}

export function TextSmall(props) {
  return <TextBase fontSize={FONT_SIZE_16} fontWeight="400" {...props} />;
}

export const TEXT_TYPE_MAIN = "main";
export const TEXT_TYPE_ACCENT = "accent";
export const TEXT_TYPE_SMALL = "small";

export function Text({ type, ...props }) {
  switch (type) {
    case TEXT_TYPE_MAIN:
      return <TextMain {...props} />;
    case TEXT_TYPE_ACCENT:
      return <TextAccent {...props} />;
    case TEXT_TYPE_SMALL:
      return <TextSmall {...props} />;
    default:
      return <TextBase {...props} />;
  }
}
