import React, { Fragment, createContext, useContext, useState, useEffect } from "react";
import { HStack, Icon, VStack, Box, SimpleGrid } from "@chakra-ui/react";
import { CaretRight, CaretLeft } from "phosphor-react";
import { fetchWithN8n2, openNewTab, URLSearchParamsService, useN8n2 } from "@com.marathonium/web2-utils";

import { AuthContext, AuthProvider } from "../AuthProvider/AuthProvider";
import { TextAccent, TextMain, TextSmall } from "../Text";
import { RuCard } from "../../icons/RuCard";
import { WorldCard } from "../../icons/WorldCard";
import { Tinkoff } from "../../icons/Tinkoff";
import { HasSubscriptionModal } from "../common/HasSubscriptionModal";
import {
  BORDER_RADIUS_40,
  COLOR_PINK,
  COLOR_WHITE,
  FONT_SIZE_16,
  FONT_SIZE_18,
  FONT_SIZE_20,
  FONT_SIZE_28,
  FONT_SIZE_32,
  FONT_SIZE_48,
  SPACING_10,
  SPACING_20,
  SPACING_30,
  SPACING_35,
  SPACING_40,
  SPACING_5,
} from "../../constants";

function OfferList({ activeIdx, onSelectOffer, platform, disabled, ...props }) {
  const { loading, data: offers, error } = useN8n2("web.offer_subscribe.list_by_platform.v1", { platform });

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  return (
    <VStack className="gt-subsription__offer-list" spacing={SPACING_20} {...props}>
      <TextMain fontSize={FONT_SIZE_20} fontWeight="600">
        Выберите тариф:
      </TextMain>

      {offers.map((o, idx) => (
        <HStack
          key={o.id}
          onClick={disabled ? null : () => onSelectOffer(o.id)}
          p={SPACING_30}
          pl={SPACING_40}
          backgroundColor={activeIdx === idx ? COLOR_PINK : "#ECECEC"}
          color={activeIdx === idx ? COLOR_WHITE : "#121212"}
          borderRadius={BORDER_RADIUS_40}
          cursor={disabled ? "not-allowed" : "pointer"}
          w="100%"
          justify="space-between"
          opacity={disabled ? 0.5 : 1}
        >
          <VStack spacing={SPACING_10} align="flex-start">
            <TextAccent fontSize={FONT_SIZE_28}>{o.title}</TextAccent>
            <TextSmall fontSize={FONT_SIZE_18}>{o.subtitle}</TextSmall>
          </VStack>

          <Icon as={CaretRight} fontSize={FONT_SIZE_32} />
        </HStack>
      ))}
    </VStack>
  );
}

function PaymentMethodIcon({ type }) {
  if (type === "RuCard") {
    return <RuCard fontSize={FONT_SIZE_48} />;
  }

  if (type === "WorldCard") {
    return <WorldCard fontSize={FONT_SIZE_48} />;
  }

  if (type === "Tinkoff") {
    return <Tinkoff fontSize={FONT_SIZE_48} />;
  }

  return null;
}

function PaymentMethods({ items, disabled, onClick, onCancel, ...props }) {
  return (
    <VStack spacing={SPACING_20} className="gt-subsription__payment" {...props}>
      <Box position="relative" w="100%" textAlign="center">
        <HStack spacing={SPACING_5} position="absolute" left={0} cursor="pointer" onClick={onCancel}>
          <Icon as={CaretLeft} fontSize={FONT_SIZE_16} />
          <TextMain fontSize={FONT_SIZE_20}>Назад</TextMain>
        </HStack>

        <TextMain fontSize={FONT_SIZE_20} fontWeight="600">
          Выберите способ оплаты:
        </TextMain>
      </Box>

      <VStack w="100%" spacing={SPACING_20} className="gt-subsription__payment-list">
        {items.map(p => {
          return (
            <HStack
              key={p.type}
              onClick={disabled || p.disabled ? null : () => onClick(p.type)}
              p={SPACING_30}
              backgroundColor={"#ECECEC"}
              color={"#121212"}
              borderRadius={BORDER_RADIUS_40}
              cursor={disabled || p.disabled ? "not-allowed" : "pointer"}
              w="100%"
              justify="space-between"
              opacity={disabled || p.disabled ? 0.3 : 1}
            >
              <HStack spacing={SPACING_35}>
                <PaymentMethodIcon type={p.icon} />

                <VStack spacing={SPACING_10} align="flex-start">
                  <TextAccent fontSize={FONT_SIZE_28}>{p.title}</TextAccent>
                  <TextSmall fontSize={FONT_SIZE_18}>{p.subtitle}</TextSmall>
                </VStack>
              </HStack>

              <Icon as={CaretRight} fontSize={FONT_SIZE_32} />
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
}

function SubscriptionWidgetView({ platform }) {
  const {
    activeIdx,
    onSelectOffer,
    isOfferListDisabled,
    isOfferSelected,
    isPaymentDisabled,
    handlePayment,
    paymentMethods,
    goToOfferList,
    hasActiveSubscription,
    setHasActiveSubscription,
  } = useContext(StateContext);

  return (
    <Fragment>
      <HasSubscriptionModal
        isOpen={hasActiveSubscription}
        onClose={() => {
          setHasActiveSubscription(false);
        }}
      />

      <Box w="100%" overflow="hidden">
        <SimpleGrid columns={2} w="200%" transform={isOfferSelected ? "translateX(-50%)" : "translateX(0%)"} transition="transform 0.3s">
          <OfferList
            activeIdx={activeIdx}
            onSelectOffer={onSelectOffer}
            platform={platform}
            disabled={isOfferListDisabled}
            opacity={isOfferSelected ? 0 : 1}
            transition="opacity 0.5s"
          />
          <PaymentMethods
            items={paymentMethods}
            disabled={isPaymentDisabled}
            onClick={handlePayment}
            onCancel={goToOfferList}
            opacity={isOfferSelected ? 1 : 0}
            transition="opacity 0.5s"
          />
        </SimpleGrid>
      </Box>
    </Fragment>
  );
}

const StateContext = createContext();

const StateContextProvider = ({ children, hook, activeIdx: initActiveIdx, platform }) => {
  const [activeIdx, setActiveIdx] = useState(initActiveIdx);
  const [isOfferListDisabled, setIsOfferListDisabled] = useState(false);
  const [isOfferSelected, setIsOfferSelected] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [isPaymentDisabled, setIsPaymentDisabled] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    hook &&
      hook.__inject({
        setActiveIdx,
      });
  });

  const { login } = useContext(AuthContext);

  function onSelectOffer(id_offer_subscribe) {
    const params = { id_offer_subscribe, platform, ...URLSearchParamsService.load() };

    function createOrder(params) {
      setIsOfferListDisabled(true);
      return fetchWithN8n2("web.order.create_by_id_offer_subscribe.v1", params)
        .then(o => {
          if (o.has_subscription) {
            setHasActiveSubscription(true);
            return;
          }

          setIsOfferSelected(true);
          setOrderId(o.id_order);
          setPaymentMethods(o.payment_methods);
        })
        .finally(() => {
          setIsOfferListDisabled(false);
        });
    }

    createOrder(params).catch(e => {
      if (e.code === 401) {
        return login(() => {
          createOrder(params);
        });
      }

      console.error(e);
    });
  }

  function handlePayment(payment_type) {
    setIsPaymentDisabled(true);

    fetchWithN8n2("web.payment_link.create.v1", { payment_type, id_order: orderId })
      .then(({ link }) => {
        openNewTab(link);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        setIsPaymentDisabled(false);
      });
  }

  function goToOfferList() {
    setIsOfferSelected(false);
  }

  return (
    <StateContext.Provider
      value={{
        activeIdx,
        onSelectOffer,
        isOfferListDisabled,
        isOfferSelected,
        isPaymentDisabled,
        handlePayment,
        paymentMethods,
        hasActiveSubscription,
        setHasActiveSubscription,
        goToOfferList,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export function SubscriptionWidget({ activeIdx = 0, platform = "landings", hook, onLogin }) {
  return (
    <AuthProvider onSuccess={onLogin}>
      <StateContextProvider hook={hook} activeIdx={activeIdx} platform={platform}>
        <SubscriptionWidgetView platform={platform} />
      </StateContextProvider>
    </AuthProvider>
  );
}
