import styles from './inventory.module.css';

const Inventory = () => {
    return (
        <div className={styles.inventory}>
            <div className="container">
                <div className={styles.inventory_title}><span>КАКОЙ ИНВЕНТАРЬ</span><br/> НУЖЕН ДЛЯ&nbsp;ТРЕНИРОВОК?</div>
                <div className={styles.inventory_subtitle}>Этого набора хватит на&nbsp;всю&nbsp;жизнь!</div>
                <div className={styles.inventory_block}>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/inv_1.png" alt="" />
                        <p>Коврик<br/> (или&nbsp;аналог)</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/inv_2.png" alt="" />
                        <p>Разборные гантели<br/> до&nbsp;15&nbsp;кг</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/inv_3.png" alt="" />
                        <p>Утяжелители 2&nbsp;шт.<br/> по&nbsp;1,5-3&nbsp;кг</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inventory;