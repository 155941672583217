import styles from './onmarafon.module.css';

const Onmarafon = () => {
    return (
        <div className={styles.onmarafon}>
            <div className="container">
                <div className={styles.onmarafon_title}>НА МАРАФОНЕ <span>НЕ&nbsp;БУДЕТ:</span></div>
                <div className={styles.onmarafon_block}>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/onm_1.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Бесконечных приседаний</strong><br/> по&nbsp;100 повторений в&nbsp;течение подхода</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/onm_2.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Упражнения «стульчик»</strong>&nbsp;— 2&nbsp;минуты<br/> у&nbsp;стены до&nbsp;мышечного</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/onm_3.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Штанги весом 50&nbsp;кг</strong>, без&nbsp;которой<br/> «мышцы не&nbsp;растут»</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/onm_4.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Одинаковых тренировок</strong>, которые<br/> заставят вас заскучать</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onmarafon;