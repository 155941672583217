import styles from './what.module.css';

const What = () => {
    return (
        <div className={styles.what}>
            <div className="container">
                <div className={styles.what_title}>Что вас ждёт<br/> на&nbsp;жиросжигающей неделе</div>
                <div className={styles.what_block}>
                    <div className={styles.what_item}>
                        <div className={styles.what_info}>
                            <div className={styles.what_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/what_1.png" alt="" /></div>
                            <div className={styles.what_text}>Индивидуальный подход</div>
                            <div className={styles.what_text2}>Поставьте цель и&nbsp;мы подберём для&nbsp;вас идеальные тренировки, которые помогут быстрее достичь желаемого. Мы позаботимся, чтобы&nbsp;тренировочный процесс был безопасен для&nbsp;вас и&nbsp;вашего организма</div>
                        </div>
                    </div>
                    {/* <div className={styles.what_item}>
                        <div className={styles.what_info}>
                            <div className={styles.what_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/what_2.png" alt="" /></div>
                            <div className={styles.what_text}>Советы по&nbsp;питанию</div>
                            <div className={styles.what_text2}>Похудение&nbsp;— это&nbsp;на&nbsp;80% питание и&nbsp;на&nbsp;20% тренировки. Мы дадим рекомендации, в&nbsp;основе которых не&nbsp;будет лежать ежедневное страдание</div>
                        </div>
                    </div> */}
                    <div className={styles.what_item}>
                        <div className={styles.what_info}>
                            <div className={styles.what_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/what_3.png" alt="" /></div>
                            <div className={styles.what_text}>Тренировки с&nbsp;постепенно<br/> возрастающей нагрузкой</div>
                            <div className={styles.what_text2}>Вы точно не&nbsp;сдадитесь в&nbsp;первый день, мы сделали мягкий процесс привыкания к&nbsp;тренировкам</div>
                        </div>
                    </div>
                    <div className={styles.what_item}>
                        <div className={styles.what_info}>
                            <div className={styles.what_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/what_4.png" alt="" /></div>
                            <div className={styles.what_text}>Мотивация</div>
                            <div className={styles.what_text2}>Советы от&nbsp;профессиональных тренеров, чтобы вы точно смогли прийти к&nbsp;формам мечты</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default What;