import styles from './press.module.css';

const Press = () => {
    return (
        <div className={styles.press}>
            <div className="container">
                <div className={styles.press_title}>ПРОКАЧАЙТЕ ПРЕСС ПО&nbsp;СИСТЕМЕ<br/> <span>КАТИ&nbsp;УСМАНОВОЙ</span></div>
                <div className={styles.press_subtitle}><strong>Плоский животик делает неотразимым любой тип фигуры:</strong> классический<br/> модельный, спортивный и&nbsp;даже&nbsp;аппетитный плюс-сайз.</div>
                <div className={styles.press_first}>Как и&nbsp;отсутствие плоского живота визуально сводит к&nbsp;минимуму все&nbsp;старания.</div>
                <div className={styles.press_second}>Женский живот — одна&nbsp;из&nbsp;самых притягательных визуально и тактильно зон для&nbsp;мужчин, узкой талии идут любые фасоны одежды 🔥</div>
            </div>
        </div>
    )
}

export default Press;