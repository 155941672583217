import React from "react";
import { Icon } from "@chakra-ui/react";

export function RuCard(props) {
  return (
    <Icon viewBox="0 0 48 48" {...props}>
      <path
        d="M26 39C26.5523 39 27 38.5523 27 38C27 37.4477 26.5523 37 26 37V39ZM26 37H10V39H26V37ZM7 34V14H5V34H7ZM10 11H38V9H10V11ZM41 14V23.5H43V14H41ZM38 11C39.6569 11 41 12.3431 41 14H43C43 11.2386 40.7614 9 38 9V11ZM7 14C7 12.3431 8.34315 11 10 11V9C7.23858 9 5 11.2386 5 14H7ZM10 37C8.34315 37 7 35.6569 7 34H5C5 36.7614 7.23858 39 10 39V37Z"
        fill="black"
      />
      <path d="M6 23.5H42V18.5H6V23.5Z" fill="black" />
      <g clipPath="url(#clip0_8593_18042)">
        <path d="M48 28H32V33C38.2484 33 41.7516 33 48 33V28Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M32 44V39H48V44H32Z" fill="#D52B1E" />
        <path d="M48 33H32V39H48V33Z" fill="#0039A6" />
      </g>
      <defs>
        <clipPath id="clip0_8593_18042">
          <rect x="32" y="28" width="16" height="16" rx="8" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
