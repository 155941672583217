import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_title}>Что вас ждёт?<br/></div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>21 тренировка в&nbsp;формате «повторяй за&nbsp;мной» 🤺</div>
                            <div className={styles.whaityou_info__text}>Ежедневно вам будет открываться новая тренировка, которую можно выполнить в&nbsp;любое время в&nbsp;течение суток. Она займет 20-30&nbsp;минут. Просто включайте видео и&nbsp;повторяйте за&nbsp;Катей. В программу включены тренировки на&nbsp;верх тела, но&nbsp;упор сделан на&nbsp;ягодицы и&nbsp;ноги.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>Ежедневные отчеты 📋</div>
                            <div className={styles.whaityou_info__text}>С помощью небольших ежедневных отчетов вы&nbsp;сможете анализировать свою работу и&nbsp;сохранять дисциплину.</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>МОРАЛЬНАЯ ПОДДЕРЖКА и мотивация 🦸‍♀️</div>
                            <div className={styles.whaityou_info__text}>Ощущение, что&nbsp;ты&nbsp;не&nbsp;одна&nbsp;— это&nbsp;очень важно. Записавшись на&nbsp;марафон, вы&nbsp;попадёте в&nbsp;фитнес-комьюнити, где&nbsp;вас со&nbsp;всех сторон готовы поддержать единомышленницы и&nbsp;Катя Усманова.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;