import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Second from './components/Second';
import Programs from './components/Programs';
import Block4 from './components/Block4';
import Block1 from './components/Block1';
import Localplatform from './components/Localplatform';
import Students from './components/Students';
import Author from './components/Author';
import Results from './components/Results';
import Faq from './components/Faq';
import Starttest from './components/Starttest';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_10_prebftest = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };

    const rendererm = ({hours, minutes, seconds}) => {
      let minuteszero = '';
      let secondszero = '';
      if(minutes<10) {minuteszero = '0'}
      if(seconds<10) {secondszero = '0'}
      return (
          <div>
              <div>{minuteszero}{minutes} <span>:</span></div>
              <div>{secondszero}{seconds}</div>
          </div>
      );
  };
    
    return (
      <>
        <DocumentTitle value=''/>
        <DocumentDescription value='' />
        <div className='presale_blackfriday'>
            <Promo scroll={scroll}/>
            <Second />
            <Programs scroll={scroll}/>
            <Block1 />
            <Block4 />
            <Localplatform renderer={rendererm} scroll={scroll} />
            <Students scroll={scroll} />
            <Author />
            <Starttest />
            <Results />
            <Faq scroll={scroll} />
            <Footer version={6} isColor={'#262626'} />
        </div>
      </>
    );
};