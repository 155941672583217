import { useState } from 'react';
import './faq.css';

const Faq = () => {
    const faqs = [
        {question: 'Тренировки проходят в определенное время? Боюсь, что не впишусь в расписание :(', answer: 'Вы сможете заниматься тогда, когда вам удобно. Доступ к тренировкам будет открываться каждые два дня. Вам нужно только выделить 20 минут в течение дня или вечера и повторять за Катей.'},
        {question: 'Нужно ли покупать абонемент в фитнес-клуб или специальное оборудование?', answer: 'Программа рассчитана на домашние тренировки, чтобы вы могли не отлучаться от малыша. Инвентарь нужен, но его легко можно заменить подручными средствами, например, бутылками с водой.'},
        {question: 'Я на грудном вскармливании, не пропадет ли молоко от тренировок?', answer: 'Если вы приступили к тренировкам спустя 6 месяцев после родов, умеренные физические нагрузки, предусмотренные нашей программой, не повлияют на лактацию. Более того, некоторые упражнения и правильно подобранное питание помогут улучшить выработку молока.'},
        {question: 'Подойдет ли мне диета, если я на грудном вскармливании?', answer: 'Да. В программе курса есть рекомендации по питанию, разработанные с учётом потребностей молодых мам и грудничков.'},
        {question: 'Через какое время после родов можно тренироваться?', answer: 'Вы можете начать тренировки спустя 6 месяцев после родов при отсутствии медицинских противопоказаний.'},
        {question: 'У меня было «кесарево сечение». Мне можно тренироваться?', answer: 'Да, спустя 6 месяцев после родов и после консультации с врачом.'},
        {question: 'Я стала мамой больше года назад. Будет ли курс полезным для меня?', answer: <ul><p>Да! Вам подойдет программа, если вы хотите:</p><li>похудеть</li><li>улучшить тонус мышц</li><li>укрепить мышечный корсет</li><li>тренироваться в комфортном режиме.</li></ul>},
        {question: 'Cмогу ли я заниматься, если живу не в России?', answer: 'Да. Тренировки доступны в любой точке мира. Вам понадобится смартфон, планшет или компьютер с доступом в интернет. Вы также можете использовать Smart TV.'},
        {question: 'Я оплатила курс, но мне ничего не открылось. Что делать?', answer: 'За день до старта мы отправляем письмо со ссылкой на вход в личный кабинет. Если вы не нашли письмо даже в папке со спамом, проверьте, правильно ли вы указали адрес электронной почты при оплате.'},
        {question: 'Можно ли заниматься, если у меня диастаз?', answer: <ul><p>Да, если размер вашего диастаза:</p><li>до 1 см над пупком</li><li>до 2,7 см на уровне пупка</li><li>до 0,9 под пупком</li></ul>},
        {question: 'У меня останется доступ к тренировкам, когда я закончу курс?', answer: 'Курс рассчитан на 2 месяца, у вас будет доступ на 1 год. Вы сможете пройти курс несколько раз или вернуться к полюбившимся тренировкам, чтобы закрепить полученный результат.'},
        {question: 'Можно ли «заморозить» программу, а потом продолжить?', answer: <p>Да, заморозка возможна на 7 дней. Для активации напишите нам в поддержку по адресу <a href='mailto:support@gymteam.ru'>support@gymteam.ru</a>.</p>},
        {question: 'У меня возникли технические проблемы. Куда писать?', answer: <p>По техническим вопросам, связанным с работой платформы или доступом к программам, пишите в нашу службу поддержки <a href='mailto:support@gymteam.ru'>support@gymteam.ru</a>.</p>},
        {question: 'Как установить мобильное приложение GymTeam?', answer: <><p>Прямые ссылки для установки:</p><p><a href="https://apps.apple.com/us/app/id1533404428" target='_blank'>IOS App</a></p><p><a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android" target='_blank'>Android App</a></p></>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через web-сайт?', answer: <><p>Чтобы войти на платформу, необходимо пройти регистрацию по номеру мобильного телефона. ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Выполните инструкцию ниже по шагам:</p><p>Шаг 1 - Откройте веб сайт <a href="https://gymteam.ru/" target='_blank'> www.gymteam.ru</a></p><p>Шаг 2 - Укажите номер телефона в международном формате.</p><p>Шаг 3 - Нажмите на кнопку «Получить код».</p><p>Шаг 4 - Получите входящий вызов.</p><p>Шаг 5 - Введите последние 4 цифры входящего номера телефона, и нажмите на кнопку «Войти».</p></>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через мобильное приложение?', answer: <><p>ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Шаг 1 - Введите номер телефона в международном формате, затем нажмите «Подтвердить».</p><p>Шаг 2 - Подтвердите входящий вызов, кликнув на кнопку.</p><p>Шаг 3 - Запомните 4 последние цифры входящего номера телефона. ВНИМАНИЕ! Отвечать на звонок не нужно.</p><p>Шаг 4 - Введите 4 цифры в поле для ввода, далее приложение автоматически произведет вход.</p><p>Шаг 5 - Если вам не поступил входящий вызов, то нажмите на кнопку «Отправить SMS сообщение».</p><p>Шаг 6 - Получите входящее SMS сообщение с кодом для входа, и введите цифры.</p></>},
        {question: 'Как купить другие программы Екатерины Усмановой?', answer: <><p>Купить другие программы вы можете самостоятельно на сайте программ или напишите нашим менеджерам <a href='mailto:support@gymteam.ru' target='_blank'>support@gymteam.ru</a></p><p>Купить программы можно по этим ссылкам:</p><p><a href='https://gymteam.ru/l/universalpop/' target='_blank'>Универсальные тренировки на ягодицы</a><br/><a href='https://gymteam.ru/l/marafonstr1' target='_blank'>Марафон Стройность 1.0</a><br/><a href='https://gymteam.ru/l/marafonabs' target='_blank'>Марафон Плоского живота</a><br/><a href='https://gymteam.ru/l/marafonpopa1' target='_blank'>Марафон Упругой Попы 1.0</a><br/><a href='https://gymteam.ru/l/marafonpopa2' target='_blank'>Марафон Упругой Попы 2.0</a><br/><a href='https://gymteam.ru/l/removefat' target='_blank'>Марафон Убираем лишний жир</a><br/><a href='https://gymteam.ru/l/anticellulite' target='_blank'>Антицеллюлитный марафон</a><br/><a href='https://gymteam.ru/l/fatburning' target='_blank'>Жиросжигающий курс</a><br/><a href='https://gymteam.ru/l/coursepop2' target='_blank'>Курс по создания идеальной Попы 2.0</a><br/><a href='https://gymteam.ru/l/coursepop3' target='_blank'>Курс идеальной попы 3.0</a><br/><a href='https://gymteam.ru/l/nutrition' target='_blank'>Курс Питание</a><br/><a href='https://gymteam.ru/l/pregnancy' target='_blank'>Курс для беременных</a><br/><a href='https://gymteam.ru/l/coursegym/' target='_blank'>Курс для зала</a></p></>}
    ];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    return(
        <div className="faqq" >
          <div className="container">
            
            <div className="faqq_title"><span>Ответы на&nbsp;вопросы,</span><br/> чтобы развеять все сомнения</div>
            <div className='faqaccordion'>
              {faqs.map((faq, index) => (
                <div key={faq.question} className={clicked == index + 1 ? 'active' : ''}>
                  <p onClick={()=>handleToggle(index+1)} className="faqquestion">{faq.question} </p>
                  <p className="faqanswer">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
    );
};

export default Faq;