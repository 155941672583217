import { useState } from 'react';
import styles from './faq.module.css';

const Faq = () => {
    const faqs = [
        {question: 'Какие преимущества даёт подписка на GymTeam по сравнению с предложениями других фитнес-платформ?', answer: <><p>С подпиской на GymTeam вы получаете мгновенный доступ к 49 фитнес-программам. Также вам будут доступны все новинки, которые появятся на платформе в период действия вашей подписки. Мы регулярно пополняем список программ.</p></>},
        {question: 'Cмогу ли я заниматься, если живу не в России?', answer: <><p>Да. Тренировки доступны в любой точке мира. Вам понадобится смартфон, планшет или компьютер с доступом в интернет. Вы также можете использовать Smart TV.</p></>},
        {question: 'Я оплатил(а) подписку, но мне ничего не открылось. Что делать?', answer: <><p>Мы предоставляем доступ по вашему номеру телефона. Проверьте актуальность контактных данных, указанных при заказе, и напишите по адресу <a href="mailto:support@gymteam.ru" target='_blank'>support@gymteam.ru</a></p></>},
        {question: 'Я новичок в спорте. Подойдут ли мне тренировки?', answer: <><p>Да. Программы GymTeam подходят для любого уровня подготовки и условий тренировок. Все занятия проходят в формате «повторяй за мной».</p></>},
        {question: 'Какой инвентарь нужен для занятий?', answer: <><p>Вам потребуется минимальный набор инвентаря, а в большинстве программ его можно полностью заменить подручными средствами: вместо блоков для йоги — толстые книги, вместо эластичной ленты — пояс от халата, вместе гантелей — бутылки с водой.</p></>},
        {question: 'Какую подписку лучше выбрать? На 3 или 12 месяцев?', answer: <><p>Подписка на 3 месяца — оптимальный вариант, если вы хотите попробовать наши тренировки. Этого времени хватит, чтобы познакомиться с тренерами и направлениями, оценить все функции приложения GymTeam. И получить результат — красивое и здоровое тело, отличное самочувствие.</p><p>На 12 месяцев — максимально выгодная. Вы не только получаете скидку при оформлении подписки, но и фиксируете цену на целый год. Сможете тренироваться в удовольствие и не думать о стоимости подписки, дополнительных платежах.</p></>},
    ];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    return(
        <div className={styles.faq} >
          <div className="stretch_container">
            <div className={styles.faq_block}>
              <div className={styles.faq_left}>
                <div className={styles.faq_title}>ОСТАЛИСЬ ВОПРОСЫ?</div>
                <div className={styles.faq_subtitle}>Напишите свой вопрос в&nbsp;удобный для&nbsp;вас мессенджер и&nbsp;мы&nbsp;ответим в&nbsp;ближайшее время.</div>
                <div className={styles.faq_links}>
                  <a href="https://vk.com/usmanovateam" target="_blank"><img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/vk.png" alt="" /></a>
                  <a href="https://t.me/GymTeamSupportBot" target="_blank"><img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/tg.png" alt="" /></a>
                </div>
              </div>
              <div className={styles.faq_right}>
                <div className={styles.accordion}>
                  {faqs.map((faq, index) => (
                    <div key={faq.answer} className={clicked == index + 1 ? styles.active : ''}>
                      <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                      <p className={styles.answer}>{faq.answer}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Faq;