import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className={styles.createbody_weight}></div>
            <div className={styles.createbody_wheel}></div>
            <div className="container">
                <div className={styles.createbody_title}>ИЗМЕНИТЕ КАЧЕСТВО ТЕЛА<br/> <span>ПО&nbsp;СИСТЕМЕ КАТИ УСМАНОВОЙ</span></div>
                <div className={styles.create_pink}>Красивые подтянутые ягодицы — предел мечтаний, ведь на контрасте с круглой попой талия выглядит тоньше. Фигура становится не просто спортивной, а более женственной.</div>
                <div className={styles.create_yellow}><strong>Внутри 5 тренировок в неделю не только на попу:</strong><br/> 3 дня качаем ягодицы, и по 1 дню — верх тела и пресс.</div>
            </div>
        </div>
    )
}

export default Createbody;