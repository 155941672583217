import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Марафон точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Не знаете, с&nbsp;чего начать, <strong>чтобы получить заметный<br/> результат</strong></li>
                            <li><strong>Мечтаете сделать кожу ровной и&nbsp;гладкой,</strong> а&nbsp;тело&nbsp;—<br/> подтянутым и&nbsp;рельефным</li>
                            <li><strong>Страдаете от&nbsp;целлюлита любой степени,</strong> то&nbsp;есть<br/> он&nbsp;заметен только при&nbsp;сжимании кожи или&nbsp;вообще<br/> без&nbsp;воздействия на&nbsp;неё</li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Пробовали бороться с&nbsp;целлюлитом, <strong>но&nbsp;ничего<br/> не&nbsp;получилось</strong></li>
                            <li>Похудели и&nbsp;подтянули всё&nbsp;тело,<br/> <strong>а&nbsp;дряблость кожи осталась</strong></li>
                            <li>Не знаете, где&nbsp;найти мотивацию, <strong>чтобы&nbsp;начать<br/> и&nbsp;не&nbsp;бросить</strong></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;