import styles from './newcourse.module.css';

const Newcourse = () => {
    return (
        <div className={styles.newcourse}>
            {/* <div className="container"> */}
                <div className={styles.newcourse_title}>СОЗДАЛа <span>НОВЫЙ КУРС</span><br/> ДЛЯ ЗАЛА</div>
                <div className={styles.newcourse_subtitle}>Внутри только проверенные упражнения и система,<br/> по которым Катя тренируется сама.</div>
                <div className={styles.newcourse_t1}><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/ic1.svg" alt="" /> Старт сразу после оплаты</div>
                <div className={styles.newcourse_t2}><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/ic2.svg" alt="" /> Длительность — 4,5 месяца</div>
                <div className={styles.newcourse_title2}>КУРС СОСТОИТ ИЗ 4-Х БЛОКОВ</div>
                <div className={styles.newcourse_block}>
                    <div className={styles.newcourse_item}>
                        <div className={styles.newcourse_info}>
                            <div className={styles.newcourse_info_name}>1-й  БЛОК: </div>
                            <div className={styles.newcourse_info_title}>Наработка<br/> техники</div>
                            <div className={styles.newcourse_info_time}>
                                <p><b>Длительность: 6 недель</b></p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>График — 3 раза в неделю:</b></p>
                                <p>пн — ягодицы, база</p>
                                <p>ср — верх тела</p>
                                <p>пт — ягодицы, изоляция</p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>Что внутри:</b></p>
                                <p>В блок входит цикл из 6-ти тренировок. Вы делаете их друг за другом первые 2 недели по графику, а потом повторяете цикл на 3-й и 4-й, а потом на 5-й и 6-й неделях.</p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>Результат:</b></p>
                                <p>Поставлена правильная техника выполнения базовых упражнений на минимальных весах. Тело готово к увеличению нагрузки для дальнейшей работы на силу, выносливость и рельеф.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.newcourse_item}>
                        <div className={styles.newcourse_info}>
                            <div className={styles.newcourse_info_name}>2-й  БЛОК: </div>
                            <div className={styles.newcourse_info_title}>УВЕЛИЧЕНИЕ<br/> СИЛОВЫХ<br/> ПОКАЗАТЕЛЕЙ</div>
                            <div className={styles.newcourse_info_time}>
                                <p><b>Длительность: 6 недель</b></p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>График — 3 раза в неделю:</b></p>
                                <p>пн — ягодицы, база</p>
                                <p>ср — верх тела</p>
                                <p>пт — ягодицы, изоляция</p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>Что внутри:</b></p>
                                <p>В блок входит цикл из 6-ти тренировок. Вы делаете их друг за другом первые 2 недели по графику, а потом повторяете цикл на 3-й и 4-й, а потом на 5-й и 6-й неделях.</p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>Результат:</b></p>
                                <p>Работа на максимальных весах с минимальным количеством повторений. Прогресс силовых показателей, создание крепкого мышечного каркаса.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.newcourse_item}>
                        <div className={styles.newcourse_info}>
                            <div className={styles.newcourse_info_name}>3-й  БЛОК: </div>
                            <div className={styles.newcourse_info_title}>наработка<br/> выносливости</div>
                            <div className={styles.newcourse_info_time}>
                                <p><b>Длительность: 3 недели</b></p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>График — 3 раза в неделю:</b></p>
                                <p>пн — ягодицы, база</p>
                                <p>ср — верх тела</p>
                                <p>пт — ягодицы, изоляция</p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>Что внутри:</b></p>
                                <p>В блок входит цикл из 3-х тренировок. Вы делаете их друг за другом в первую неделю блока по графику, потом повторяете цикл на 2-й и 3-й неделе.</p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>Результат:</b></p>
                                <p>Проработаны медленные мышечные волокна, которые обычно тяжело прокачать, но они необходимы для создания красивого рельефа и выносливости.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.newcourse_item}>
                        <div className={styles.newcourse_info}>
                            <div className={styles.newcourse_info_name}>4-й  БЛОК: </div>
                            <div className={styles.newcourse_info_title}>Работа над<br/> мышечными<br/> объёмами</div>
                            <div className={styles.newcourse_info_time}>
                                <p><b>Длительность: 3 недели</b></p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>График — 3 раза в неделю:</b></p>
                                <p>пн — ягодицы, база</p>
                                <p>ср — верх тела</p>
                                <p>пт — ягодицы, изоляция</p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>Что внутри:</b></p>
                                <p>В блок входит цикл из 3-х тренировок. Вы делаете их друг за другом в первую неделю блока по графику, потом повторяете цикл на 2-й и 3-й неделе.</p>
                            </div>
                            <div className={styles.newcourse_info_text}>
                                <p><b>Результат:</b></p>
                                <p>Гармонично развиты все группы мышц: подтянутая спина, рельефный живот, идеальная попа.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                <div className={styles.newcourse_title3}>Результат курса:</div>
                <div className={styles.newcourse_subtitle3}>Спортивное, подтянутое и рельефное тело, которое<br/> сложно создать дома.</div>
                <div className={styles.newcourse_block2}>
                        <div className={styles.newcourse_right_t}>Домашние тренировки дают крутые результаты,</div>
                        <div className={styles.newcourse_right_t2}>но только<br/> до определённого уровня</div>
                        <div className={styles.newcourse_right_t3}>Без тренажёрного зала не обойтись, если вы чувствуете,<br/> что прогресса больше нет. Пора увеличивать нагрузку<br/> и менять систему.</div>
                    </div>
                </div>
            {/* </div> */}
        </div>
    )
}

export default Newcourse;