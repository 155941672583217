import { useParams } from "react-router-dom";
import { SalesWidget } from "@com.marathonium/web2-ui";
import { useWebRpc } from "@com.marathonium/web2-utils";

function OrderPaymentError() {
  return <p>Невозможно отобразить заказ</p>;
}

function OrderPayment({ id_order, code }) {
  const { loading, data, error } = useWebRpc("offers_by_order_v1", {
    params: { id_order, code },
  });

  if (loading) {
    return null;
  }

  if (error) {
    return <OrderPaymentError />;
  }

  const { id_form, id_offers, user } = data || {};

  return (
    <SalesWidget
      id={id_form}
      initValue={id_offers}
      orderId={id_order}
      initUserData={user}
      title='Ваш заказ:'
    />
  );
}

export function L2024_06_Order() {
  const { id, code } = useParams();

  return (
    <>
      <OrderPayment id_order={id} code={code} />
    </>
  );
}
