import './mainnote.css';

const Mainnote = () => {
    return (
        <div className="mainnote">
            <div className="container">
            <div className="mainnote_pretitle">И ГЛАВНОЕ!</div>
            <div className="mainnote_title">Тренировки помогут в&nbsp;решении<br/> <span>душевных проблем</span></div>
            <div className="mainnote_block">
                <div className="mainnote_item">
                <p><strong>Вы прочувствуете своё новое тело и&nbsp;примите изменения</strong>, которые произошли за время беременности и&nbsp;после родов.</p>
                </div>
                <div className="mainnote_item">
                <p>Начнёте работу над несовершенствами, <strong>будете искренне радоваться своим результатам</strong> и&nbsp;стремиться к&nbsp;новым свершениям.</p>
                </div>
                <div className="mainnote_item">
                <p><strong>Поймёте, что к&nbsp;вам вернулся контроль над ВАШИМ телом.</strong> Это очень важно, если&nbsp;роды были травмирующими.</p>
                </div>
                <div className="mainnote_item">
                <p>Вновь полюбите своё тело со&nbsp;всеми его особенностями, <strong>начнёте улыбаться отражению в&nbsp;зеркале.</strong></p>
                </div>
                <div className="mainnote_item">
                <p><strong>Каждый день будет чуточку лучше.</strong> Вслед за&nbsp;телом вас ждёт трансформация мышления и&nbsp;жизни.</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Mainnote;