import styles from './code.module.css';

const Code = () => {
    return (
        <div className={styles.code}>
            <div className="container">
                <div className={styles.code_title}>У&nbsp;вас уже есть промокод<br/> <span>от&nbsp;работодателя?</span></div>
                <div className={styles.code_subtitle}>Получите тестовый доступ на месяц бесплатно</div>
                <div className={styles.code_block}>
                    <div className={styles.code_item}>
                        <div className={styles.code_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/code1.jpg" alt="" /></div>
                        <div className={styles.code_item_number}>1</div>
                        <div className={styles.code_item_text}><b>Введите ваш номер телефона</b><br/> к&nbsp;нему будет привязан личный кабинет</div>
                    </div>
                    <div className={styles.code_item}>
                        <div className={styles.code_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/code2.jpg" alt="" /></div>
                        <div className={styles.code_item_number}>2</div>
                        <div className={styles.code_item_text}><b>Дождитесь звонка или&nbsp;смс</b><br/> введите код из&nbsp;смс или&nbsp;4&nbsp;последние цифры входящего номера</div>
                    </div>
                    <div className={styles.code_item}>
                        <div className={styles.code_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/code3.jpg" alt="" /></div>
                        <div className={styles.code_item_number}>3</div>
                        <div className={styles.code_item_text}><b>Нажмите на&nbsp;номер телефона в&nbsp;правом верхнем углу страницы</b><br/> и&nbsp;выберите раздел “компания” в&nbsp;меню</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Code;