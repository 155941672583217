import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className="container">
                <div className={styles.createbody_title}>ИЗМЕНИТЕ КАЧЕСТВО ТЕЛА<br/> <span>ПО&nbsp;СИСТЕМЕ КАТИ УСМАНОВОЙ</span></div>
                <div className={styles.createbody_subtitle}>Выводы, которые делают все&nbsp;девушки, пытающиеся<br/> похудеть самостоятельно:</div>
                <div className={styles.createbody_block}>
                    <div className={styles.createbody_item}>
                        <ul>
                            <li>Убрать жир только <strong>с&nbsp;бёдер невозможно</strong></li>
                            <li>Упражнения на&nbsp;пресс <strong>не&nbsp;сжигают жир с&nbsp;живота</strong></li>
                            <li>Придать телу красивый рельеф <strong>можно только в&nbsp;тренажёрке</strong></li>
                        </ul>
                    </div>
                    <div className={styles.createbody_item}>
                        <ul>
                            <li>Резкое похудение <strong>усугубляет целлюлит</strong></li>
                            <li>Через неделю после кефирной диеты <strong>вес&nbsp;вернётся</strong></li>
                            <li>Если только держать диету, <strong>грудь&nbsp;похудеет, а&nbsp;живот&nbsp;останется</strong></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Createbody;