import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_title}>Что вас ждёт?<br/></div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>30&nbsp;дней по&nbsp;чёткой схеме для&nbsp;результата 🤸‍♀️</div>
                            <div className={styles.whaityou_info__text}>В&nbsp;программе до&nbsp;мелочей продуманы все аспекту создания идеальных ягодиц. Это не&nbsp;просто серия тренировок, а&nbsp;система из&nbsp;тренинга, отдыха, питания и&nbsp;дисциплины. Вам надо только выполнять все&nbsp;рекомендации.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>Тренировки в&nbsp;формате «Повторяй за&nbsp;Катей» 🙋‍♀️</div>
                            <div className={styles.whaityou_info__text}>Тренировки длятся 20-30&nbsp;минут. Заниматься можно в&nbsp;любое удобное время и&nbsp;в&nbsp;любом месте: дома, на&nbsp;улице, в&nbsp;спортзале. Включайте видео с&nbsp;тренировкой дня и&nbsp;повторяйте за&nbsp;Катей.</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>Варианты меню на&nbsp;каждый день 🍲</div>
                            <div className={styles.whaityou_info__text}>В&nbsp;программу входит подробная инструкция по&nbsp;расчёту вашего «калоража» на&nbsp;каждый день с&nbsp;рекомендациями по&nbsp;дефициту. А&nbsp;также гайд по&nbsp;питанию — 21&nbsp;вариант меню из&nbsp;5&nbsp;приемов пищи на&nbsp;каждый день.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;