import { HideForSegment, ShowForSegment, addUserToGroup } from "@com.marathonium/web2-ui";
import styles from './start.module.css';

const Start = () => {
    const addFunction = () => {
        addUserToGroup(window.lps_settings.user_groups.fatlost)
        window.location.href='https://gymteam.ru/trainings/c191bde9-d914-435a-a6fd-3b81056d9ecb'
    }

    return (
        <div className={`${styles.start} scrollhere`}>
            
            <div className="container">
                <ShowForSegment id={window.lps_settings.segments.fatlost6}>
                        <div className={`${styles.start_block} ${styles.start_block_new}`}>
                            <div className={styles.start_left}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/1year.jpg" alt="" />
                            </div>
                            <div className={styles.start_right}>
                                <div className={styles.start_title}>Для вас есть специальное предложение <span>на&nbsp;все&nbsp;программы GymTeam!</span></div>
                                <a href="/l/fatlost/1year" className={styles.start_btn}>Узнать подробности</a>
                            </div>
                        </div>
			    </ShowForSegment>
                    {/* <ShowForGroup id={window.lps_settings.user_groups.fatlost} > */}
                    
                        <div className={styles.start_block}>
                            <div className={styles.start_left}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/start_1.jpg" alt="" />
                            </div>
                            <div className={styles.start_right}>
                                <div className={styles.start_title}>Вы уже являетесь участником жиросжигающей недели <span>от&nbsp;GYMTEAM</span></div>
                                <div className={styles.start_info}>
                                    <p><b>Ваш статус:</b> <i>участник</i></p>
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_anketa}><p><b>Анкета:</b> заполнена</p></ShowForSegment>
                                    <HideForSegment id={window.lps_settings.segments.fatlost_anketa}><p><b>Анкета:</b> не заполнена <a href="https://gymteam.ru/lessons/50a2d2dc-b6b0-4bd7-9f44-700476f14faf" target="_blank">Заполнить анкету</a></p></HideForSegment>
                                    <p><b>Выполнено тренировок:</b> 
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t0}>0 из 7</ShowForSegment>
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t1}>1 из 7</ShowForSegment>
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t2}>2 из 7</ShowForSegment>
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t3}>3 из 7</ShowForSegment>
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t4}>более 4-х тренировок</ShowForSegment></p>
                                    <p><b>Баллы:</b> 
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t0}>0 баллов</ShowForSegment>
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t1}>100 баллов</ShowForSegment>
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t2}>300 баллов</ShowForSegment>
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t3}>600 баллов</ShowForSegment>
                                    <ShowForSegment id={window.lps_settings.segments.fatlost_t4}>600 баллов</ShowForSegment> <a href="https://gymteam.ru/lessons/f450c175-3bc8-4f8f-8e8b-75e078c9ad60" target="_blank">Что за баллы?</a></p>
                                </div>
                                <a className={styles.start_btn} onClick={addFunction}>Перейти в тренинг</a>
                            </div>
                        </div>
                        
                    {/* </ShowForGroup> */}
                    {/* <HideForGroup id={window.lps_settings.user_groups.fatlost}>
                        <div className={styles.start_block}>
                            <div className={styles.start_left}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/start_1.jpg" alt="" />
                            </div>
                            <div className={styles.start_right}>
                                <div className={styles.start_title}>сделайте<br/> 3&nbsp;простых шага,</div>
                                <div className={styles.start_subtitle}>чтобы принять участие в бесплатной жиросжигающей неделе</div>
                                <ul>
                                    <li>1. Нажмите кнопку участвовать</li>
                                    <li>2. Подтвердите свою почту, чтобы получить бонусные баллы за тренировки</li>
                                    <li>3. Приступайте к тренировкам</li>
                                </ul>
                                <div className={styles.start_btn} onClick={addFunction}>Участвовать</div>
                            </div>
                        </div>
                    </HideForGroup> */}
				
            </div>
            <div className={styles.start_img}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/start_img.jpg" alt="" />
            </div>
        </div>
    );
};

export default Start;

