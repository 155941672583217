import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className={styles.createbody_weight}></div>
            <div className="container">
                <div className={styles.createbody_title}>НЕ ОЧЕРЕДНАЯ ДИЕТА, <span>А&nbsp;СИСТЕМА<br/> ПЕРЕХОДА НА&nbsp;ПРАВИЛЬНОЕ ПИТАНИЕ</span></div>
                <div className={styles.create_pink}><p><strong>Не будет никаких диет&nbsp;— только рациональное питание.</strong></p><p>Сбалансированное <strong>по&nbsp;белкам, жирам углеводам.</strong> На&nbsp;выходе вы составите себе полноценный вкусный рацион, на&nbsp;котором не&nbsp;будете страдать.</p><p><span><img src="https://gymteam.kinescopecdn.net/img/l/nutrition/arrow.svg" alt="" />ЗАЧЕМ?</span></p></div>
                <div className={styles.create_yellow}>Подтянутое и&nbsp;красивое тело&nbsp;— это&nbsp;регулярные тренировки <strong>и&nbsp;сбалансированное питание</strong></div>
            </div>
        </div>
    )
}

export default Createbody;