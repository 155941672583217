import { useState } from 'react';
import './author.css';

const Author = () => {
   const [play, setPlay] = useState(false);
    const url = play ? "?autoplay=1" : '';
    return (
        <div className="authorr">
          <div className="container">
            <div className="author_block">
              <div className="author_block__info">
                <div className="author_block__info-title">АВТОР КУРСА</div>
                <div className='author_block__info-name'>ЕКАТЕРИНА УСМАНОВА</div>
                <p>Чемпионка России по&nbsp;фитнес-бикини и&nbsp;молодая мама, ожидающая второго ребёнка.</p>
                <p><strong>После первых родов сбросила 20&nbsp;кг за 100&nbsp;дней,</strong> благодаря собственной системе тренировок для беременных.</p>
                <p><strong>Смотрите видео о&nbsp;новом курсе</strong> 🥳 🎉</p>
              </div>
              <div className="author_block__video2">
                <div className={'author_block__video-preview2' + (play ? ' hide' : '')} onClick={() => setPlay(true)}></div>
                <iframe src={'https://kinescope.io/embed/202272400'+url} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Author;