import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_title}>СЕРЬЁЗНЫЕ ТРЕНИРОВКИ<br/> ДЛЯ&nbsp;ВПЕЧАТЛЯЮЩЕГО РЕЗУЛЬТАТА</div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>45 ДНЕЙ НАБОРА МАССЫ И&nbsp;45&nbsp;ДНЕЙ СУШКИ 🏋️‍♀️‍‍</div>
                            <div className={styles.whaityou_info__text}>Не&nbsp;бойтесь слова «масса». Мы начнем с&nbsp;контролируемого набора мышц, без&nbsp;которого невозможно создать по-настоящему спортивные ягодицы. А&nbsp;во&nbsp;второй части курса отшлифуем их до&nbsp;идеала и&nbsp;сожжем лишнее. Итог: рельефное тело без&nbsp;лишнего жира.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>КОМПЛЕКСЫ УПРАЖНЕНИЙ НА&nbsp;РАСТЯЖКУ 🧘‍‍</div>
                            <div className={styles.whaityou_info__text}>После каждого занятия вы&nbsp;будете растягивать мышцы, которые работали на&nbsp;тренировке. Заминка нужна, чтобы снять мышечное и&nbsp;суставное напряжение, увеличить гибкость и&nbsp;подвижность суставов и&nbsp;облегчить восстановление.</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ТРЕНИРОВКИ ДЛЯ&nbsp;ДОМА И&nbsp;ЗАЛА 🏟️‍</div>
                            <div className={styles.whaityou_info__text}>Второе правило построения эталонных ягодиц&nbsp;— увеличение нагрузки. В&nbsp;зале условия для&nbsp;этого благоприятнее, но&nbsp;дома тоже&nbsp;можно создать огромное пространство вариантов. Если у&nbsp;вас нет абонемента в&nbsp;фитнес-клуб, выбирайте домашний класс и&nbsp;ознакомьтесь с&nbsp;перечнем инвентаря.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;