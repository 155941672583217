import styles from './guide.module.css';
import { useDisclosure } from "@chakra-ui/react";
import { AuthWidget, addUserToGroup, checkUserInGroup } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import { useEffect, useState } from 'react';



const Guide = () => {
    const [inGroup, setInGroup] = useState(false)
    const { isOpen, onClose, onOpen } = useDisclosure();

    useEffect(()=> {
        checkUserInGroup(window.lps_settings.user_groups.mainpage_guide).then(res => {
            if(res !== null) {
                setInGroup(true)
            }
        })
    }, [])
    
    
    return (
        <div className={styles.guide}>
            <div className="container">
                <div className={styles.guide_block}>
                    <div className={styles.guide_block_title}>Не&nbsp;знаешь, с&nbsp;чего<br/> начать, чтобы увидеть<br/> реальные изменения?</div>
                    <div className={styles.guide_block_text}>Скачай наш гайд <b>&laquo;8&nbsp;вещей, которые нужно знать<br/> новичку перед тренировкой&raquo;</b> и&nbsp;узнай, как эффективно<br/> тренироваться, на&nbsp;чём сосредоточиться, как избежать<br/> типичных ошибок и&nbsp;не&nbsp;потерять мотивацию на&nbsp;пути<br/> к&nbsp;телу своей мечты.</div>
                    <Authorized>
                        {inGroup ?
                        <div className={styles.guide_block_text}>Вы ранее уже скачали гайд, проверьте свою почту</div>
                        :
                        <div className={styles.guide_block_btn} onClick={()=>{addUserToGroup(window.lps_settings.user_groups.mainpage_guide); window.location.replace('/l/mainpage/thx_mail'); }}>Скачать гайд</div>
                        }
                        
                    </Authorized>
                    <Anonymous>
                        <div className={styles.guide_block_btn} onClick={onOpen}>Скачать гайд</div>
                        <AuthWidget isOpen={isOpen} onClose={onClose} modal={true} onSuccess={()=>{addUserToGroup(window.lps_settings.user_groups.mainpage_guide); window.location.replace('/l/mainpage/thx_mail'); }} />
                    </Anonymous>
                    <div className={styles.mob}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/guide_mob.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Guide;