import React from "react";
import { useMediaQuery } from "react-responsive";

import { DESKTOP_MIN_WIDTH, DESKTOP_LARGE_MIN_WIDTH } from "../../constants";

export const desktopMediaQuery = { minWidth: DESKTOP_MIN_WIDTH };
export const desktopLargeMediaQuery = { minWidth: DESKTOP_LARGE_MIN_WIDTH };
export const desktopMediumMediaQuery = { minWidth: DESKTOP_MIN_WIDTH, maxWidth: DESKTOP_LARGE_MIN_WIDTH - 1 };
export const mobileMediaQuery = { maxWidth: DESKTOP_MIN_WIDTH - 1 };
export const portraitMediaQuery = { query: "(orientation: portrait)" };
export const landscapeMediaQuery = { query: "(orientation: landscape)" };
export const ultraWideMediaQuery = { query: "(min-aspect-ratio: 7/3)" };

export function Desktop({ children }) {
  return useMediaQuery(desktopMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function DesktopLarge({ children }) {
  return useMediaQuery(desktopLargeMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function DesktopMedium({ children }) {
  return useMediaQuery(desktopMediumMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function Mobile({ children }) {
  return useMediaQuery(mobileMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function Portrait({ children }) {
  return useMediaQuery(portraitMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function Landscape({ children }) {
  return useMediaQuery(landscapeMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function MobilePortrait({ children }) {
  return (
    <Mobile>
      <Portrait>{children}</Portrait>
    </Mobile>
  );
}

export function MobileLandscape({ children }) {
  return (
    <Mobile>
      <Landscape>{children}</Landscape>
    </Mobile>
  );
}

export function DesktopOrMobileLandscape({ children }) {
  return (
    <React.Fragment>
      <MobileLandscape>{children}</MobileLandscape>
      <Desktop>{children}</Desktop>
    </React.Fragment>
  );
}

export function DesktopOrMobilePortrait({ children }) {
  return (
    <React.Fragment>
      <MobilePortrait>{children}</MobilePortrait>
      <Desktop>{children}</Desktop>
    </React.Fragment>
  );
}

export function MobileOrDesktopMedium({ children }) {
  return (
    <React.Fragment>
      <Mobile>{children}</Mobile>
      <DesktopMedium>{children}</DesktopMedium>
    </React.Fragment>
  );
}

export function AspectRatioUltraWide({ children }) {
  return useMediaQuery(ultraWideMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function AspectRatioNotUltraWide({ children }) {
  return !useMediaQuery(ultraWideMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}
