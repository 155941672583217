import styles from './reviews.module.css';

import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, Pagination} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";
import { useRef } from 'react';

const Reviews = () => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.reviews}>
            <div className="container">

                <div className={styles.reviews_title}>Вот что пишут&nbsp;те, кто уже<br/> тренируется с&nbsp;GymTeam</div>
                <Swiper
                        modules={[Pagination, Navigation]}
                        // autoHeight={true}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        // loop
                        onSwiper={(swiper) => {
                            setTimeout(() => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current
                            
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }}
                        spaceBetween={20}
                        slidesPerView='auto'
                        className={styles.slider}
                    >
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Ульяна Я.</div>
                            <div className={styles.slide_text}>я&nbsp;попробовала оооочень много приложений но&nbsp;в&nbsp;етом прям есть всееее️ тренировки каторые надо! если вообще ниразу не&nbsp;занималась и&nbsp;страшно начинать это прям отличный способ тренировки дома начать точно👍</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Света Куралькова</div>
                            <div className={styles.slide_text}>Третий год занимаюсь на&nbsp;вашей платформе. Уже есть полюбившиеся тренера и&nbsp;тренировки. А&nbsp;теперь и&nbsp;каждую неделю новые, мне очень нравятся!</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Катя</div>
                            <div className={styles.slide_text}>Блииин, это просто огонь 😍🤸‍♀️ столько упражнений и&nbsp;интерфейс крутой! каждый день открываю с&nbsp;удовольствием 💪💪 за&nbsp;2&nbsp;недели больше разных упражнений сделала чем за&nbsp;весь год с&nbsp;другим популярным приложением 😂👍</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Мила Касаткина</div>
                            <div className={styles.slide_text}>Давно пользуюсь приложением! Очень довольна! Много программ, много интересной и&nbsp;полезной информации, отличная обратная связь! Рекомендую</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Ангелина Ванеева</div>
                            <div className={styles.slide_text}>Да&nbsp;мне нравится платформа и&nbsp;тренировки(Разнообразные, интересные) и&nbsp;я&nbsp;планирую пользоваться ей&nbsp;дальше. Рекомендую</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Ольга М.</div>
                            <div className={styles.slide_text}>это приложение просто спасение! оно заменило спорт зал и&nbsp;все эти групповые тренировки! не&nbsp;трачу время на&nbsp;дорогу и&nbsp;занимаюсь когда хочу супер! спасибо!</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Елена Родионова</div>
                            <div className={styles.slide_text}>Очень удобное приложение, много разных тренировок на&nbsp;любое настроение.</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Алёна Т.</div>
                            <div className={styles.slide_text}>Очень классное приложение! Ничего не&nbsp;лагает, все супер работать, очень грамотно составлено!</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Мария</div>
                            <div className={styles.slide_text}>Очень довольна! Спасибо. Великолепные тренера!!! Классная запись!!!</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Настя С.</div>
                            <div className={styles.slide_text}>Ура!!! спасибо!!! храни вас тренировочный боженька</div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_name}>Маргарита</div>
                            <div className={styles.slide_text}>Восторг! впервые приобрела тренировки онлайн, не&nbsp;ожидала, что так эффективно пройдёт это вводное занятие! тренер огонь)</div>
                        </SwiperSlide>
                </Swiper>
                <div className={styles.slider_nav}>
                    <div ref={navigationPrevRef} className={styles.slider_btn_prev}></div>
                    <div ref={navigationNextRef} className={styles.slider_btn_next}></div>
                </div>
            </div>
        </div>
    )
}

export default Reviews;