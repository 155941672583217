import styles from './programs.module.css';

const Programs = () => {
    return (
        <div className={styles.programs}>
            <div className="container">
                <div className={styles.programs_title}>7&nbsp;экспертов&nbsp;-<br/> 7&nbsp;тренировок&nbsp;- 7&nbsp;дней</div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/program_1.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_title}>HIIT</div>
                            <div className={styles.programs_info_text}>Высокоэффективная тренировка, которая поможет разогнать метаболизм и&nbsp;ускорит похудение</div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/program_2.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_title}>Табата</div>
                            <div className={styles.programs_info_text}>Интервальный тренинг, который запустит процесс активного жиросжигания, укрепит мышцы и&nbsp;повысит выносливость</div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/program_3.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_title}>Функциональные тренировки</div>
                            <div className={styles.programs_info_text}>Повысят тонус мышц и&nbsp;сделают тело подтянутым</div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/program_4.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_title}>Йога</div>
                            <div className={styles.programs_info_text}>Снижает уровень стресса и&nbsp;риски переесть</div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/program_5.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_title}>Фейсфитнес</div>
                            <div className={styles.programs_info_text}>Тренировки помогут снять отёк с&nbsp;лица и&nbsp;сделают его худее</div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/program_6.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_title}>Секретная тренировка</div>
                            <div className={styles.programs_info_text}>Раскроем тайны второго подбородка и&nbsp;начнём от&nbsp;него избавляться</div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/program_7.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_title}>Специально для&nbsp;вас</div>
                            <div className={styles.programs_info_text}>Мы определим тренировку, исходя из&nbsp;вашей цели!</div>
                        </div>
                    </div>
                    <div className={`${styles.programs_item} ${styles.programs_item_long}`}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/program_8.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_title}>Каждая тренировка будет учитывать вашу цель, уровень физической подготовки и&nbsp;состояние здоровья</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Programs;