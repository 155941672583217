import { useLayoutEffect } from 'react';
import styles from './start.module.css';

const Start = () => {
    useLayoutEffect(()=>{
        document.querySelector('.asdsad').style.height = `${window.lps_settings.other.google_form_1_height}px`;
    }, [])
    return (
        <div className={`${styles.start} scrollhere`}>
            <div className="container">
                <div className={styles.start_title}>Запись на&nbsp;<span>Демо</span></div>
                <div className={styles.start_subtitle}>Получите тестовый доступ на&nbsp;месяц бесплатно</div>
                <div className={styles.start_block}>
                    <div className={styles.start_block_title}>Пройдите авторизацию, чтобы&nbsp;оставить заявку</div>
                    <div className={`${styles.start_block_form} asdsad`}>
                        <iframe src={window.lps_settings.other.google_form_1} width="100%">Загрузка…</iframe>
                    </div>
                </div>
                
            </div>
            <div className={styles.start_img}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/corporate/start_bg.jpg" alt="" />
            </div>
        </div>
    );
};

export default Start;