import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
                <div className={styles.block}>
                    <div className={styles.title}>Онлайн-тренировки<br/> для дома и&nbsp;зала<br/> <span>с&nbsp;Катей Усмановой</span></div>
                    <div className={styles.subtitle}>Авторские фитнес-программы под разные<br/> цели для любого уровня подготовки</div>
                    <div className={styles.i1}><img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/promo_model.png" alt="" /></div>
                    <div className={styles.i2}><img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/promo1.png" alt="" /></div>
                    <div className={styles.i3}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/promo2.png" alt="" />
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr2_2.png" alt="" />
                    </div>
                    <div className={styles.btn} onClick={scroll}>
                        <p>ПОСМОТРЕТЬ  ПРОГРАММЫ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/arrow.svg" alt="" />
                    </div>
                    <div className={styles.info}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/users.svg" alt="" />
                        <p>С&nbsp;нами тренируются уже более<br/> <b>300&nbsp;000&nbsp;девушек!</b></p>
                    </div>
                </div>
        </div>
    )
}

export default Promo;