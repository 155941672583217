import styles from './inventory.module.css';

const Inventory = () => {
    return (
        <div className={styles.inventory}>
            <div className="container">
                <div className={styles.inventory_title}><p>Вы получите результат без<br/> инвентаря — для&nbsp;марафона<br/> <span>нужен только&nbsp;коврик!</span></p></div>
                <div className={styles.inventory_subtitle}>Но можно обойтись даже&nbsp;без&nbsp;него</div>
            </div>
        </div>
    )
}

export default Inventory;