import './makeyouself.css';

const Makeyouself = () => {
    return (
        <div className="makeyouself">
            <div className="container">
            <div className="makeyouself_title"><span>Займитесь собой</span><br/> без чувства вины</div>
            <div className="makeyouself_block">
                <div className="makeyouself_item">
                <div className="makeyouself_item-info">
                <div className="makeyouself_item-description">Через день — новая тренировка<br/> на&nbsp;20&nbsp;минут в&nbsp;формате <strong>«повторяй<br/> за&nbsp;мной»</strong></div>
                <div className="makeyouself_item-number">01</div>
                </div>
                </div>
                <div className="makeyouself_item">
                <div className="makeyouself_item-info">
                <div className="makeyouself_item-description">Еженедельно — <strong>замеры для самоконтроля</strong><br/> и&nbsp;мотивации от&nbsp;видимых результатов</div>
                <div className="makeyouself_item-number">02</div>
                </div>
                </div>
                <div className="makeyouself_item">
                <div className="makeyouself_item-info">
                <div className="makeyouself_item-description">В дополнительных материалах —<br/> <strong>рекомендации по&nbsp;питанию</strong><br/> от&nbsp;профессионалах</div>
                <div className="makeyouself_item-number">03</div>
                </div>
                </div>
                <div className="makeyouself_item">
                <div className="makeyouself_item-info">
                <div className="makeyouself_item-description"><strong>Инвентарь, который можно заменить.</strong><br/> Коврик, 2&nbsp;гантели по&nbsp;2&nbsp;кг, фитнес-резинка<br/> с сопротивлением до&nbsp;5&nbsp;кг и&nbsp;утяжелители</div>
                <div className="makeyouself_item-number">04</div>
                </div>
                </div>
            </div>
            <div className="makeyouself_more">
                <div className="makeyouself_more-img">
                <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/makemore.png' alt="" />
                </div>
                <div className="makeyouself_more-description">
                <p>Можно тренироваться как дома,<br/> так и&nbsp;в&nbsp;зале</p>
                <p><span>Дайте папе возможность наладить связь с&nbsp;ребенком,<br/> а&nbsp;сами занимайтесь в&nbsp;удовольствие.</span></p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Makeyouself;