import React, { useEffect, useState } from 'react';

import { Center } from "@chakra-ui/react";
import { LoginWidget,  ReferralCode, ReferralLink, SalesWidget, getReferralLink, getReferralCode, ShowForSegment, HideForSegment } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized, useInstance } from "@com.marathonium/web2-utils";

export const Reftest = () => {
    const [link, setLink] = useState('')

    useEffect(() => {
      getReferralCode().then(res => {
        setLink(window.location.origin+'/l/refregpage?referral='+res.code)
      })
      
    }, [])
    
    function LoginForm() {
        return (
          <>
            <Authorized>
                <div style={{width: 1400, margin: '0 auto', paddingTop: 60}}>
                    <div style={{display: 'flex'}}>Ваш реферальный код: <b style={{display: 'inline-block', marginLeft: 5, fontSize: 18}}><ReferralCode/></b></div>
                    <div style={{margin: '10px 0 40px'}}>Ваша реферальная ссылка: <a href={link} style={{fontWeight: 'bold', fontSize: 18}}>{link}</a></div>
                    <ShowForSegment id={window.lps_settings.segments.ref_one_user}>
                        <div style={{border: '1px solid #d1d1d1', borderRadius: 5, display: 'inline-block', padding: '5px 15px'}}>По вашей ссылке зарегистрировался 1 человек</div>
                    </ShowForSegment>
                    <ShowForSegment id={window.lps_settings.segments.ref_two_users}>
                        <div style={{border: '1px solid #d1d1d1', borderRadius: 5, display: 'inline-block', padding: '5px 15px'}}>По вашей ссылке зарегистрировался 2 человека</div>
                    </ShowForSegment>
                    <ShowForSegment id={window.lps_settings.segments.ref_three_users}>
                        <div style={{border: '1px solid #d1d1d1', borderRadius: 5, display: 'inline-block', padding: '5px 15px'}}>По вашей ссылке зарегистрировался 3 человека</div>
                    </ShowForSegment>
                </div>
            </Authorized>
            <Anonymous>
                <Center h="80vh">
                  <LoginWidget />
                </Center>
            </Anonymous>
          </>
        );
      }

    return (
      <LoginForm/>
    );
};