import { Center } from "@chakra-ui/react";
import { LoginWidget, addUserToGroup } from "@com.marathonium/web2-ui";
import { fetchWithN8n2, setJWT, Anonymous, Authorized } from "@com.marathonium/web2-utils";
import { useEffect, useRef } from "react";

export const Guest = () => {
    const inputRef = useRef(null)
    
    const loginByAnonimous = (e) => {
		e.preventDefault();

		let usp = new URLSearchParams(window.location.search);

		fetchWithN8n2("web.user.login_anonymous.v1", {
			platform: window["97e74e0a-d6d2-4052-a387-cd4a619f7b94"].platform,
			gateway: window["97e74e0a-d6d2-4052-a387-cd4a619f7b94"].gateway,
			utm_source: usp.get("utm_source"),
			utm_medium: usp.get("utm_medium"),
			utm_content: usp.get("utm_content"),
		}).then((j) => {
			if (j.success === true) {
				setJWT(j.token);

				var _tmr = window._tmr || (window._tmr = []);
				_tmr.push({
					id: "3396944",
					type: "pageView",
					start: new Date().getTime(),
				});
				(function (d, w, id) {
					if (d.getElementById(id)) return;
					var ts = d.createElement("script");
					ts.type = "text/javascript";
					ts.async = true;
					ts.id = id;
					ts.src = "https://top-fwz1.mail.ru/js/code.js";
					var f = function () {
						var s = d.getElementsByTagName("script")[0];
						s.parentNode.insertBefore(ts, s);
					};
					if (w.opera == "[object Opera]") {
						d.addEventListener("DOMContentLoaded", f, false);
					} else {
						f();
					}
				})(document, window, "tmr-code");
				_tmr.push({ type: "reachGoal", id: 3396944, goal: "reg-fatlost" });
				window._tmr.push({
					type: "reachGoal",
					id: 3396944,
					goal: "reg-fatlost",
				});

                
				redirectFunction();
			}
		});

		return false;
	};

    const redirectFunction = () => {
		addUserToGroup(window.lps_settings.user_groups.fatlost);
		window.location.href = "https://gymteam.ru/practics/50a2d2dc-b6b0-4bd7-9f44-700476f14faf";
	};

    useEffect(()=>{
        inputRef.current.click()
    }, [])
    
    // function LoginForm() {
    //     return (
    //       <>
    //         <Authorized>
    //             <Center h="100vh">
    //             <div>Авторизован</div>
    //             </Center>
    //         </Authorized>
    //         <Anonymous>
    //             <Center h="100vh">
    //             <a href="/login_by_anonymous" onClick={loginByAnonimous} ref={inputRef}>
	// 				Войти как гость
	// 			</a>
    //             <LoginWidget onSuccess={()=>alert('Success')} />
    //             </Center>
    //         </Anonymous>
    //       </>
    //     );
    //   }

    return (
      <Center h="100vh">
          <a href="/login_by_anonymous" onClick={loginByAnonimous} ref={inputRef} style={{display:'none'}}></a>
      </Center>
    );
};