import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import { useEffect, useState } from 'react';
import Promo from './components/Promo';
import Footer from '../../components/Footer';
import Logos from './components/Logos';
import About from './components/About';
import Start from './components/Start';
import Code from './components/Code';
import './style.css';

export const L2024_01_Corporate = () => {
    const [isActive, setIsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const scroll = (name) => {
        const section = document.querySelector( name ).offsetTop;
        window.scrollTo({top: section - 60, behavior: "smooth"});
        setIsActive(false)
    };

    useEffect(() => {
        const onPageLoad = () => {
            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        };
        
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
      
    }, []);

    return (
        <>
        <DocumentTitle value='КОРПОРАТИВНЫЙ СПОРТ'/>
        <DocumentDescription value='КОРПОРАТИВНЫЙ СПОРТ' />
        {
        !isLoading ?
        <div className='corporate_24_01'>
            <Promo scroll={() => scroll('.scrollhere')} />
            <Logos />
            <About />
            <Start />
            <Code />
            <Footer />
        </div>
        :
        <div className='gt_preloader'>
            <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preloader_logo.svg" alt="" />
            <div className='gt_loader'></div>
        </div>
        }
        </>
    );
};