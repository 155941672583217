import './gallery.css';
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, Lazy} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

const Gallery = ({scrollStart}) => {
    const [count, setCount] = useState(15)
    const [windowWidth, setWindowWidth] = useState(0)
    const [items, setItems] = useState([
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/5.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/6.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/7.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/8.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/9.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/10.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/11.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/12.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/13.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/14.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/15.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/16.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/17.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/18.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/19.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/20.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/21.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/22.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/23.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/24.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/25.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/26.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/27.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/28.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/29.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/30.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/31.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/32.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/33.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/34.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/35.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/36.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/37.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/38.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/39.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/40.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/41.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/42.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/43.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/44.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/45.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/46.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/47.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/48.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/49.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/common/gallery/50.jpg'}
    ]);
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const getItems = useCallback(() => {
        return items.map((item, index) => {
        if(index+1 <= count) {
        return (
            <a key={item.src} className="gallery-item" data-src={item.src}>
                <img src={item.src} alt={item.src} loading="lazy" />
            </a>
        );
        }
        });
    }, [count]);

    const showMore = () => {
        if(count > 50) {
            setCount(15)
            scrollStart()
        } else {
            setCount(count + 15)
        }
    }

    useLayoutEffect(() => {
        function updateSize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    
    return (
        <div className="gallery scrollstart">
          <div className="container">
            <div className="gallery_title">Программы Кати Усмановой –</div>
            <div className="gallery_subtitle">это всегда результат, подтверждённый «До и После» учениц</div>
            {windowWidth > 1199 ? 
            <>
                <LightGallery
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    mode="lg-fade"
                    elementClassNames="gallery_block"
                >
                {getItems()}
                </LightGallery>
                <button className='main_btn_p' onClick={showMore}>{count>50 ? 'Скрыть изображения' : 'Показать еще'}</button>
            </>
            : 
            <>
                <Swiper 
                lazy={true}
                    slidesPerView={1}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                        
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
                    modules={[Navigation, Lazy]}
                >
                    <div className="slider_nav">
                        <div ref={navigationPrevRef} className='slider_btn_prev'></div>
                        <div ref={navigationNextRef} className='slider_btn_next'></div>
                    </div>
                    {items.map(item => 
                    <SwiperSlide key={item.src}>
                        <img data-src={item.src} alt="" className='swiper-lazy' />
                    </SwiperSlide>
                    )}
                </Swiper>
            </>
            }
          </div>
        </div>
    );
};

export default Gallery;