import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className={styles.createbody_weight}></div>
            <div className="container">
                <div className={styles.createbody_title}>СОЗДАЙТЕ ТЕЛО <span>ПО СИСТЕМЕ<br/> КАТИ УСМАНОВОЙ</span></div>
                <div className={styles.createbody_subtitle}>Спортивные ягодицы&nbsp;— «высший пилотаж» в&nbsp;работе над&nbsp;фигурой:<br/> <strong>женственно, красиво, сексуально.</strong></div>
                <div className={styles.create_pink}>Но что делать, если&nbsp;нет возможности ходить в&nbsp;фитнес-клуб и&nbsp;заниматься персонально с&nbsp;тренером? <strong>Можно&nbsp;ли накачать попу в&nbsp;домашних условиях?</strong></div>
                <div className={styles.create_yellow}>Марафон упругой попы&nbsp;1.0&nbsp;— классика в&nbsp;накачке ягодиц. <strong>Программа, завоевавшая сердца тысяч участниц!</strong></div>
            </div>
        </div>
    )
}

export default Createbody;