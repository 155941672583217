import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Программа точно подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li><b>Мечтаете создать идеальные ягодицы,</b> занимаясь в&nbsp;своём темпе</li>
                            <li><b>Хотите поддерживать</b> хорошую спортивную форму</li>
                            <li><b>Боитесь сразу давать большую нагрузку,</b> но&nbsp;готовы двигаться постепенно</li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li><b>Хотите добавить объёмов,</b> чтобы&nbsp;сделать фигуру спортивной и&nbsp;соблазнительной</li>
                            <li><b>Испытываете трудности</b> в&nbsp;наборе мышечной массы или&nbsp;похудении</li>
                            <li><b>Не знаете, где&nbsp;найти мотивацию</b>, чтобы&nbsp;начать и&nbsp;не&nbsp;бросить</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;