import { extendTheme } from '@chakra-ui/react';

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
    fonts: {
        bebasNeue: `'Bebas Neue', cursive`,
        montserrat: `'Montserrat', sans-serif`,
        gilroy: `'Gilroy', sans-serif`,
    },
    breakpoints: {
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
    },
});
