import './start.css';
import styles from './start.module.css';

import { SalesWidget } from "@com.marathonium/web2-ui";

const Start = () => {
    return (
        <div className="startsection">
          <div className="container">
            <div className="start_title">Пройдите курс для беременных,<br/> <span>чтобы сохранить красоту</span><br/> и&nbsp;быстро вернуть стройность<br/> после родов</div>
            <div className="start_subtitle">
              <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/clock_icon.jpg' alt="" />
              <p>Старт – {window.lps_settings.pregnancy.date}</p>
            </div>
            <div className='pregnancy_start'>
              <SalesWidget id={window.lps_settings.forms.pregnancy_id} />
            </div>
            <div className="start_info">
                {/* <div className={styles.start_parts}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/pregnancy/start_icon.png" alt="" />
                    <div className={styles.start_parts__title}>Оплатите Долями без&nbsp;банка, переплат и&nbsp;рисков</div>
                    <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж<br/> на&nbsp;4&nbsp;части по&nbsp;1&nbsp;250&nbsp;₽.Всего на&nbsp;6&nbsp;недель.</div>
                    <div className={styles.start_parts_block}>
                        <div className={styles.start_parts_item}>
                            <div className={styles.start_parts_number}><p>1</p></div>
                            <p>1250 ₽<br/> <span>сегодня</span></p>
                        </div>
                        <div className={styles.start_parts_item}>
                            <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                            <p>1250 ₽<br/> <span>через 2&nbsp;недели</span></p>
                        </div>
                        <div className={styles.start_parts_item}>
                            <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                            <p>1250 ₽<br/> <span>через 4&nbsp;недели</span></p>
                        </div>
                        <div className={styles.start_parts_item}>
                            <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                            <p>1250 ₽<br/> <span>через 6&nbsp;недель</span></p>
                        </div>
                    </div>
                </div> */}
                <div className="start_info-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/tnkf_icon.png' alt="" />
                </div>
                <div className="start_info-description">
                  <p>Курс для беременных доступен в рассрочку</p>
                  <span>На 6&nbsp;месяцев без процентов и&nbsp;переплат <b>от&nbsp;582 ₽</b> в&nbsp;месяц. Первый платёж — через<br/> 30 дней. Только для граждан РФ.</span>
                </div>
              </div>
          </div>
        </div>
    );
};

export default Start;