import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_pretitle}>Тогда как&nbsp;избавиться от&nbsp;целлюлита?</div>
                <div className={styles.whaityou_title}>ИЗБАВЬТЕСЬ ОТ&nbsp;ЦЕЛЛЮЛИТА<br/> БЕЗ&nbsp;СТРЕССА</div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ТРЕНИРОВКИ В&nbsp;ФОРМАТЕ «ПОВТОРЯЙ ЗА КАТЕЙ» 🙋‍</div>
                            <div className={styles.whaityou_info__text}>В приложении вам будут открываться тренировки с&nbsp;Катей Усмановой. Не&nbsp;нужно менять свой график&nbsp;— на&nbsp;выполнение есть целые сутки. Просто включайте видео, смотрите и&nbsp;повторяйте.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ВСЕГО 20-30 МИНУТ НА&nbsp;ТРЕНИРОВКУ 🤸‍</div>
                            <div className={styles.whaityou_info__text}>Такие занятия точно впишутся в&nbsp;ваш&nbsp;распорядок дня, ведь&nbsp;вам не&nbsp;потребуется тратить время на дорогу. Вы&nbsp;сможете тренироваться и&nbsp;дома, и&nbsp;в&nbsp;любом другом подходящем месте.</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ТЕХНИКИ САМОМАССАЖА 🦸‍</div>
                            <div className={styles.whaityou_info__text}>Катя Усманова покажет 2&nbsp;техники массажа, которые сделают вашу кожу упругой и&nbsp;гладкой. Вы узнаете, что&nbsp;происходит в&nbsp;слоях кожи, пораженных целлюлитом.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;