import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_title}>МАРАФОН ПЛОСКОГО ЖИВОТА — это…</div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ТРЕНИРОВКИ<br/> ПО&nbsp;15&ndash;30&nbsp;МИНУТ&nbsp;В&nbsp;ДЕНЬ 🤸‍♀️</div>
                            <div className={styles.whaityou_info__text}>Ежедневно в&nbsp;личном кабинете будет открываться новая тренировка. Вам не&nbsp;придется менять свой график&nbsp;— на&nbsp;выполнение есть целые&nbsp;сутки. Просто включайте видео и&nbsp;повторяйте.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>УПРАЖНЕНИЯ НЕ&nbsp;ТОЛЬКО НА&nbsp;ПРЕСС</div>
                            <div className={styles.whaityou_info__text}>Основная цель марафона&nbsp;— сделать животик плоским, рельефным и&nbsp;соблазнительным. Именно поэтому акцент сделан на&nbsp;проработку пресса, но&nbsp;в&nbsp;программе есть тренировки на&nbsp;ноги и&nbsp;ягодицы.</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>МОТИВАЦИЯ И&nbsp;МОРАЛЬНАЯ ПОДДЕРЖКА 🦸‍♀️</div>
                            <div className={styles.whaityou_info__text}>Ощущение, что ты&nbsp;не&nbsp;одна&nbsp;— это&nbsp;очень важно. Записавшись на&nbsp;марафон, вы&nbsp;попадёте в&nbsp;фитнес-комьюнити, где&nbsp;вас со&nbsp;всех сторон готовы поддержать единомышленницы и&nbsp;Катя Усманова.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;