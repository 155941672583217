import styles from './afterall.module.css';

const Afterall = () => {
    return (
        <div className={styles.afterall}>
            <div className="container">
                <div className="l2211_str1_title">В ИТОГЕ ВЫ <span>ПОЛУЧИТЕ</span></div>
                <div className="l2211_str1_subtitle">подтянутое, стройное тело и&nbsp;ежедневный заряд энергии:</div>
                <div className={styles.afterall_block}>
                    <div className={styles.afterall_item}>
                        <div className={styles.afterall_info}>
                            <div className={styles.afterall_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str1/afterall_1.png" alt="" />
                            </div>
                            <p><strong>Полюбите своё<br/> отражение</strong> в&nbsp;зеркале и&nbsp;станете уверенней</p>
                        </div>
                    </div>
                    <div className={styles.afterall_item}>
                        <div className={styles.afterall_info}>
                            <div className={styles.afterall_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str1/afterall_2.png" alt="" />
                            </div>
                            <p><strong>Повысите настроение</strong> и&nbsp;работоспособность</p>
                        </div>
                    </div>
                    <div className={styles.afterall_item}>
                        <div className={styles.afterall_info}>
                            <div className={styles.afterall_info__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str1/afterall_3.png" alt="" />
                            </div>
                            <p><strong>Перестанете страдать</strong><br/> от&nbsp;диет и&nbsp;голодать</p>
                        </div>
                    </div>
                </div>
                <div className={styles.afterall_text}>Всё это без инвентаря — <strong>для марафона нужен только коврик!</strong><br/> Но можно обойтись даже без него 😉</div>
            </div>
        </div>
    )
}

export default Afterall;