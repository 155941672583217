import { useEffect, useRef, useState } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Lazy, Navigation} from 'swiper';
import 'swiper/css';
import "swiper/css/grid";
import "swiper/css/navigation";

import styles from './gift.module.css';

const Gift = ({scroll}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
      const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
      );

      function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(
          getWindowDimensions()
        );
      
        useEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener("resize", handleResize);
          return () => window.removeEventListener("resize", handleResize);
        }, []);
      
        return windowDimensions;
      }

      const { height, width } = useWindowDimensions();
    return (
        <div className={styles.gift}>
            
                <div className={styles.gift_title}><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka/text2.png" alt="" /><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka/text2_mob.png" alt="" /></div>
                <div className={styles.gift_subtitle}>за каждую покупку</div>
            
            {width > 991 ? 
                <div className={styles.gift_block}>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift1.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Путеводитель</div>
                                <div className={styles.gift_slide__text}>«Оборудование и инвентарь в тренажёрном зале»</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift2.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Сборник</div>
                                <div className={styles.gift_slide__text}>«Продукты с высоким содержанием белка для набора мышечной массы»</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift3.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Гайд</div>
                                <div className={styles.gift_slide__text}>«Правила хорошего тона в тренажёрном зале»</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift4.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Гайд</div>
                                <div className={styles.gift_slide__text}>«Как правильно читать показатели умных весов»</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift5.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Инструкция</div>
                                <div className={styles.gift_slide__text}>«Одежда, обувь и аксессуары для фитнеса: как выбрать и ухаживать»</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gift_item}>
                        <div className={styles.gift_slide}>
                            <div className={styles.gift_slide__img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift6.png" alt="" />
                            </div>
                            <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Гайд</div>
                                <div className={styles.gift_slide__text}>Дисциплина: как превратить спорт в привычку и не сдаться на третий день</div>
                            </div>
                        </div>
                    </div>
            </div>
            : 
            <div className="container">
            <div className={styles.gift_slider2}>
                <div className={styles.slider_nav}>
                            <div ref={navigationPrevRef} className={styles.slider_btn_prev}><img src="https://gymteam.kinescopecdn.net/img/l/2023/goodbyeoversize/prev.svg" alt="" /></div>
                            <div ref={navigationNextRef} className={styles.slider_btn_next}><img src="https://gymteam.kinescopecdn.net/img/l/2023/goodbyeoversize/next.svg" alt="" /></div>
                        </div>
                    <Swiper 
                        lazy={true}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        onSwiper={(swiper) => {
                            setTimeout(() => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current
                            
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }}
                        slidesPerView={1}
                        loop
                        modules={[Navigation, Lazy]}
                    >
                        <SwiperSlide>
                            <div className={styles.gift_slide}>
                                <div className={styles.gift_slide__img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift1.png" alt="" />
                                </div>
                                <div className={styles.gift_slide__info}>
                                    <div className={styles.gift_slide__bef}>Путеводитель</div>
                                    <div className={styles.gift_slide__text}>«Оборудование и инвентарь в тренажёрном зале»</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.gift_slide}>
                                <div className={styles.gift_slide__img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift2.png" alt="" />
                                </div>
                                <div className={styles.gift_slide__info}>
                                <div className={styles.gift_slide__bef}>Сборник</div>
                                    <div className={styles.gift_slide__text}>«Продукты с высоким содержанием белка для набора мышечной массы»</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.gift_slide}>
                                <div className={styles.gift_slide__img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift3.png" alt="" />
                                </div>
                                <div className={styles.gift_slide__info}>
                                    <div className={styles.gift_slide__bef}>Гайд</div>
                                    <div className={styles.gift_slide__text}>«Правила хорошего тона в тренажёрном зале»</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.gift_slide}>
                                <div className={styles.gift_slide__img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift4.png" alt="" />
                                </div>
                                <div className={styles.gift_slide__info}>
                                    <div className={styles.gift_slide__bef}>Гайд</div>
                                    <div className={styles.gift_slide__text}>«Как правильно читать показатели умных весов»</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.gift_slide}>
                                <div className={styles.gift_slide__img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift5.png" alt="" />
                                </div>
                                <div className={styles.gift_slide__info}>
                                    <div className={styles.gift_slide__bef}>Инструкция</div>
                                    <div className={styles.gift_slide__text}>«Одежда, обувь и аксессуары для фитнеса: как выбрать и ухаживать»</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={styles.gift_slide}>
                                <div className={styles.gift_slide__img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/gift6.png" alt="" />
                                </div>
                                <div className={styles.gift_slide__info}>
                                    <div className={styles.gift_slide__bef}>Гайд</div>
                                    <div className={styles.gift_slide__text}>Дисциплина: как превратить спорт в привычку и не сдаться на третий день</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                </div>
            }

            <div className={styles.promo_btn} onClick={scroll}><p>ЗАБРАТЬ КУРС И БОНУСЫ</p></div>
                </div>
        
    )
}

export default Gift;