import './youget.css';

const Youget = ({scroll}) => {
    return (
        <div className="youget">
          <div className="youget_arrow">
            <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/arrow_icon.png' alt="" />
          </div>
          <div className="container">
            <div className="youget_title">ВЫ ПОЛУЧИТЕ:</div>
            <div className="yuoget_block">
              <div className="youget_item">
                <div className="youget_item-number">01</div>
                <div className="youget_item-description"><strong>Подтянутое тело</strong> даже при&nbsp;естественном наборе веса</div>
              </div>
              <div className="youget_item">
                <div className="youget_item-number">02</div>
                <div className="youget_item-description"><strong>Ощущение спокойствия</strong> и&nbsp;уверенности в&nbsp;период ожидания малыша</div>
              </div>
              <div className="youget_item">
                <div className="youget_item-number">03</div>
                <div className="youget_item-description">Возможность <strong>облегчить неприятные симптомы</strong> на&nbsp;поздних сроках</div>
              </div>
              <div className="youget_item">
                <div className="youget_item-number">04</div>
                <div className="youget_item-description"><strong>Профилактику</strong> ряда послеродовых проблем</div>
              </div>
              <div className="youget_item">
                <div className="youget_item-number">05</div>
                <div className="youget_item-description"><strong>Лёгкое восстановление</strong> стройности и&nbsp;здоровья после родов</div>
              </div>
              <div className="youget_item">
                <div className="youget_item-number">06</div>
                <div className="youget_item-description"><strong>Силы,</strong> чтобы адаптироваться в&nbsp;роли молодой мамы</div>
              </div>
            </div>
            <button className='main_btn_p' onClick={scroll}>Участвовать</button>
          </div>
          <div className="youget_face">
            <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/face_icon.png' alt="" />
          </div>
        </div>
    );
};

export default Youget;