import styles from './line.module.css';

const Line = () => {
    return (
        <div className={styles.line}>
            <div className="container">
                <div className={styles.title}>Мы настолько уверены<br/> в&nbsp;курсе, что</div>
                <div className={styles.subtitle}>даём сразу<br/> 5&nbsp;гарантий</div>
                <div className={styles.preims_line}>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__text}>Гарантия <b>лучшей<br/> цены</b></div>
                    </div>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__text}>Гарантия <b>уникальности</b><br/> программы</div>
                    </div>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__text}>Гарантия <b>соблюдения<br/> сроков</b></div>
                    </div>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__text}>Гарантия <b>юридической<br/> чистоты</b> сделки</div>
                    </div>
                    <div className={styles.preims_line__item}>
                        <div className={styles.preims_line__text}>Гарантия предоставления<br/> <b>необходимой информации</b></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Line;