import Countdown from 'react-countdown';
import styles from './localplatform.module.css';

const Localplatform = ({version, isColor, isRadius, image, renderer, scroll}) => {
    return (
        <div className={`${styles.platformgt} ${version == 2 && styles.platform_black} ${version == 3 && styles.platform_gray}` } style={{backgroundColor: isColor && isColor}}>
            <div className="container platform_container">
                <div className={styles.platformgt_block} style={{borderRadius: isRadius && isRadius}}>
                    <div className={styles.platformgt_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" /></div>
                    <div className={styles.s1}>Бонус №3</div>
                    <div className={styles.platformgt_title}>ПОДПИСКА<br/> НА&nbsp;ПЛАТФОРМУ GYMTEAM<br/> <span>НА&nbsp;1&nbsp;ГОД</span> <p><em>9&nbsp;588&nbsp;₽.</em> БЕСПЛАТНО</p></div>
                    <div className={styles.platformgt_image}>
                        {image ?
                            <>
                                <img className={styles.platformgt_image_d} src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/platform.png" alt="" />
                                <img className={styles.platformgt_image_m} src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/platform.png" alt="" />
                            </>
                        :
                            <>
                                {/* <img className={styles.platformgt_image_d} src="https://gymteam.kinescopecdn.net/img/l/common/platform_2.png" alt="" /> */}
                                {/* <img className={styles.platformgt_image_m} src="https://gymteam.kinescopecdn.net/img/l/common/platform_2.png" alt="" /> */}
                                <img className={styles.platformgt_image_d} src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/phone3.jpg" alt="" />
                                <img className={styles.platformgt_image_m} src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/phone3.jpg" alt="" />
                            </>
                        }
                        
                    </div>
                    <div className={styles.platformgt_subtitle}>
                        <p>Уникальные программы от&nbsp;тренеров, которых Катя отобрала лично и&nbsp;с&nbsp;которыми занимается сама.</p>
                        <p>Вдохновляющие видеотренировки без изнуряющих нагрузок, которые разработаны с&nbsp;учётом последних достижений в&nbsp;науке: анатомии, физиологии и&nbsp;биомеханики движения человека.</p>
                    </div>
                    <div className={styles.platformgt_info}>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>&gt;3000</div>
                            <p>тренировок</p>
                        </div>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>79</div>
                            <p>программ</p>
                        </div>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>оценка 4,9</div>
                            <div>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/app1.svg" alt="" /> Apple</div>
                                <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/app2.svg" alt="" /> Android</div>
                            </div>
                        </div>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>каждую неделю</div>
                            <p>новые тренировки</p>
                        </div>
                    </div>
                </div>

                <div className={styles.btn} onClick={scroll}>Забрать все подарки</div>
                <div className={styles.countdown}>
                    <p>Бонусы сгорят через:</p>
                    <div className={styles.countdown_timer}>
                        <Countdown date={Date.now() + 3600000} renderer={renderer} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Localplatform;