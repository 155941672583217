import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from './start.module.css';
import { useState } from "react";


const Starttest = () => {
    const [form, setForm] = useState(false);

    return (
        <div className={styles.oh}>
            <div className={styles.line}>
                <div className={styles.line_text}>
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                </div>
            </div>

            <div className={('scrollhere ') + styles.start}>
                <div className="container">
                    <div className={styles.block}>
                        <div className={styles.pretitle}>Такое предложение бывает <b>только раз в&nbsp;году!</b></div>
                        <div className={styles.title}>Прямо сейчас забирай<br/> все программы Кати Усмановой<br/> <span>С&nbsp;ВЫГОДОЙ&nbsp;91%</span></div>
                        <div className={styles.prices}>7&nbsp;990&nbsp;<i>₽</i> <em>вместо 85&nbsp;990&nbsp;<i>₽</i></em> <span>(от&nbsp;1 331,5&nbsp;₽/мес.)</span></div>
                        <div className={styles.remains}>
                            <div className={styles.remains_text}>Осталось мест по&nbsp;спецпредложению на&nbsp;12&nbsp;программ с&nbsp;домашними тренировками от&nbsp;Кати Усмановой <b>по&nbsp;самой выгодной цене:</b></div>
                            <div className={styles.remains_number}>{window.lps_settings.other.count}</div>
                        </div>
                        <div className={styles.pretitle}>Такое предложение бывает <b>только раз в&nbsp;году!</b></div>
                        <div className={styles.dts}>
                            <div className={styles.dts_item}>
                                <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts1.svg" alt="" /></div>
                                <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 1&nbsp;год</div>
                            </div>
                            <div className={styles.dts_item}>
                                <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts2.svg" alt="" /></div>
                                <div className={styles.dts_item_text}>Старт тренировок&nbsp;&mdash; сразу после оплаты</div>
                            </div>
                            <div className={styles.dts_item}>
                                <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts3.svg" alt="" /></div>
                                <div className={styles.dts_item_text}>Чат с&nbsp;куратором&nbsp;&mdash; с&nbsp;18&nbsp;ноября</div>
                            </div>
                        </div>
                        <div className={styles.info_all}>
                            <div className={styles.info_all_name}>Все программы Кати Усмановой</div>
                            <div className={styles.info_all_prices}>7&nbsp;990&nbsp;₽ <em>85&nbsp;990&nbsp;₽</em> <span>от&nbsp;1 331,5&nbsp;₽/мес.</span></div>
                        </div>
                        <div className={styles.info}>
                            <div className={styles.info_item}>
                                <p><b>Марафон</b> &laquo;Стройность 1.0&raquo;</p>
                                <span>5&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Марафон</b> &laquo;Упругой попы 1.0&raquo;</p>
                                <span>5&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Марафон</b> &laquo;Упругой попы 2.0&raquo;</p>
                                <span>7&nbsp;900&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Марафон</b> &laquo;Плоского живота&raquo;</p>
                                <span>5&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Марафон</b> &laquo;Антицеллюлитный&raquo;</p>
                                <span>7&nbsp;500&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Марафон</b> &laquo;Убираем лишний жир&raquo;</p>
                                <span>7&nbsp;900&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Курс</b> &laquo;Восстановление после родов&raquo;</p>
                                <span>7&nbsp;900&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Курс</b> &laquo;Питание&raquo;</p>
                                <span>10&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Курс</b> &laquo;Идеальной попы 2.0&raquo;</p>
                                <span>6&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Курс</b> &laquo;Идеальной попы 3.0&raquo;</p>
                                <span>12&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Курс</b> &laquo;Жиросжигающий&raquo;</p>
                                <span>7&nbsp;000&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>Универсальные тренировки на&nbsp;ягодицы</b></p>
                                <span>4&nbsp;790&nbsp;₽</span>
                            </div>
                        </div>
                        <div className={styles.info_more}>+ чат с&nbsp;куратором на&nbsp;1&nbsp;месяц</div>

                        <div className={styles.info_all}>
                            <div className={styles.info_all_name}>Плюс бонусы:</div>
                            <div className={styles.info_all_prices}>Бесплатно <em>6&nbsp;687&nbsp;₽</em></div>
                        </div>
                        <div className={styles.info}>
                            <div className={styles.info_item}>
                                <p><b>+ 11&nbsp;полезных гайдов</b></p>
                                <span>3&nbsp;990&nbsp;₽</span>
                            </div>
                            <div className={styles.info_item}>
                                <p><b>+ подписка на&nbsp;тренировки GymTeam на&nbsp;3&nbsp;месяца</b></p>
                                <span>2&nbsp;697&nbsp;₽</span>
                            </div>
                        </div>

                        <div style={{margin: 'auto', padding: 12, background: '#FB4F54', cursor: 'pointer', color: '#fff', width: 300, textAlign: 'center'}} onClick={() => setForm(true)}>КНОПКА</div>
                        {form && <SalesWidget id={window.lps_settings.forms.test_id} buttonText="ОФОРМИТЬ ЗАКАЗ" />}

                        <div className={styles.pay}>
                            <div className={styles.pay_item}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank1.png" alt="" />
                                <p>Рассрочка без процентов от&nbsp;1 331,5&nbsp;₽ в&nbsp;месяц</p>
                                <span>Ты&nbsp;можешь оформить заказ в&nbsp;рассрочку: на&nbsp;6&nbsp;месяцев без процентов<br/> и&nbsp;предварительного взноса. Только для граждан&nbsp;РФ.</span>
                            </div>
                            <div className={styles.pay_item}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank2.png" alt="" />
                                <p>Оплати Долями, без банка, переплат и&nbsp;рисков</p>
                                <span>Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; раздели платёж на&nbsp;4&nbsp;части от&nbsp;1 997,5&nbsp;₽.<br/> Всего на&nbsp;6&nbsp;недель.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Starttest;