import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/universalpop/logo.svg' alt="gymteam" /></div>
                <div className={styles.promo_pretitle}>Онлайн-курс Кати Усмановой</div>
                <div className={styles.promo_title}>ЖИРО<span>СЖИГАЮЩИЙ</span></div>
                <div className={styles.promo_subtitle}><strong>45&nbsp;дней и 3&nbsp;класса тренировок</strong> для легендарного<br/> эффекта по&nbsp;системе чемпионки фитнес-бикини</div>
                <div className={styles.promo_items}>
                    <div className={styles.promo_item1}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/icon1.png" alt="" /> <p>Для любого уровня</p></div>
                    <div className={styles.promo_item2}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/icon2.png" alt="" /> <p>45 дней</p></div>
                    <div className={styles.promo_item3}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/icon3.png" alt="" /> <p>С инвентарём</p></div>
                </div>
                <div className={styles.promo_btns}>
                    <div className='l2212_fat_btn' onClick={scroll}><p>Участвовать</p></div>
                    <div className={styles.promo_date}><p>Старт {window.lps_settings.dates.mainlands}</p></div>
                </div>
            </div>
            <div className={styles.promo_point1}>#<span>сжечь</span>жир</div>
            <div className={styles.promo_point2}>#придать<span>рельеф</span></div>
            <div className={styles.promo_point3}>#подтянуть<span>тело</span></div>
        </div>
    )
}

export default Promo;