import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Курс точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Стремитесь похудеть, но&nbsp;при этом <strong>питаться вкусно и&nbsp;полезно</strong></li>
                            <li>Тренируетесь профессионально&nbsp;— <strong>вам нужна энергия и&nbsp;рельеф</strong></li>
                            <li>Перепробовали самые популярные диеты, <strong>но&nbsp;срывались раз&nbsp;за&nbsp;разом</strong></li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Хотите изменить не&nbsp;только качество тела, <strong>но&nbsp;и&nbsp;жизни</strong></li>
                            <li><strong>Готовы позаботиться</strong> о&nbsp;здоровье себя и&nbsp;своих близких</li>
                            <li>Беременны или кормите грудью&nbsp;— <strong>вам важно питаться правильно</strong></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;