import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className="container">
                <div className={styles.createbody_title}>ИЗМЕНИТЕ КАЧЕСТВО ТЕЛА<br/> <span>ПО&nbsp;СИСТЕМЕ КАТИ УСМАНОВОЙ</span></div>
                <div className={styles.createbody_subtitle}><strong>Целых 3&nbsp;программы в&nbsp;одной:</strong> силовая, интенсивная, умеренная</div>
                <div className={styles.createbody_block}>
                    <div className={styles.createbody_item}>
                        <ul>
                            <li><strong>Индивидуальный подход</strong> — возможность выбрать нагрузку под&nbsp;свои цели</li>
                            <li>Только методики, доказавшие <strong>свою эффективность</strong></li>
                            <li><strong>Рекомендации по питанию</strong> от&nbsp;экспертов для&nbsp;ВАУ-результата</li>
                        </ul>
                    </div>
                    <div className={styles.createbody_item}>
                        <ul>
                            <li>Тренировки для тех, кто готов к&nbsp;большему: <strong>накачать, сжечь, подтянуть</strong></li>
                            <li>Времени достаточно, чтобы&nbsp;сделать спорт частью жизни</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Createbody;