import styles from './style.module.css';

export function L2024_07_Thanks() {

  return (
    <div className={styles.block}>
      <div className={styles.title}>Спасибо, ваш заказ оплачен!</div>
      <a href='/login' className={styles.btn}>Открыть &laquo;Мои тренировки&raquo;</a>
    </div>
  );
}
