import './readyform.css';
import Countdown from 'react-countdown';
import styles from './start.module.css';
import { SalesWidget } from "@com.marathonium/web2-ui";

const Readyform = ({date, renderer}) => {
    return (
        <div className="readyform">
            <div className="container">
            <div className="readyform_title">ГОТОВЫ ВЕРНУТЬСЯ<br/> В ДОРОДОВУЮ ФОРМУ</div>
            <div className="readyform_subtitle">и вновь полюбить своё тело за&nbsp;2&nbsp;месяца?</div>
            <div className="readyform_note">Забирайте курс «Фитнес-восстановление после родов» по спеццене</div>
            <div className="readyform_dates">
                <p>Доступ — <strong>1 год</strong></p>
                <p>Старт — <strong>{window.lps_settings.dates.mainlands}</strong></p>
            </div>
            {/* <div className="readyform_countdown">
                <p>Предложение сгорит через:</p>
                <Countdown date={Date.now() + date} renderer={renderer} />
            </div> */}
            <div className={styles.start_form}>
                            <div className={styles.start_form_left}>
                                <div className={styles.start_form_title}>Курс &laquo;Фитнес-восстановление после родов&raquo;</div>
                                <div className={styles.start_form_subtitle}>Курс &laquo;Фитнес-восстановление после родов&raquo;, старт сразу после после оплаты</div>
                            </div>
                            <div className={styles.start_form_right}>
                                <div className={styles.start_form_discount}>
                                    <p>-10%</p>
                                </div>
                                <div className={styles.start_form_prices}>
                                    <span>7&nbsp;900&nbsp;руб.</span>
                                    <p>7&nbsp;110&nbsp;руб.</p>
                                </div>
                                <div className={styles.start_form_ot}>от&nbsp;1185 руб./мес.</div>
                            </div>
                        </div>
                        <div class="afterpregnancy_start">
                            <SalesWidget id={window.lps_settings.forms.afterpregnancy_id} />
                        </div>
                        <div className={styles.countdown_timer}>
                            <p>Успей забрать марафон по&nbsp;самой выгодной цене</p>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
            
            <div className="readyform_start_info">
                <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/tnkf_icon.svg" alt="" />
                <p><span>ОТ 1&nbsp;317&nbsp;₽ В МЕСЯЦ</span></p>
                <p>Этот марафон можно забрать в рассрочку. На 6&nbsp;месяцев без процентов и&nbsp;предварительного взноса, <strong>от 1&nbsp;317&nbsp;рублей в&nbsp;месяц</strong> (только для граждан РФ).<br/> Первый платёж через 30&nbsp;дней.</p>
            </div>
            </div>
        </div>
    )
}

export default Readyform;