import styles from './programs.module.css';

const Programs = () => {
    return (
        <div className={`scrollhere ${styles.programs}`}>
            <div className="container">
                
                {/* <div className={styles.t1}>Выбери любую из&nbsp;фитнес-<br/>программ под свою цель</div> */}
                <div className={styles.t1}>Наши фитнес-программы</div>
                </div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr1.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Похудеть</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>МАРАФОН <span>СТРОЙНОСТЬ 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 21&nbsp;день</li>
                                        <li><b>Без инвентаря</b></li>
                                        <li><b>Длительность тренировок:</b> 20-40&nbsp;минут</li>
                                        <li><b>График тренировок:</b> ежедневно</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p1" name="p1" />
                                        <label htmlFor="p1">Для кого подойдёт <p>Марафон для&nbsp;новичков и&nbsp;тех, кто уже обладает некоторым опытом. <b>Программа поможет быстро похудеть</b> и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p></label>
                                    </div>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn1" name="pn1" />
                                        <label htmlFor="pn1">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Похудеешь минимум на&nbsp;1&nbsp;размер</li>
                                                <li>Подтянешь руки, живот, попу и&nbsp;ноги</li>
                                                <li>Улучшишь самочувствие и&nbsp;повысишь уровень энергии</li>
                                                <li>Разберешься в&nbsp;системе сбалансированного питания</li>
                                                <li>Сделаешь фитнес своей привычкой</li>
                                            </ul>
                                            <p>Марафон предназначен для того, чтобы достичь конкретных результатов в&nbsp;короткие сроки, следуя структурированной программе тренировок и&nbsp;рационального питания.</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr2.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 1.0</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p2" name="p2" />
                                    <label htmlFor="p2">Для кого подойдёт <p>Марафон для тех, кто хочет добавить объём и&nbsp;тонус ягодицам, заметно улучшить форму в&nbsp;короткий срок.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn2" name="pn2" />
                                        <label htmlFor="pn2">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Подтянешь и&nbsp;укрепишь мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавишься от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr3.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 2.0</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 30&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт.&nbsp;по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p3" name="p3" />
                                    <label htmlFor="p3">Для кого подойдёт <p>Марафон для тех, кто хочет добавить объём ягодицам, качественно проработать зону, заметно улучшить форму и&nbsp;тонус ягодичных мышц.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn3" name="pn3" />
                                        <label htmlFor="pn3">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Увеличишь объем и&nbsp;форму ягодиц</li>
                                                <li>Подтянешь и&nbsp;укрепишь мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавишься от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr4.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Укрепить пресс</div>
                                        <div className={styles.programs_tag}>Избавиться от животика</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Плоского живота</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Без инвентаря</b></li>
                                    <li><b>Длительность тренировок:</b> 15-30&nbsp;минут</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p5" name="p5" />
                                    <label htmlFor="p5">Для кого подойдёт <p>Марафон направлен на&nbsp;глубокую проработку мышц пресса <b>для создания красивого рельефа на&nbsp;животике</b>.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn5" name="pn5" />
                                        <label htmlFor="pn5">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Уменьшишь объемы талии и&nbsp;живота</li>
                                                <li>Повысишь мышечный тонус всего тела и&nbsp;подтянешь мышцы пресса</li>
                                                <li>Снизишь процент жира в&nbsp;области живота</li>
                                                <li>Улучшишь осанку и&nbsp;усилишь поддержку спины</li>
                                                <li>Повысишь общую выносливость организма и&nbsp;уровень энергии</li>
                                                <li>Приобретешь знания в&nbsp;области питания и&nbsp;тренировок, которые будут полезны всегда</li>
                                            </ul>
                                            <p>Этот марафон подходит как начинающим, так и&nbsp;опытным спортсменам, так как программы могут быть адаптированы под разные уровни физической подготовки.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr5.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Подтянуть тело</div>
                                        <div className={styles.programs_tag}>Быстро похудеть</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Убираем<br/> лишний жир</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 1&nbsp;месяц</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по 1,5-3&nbsp;кг</li>
                                    <li><b>Длительность тренировок:</b> 40-50&nbsp;минут</li>
                                    <li><b>График тренировок:</b> через&nbsp;день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p4" name="p4" />
                                    <label htmlFor="p4">Для кого подойдёт <p>Марафон подойдёт тем, кто хочет <b>быстро сбросить лишний вес, сделать тело более подтянутым</b> и&nbsp;рельефным. В&nbsp;программе также&nbsp;— рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn4" name="pn4" />
                                        <label htmlFor="pn4">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>В максимально короткие сроки избавишься от&nbsp;лишнего веса без&nbsp;ущерба для здоровья</li>
                                                <li>Сформируешь более подтянутое, стройное и&nbsp;рельефное тело</li>
                                                <li>Ускоришь метаболизм и&nbsp;общее состояние здоровья</li>
                                                <li>Начнешь правильно питаться и&nbsp;научишься поддерживать вес</li>
                                                <li>Изучишь техники самомассажа для активации обменных процессов и&nbsp;ускорения сжигания жира</li>
                                            </ul>
                                            <p>Марафон предназначен для тех, кто стремится к&nbsp;быстрым и&nbsp;заметным результатам в&nbsp;снижении веса и&nbsp;формировании подтянутого тела. Программа включает в&nbsp;себя не&nbsp;только эффективные тренировки, но&nbsp;и&nbsp;рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа. Это делает его комплексным и&nbsp;эффективным инструментом для достижения фитнес-целей.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr6.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Избавиться от дряблости</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Антицеллюлитный</span></div>
                                    <div className={styles.programs_img__info_price}>7 500 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 30&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг. Для массажа потребуются вакуумная банка, скребок гуаша или обычная столовая ложка</li>
                                    <li><b>Длительность тренировок:</b> 20-40&nbsp;минут</li>
                                    <li><b>График тренировок:</b> через день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p6" name="p6" />
                                    <label htmlFor="p6">Для кого подойдёт <p>Марафон для тех, кто хочет <b>избавиться от&nbsp;целлюлита и&nbsp;дряблости кожи</b>, изменить качество тела. Внутри кроме тренировок техники самомассажа.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn6" name="pn6" />
                                        <label htmlFor="pn6">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Избавишься от&nbsp;целлюлита и&nbsp;улучшишь текстуру кожи</li>
                                                <li>Повысишь тонус и&nbsp;упругость кожи</li>
                                                <li>Подчеркнешь контуры тела и&nbsp;сформируешь более стройную и&nbsp;подтянутую фигуру</li>
                                                <li>Освоишь техники самомассажа для улучшения кровообращения и&nbsp;лимфодренажа </li>
                                                <li>Повысишь уверенность в&nbsp;себе и&nbsp;улучшишь самочувствие</li>
                                            </ul>
                                            <p>Марафон создан для того, чтобы избавиться от&nbsp;целлюлита и&nbsp;повысить качество тела. Внутри программы&nbsp;&mdash; не&nbsp;только тренировки, но&nbsp;и&nbsp;обучение эффективным техникам самомассажа. Это делает марафон комплексным и&nbsp;эффективным средством для изменения качества тела.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr7.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Для продвинутых</div>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>идеальной попы 2.0</span></div>
                                    <div className={styles.programs_img__info_price}>6 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 45&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, штанга/бодибар от&nbsp;8&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт.&nbsp;по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p7" name="p7" />
                                    <label htmlFor="p7">Для кого подойдёт <p>Курс для тех, кто хочет заметно улучшить форму ягодиц, не&nbsp;считая калории и&nbsp;качественно проработать ягодичные мышцы.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn7" name="pn7" />
                                        <label htmlFor="pn7">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Увеличишь объем, округлость и&nbsp;форму ягодиц</li>
                                                <li>Подтянешь и&nbsp;укрепишь мышцы ног</li>
                                                <li>Избавишься от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Этот курс предназначен для тех, кто хочет комплексно проработать зону ягодиц и&nbsp;ног, чтобы заметно улучшить форму и&nbsp;нарастить объем.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr8.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Для продвинутых</div>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>идеальной попы 3.0</span></div>
                                    <div className={styles.programs_img__info_price}>12 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 90&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> бодибар или штанга весом от&nbsp;8&nbsp;кг, гантели разборные до&nbsp;15&nbsp;кг, утяжелители по&nbsp;1,5-3&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p8" name="p8" />
                                    <label htmlFor="p8">Для кого подойдёт <p>Курс для тех, кто хочет добавить объём и&nbsp;изменить форму ягодиц, ищишь комплексный подход к&nbsp;формированию идеальной попы.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn8" name="pn8" />
                                        <label htmlFor="pn8">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Увеличишь объем и&nbsp;округлость ягодиц</li>
                                                <li>Улучшишь форму и&nbsp;симметрию ягодиц</li>
                                                <li>Подтянешь и&nbsp;укрепишь мышцы ягодиц и&nbsp;ног</li>
                                                <li>Уменьшишь проблемные зоны и&nbsp;несовершенства</li>
                                                <li>Повысь уверенность в&nbsp;себе и&nbsp;самооценку</li>
                                            </ul>
                                            <p>Этот курс предназначен для тех, кто хочет преобразить свои ягодицы и&nbsp;достичь идеальной формы и&nbsp;объема. Благодаря точечным упражнениям и&nbsp;индивидуальному подходу к&nbsp;тренировкам, вы&nbsp;сможешь достичь желаемых результатов и&nbsp;получить попу, о&nbsp;которой мечтали.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr9.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Придать телу рельеф</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>Жиросжигающий</span></div>
                                    <div className={styles.programs_img__info_price}>7 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 3&nbsp;месяца</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                    <li><b>Группы:</b> умеренный, интенсивный и силовой классы</li>
                                    <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p9" name="p9" />
                                    <label htmlFor="p9">Для кого подойдёт <p>Марафон для тех, кто устал от&nbsp;неэффективных диет и&nbsp;экспериментов над своим телом и&nbsp;мечтаешь избавиться от&nbsp;лишнего жира в&nbsp;проблемных зонах.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn9" name="pn9" />
                                        <label htmlFor="pn9">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Снизишь процент жира в&nbsp;организме за&nbsp;счет ускорения метаболизма </li>
                                                <li>Станешь выносливее и&nbsp;улучшишь физическую форму благодаря интенсивным тренировкам.</li>
                                                <li>Усилишь мышечный корсет и&nbsp;осанку</li>
                                                <li>Уменьшишь объемы талии, бедер и&nbsp;других проблемных зон за&nbsp;счет активного жиросжигания</li>
                                                <li>Нормализуешь питание</li>
                                            </ul>
                                            <p>Курс поможет избавиться от&nbsp;лишнего веса, запустить процессы активного жиросжигания и&nbsp;обмена веществ, поможет нормализовать питание и&nbsp;сделать физическую нагрузку нормой.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr10.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Придать телу рельеф</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>для зала</span></div>
                                    <div className={styles.programs_img__info_price}>10 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 4,5 месяца</li>
                                    <li><b>Инвентарь:</b> тренажёры в&nbsp;зале</li>
                                    <li><b>Где заниматься:</b> в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> 3&nbsp;раза в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p10" name="p10" />
                                    <label htmlFor="p10">Для кого подойдёт <p>Ты&nbsp;хочешь эффективно использовать время в&nbsp;тренажерном зале и&nbsp;получить максимальные результаты от&nbsp;своих тренировок, а&nbsp;также если ты&nbsp;достигла своего &laquo;потолка&raquo; в&nbsp;домашних тренировках. Курс поможет создать спортивное, подтянутое и&nbsp;рельефное тело, которое сложно создать дома.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn10" name="pn10" />
                                        <label htmlFor="pn10">Результаты после курса 
                                            <div>
                                                <ul>
                                                    <li>Повысишь физическую силу и&nbsp;выносливость</li>
                                                    <li>Улучшишь композицию тела: сбросишь лишние килограммы и&nbsp;нарастишь мышечную массу.</li>
                                                    <li>Овладеешь техникой выполнения упражнений</li>
                                                </ul>
                                                <p>Курс для зала разработан, чтобы помочь тебе достичь желаемых результатов в&nbsp;кратчайшие сроки&nbsp;&mdash; рельефное и&nbsp;спортивное, но&nbsp;не&nbsp;перекачанное тело. Включает комплекс силовых тренировок, которые подходят для любого уровня подготовки.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr11.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Наладить питание</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>ПИТАНИЕ</span></div>
                                    <div className={styles.programs_img__info_price}>10 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 45&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> без инвентаря</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p11" name="p11" />
                                    <label htmlFor="p11">Для кого подойдёт <p>Курс для тех, кто хочет научиться самостоятельно составлять сбалансированный рацион питания под свои цели, привить себе привычку всегда питаться правильно.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn11" name="pn11" />
                                        <label htmlFor="pn11">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Научишься самостоятельно составлять сбалансированный рацион питания, который соответствует твоим потребностям и&nbsp;целям</li>
                                                <li>Научишься правильно распределять белки, жиры и&nbsp;углеводы в&nbsp;рационе</li>
                                                <li>Улучшишь общее состояния здоровья, уровень энергии и&nbsp;физическую форму</li>
                                                <li>Избавишься от&nbsp;необходимости обращаться к&nbsp;диетологу или нутрициологу благодаря полученным знаниям и&nbsp;навыкам</li>
                                                <li>Повысишь самодисциплину и&nbsp;контроль над питанием, научишься постоянно поддерживать вес</li>
                                            </ul>
                                            <p>Курс поможет самостоятельно создать здоровый и&nbsp;сбалансированный рацион питания, который будет соответствовать твоим целям и&nbsp;потребностям. Полученные знания и&nbsp;навыки помогут контролировать свое питание и&nbsp;поддерживать здоровый образ жизни на&nbsp;долгосрочной основе.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr12.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}><span>Универсальные тренировки на&nbsp;ягодицы</span></div>
                                    <div className={styles.programs_img__info_price}>4 790 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 10&nbsp;тренировок</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома</li>
                                    <li><b>Длительность тренировок:</b> 15-20&nbsp;минут</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p12" name="p12" />
                                    <label htmlFor="p12">Для кого подойдёт <p>Марафон представляет собой конструктор из&nbsp;10&nbsp;тренировок, которые ты можешь комбинировать под физическую подготовку, самочувствие и&nbsp;даже настроение.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn12" name="pn12" />
                                        <label htmlFor="pn12">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Укрепишь и&nbsp;подтянешь мышцы ягодиц</li>
                                                <li>Улучшишь форму и&nbsp;контуры ягодиц</li>
                                                <li>Повысишь выносливость и&nbsp;уровень энергии</li>
                                                <li>Улучшишь самочувствие и&nbsp;повысишь энергию</li>
                                            </ul>
                                            <p>Марафон представляет собой уникальный конструктор из&nbsp;10&nbsp;тренировок на&nbsp;ягодицы, которые можно комбинировать в&nbsp;соответствии с&nbsp;твоими потребностями, физической подготовкой и&nbsp;настроением. Он&nbsp;разработан для того, чтобы обеспечить максимальную эффективность и&nbsp;удобство тренировок.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr13.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Восстановление</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>курс <span>для беременных</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> с&nbsp;1&nbsp;триместра до&nbsp;родов</li>
                                    <li><b>Инвентарь:</b> коврик, фитбол 55-60&nbsp;см, фитнес-резинка или ремень для йоги, 2&nbsp;гантели весом 1-2&nbsp;кг, мяч для пилатеса, ролл для пилатеса, шипованный мяч, блоки для йоги (можно заменить толстыми книгами).</li>
                                    <li><b>Где заниматься:</b> дома</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p13" name="p13" />
                                    <label htmlFor="p13">Для кого подойдёт <p>Ты&nbsp;беременна или планируешь беременность. Хочешь сохранить красоту тела во&nbsp;время беременности и&nbsp;быстро восстановиться после родов, боишься проблем с&nbsp;диастазом и&nbsp;растяжками, планируешь легкие роды.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn13" name="pn13" />
                                        <label htmlFor="pn13">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Подтянутое тело даже при естественном наборе веса</li>
                                                <li>Ощущение спокойствия и&nbsp;уверенности в&nbsp;период ожидания малыша</li>
                                                <li>Возможность облегчить неприятные симптомы на&nbsp;поздних сроках</li>
                                                <li>Профилактику ряда послеродовых проблем</li>
                                                <li>Лёгкое восстановление стройности и&nbsp;здоровья после родов</li>
                                            </ul>
                                            <p>Курс поможет подготовить тело к&nbsp;родам, облегчить неприятные симптомы беременности и&nbsp;поддержать хорошее самочувствие, а&nbsp;также максимально сохранить подтянутость тела.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/allprogramms/pr14.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Восстановление</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>курс <span>Восстановление после родов</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 60&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, гантели 2&nbsp;кг и&nbsp;фитнес-резинки с&nbsp;сопротивлением до&nbsp;5&nbsp;кг, утяжелители 1,5-3&nbsp;кг&nbsp;&mdash; по&nbsp;желанию</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> через день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p14" name="p14" />
                                    <label htmlFor="p14">Для кого подойдёт <p>Ты&nbsp;недавно стала мамой и&nbsp;стремишься вернуться к&nbsp;своей прежней форме. Хочешь избавиться от&nbsp;лишних килограммов и&nbsp;восстановить тонус мышц после беременности.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn14" name="pn14" />
                                        <label htmlFor="pn14">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Похудеешь и&nbsp;восстановишь форму</li>
                                                <li>Укрепишь мышцы брюшного пресса и&nbsp;тазового дна</li>
                                                <li>Подтянешь тело</li>
                                            </ul>
                                            <p>Курс поможет вернуться после родов в&nbsp;прежнюю форму: сбросить лишние килограммы, подтянуть тело и&nbsp;укрепить мышцы тазового дна. Включает рекомендации по&nbsp;питанию для кормящих мам.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
                
        
        </div>
    )
}

export default Programs;