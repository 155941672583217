import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Footer from '../../components/Footer';

import styles from './style.module.css';

export const L2023_06_Ofertacontestgym = () => {

    return (
        <>
            <DocumentTitle value='ДОГОВОР (ОФЕРТА) НА УЧАСТИЕ В КОНКУРСЕ'/>
            <DocumentDescription value='ДОГОВОР (ОФЕРТА) НА УЧАСТИЕ В КОНКУРСЕ' />
            <div className={styles.oferta}>
                <h1>ДОГОВОР (ОФЕРТА) НА УЧАСТИЕ В КОНКУРСЕ</h1>
                <div><p>г. Москва</p><p>12 мая 2023 года</p></div>
                <p>Настоящий договор-оферта (далее по тексту – Договор) адресован любому лицу (неопределенному кругу лиц), чья воля будет выражена им лично, выразившему готовность принять предложение Оферента, на указанных ниже условиях.</p>
                <p>Настоящий Договор является публичной офертой в соответствии с п. 2 ст. 437 Гражданского кодекса Российской Федерации, принятием условий (акцептом) которой является подача заявки на участие в Конкурсе в порядке, предусмотренном настоящим Договором.</p>
                <p>Настоящий Договор представляет собой оферту Индивидуальный предприниматель Голубцова Юлия Дмитриевна (ОГРНИП 317028000125667 ИНН 027719735419), заключить (принять) договор (оферту) на изложенных условиях</p>
                <h2>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ, ПРИМЕНЯЕМЫЕ В ДОГОВОРЕ</h2>
                <p>«Конкурс» – открытое и доступное для участия неопределенному кругу лиц, отвечающих требованиям к Участникам настоящего Договора, мероприятие, условия и порядок проведения которого устанавливаются настоящим Договором.</p>
                <p>«Организатор Конкурса» – Индивидуальный предприниматель Голубцова Юлия Дмитриевна (ОГРНИП 317028000125667 ИНН 027719735419).</p>
                <p>Организатор Конкурса:
                    <ul>
                        <li>Утверждает Договор-оферту, адресованную Участникам Конкурса;</li>
                        <li>размещает информацию о Конкурсе на Сайте и в средствах массовой информации;</li>
                        <li>устанавливает порядок проведения и осуществляет регистрацию Участников Конкурса;</li>
                        <li>организует опубликование результатов Конкурса и награждение победителей Конкурса;</li>
                        <li>осуществляет техническое и иное обеспечение Конкурса;</li>
                    </ul>
                </p>
                <p>«Участник» – физическое лицо, достигшее 18 лет, приобретшее любой из пакетов тренировок на распродаже со стартом с 15 мая до 4 июня 2023 года.</p>
                <p>«Сайт» — программно-аппаратный комплекс, на котором размещены все официальные и правоустанавливающие документы Конкурса, а также документы, посредством которых производится информирование участников Конкурсов в сети Интернет о ходе проведения Конкурса и его результатах, имеет указатель страницы в сети Интернет: <a href='https://gymteam.ru/l/missutcontestresultsaugust2023/' target='_blank'>https://gymteam.ru/l/missutcon...</a></p>
                <p>«Акцепт» - согласие Участника с условиями Договора, выраженное и предполагаемое при условии выполнения им требований участия в Конкурсе, предусмотренных Договором, в полном объеме.</p>
                <h2>2. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                <p>2.1. Договор (Оферта) на участие в Конкурсе определяет условия и порядок организации и проведения Конкурса: «В ШКОЛЕ ОНЛАЙН-ФИТНЕСА КАТИ УСМАНОВОЙ РАСПРОЖАРКА» (далее по тексту — Конкурс), в том числе сроки и место проведения Конкурса, требования, соответствие, соблюдение и выполнение которых обязательно для лиц, желающих стать Участниками Конкурса.</p>
                <p>2.2. При совместном упоминании по тексту Договора Организатор и Участник именуются «Стороны», а каждый из них по отдельности — «Сторона».</p>
                <p>2.3. Каждая Сторона гарантирует другой Стороне, что обладает необходимой право- и дееспособностью, а равно всеми правами и полномочиями, необходимыми и достаточными для заключения и исполнения Договора в соответствии с его условиями.</p>
                <p>2.4. Действующая редакция Договора обязательна для ознакомления Участником до момента совершения акцепта. Акцепт настоящего Договора-оферты предполагается Организатором в случае подачи Участником заявки на участие в Конкурсе.</p>
                <p>2.5. Организатор в одностороннем порядке вправе изменить содержание Договора исключительно в целях приведения в соответствие с изменившимся законодательством Российской Федерации. Организатор может вносить указанные изменения без предварительного уведомления Участника. Изменения содержания Договора вступают в законную силу с момента их публикации по адресу постоянного размещения Договора в виде обновленного текста Договора.</p>
                <h2>3. ПРЕДМЕТ ДОГОВОРА</h2>
                <p>3.1. Организатор обязуется обеспечить проведение Конкурса «В ШКОЛЕ ОНЛАЙН-ФИТНЕСА КАТИ УСМАНОВОЙ РАСПРОЖАРКА» в период с 15 мая 2023 года по 28 августа 2023 года, а Участник в установленные настоящим Договором сроки предоставить все необходимые сведения и документы для участия в Конкурсе (сроки могут быть изменены Организатором с обязательным уведомлением Участников).</p>
                <p>3.2. Участник Конкурса - физическое лицо, достигшее 18 лет.</p>
                <p>3.3. Официальным языком Конкурса является официальный язык Российской Федерации — русский язык.</p>
                <p>3.4. Место проведения Конкурса: официальный сайт <a href="https://gymteam.ru/l/missutcontestresultsaugust2023/" target='_blank'>https://gymteam.ru/l/missutcon...</a></p>
                <p>3.5. Срок объявления победителей Конкурса — не позднее 28.08.2023 года (сроки могут быть изменены Организатором с обязательным уведомлением Участников).</p>
                <p>3.6. Сроки выполнения обязательств Организатора по предоставлению победителям Конкурса призов, предусмотренных настоящим Договором — в течение 90 календарных дней с момента объявления победителя Конкурса(сроки могут быть изменены Организатором с обязательным уведомлением Участников).</p>
                <p>3.7. Акцепт оферты означает, что Участник согласен со всеми положениями Договора и обязуется им следовать.</p>
                <h2>4. УСЛОВИЯ УЧАСТИЯ В КОНКУРСЕ</h2>
                <p>4.1. В целях участия в Конкурсе лица, соответствующие требованиям, установленным в п.3.2 настоящего Договора, обязаны выполнить следующие действия:</p>
                <p>4.1.1. Ознакомиться с правилами конкурса и выразить согласие на обработку персональных данных.</p>
                <p>4.1.2. Приобрести любой из пакетов тренировок на распродаже со стартом в период с 15 мая до 4 июня 2023 года.</p>
                <p>4.2. Участник Конкурса гарантирует достоверность любой информации, содержащейся в заявке на участие в Конкурсе.</p>
                <h2>5. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
                <p>5.1. Организатор информирует участников обо всех событиях, связанных с проведением конкурса в сети Интернет на официальном сайте https://usmanovateam.ru. Размещение любой официальной информации на Сайте является официальным порядком информирования. Каждый участник считается информированным надлежащим способом с момента публикации информации на Сайте.</p>
                <p>5.2. Каждый Участник принимает, что участие в Конкурсе добровольно, и Организатор Конкурса не несёт ответственности за содержание авторских материалов и не может гарантировать и не обещает никаких специфических результатов от участия в Конкурсе и/или использования сайта Конкурса и/или его сервисов.</p>
                <p>5.3. Организатор Конкурса вправе затребовать у любого Участника Конкурса или любого лица, подавшего заявку на участие в Конкурсе: документ, удостоверяющий личность; адрес регистрации, почтовый адрес, другие документы и сведения, необходимые для соблюдения законодательства Российской Федерации и/или для организации и проведения конкурса, в форме отсканированных копий документов.</p>
                <p>5.4. Участник обязуется не нарушать авторские права третьих лиц.</p>
                <p>5.5. За нарушение установленного порядка участия в Конкурсе, устанавливаемого Договором, или несоблюдение действующего законодательства, работа Участника может быть оставлена Организатором без рассмотрения на любой стадии Конкурса без предоставления каких-либо мотивированных разъяснений или подтверждающих документов.</p>
                <p>5.6. В случае непредставления Участником сведений согласно форме заявки в установленном Договором порядке или их представления в несогласованном с Организатором формате, а также предоставления неверных сопроводительных данных или реквизитов, такой Участник принимает на себя все риски и последствия, связанные с оставлением его заявки без рассмотрения или непоступления в его адрес приза в случае победы.</p>
                <p>5.7. Основания для исключения Участника из Конкурса:
                    <ul>
                        <li>нарушение правил участия в Конкурсе, изложенных в Договоре;</li>
                        <li>проявление неуважения к Организатору Конкурса;</li>
                        <li>непредставление необходимых для участия в Конкурсе сведений в порядке и сроки, указанные в настоящем Договоре.</li>
                    </ul>
                </p>
                <p>5.8. Обслуживание Участника осуществляется Организатором 24 часа 7 дней в неделю. Действия во исполнение Договора, выполняемые не в автоматическом режиме, совершаются Организатором в рабочие дни, являющиеся таковыми в соответствии с законодательством Российской Федерации.</p>
                <p>5.9. Права Организатора:</p>
                <p>5.9.1. отказать в регистрации заявки в случае обнаружения несоответствия работы требованиям, предъявляемой к ней данным Договором, без мотивированного разъяснения причин такого отказа;</p>
                <p>5.9.2. производить модификацию интерфейсов и программного обеспечения, используемого при взаимодействии Сторон в рамках Договора;</p>
                <p>5.9.3. приостанавливать работу программных и/или аппаратных средств, обеспечивающих техническое взаимодействие Сторон в рамках Договора, при обнаружении существенных неисправностей, ошибок и сбоев, а также в целях проведения профилактических работ и предотвращения случаев несанкционированного доступа;</p>
                <p>5.9.4. обрабатывать любым способом в целях исполнения Договора любые персональные данные Участника, предоставленные Участником лично, либо через третьих лиц при заключении, либо в период действия Договора.</p>
                <p>5.10. Обязанности Организатора:</p>
                <p>5.10.1. принимать меры для предотвращения несанкционированного доступа иных лиц к информации о персональных данных Участника, отличной от опубликованной в открытом доступе на официальном сайте Организатора;</p>
                <p>5.10.2. обеспечивает взятые на себя обязательства</p>
                <h2>6.4. ОБЯЗАННОСТИ УЧАСТНИКА</h2>
                <p>6.4.1. предоставить Организатору достоверные и актуальные сведения, контактные данные и иные требуемые Организатором для участия в Конкурсе документы, предусмотренные настоящим Договором;</p>
                <p>6.4.2. своевременно информировать Организатора об изменении персональных данных, реквизитов и контактных данных в документах, предъявляемых на Конкурс;</p>
                <p>6.4.3. предоставить право использования видео и фотоматериалов Организатору любым доступным способом и на неограниченный срок;</p>
                <p>6.4.4. Участник извещен, что Выигрыш подлежит налогообложению в соответствии с законодательством РФ. Участник самостоятельно подает декларацию о доходах в налоговые органы по месту жительства. Организатор вправе выдать приз в денежном эквиваленте или заменить на равнозначный по стоимости. Стоимость приза оценивается на момент завершения конкурса.</p>
                <p>6.4.5. иные обязанности, предусмотренные настоящим Договором.</p>
                <h2>7. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА</h2>
                <p>7.1. Акцептом условий Договора является приобретение любого из пакетов тренировок на распродаже со стартом в июне на официальном сайте Организатора. Акцепт условий Договора является безотзывным.</p>
                <p>7.2. Акцепт условий Договора означает полное и безоговорочное принятие Участником всех условий Договора без каких-либо изъятий и/или ограничений и равносилен заключению двухстороннего письменного договора о Конкурсе.</p>
                <h2>8. МЕРЫ ПООЩЕРЕНИЯ УЧАСТНИКОВ КОНКУРСА И ВЫБОР ПОБЕДИТЕЛЕЙ</h2>
                <p>8.1. Организатор поощряет Участников Конкурса, признанных Организатором победителями, призами в установленной настоящим Договором форме.</p>
                <p>8.2. Меры поощрения (призы), причитающиеся победителям Конкурса:</p>
                <p>8.2.1. Победители открытого голосования получают денежные призы:
                    <ul>
                        <li>1 место – 100 000 руб</li>
                        <li>2 место – 70 000  руб</li>
                        <li>3 место – 50 000 руб</li>
                        <li>4 место – 30 000 руб</li>
                        <li>5 место – 10 000 руб</li>
                    </ul>
                </p>
                <p>8.2.2. Все переводы денежных средств за пределы границ Российской Федерации может быть осуществлен только платежной системой Золотая Корона. В случае если Победитель не хочет получить денежный приз через платежную систему Золотая Корона, то выплата не может быть осуществлена.</p>
                <p>8.3. Выбор победителя Конкурса осуществляется по следующим критериям:<br/> — выполнение всех условий Конкурса;</p>
                <p>Организатор не обязан доводить до Участников информацию об обстоятельствах и причинах выбора соответствующих победителей. Решение обжалованию не подлежит.</p>
                <p>8.4. В течение трёх календарных дней с момента объявления результатов Конкурса победитель Конкурса должен направить на адрес электронной почты организатора следующие сведения и документы:</p>
                <p>8.5.1. Копию документа, удостоверяющего личность.</p>
                <p>8.5.2. Контактный номер телефона;</p>
                <p>8.5.3. Доказательства выполнения п. 4.1.2 настоящего Договора;</p>
                <p>8.5.4. Иные сведения, необходимые Организатору для предоставления мер поощрения (приза), предусмотренных настоящим Договором.</p>
                <p>8.6. Копии документов, предоставляемых Участником Конкурса, должны соответствовать следующим техническим требованиям: Файл-изображение в одном из форматов: JPG, JPEG, GIF, PNG, TIF, TIFF с разрешением не менее 100 DPI, размером не более 5 мегабайт. Информация, отраженная в представляемой копии документа, в том числе его реквизиты, должна быть читаема и различима без дополнительной обработки и иных манипуляций со стороны Организатора.</p>
                <p>8.7. В случае если победитель Конкурса не соблюдает и не выполняет требования п. 8.5-8.6 Договора, по истечении трех календарных дней с момента объявления результатов Конкурса, организатор вправе выбрать другого победителя из числа Участников.</p>
                <p>8.8. Победитель обязан выложить не менее 3(трех) видео сториз в свой аккаунт социальной сети с отметкой аккаунта @usmanovakate. Примеры сценарием видео направляет Организатор.</p>
                <h2>9. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p>9.1. Участник дает Организатору согласие на обработку любым способом в целях исполнения Договора любых персональных данных Участника, предоставленных Участником лично, при заключении, либо в период действия Договора.</p>
                <p>9.2. Заключая Договор, Участник своей волей и в своем интересе дает согласие Организатору на запись, систематизацию, накопление, хранение, уточнение, извлечение, использование своих персональных данных, указанных им при заполнении регистрационной формы на Сайте или становящихся известными Организатору в связи с исполнением Договора, в частности, фамилии, имени, отчества, адреса регистрации, постоянного проживания, даты и места рождения, номера мобильного телефона, личного электронного адреса, электронного адреса аккаунтов в социальных сетях, личных фотографий (фотоизображений), в том числе путем автоматизированной обработки таких данных, в целях осуществления Организатором основных видов деятельности в соответствии с Уставом и осуществления Организатором действий, предусмотренных условиями настоящего договора, а также сбор и анализ материалов относительно востребованности Конкурса.</p>
                <p>9.3. Организатор вправе использовать предоставленные Участником персональные данные в целях обеспечения соблюдения требований, действующих законодательных и иных нормативных правовых актов Российской Федерации (в том числе в целях предупреждения и пресечения незаконных и/или противоправных действий других пользователей Сайта). Раскрытие предоставленной Участником информации может быть произведено лишь в соответствии с действующим законодательством Российской Федерации по требованию суда, правоохранительных органов, а равно в иных предусмотренных законодательством Российской Федерации случаях.</p>
                <p>9.4. Участник самостоятельно обеспечивает сохранность своих персональных данных.</p>
                <p>9.5. Согласие Участника на обработку персональных данных действует с даты заключения Договора, истекает спустя 5 (пять) лет с даты окончания проведения Конкурса, за исключением случаев обработки таких данных в целях, где срок обработки составляет 75 (семьдесят пять) лет с даты расторжения Договора. Согласие на обработку персональных данных не может быть отозвано Участником.</p>
                <p>9.6. Организатор вправе осуществлять хранение (архивное хранение) и комплектование документов и персональных данных, в том числе в форме электронных (цифровых) документов (оригиналов и копий), в электронных базах данных включительно.</p>
                <p>9.7. Участник выражает согласие на опубликование своих персональных данных, а именно фамилии, имени, отчества, города проживания, при опубликовании Организатором результатов Конкурса, а также при опубликовании интервью с Участником, в случае его избрания победителем Конкурса.</p>
                <h2>10. СРОК ДЕЙСТВИЯ ДОГОВОРА, ПОРЯДОК ЕГО ИЗМЕНЕНИЯ И РАСТОРЖЕНИЯ</h2>
                <p>10.1. Договор считается заключенным и вступает в силу с даты акцепта Участником оферты Организатора. При невыполнении Участником хотя бы одного из указанных в настоящем Договоре условий и требований участия в Конкурсе, Договор не считается заключенным, соответственно Участник не допускается до участия в Конкурсе.</p>
                <p>10.2. Договор действует до исполнения Сторонами своих обязательств по нему в полном объеме, если не будет расторгнут досрочно одной из Сторон или обеими Сторонами по соглашению друг с другом.</p>
                <p>10.3. Договор может быть изменен или расторгнут по основаниям и в порядке, предусмотренным законодательством Российской Федерации и Договором.</p>
                <p>10.4. В соответствии с пунктом 1 ст. 450 Гражданского кодекса Российской Федерации Стороны договорились, что Организатор имеет право вносить изменения в условия Договора. Изменения, внесенные Организатором, становятся обязательными для Сторон с момента их размещения на Сайте, если иное не предусмотрено Организатором.</p>
                <p>10.5. В случае несогласия с изменением условий Договора, Участник имеет право в течение трех календарных дней с момента размещения новой редакции Договора на официальном сайте Организатора в одностороннем порядке отказаться от исполнения Договора путем передачи Организатору оригинального экземпляра подписанного заявления на бумажном носителе.</p>
                <p>10.6. В случае неполучения Организатором письменного уведомления от Участника об одностороннем отказе от исполнения Договора в срок, установленный пунктом.</p>
                <p>10.7 Договора, Участник считается выразившим согласие с изменениями условий Договора.</p>
                <p>10.8. Отказ от исполнения Договора, расторжение Договора, прекращение действия Договора по иным причинам влечет прекращение участие Участника в Конкурсе.</p>
                <p>10.9. Участник вправе в любое время отказаться от исполнения Договора путем направления соответствующего уведомления Организатору способами, предусмотренными Договором. В данном случае Договор считается расторгнутым по истечении 3 (трех) рабочих дней после получения Организатором соответствующего уведомления.</p>
                <h2>11. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
                <p>11.1. Стороны несут ответственность за ненадлежащее исполнение своих обязанностей в соответствии с законодательством Российской Федерации и условиями Договора.</p>
                <p>11.2. Организатор не несет ответственности:</p>
                <p>11.2.1. за сбои в работе почты, Интернета, сетей связи, возникшие по независящим от Организатора причинам и повлекшие за собой несвоевременное получение или неполучение Участником уведомлений Организатора;</p>
                <p>11.2.2. в случае технических сбоев (отключение/повреждение электропитания и сетей связи, сбои программного обеспечения процессингового центра и технические сбои в платежных системах), повлекших за собой невыполнение Организатора условий Договора;</p>
                <p>11.2.3. если информация о персональных данных Участника станет известной иным лицам в результате прослушивания или перехвата каналов связи во время их использования, а также в результате иных противоправных действий третьих лиц, направленных на получение несанкционированного доступа к персональным данным Участника против воли Организатора и Участника Конкурса;</p>
                <p>11.2.4. если информация о персональных данных Участника станет известной иным лицам в результате несоблюдения Участником условий хранения и использования персональных данных;</p>
                <p>11.2.5. за временное отсутствие у Участника доступа к средствам связи, обеспечивающим взаимодействие с Организатором в рамках Договора, а также связанные с этим убытки Участника;</p>
                <p>11.2.6. за убытки, возникшие в результате предоставления Участником недостоверных, некорректных, ошибочных данных для направления уведомлений;</p>
                <p>11.2.7. за убытки, возникшие в результате предоставления Участником недостоверных идентификационных данных;</p>
                <p>11.2.8. за убытки, возникшие в результате не обновления Участником данных для направления уведомлений.</p>
                <p>11.3. Стороны освобождаются от имущественной ответственности за неисполнение или ненадлежащее исполнение обязательств по Договору, если оно вызвано действиями непреодолимой силы, т.е. чрезвычайными и непредотвратимыми обстоятельствами, в том числе стихийными явлениями, военными действиями, актами органов власти. При возникновении обстоятельств непреодолимой силы Сторона должна в течение 3 (трех) рабочих дней проинформировать другую Сторону в письменной форме о невозможности исполнения обязательств.</p>
                <h2>12. ТЕХНИЧЕСКАЯ ПОДДЕРЖКА УЧАСТНИКОВ КОНКУРСА</h2>
                <p>12.1. Организатор конкурса согласен проводить техническую поддержку Участника Конкурса, касающуюся пользования официальным сайтом Организатора, до истечения срока проведения Конкурса, установленного настоящим Договором.</p>
                <p>12.2. Техническая поддержка Участников Конкурса, указанная в п. 12.1 Договора, осуществляется по электронным каналам связи (электронной почте) и/или путем размещения определенной информации на Сайте Организатора.</p>
                <h2>13. ПРОЧИЕ ПОЛОЖЕНИЯ</h2>
                <p>13.1. Участник гарантирует, что все условия Договора ему понятны, и он принимает их безусловно и в полном объеме.</p>
                <p>13.2. Участник гарантирует, что не будет использовать Конкурсы в противоправных целях, а также в иных целях, нежели указанные в Договоре.</p>
                <p>13.3. Временем совершения всех операций с Участником является московское время, определяемое на основании данных сервера Организатора, обрабатывающего информацию о ходе Конкурса, если иной порядок определения времени не был оговорен дополнительно.</p>
                <p>13.4. К отношениям Сторон в рамках Договора применяется материальное и процессуальное право Российской Федерации независимо от гражданства и места жительства Участника.</p>
            </div>
            <Footer />
        </>
    );
};