import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import { Landing } from "./Landing";
import { MyRedirect } from "./components/redirec";

export const L2024_10_gtv = () => {

  function LoginForm() {
    return (
      <>
        <Authorized>
          <MyRedirect url='/'/>
        </Authorized>
        <Anonymous>
          <Landing/>
        </Anonymous>
      </>
    );
  }

    return (
      // <LoginForm />
      <Landing />
    );
};