import { useEffect, useState } from 'react';
import Gtvmodal from '../../2023/gtv/components/Modal';
import {DocumentTitle, DocumentDescription} from '../DocumentMeta';
import Footer from '../Footer';
import Header from '../header/Header';

import styles from './style.module.css';

export const Legal_confidentiality = () => {
    const [isActive, setIsActive] = useState(false)
    const [isModalActive, setIsModalActive] = useState(false)

    useEffect(() => {
        isActive ? document.querySelector('body').classList.add('gtv-fixed') : document.querySelector('body').classList.remove('gtv-fixed')
      }, [isActive])

    return (
        <>
            <DocumentTitle value=''/>
            <DocumentDescription value='' />
            <Header isActive={isActive} setIsActive={setIsActive} setIsModalActive={setIsModalActive} />
            <div className={styles.oferta}>
                <h1>Политика защиты персональной информации пользователей сайта и мобильных приложений</h1>
                <h2>1. Общие условия</h2>
                <p>1.1. Настоящая Политика определяет порядок обработки и защиты ООО "Онлайн-фитнес" и его партнерами (далее – Партнеры) информации о физических лицах (далее – Пользователь), которая может быть получена ИП при приобретении Пользователем услуг/товаров, неисключительной лицензии, предоставляемых в том числе посредством сайта, сервисов, служб, программ, используемых ИП и/или Партнерами (далее – Сайт, Сервисы).</p>
                <p>1.2. Целью настоящей Политики является обеспечение надлежащей защиты персональной информации, которую Пользователь предоставляет о себе самостоятельно при использовании Сайта, Сервисов или в процессе регистрации (создании учетной записи), для приобретения товаров/услуг, неисключительной лицензии от несанкционированного доступа и разглашения.</p>
                <p>1.3. Отношения, связанные со сбором, хранением, распространением и защитой информации предоставляемой Пользователем, регулируются настоящей Политикой, иными официальными документами ИП и действующим законодательством Российской Федерации.</p>
                <p>1.4. Регистрируясь на Сайте и используя Сайт и Сервисы Пользователь выражает свое полное согласие с условиями настоящей Политики.</p>
                <p>1.5. В случае несогласия Пользователя с условиями настоящей Политики использование Сайта и/или каких-либо Сервисов доступных при использовании Сайта должно быть немедленно прекращено.</p>
                <p>1.6. В случае несогласия Пользователя в получении информации от ИП и/или Партнеров Пользователь может отписаться от рассылки:
                    <ul>
                        <li>путем направления уведомления на электронную почту info@gymteam.ru</li>
                    </ul>
                </p>
                <p>При поступлении уведомлений на электронную почту info@gymteam.ru или при телефонном обращении в контакт-центр в специальном программном обеспечении для учета действий по соответствующему Пользователю, создается обращение по итогам полученной от Пользователя информации. Обращение обрабатывается максимум в течение 24 часов. В результате информация о Пользователе не включается в сегмент рассылок по соответствующему региону.</p>
                <h2>2. Цели сбора, обработки и хранения информации предоставляемой пользователями Сайта</h2>
                <p>2.1. Обработка персональных данных Пользователя осуществляется в соответствии с законодательством Российской Федерации. ИП обрабатывает персональные данные Пользователя в целях:
                    <ul>
                        <li>идентификации стороны в рамках соглашений и договоров, заключаемых с ИП и/или Партнерами;</li>
                        <li>предоставления Пользователю товаров/услуг, неисключительной лицензии, доступа к Сайту, Сервисам;</li>
                        <li>связи с Пользователем, направлении Пользователю транзакционных писем в момент получения заявки регистрации на Сайте или получении оплаты от Пользователя, разово, если Пользователь совершает эти действия, направлении Пользователю уведомлений, запросов;</li>
                        <li>направлении Пользователю сообщений рекламного и/или информационного характера;</li>
                        <li>проверки, исследования и анализа таких данных, позволяющих поддерживать и улучшать сервисы и разделы Сайта, а также разрабатывать новые сервисы и разделы Сайта;</li>
                        <li>проведение статистических и иных исследований, на основе обезличенных данных.</li>
                        <li>обработка вышеуказанных персональных данных может осуществляться путем смешанной обработки персональных данных, и может включать в себя сбор, систематизацию, накопление, хранение, уточнение, обезличивание, блокирование, уничтожение персональных данных.</li>
                        <li>персональные данные могут передаваться третьим лицам с использованием сетей общего пользования и международного информационного обмена, с использованием трансграничной передачи данных на территории иностранных государств, в том числе и в случае, если этими странами не обеспечивается адекватная защита прав субъектов персональных данных с целью получения Пользователем товаров/услуг, а также для достижения иных целей.</li>
                        <li>персональные данные могут обрабатываться в течение всего срока исковой давности, предусмотренного законодательством Российской Федерации в отношении заключенного Пользователем с ИП и/или Партерами Договора.</li>
                    </ul>
                </p>
                <h2>3. Условия обработки персональной информации предоставленной Пользователем и ее передачи третьим лицам</h2>
                <p>3.1. ИП принимает все необходимые меры для защиты персональных данных Пользователя от неправомерного доступа, изменения, раскрытия или уничтожения.</p>
                <p>3.2. ИП предоставляет доступ к персональным данным Пользователя только тем работникам, подрядчикам и Партнерам, которым эта информация необходима для обеспечения функционирования Сайта, Сервисов и оказания Услуг, продажи товаров, получении неисключительной лицензии Пользователем.</p>
                <p>3.3. ИП вправе использовать предоставленную Пользователем информацию, в том числе персональные данные, в целях обеспечения соблюдения требований действующего законодательства Российской Федерации (в том числе в целях предупреждения и/или пресечения незаконных и/или противоправных действий Пользователей). Раскрытие предоставленной Пользователем информации может быть произведено лишь в соответствии с действующим законодательством Российской Федерации по требованию суда, правоохранительных органов, а равно в иных предусмотренных законодательством Российской Федерации случаях.</p>
                <p>3.4. ИП не проверяет достоверность информации предоставляемой Пользователем и исходит из того, что Пользователь в рамках добросовестности предоставляет достоверную и достаточную информацию, заботится о своевременности внесения изменений в ранее предоставленную информацию при появлении такой необходимости, включая, но не ограничиваясь изменение номера телефона.</p>
                <h2>4. Условия пользования Сайтом, Сервисами</h2>
                <p>4.1. Пользователь при пользовании Сайтом, подтверждает, что:
                    <ul>
                        <li>обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию (создание учетной записи) и использовать Услуги сайта;</li>
                        <li>указывает достоверную информацию о себе в объемах, необходимых для пользования Услугами Сайта, обязательные для заполнения поля для дальнейшего предоставления Услуг сайта помечены специальным образом, вся иная информация предоставляется пользователем по его собственному усмотрению.</li>
                        <li>осознает, что информация на Сайте, размещаемая Пользователем о себе, может становиться доступной для третьих лиц, не оговоренных в настоящей Политике и может быть скопирована и распространена ими;</li>
                        <li>ознакомлен с настоящей Политикой, выражает свое согласие с ней и принимает на себя указанные в ней права и обязанности. </li>
                    </ul>
                    <br/>Ознакомление с условиями настоящей Политики и проставление галочки под ссылкой на данную Политику является письменным согласием Пользователя на сбор, хранение, обработку и передачу третьим лицам персональных данных предоставляемых Пользователем.
                </p>
                <p>4.2. ИП не проверяет достоверность получаемой (собираемой) информации о Пользователях, за исключением случаев, когда такая проверка необходима в целях исполнения обязательств перед Пользователем.</p>
                <h2>5. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:</h2>
                <p>5.1. Данные предоставленные Пользователем самостоятельно при пользовании Сайтом, Сервисами включая но, не ограничиваясь: Фамилия, имя, отчество; Фамилия, имя в латинской транскрипции, как они указаны в заграничном паспорте; Год, месяц и число рождения; Место рождения; Пол; Данные об общегражданском паспорте Российской Федерации: Серия и номер общероссийского паспорта; дата его выдачи; наименование органа, выдавшего паспорт; срок действия общероссийского паспорта либо свидетельства о рождении; Адрес регистрации; Адрес электронной почты; Домашний и контактный (мобильный) телефоны; Гражданство при рождении и в настоящее время; Семейное положение; Данные об образовании и ученой степени; Данные о работодателе и работе: наименование, адрес и телефон работодателя; должность в настоящее время; размер заработной платы; данные о росте, весе и состоянии здоровья.</p>
                <p>5.2. Данные, которые автоматически передаются Сервисам в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой страницы.</p>
                <p>5.3. Иная информация о Пользователе, сбор и/или предоставление которой определено в Регулирующих документах отдельных Сервисов ИП.</p>
                <h2>6. Изменение и удаление персональных данных</h2>
                <p>6.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию или её часть, а также параметры её конфиденциальности, воспользовавшись функцией редактирования персональных данных в разделе, либо в персональном разделе соответствующего Сервиса. Пользователь обязан заботится о своевременности внесения изменений в ранее предоставленную информацию, ее актуализации, в противном случае ИП не несет ответственности за неполучение уведомлений, товаров/услуг и т.п.</p>
                <p>6.2. Пользователь также может удалить предоставленную им в рамках определенной учетной записи персональную информацию. При этом удаление аккаунта может повлечь невозможность использования некоторых Сервисов.</p>
                <h2>7. Изменение Политики конфиденциальности. Применимое законодательство</h2>
                <p>7.1. ИП имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция всегда находится на странице по адресу <a href="/l/legal/confidentiality">https://gymteam.ru/legal/confidentiality</a></p>
                <p>7.2. К настоящей Политике и отношениям между Пользователем и ИП возникающим в связи с применением Политики конфиденциальности, подлежит применению право Российской Федерации.</p>
                <h2>8. Обратная связь. Вопросы и предложения</h2>
                <p>8.1. Все предложения или вопросы по поводу настоящей Политики следует сообщать в Службу поддержки по электронной почте info@gymteam.ru или путем направления корреспонденции по адресу: Москва, Очаковское шоссе, д. 34, офис А506</p>
            </div>
            <Footer />
            <Gtvmodal isModalActive={isModalActive} setIsModalActive={setIsModalActive} />
        </>
    );
};