import styles from './onmarafon.module.css';

const Onmarafon = () => {
    return (
        <div className={styles.onmarafon}>
            <div className="container">
                <div className="l2211_str1_title">НА МАРАФОНЕ <span>НЕ БУДЕТ:</span></div>
                <div className={styles.onmarafon_block}>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/onm_1.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Изнурительного</strong> кардио</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str1/onm_2.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Жестких</strong> диет</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str1/onm_3.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Откатов <strong>в весе</strong></div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/onm_4.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Пищевых и психологических <strong>срывов</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onmarafon;