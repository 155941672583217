import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_pretitle}>Пропустите этап «проб&nbsp;и&nbsp;ошибок»&nbsp;&mdash;</div>
                <div className={styles.whaityou_title}>ЗАПУСТИТЕ ЖИРОСЖИГАНИЕ<br/> ГРАМОТНО</div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ТРЕНИРОВКИ В&nbsp;ФОРМАТЕ «ПОВТОРЯЙ ЗА КАТЕЙ» 🙋‍</div>
                            <div className={styles.whaityou_info__text}>На&nbsp;пресс, ягодицы и&nbsp;всё&nbsp;тело&nbsp;&mdash; раз&nbsp;в&nbsp;2&nbsp;дня (через&nbsp;день) вам будет открываться новое занятие с&nbsp;Катей Усмановой. Просто включайте видео, внимательно смотрите и&nbsp;повторяйте.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ВСЕГО 20-30 МИНУТ НА&nbsp;ТРЕНИРОВКУ 🤸‍</div>
                            <div className={styles.whaityou_info__text}>Такие занятия точно впишутся в&nbsp;ваш распорядок дня, ведь вам не&nbsp;потребуется тратить время на&nbsp;дорогу. Вы&nbsp;сможете тренироваться и&nbsp;дома, и&nbsp;в&nbsp;любом другом подходящем месте.</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ТЕХНИКИ САМОМАССАЖА 🦸‍</div>
                            <div className={styles.whaityou_info__text}>Целлюлит в&nbsp;разной степени есть&nbsp;у&nbsp;85% женщин, поэтому в&nbsp;марафон включен курс антицеллюлитного массажа, который сделает кожу гладкой и&nbsp;упругой.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;