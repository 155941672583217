import { useEffect, useRef, useState } from 'react';
import styles from './faq.module.css';

const Faq = ({scroll}) => {
  const faqs = [
    {question: 'Подойдут ли мне тренировки?', answer: <div><p>Тренировки подойдут вам, если у вас нет медицинских противопоказаний к спортивным нагрузкам, но:</p><ul><li>есть лишние кг, от которых нужно избавиться</li><li>есть проблемные зоны</li><li>вы хотите нарастить мышечную массу, создать рельеф тела</li></ul></div>},
    {question: 'В какой последовательности открываются тренировки?', answer: <div><p>25 ноября вы получаете доступ сразу ко всем программам тренировок набора.</p></div>},
    {question: 'Тренировки проходят в определённое время?', answer: 'Вы сможете заниматься тогда, когда вам удобно. Вам нужно только выделить 20-40 минут в течение дня или вечера и повторять упражнения за тренером.'},
    {question: 'Нужно ли покупать абонемент в фитнес-клуб или специальное оборудование?', answer: <div><p>Программы рассчитаны для тренировок дома. Вы можете обойтись без инвентаря и использовать подручные средства, но для лучшего результата советуем купить минимальное оборудование: фитнес-резинка с сопротивлением medium, наборные или цельные гантели весом 2 кг, утяжелители весом 1-2 кг.</p></div>},
    {question: 'Подойдут ли  мне программы из набора, если у меня есть проблемы со здоровьем?', answer: <div><p>Мы делаем индивидуальный подбор плана тренировок перед стартом с учетом индивидуальных противопоказаний участниц.</p></div>},
    {question: 'Подойдут ли мне программы из набора, если я недавно родила?', answer: <div><p>Вы можете заниматься по курсу «Восстановление после родов», который есть в общем наборе. Также наши эксперты подберут для вас программу тренировок на основе индивидуальных показаний, чтобы вам было максимально полезно тренироваться и постепенно вливаться спортивный режим.</p></div>},
    {question: 'Чем отличается программа питания в наборе от других программ?', answer: <div><p>Программа питания в нашем наборе основана на принципе питания по инсулиновым часам и методе «тарелки». Это значит, что вам не придётся подсчитывать калории или взвешивать еду — достаточно соблюдать баланс продуктов на тарелке. Такой подход помогает стабилизировать уровень сахара и инсулина в крови, что снижает тягу к сладкому и перекусам, а также способствует более эффективному сжиганию жира. Питание по инсулиновым часам поддерживает обмен веществ и обеспечивает стабильный уровень энергии в течение дня.</p><p>Программа легко адаптируется под разные типы питания и помогает улучшить функции пищеварительной системы, делая процесс здорового похудения комфортным и эффективным.</p></div>},
    {question: 'Как проходит розыгрыш призов?', answer: <div><p>В розыгрыше заявленных призов участвуют все, кто оплатил комплект программ по акции ЧЁРНАЯ ПЯТНИЦА. Вы автоматически попадаете в список участниц розыгрыша. Выбор победителей осуществляется через генератор случайных чисел. В случае выигрыша в течение 24 часов с вами связывается наш менеджер и сообщает, как получить приз. Розыгрыш пройдёт 20 ноября в 12:00 Мск.</p><p>Поездка в Дубай на двоих будет разыграна 15 декабря в 12:00 Мск среди всех купивших программы по акции ЧЁРНАЯ ПЯТНИЦА. Вы автоматически попадаете в список участниц розыгрыша. Выбор победителей осуществляется через генератор случайных чисел. В случае выигрыша в течение 24 часов с вами связывается наш менеджер и сообщает, как получить приз.</p></div>},
    {question: 'Как мне считать калории', answer: <div><p>Для вашего удобства мы сделали калькулятор КБЖУ, который учтет вашу активность и фитнес цели.<br/> Чтобы воспользоваться калькулятором, перейдите по ссылке <a href="https://gymteam.ru/l/calculator/" target='_blank'>https://gymteam.ru/l/calculator/</a></p></div>},
    {question: 'Cмогу ли я заниматься, если живу не в России?', answer: <div><p>Да. Тренировки доступны в любой точке мира. Вам понадобится смартфон, планшет или компьютер с доступом в интернет. Вы также можете использовать Smart TV.</p></div>},
    {question: 'Я оплатила заказ, но мне не пришло письмо с доступом. Что делать?', answer: <div><p>25 ноября мы отправим письмо со ссылкой на вход в личный кабинет. Если вы не нашли письмо даже в папке со спамом, проверьте, правильно ли вы указали адрес электронной почты при оплате.</p><p>Обратите внимание, что письмо со ссылкой на личный кабинет приходит в течение 24-х часов после оплаты.</p><p>Если не нашли письмо, то напишите в нашу службу поддержки: <a href="mailtosupport@gymteam.ru">support@gymteam.ru</a></p><p><a href="https://t.me/GymTeamSupportBot" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon.svg" alt="" /></a>&nbsp;&nbsp;<a href="https://api.whatsapp.com/send?phone=79958871172" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon.svg" alt="" /></a></p></div>},
    {question: 'Я оплатила, как получить доступ к тренировкам?', answer: <div><p>Вы получите доступ к программам комплекта в личном кабинете GymTeam в день старта 25 ноября.</p><p>Для доступа вам нужно авторизоваться на сайте или в приложении GymTeam.</p><p>Важно! Авторизация происходит только по номеру телефона, который указали при оплате. Обратите внимание, что письмо со ссылкой на личный кабинет приходит в течение 24-х часов после оплаты.</p></div>},
    {question: 'Могу я оплатить курс картой иностранного банка?', answer: <div><p>Да, можете. Всё просто — в блоке с ценой надо нажать «Продолжить» и заполнить форму заказа актуальными данными. Потом нажать кнопку «Оплатить картой иностранного банка».</p><p>Откроется платёжная страница, вам надо выбрать удобный способ оплаты и нажать кнопку «Оплатить».</p></div>},
    {question: 'Как купить другие программы Екатерины Усмановой?', answer: <div><p>Купить другие программы вы можете самостоятельно на главной странице <a href="https://gymteam.ru/l/mainpage" target='_blank'>https://gymteam.ru/l/mainpage</a> или напишите нашим менеджерам:</p><p><a href="mailto:support@gymteam.ru" target='_blank'>support@gymteam.ru</a></p><p><a href="https://t.me/GymTeamSupportBot" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon.svg" alt="" /></a>&nbsp;&nbsp;<a href="https://api.whatsapp.com/send?phone=79958871172" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon.svg" alt="" /></a></p></div>}
];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    const ref = useRef(null)

    useEffect(() => {
        // window.addEventListener('scroll', scrollHandler);

        // return () => {
        //     window.removeEventListener('scroll', scrollHandler);
        // };
    }, []);

    function scrollHandler() {
        const scolling = window.scrollY;
        const block1 = document.querySelector('.new__b1').offsetHeight;
        const block2 = document.querySelector('.new__b2').offsetHeight;
        const block3 = document.querySelector('.new__b3').offsetHeight;
        const block4 = document.querySelector('.new__b4').offsetHeight;
        const block5 = document.querySelector('.new__b5').offsetHeight;
        const block6 = document.querySelector('.new__b6').offsetHeight;
        const block7 = document.querySelector('.new__b7').offsetHeight;
        const fullSize = block1 + block2 + block3 + block4 + block5 + block6 + block7;
        const res = scolling - fullSize;
        if(Math.sign(res) == -1) {
            ref.current.style.transform = `translateY(${Math.abs(res)/6}px)`
        } else {
            ref.current.style.transform = `translateY(-${Math.abs(res)/5}px)`
        }
    }

    return(
        <div className={styles.faq}>
          <div className="container">
            <div className={styles.faq_block}>
              <div className={styles.faq_left}>
                <div className={styles.faq_titles}>
                  <div className={styles.faq_title}>Отвечаем<br/> на&nbsp;вопросы</div>
                  <div className={styles.promo_btn} onClick={scroll}>Забрать со&nbsp;скидкой&nbsp;89%</div>
                </div>
              </div>
              <div className={styles.faq_right}>
                <div className={styles.accordion}>
                  {faqs.map((faq, index) => (
                    <div key={faq.answer} className={clicked == index + 1 ? styles.active : ''}>
                      <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                      <p className={styles.answer}>{faq.answer}</p>
                    </div>
                  ))}
                </div>
                <div className={styles.promo_btn} onClick={scroll}>Забрать со&nbsp;скидкой&nbsp;89%</div>
                <div className={styles.oferta}>
                  <a href="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/Призовая оферта черной пятницы 2024.pdf" target='_blank'>Призовая оферта</a>
                  <a href="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/1я волна оферта черной пятницы 2024.pdf" target='_blank'>Призовая оферта 2</a>
                  <a href="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/Лучший результат Черной пятницы 2024 (конкурс).pdf" target='_blank'>Конкурсная оферта</a>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Faq;