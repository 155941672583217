import { useLayoutEffect, useRef, useState } from 'react';
import styles from './tabs.module.css';

import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, Pagination} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";

const items = [
    {name: <>Стройная фигура</>},
    {name: <>Зарядиться энергией</>},
    {name: <>Гибкость и&nbsp;грация</>},
    {name: <>Выглядеть моложе</>},
    {name: <>Стать выносливее</>},
    {name: <>Свобода движений</>},
    {name: <>Антистресс фитнес</>},
];
// const items2 = [
//     'Стройная фигура',
//     'Зарядиться энергией',
//     'Гибкость и грация',
//     'Выглядеть моложе',
//     'Стать выносливее',
//     'Свобода движений',
//     'Антистресс фитнес'
// ];

const Tabs = () => {
    const [active, setActive] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const openTab = e => setActive(e.target.dataset.index);

    useLayoutEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // const pagination = {
    //     clickable: true,

    //     renderBullet: function (index, className) {            
    //         return '<span class="' + className + '">' + items2[index] + '</span>';
    //     },
    // };

    return (
        <div className={styles.tabs}>
            <div className="container">
                <div className={styles.t1}>GymTeam для красоты</div>
                <div className={styles.tabs_items}>
                    {items.map((n, i) => (
                    <div
                        className={`${styles.tabs_item} ${i == active && `${styles.tabs_item_active}`}`}
                        onClick={openTab}
                        data-index={i}
                    >{n.name}</div>
                    ))}
                </div>
                {width > 576 ?
                <>
                    {active == 0 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_1.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Интервальный тренинг HIIT</div>
                                    <div className={styles.content_item_text}>Короткие, высокоинтенсивные тренировки, которые ускоряют метаболизм, развивают выносливость, способствуют сжиганию жира и&nbsp;укреплению сердечно-сосудистой системы без тренажеров и&nbsp;спортивных снарядов.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_2.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Фитнес-бокс</div>
                                    <div className={styles.content_item_text}>Аэробные кардиотренировки, сочетающие классические техники бокса и&nbsp;фитнеса. Высокий темп позволяет держать пульс в&nbsp;диапазоне, максимально эффективном для сжигания калорий. Фитнес-бокс улучшает выносливость, координацию и&nbsp;силу, помогая также снять стресс и&nbsp;повысить уверенность в&nbsp;себе.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 1 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_3.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Тай-чи</div>
                                    <div className={styles.content_item_text}>
                                        <p>Тай-чи укрепляет тело и&nbsp;учит концентрации и&nbsp;правильному дыханию. Плавные и&nbsp;мягкие движения развивают равновесие, снимают стресс и&nbsp;улучшают гибкость.</p>
                                        <p>Практика тай-чи способствует общему оздоровлению и&nbsp;повышает жизненный тонус благодаря восьми базовым формам.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_4.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Утренняя зарядка</div>
                                    <div className={styles.content_item_text}>Утренние зарядки помогут мягко пробудить тело и&nbsp;зарядить энергией на&nbsp;весь день. Короткий комплекс, сочетающий фитнес, растяжку, йогу и&nbsp;пилатес, займёт всего 15&nbsp;минут и&nbsp;подходит для любого образа жизни. В&nbsp;результате&nbsp;&mdash; отсутствие болей в&nbsp;спине, гибкость, здоровье суставов и&nbsp;ощущение легкости.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 2 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_5.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Пилатес</div>
                                    <div className={styles.content_item_text}>Плавное погружение в&nbsp;пилатес: от&nbsp;дыхательных упражнений до&nbsp;мягкой проработки мышц и&nbsp;суставов. Улучшается осанка, укрепляются мышцы, снижается напряжение, проходит боль в&nbsp;теле, исчезает стресс, нормализуется сон, улучшается кровоснабжение, активизируется лимфоток, наполняя тело энергией и&nbsp;легкостью.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_6.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Растяжка на&nbsp;каждый день</div>
                                    <div className={styles.content_item_text}>Ежедневные тренировки по&nbsp;растяжке: улучшите эластичность мышц и&nbsp;связок. Улучшается лимфоток и&nbsp;кровоснабжение, ускоряется восстановление после нагрузок, укрепляется устойчивость к&nbsp;травмам, повышается здоровье суставов, что способствует ощущению лёгкости и&nbsp;гибкости.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 3 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_7.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Фейсфитнес</div>
                                    <div className={styles.content_item_text}>Фейсфитнес улучшает тонус лица, разглаживает кожу и&nbsp;придает ей&nbsp;упругость. Занятия способствуют уменьшению морщин и&nbsp;улучшению контура. Быстрый эффект: изменения могут быть заметны уже после первого занятия.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_8.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Лимфодренажный комплекс</div>
                                    <div className={styles.content_item_text}>Лимфодренажный комплекс на&nbsp;основе массажа и&nbsp;тейпирования помогает избавиться от&nbsp;отеков и&nbsp;улучшить общее самочувствие. Уже после курса заметно уменьшение объема тела, кожа приобретает упругость, ощущается легкость. Тейпирование можно проводить как по&nbsp;зонам, так и&nbsp;комплексно.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 4 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_9.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Табата</div>
                                    <div className={styles.content_item_text}>Пройдя все занятия ваше тело обретет подтянутость, упругость и&nbsp;изящный рельеф. Вы&nbsp;почувствуете прилив энергии, выносливость выйдет на&nbsp;новый уровень, а&nbsp;ненужные сантиметры начнут исчезать.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_10.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Кроссфит</div>
                                    <div className={styles.content_item_text}>Благодаря разнообразию упражнений одновременно способствует интенсивному сжиганию жира за&nbsp;счет кардионагрузок, улучшает гибкость, равновесие и&nbsp;координацию благодаря гимнастике. Упражнения со&nbsp;свободными весами увеличивают мышечную и&nbsp;общую выносливость, а&nbsp;также повышают устойчивость к&nbsp;нагрузкам.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 5 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_11.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Функциональные тренировки</div>
                                    <div className={styles.content_item_text}>Упражнения на&nbsp;основе естественных движений включают чередование кардио, силовых нагрузок и&nbsp;калистеники с&nbsp;минимальным риском травм. Это путь к&nbsp;гармоничному телу без изнурительных усилий: улучшенный тонус мышц, увеличение двигательной активности, укрепление спины и&nbsp;пресса, повышение качества жизни и&nbsp;мотивация к&nbsp;регулярным занятиям.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_12.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Body Balance</div>
                                    <div className={styles.content_item_text}>Body Balance сочетает Тай-Чи, пилатес и&nbsp;растяжку, идеально подходит для новичков, стремящихся к&nbsp;гармоничному развитию тела. Оно избавляет от&nbsp;болей в&nbsp;спине, улучшает осанку и&nbsp;эластичность мышц. Контроль дыхания, лучшее движение и&nbsp;расслабление гарантированы.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 6 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_3.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Тай-чи</div>
                                    <div className={styles.content_item_text}>
                                        <p>Тай-чи укрепляет тело и&nbsp;учит концентрации и&nbsp;правильному дыханию. Плавные и&nbsp;мягкие движения развивают равновесие, снимают стресс и&nbsp;улучшают гибкость.</p>
                                        <p>Практика тай-чи способствует общему оздоровлению и&nbsp;повышает жизненный тонус благодаря восьми базовым формам.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_14.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Дыхательные практики</div>
                                    <div className={styles.content_item_text}>Дыхательные практики учат расслаблению и&nbsp;обретению энергии, помогая справляться с&nbsp;эмоциональной нестабильностью и&nbsp;нервными перегрузками. Правильное дыхание улучшает работу органов и&nbsp;снимает дискомфорт в&nbsp;груди, повышая энергию и&nbsp;выносливость, усиливает контроль эмоций и&nbsp;сопротивляемость стрессу.</div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={styles.content_more}>И&nbsp;ещё десятки программ для красоты и&nbsp;здоровья...</div>
                </>
                
                :

                <>
                    <Swiper
                        // pagination={pagination}
                        modules={[Pagination, Navigation]}
                        // autoHeight={true}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        loop
                        onSwiper={(swiper) => {
                            setTimeout(() => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current
                            
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }}
                        slidesPerView={1.15}
                        spaceBetween={20}
                        className={styles.slider}
                    >
                        {active == 0 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_1.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Интервальный тренинг HIIT</div>
                                    <div className={styles.content_item_text}>Короткие, высокоинтенсивные тренировки, которые ускоряют метаболизм, развивают выносливость, способствуют сжиганию жира и&nbsp;укреплению сердечно-сосудистой системы без тренажеров и&nbsp;спортивных снарядов.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 0 && 
                        <SwiperSlide>
                        <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_2.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Фитнес-бокс</div>
                                    <div className={styles.content_item_text}>Аэробные кардиотренировки, сочетающие классические техники бокса и&nbsp;фитнеса. Высокий темп позволяет держать пульс в&nbsp;диапазоне, максимально эффективном для сжигания калорий. Фитнес-бокс улучшает выносливость, координацию и&nbsp;силу, помогая также снять стресс и&nbsp;повысить уверенность в&nbsp;себе.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 1 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_3.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Тай-чи</div>
                                    <div className={styles.content_item_text}>
                                        <p>Тай-чи укрепляет тело и&nbsp;учит концентрации и&nbsp;правильному дыханию. Плавные и&nbsp;мягкие движения развивают равновесие, снимают стресс и&nbsp;улучшают гибкость.</p>
                                        <p>Практика тай-чи способствует общему оздоровлению и&nbsp;повышает жизненный тонус благодаря восьми базовым формам.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 1 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_4.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Утренняя зарядка</div>
                                    <div className={styles.content_item_text}>Утренние зарядки помогут мягко пробудить тело и&nbsp;зарядить энергией на&nbsp;весь день. Короткий комплекс, сочетающий фитнес, растяжку, йогу и&nbsp;пилатес, займёт всего 15&nbsp;минут и&nbsp;подходит для любого образа жизни. В&nbsp;результате&nbsp;&mdash; отсутствие болей в&nbsp;спине, гибкость, здоровье суставов и&nbsp;ощущение легкости.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 2 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_5.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Пилатес</div>
                                    <div className={styles.content_item_text}>Плавное погружение в&nbsp;пилатес: от&nbsp;дыхательных упражнений до&nbsp;мягкой проработки мышц и&nbsp;суставов. Улучшается осанка, укрепляются мышцы, снижается напряжение, проходит боль в&nbsp;теле, исчезает стресс, нормализуется сон, улучшается кровоснабжение, активизируется лимфоток, наполняя тело энергией и&nbsp;легкостью.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 2 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_6.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Растяжка на&nbsp;каждый день</div>
                                    <div className={styles.content_item_text}>Ежедневные тренировки по&nbsp;растяжке: улучшите эластичность мышц и&nbsp;связок. Улучшается лимфоток и&nbsp;кровоснабжение, ускоряется восстановление после нагрузок, укрепляется устойчивость к&nbsp;травмам, повышается здоровье суставов, что способствует ощущению лёгкости и&nbsp;гибкости.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 3 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_7.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Фейсфитнес</div>
                                    <div className={styles.content_item_text}>Фейсфитнес улучшает тонус лица, разглаживает кожу и&nbsp;придает ей&nbsp;упругость. Занятия способствуют уменьшению морщин и&nbsp;улучшению контура. Быстрый эффект: изменения могут быть заметны уже после первого занятия.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 3 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_8.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Лимфодренажный комплекс</div>
                                    <div className={styles.content_item_text}>Лимфодренажный комплекс на&nbsp;основе массажа и&nbsp;тейпирования помогает избавиться от&nbsp;отеков и&nbsp;улучшить общее самочувствие. Уже после курса заметно уменьшение объема тела, кожа приобретает упругость, ощущается легкость. Тейпирование можно проводить как по&nbsp;зонам, так и&nbsp;комплексно.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 4 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_9.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Табата</div>
                                    <div className={styles.content_item_text}>Пройдя все занятия ваше тело обретет подтянутость, упругость и&nbsp;изящный рельеф. Вы&nbsp;почувствуете прилив энергии, выносливость выйдет на&nbsp;новый уровень, а&nbsp;ненужные сантиметры начнут исчезать.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 4 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_10.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Кроссфит</div>
                                    <div className={styles.content_item_text}>Благодаря разнообразию упражнений одновременно способствует интенсивному сжиганию жира за&nbsp;счет кардионагрузок, улучшает гибкость, равновесие и&nbsp;координацию благодаря гимнастике. Упражнения со&nbsp;свободными весами увеличивают мышечную и&nbsp;общую выносливость, а&nbsp;также повышают устойчивость к&nbsp;нагрузкам.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 5 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_11.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Функциональные тренировки</div>
                                    <div className={styles.content_item_text}>Упражнения на&nbsp;основе естественных движений включают чередование кардио, силовых нагрузок и&nbsp;калистеники с&nbsp;минимальным риском травм. Это путь к&nbsp;гармоничному телу без изнурительных усилий: улучшенный тонус мышц, увеличение двигательной активности, укрепление спины и&nbsp;пресса, повышение качества жизни и&nbsp;мотивация к&nbsp;регулярным занятиям.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 5 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_12.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Body Balance</div>
                                    <div className={styles.content_item_text}>Body Balance сочетает Тай-Чи, пилатес и&nbsp;растяжку, идеально подходит для новичков, стремящихся к&nbsp;гармоничному развитию тела. Оно избавляет от&nbsp;болей в&nbsp;спине, улучшает осанку и&nbsp;эластичность мышц. Контроль дыхания, лучшее движение и&nbsp;расслабление гарантированы.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 6 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_3.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Тай-чи</div>
                                    <div className={styles.content_item_text}>
                                        <p>Тай-чи укрепляет тело и&nbsp;учит концентрации и&nbsp;правильному дыханию. Плавные и&nbsp;мягкие движения развивают равновесие, снимают стресс и&nbsp;улучшают гибкость.</p>
                                        <p>Практика тай-чи способствует общему оздоровлению и&nbsp;повышает жизненный тонус благодаря восьми базовым формам.</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 6 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab1_14.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Дыхательные практики</div>
                                    <div className={styles.content_item_text}>Дыхательные практики учат расслаблению и&nbsp;обретению энергии, помогая справляться с&nbsp;эмоциональной нестабильностью и&nbsp;нервными перегрузками. Правильное дыхание улучшает работу органов и&nbsp;снимает дискомфорт в&nbsp;груди, повышая энергию и&nbsp;выносливость, усиливает контроль эмоций и&nbsp;сопротивляемость стрессу.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                    </Swiper>
                    
                    <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}></div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}></div>
                    </div>
                </>

                }
            </div>
        </div>
    )
}

export default Tabs;