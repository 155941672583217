import React from "react";
import { Icon } from "@chakra-ui/react";

export function ArrowSmall(props) {
  return (
    <Icon viewBox="0 0 24 25" {...props}>
      <path
        d="M16.7929 9.5H7.20712C6.76167 9.5 6.53858 10.0386 6.85356 10.3536L11.6465 15.1464C11.8417 15.3417 12.1583 15.3417 12.3536 15.1464L17.1465 10.3536C17.4614 10.0386 17.2384 9.5 16.7929 9.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}
