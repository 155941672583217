import styles from './onmarafon.module.css';

const Onmarafon = () => {
    return (
        <div className={styles.onmarafon}>
            <div className="container">
                <div className={styles.onmarafon_title}>в&nbsp;наборе тренировок <span>нет:</span></div>
                <div className={styles.onmarafon_block}>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/onm_1.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Жёстких диет<br/> и <b>ограничений</b></div>
                        </div>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/onm_2.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>100 приседаний <b>за раз</b></div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/onm_3.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><b>Сомнительных методов</b><br/> и упражнений</div>
                        </div>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/onm_4.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Психологического <b>давления</b></div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item2}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/onm_5.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onmarafon;