import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import Countdown from 'react-countdown';
import styles from './start.module.css';

const Start = ({date, renderer, scroll2}) => {
    
    const [widget] = useInstance();

    function myfunc(n) {
        widget.selectByIndex(n);
        setTimeout(()=>{
            widget.selectByIndex(n);
            scroll2(); 
            widget.submit();
        }, 100)
    }

    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">

            <div className={styles.start_info}>
                    <div className={styles.start_info_title}>СПЕЦИАЛЬНО ДЛЯ ВАС: КОМПЛЕКТЫ ПРОГРАММ<br/> ДЛЯ ПРОРАБОТКИ ВСЕго тела<br/> И&nbsp;ВОССТАНОВЛЕНИЯ ЗДОРОВЬЯ</div>
                    <div className={styles.start_info_subtitle}>ПОЛУЧИТЕ ГОДОВОЙ ДОСТУП НА&nbsp;ЛЮБОЙ КОМПЛЕКТ</div>
                    <div className={styles.start_countdown}>
                            <p>Специальное предложение сгорит через:</p>
                            <div className={styles.start_countdown_timer}>
                                <Countdown date={Date.now() + 1200000} renderer={renderer} />
                            </div>
                    </div>
                    <div className={styles.start_info_block}>
                        
                        <div className={styles.start_info_left}>
                            <div className={styles.start_info_title2}>Коллекция программ Кати Усмановой</div>
                            <div className={styles.start_info_prices}>
                                    <div className={styles.start_info_price}>Стоимость для вас <em>73&nbsp;300₽</em> <span>3&nbsp;990&nbsp;₽</span></div>
                                </div>
                                <div className={styles.vigoda}>Старт 18&nbsp;мая</div>
                                <div className={styles.vigoda}>Срок доступа&nbsp;&mdash; 1&nbsp;год</div>
                            
                            
                            <div className={styles.pr_info}>
                                <p>Марафон Стройность 1.0</p>
                                <span>5000₽</span>
                            </div>
                            <div className={styles.pr_text}>Похудеть и&nbsp;подтянуть всё тело за&nbsp;21&nbsp;день дома и&nbsp;без инвентаря</div>
                            
                            <div className={styles.pr_info}>
                                <p>Марафон Плоского живота</p>
                                <span>5000₽</span>
                            </div>
                            <div className={styles.pr_text}>Быстро сделать животик более плоским и&nbsp;подтянутым</div>

                            <div className={styles.pr_info}>
                                <p>Марафон красивой и&nbsp;упругой попы 1.0</p>
                                <span>5000₽</span>
                            </div>
                            <div className={styles.pr_text}>Изменить форму и&nbsp;объем ягодиц и&nbsp;сделать её&nbsp;спортивнее</div>

                            <div className={styles.pr_info}>
                                <p>Марафон красивой и&nbsp;упругой попы 2.0</p>
                                <span>7900₽</span>
                            </div>
                            <div className={styles.pr_text}>Создать ягодицы с&nbsp;эффектом PushUp</div>

                            <div className={styles.pr_info}>
                                <p>Марафон Убираем лишний жир</p>
                                <span>7900₽</span>
                            </div>
                            <div className={styles.pr_text}>Сжечь жир с&nbsp;проблемных мест и&nbsp;наладить отношения с&nbsp;питанием</div>

                            <div className={styles.pr_info}>
                                <p>Антицеллюлитный марафон</p>
                                <span>7500₽</span>
                            </div>
                            <div className={styles.pr_text}>Подтянуть тело, избавиться от&nbsp;целлюлита, сделать кожу гладкой и&nbsp;ровной</div>

                            <div className={styles.pr_info}>
                                <p>Курс идеальной попы 2.0</p>
                                <span>6000₽</span>
                            </div>
                            <div className={styles.pr_text}>Заметно улучшить форму ягодиц</div>

                            <div className={styles.pr_info}>
                                <p>Курс идеальной попы 3.0</p>
                                <span>12000₽</span>
                            </div>
                            <div className={styles.pr_text}>Поработать над объемом ягодиц и&nbsp;отшлифовать до&nbsp;идеала</div>

                            <div className={styles.pr_info}>
                                <p>Жиросжигающий курс</p>
                                <span>7000₽</span>
                            </div>
                            <div className={styles.pr_text}>Избавиться от&nbsp;лишнего жира, сохраняя мышцы</div>

                            <div className={styles.pr_info}>
                                <p>Курс Питание</p>
                                <span>10000₽</span>
                            </div>
                            <div className={styles.pr_text}>Наладить отношения с&nbsp;едой и&nbsp;похудеть навсегда</div>

                            <div className={styles.pr_pres}>подарки при покупке
                                <p>🎁 Путеводитель по&nbsp;прохождению тренировочных программ на&nbsp;год</p>
                                <p>🎁 Трекер &laquo;30&nbsp;дней без сладкого&raquo;</p>
                                <p>🎁 Чек-лист динамики веса и&nbsp;питания</p>
                                <p>🎁 Аудиокурс &laquo;Женсовет&raquo;&nbsp;&mdash; 20&nbsp;ответов на&nbsp;каждый день</p>
                            </div>

                            <div className={styles.start_info_end}>
                                <div className={styles.promo_btn} onClick={()=>myfunc(0)}><p>забрать программы</p></div>
                            </div>
                        </div>

                        <div className={styles.start_info_left}>
                            <div className={styles.start_info_title2}>Подписка на&nbsp;ВСЕ программы GymTeam&nbsp;&mdash; проекта Кати Усмановой на&nbsp;год, включая пополнения каталога за&nbsp;этот период</div>
                            <div className={styles.start_info_prices}>
                                    <div className={styles.start_info_price}>Стоимость для вас <em>9&nbsp;590&nbsp;₽</em> <span>4&nbsp;490&nbsp;₽</span></div>
                                </div>
                                <div className={styles.vigoda}>Старт 18&nbsp;мая</div>
                                <div className={styles.vigoda}>Срок доступа&nbsp;&mdash; 1&nbsp;год</div>
                            
                            
                            <p><b>Внутри подписки:</b></p>
                            <ul>
                                <li>Наборы тренировок на&nbsp;похудение, растяжку, ягодицы, пресс, расслабление и&nbsp;выносливость для возраста от&nbsp;18&nbsp;до&nbsp;60&nbsp;лет</li>
                                <li>50+&nbsp;фитнес-программ для всех возрастов, более 1&nbsp;500 тренировок&nbsp;&mdash; чередуйте стили как угодно и&nbsp;найдите свой</li>
                                <li>Занятия от&nbsp;15&nbsp;до&nbsp;50&nbsp;минут</li>
                                <li>Только действующие тренеры-эксперты с&nbsp;опытом работы более 10&nbsp;лет</li>
                                <li>Ежемесячно добавляются новые программы</li>
                            </ul>

                            <div className={styles.start_info_end}>
                                <div className={styles.promo_btn} onClick={()=>myfunc(1)}><p>забрать программы</p></div>
                            </div>
                        </div>

                        <div className={styles.start_info_left}>
                            <div className={styles.start_info_title2}>Коллекция программ Кати Усмановой + подписка на&nbsp;все программы GymTeam</div>
                            <div className={styles.start_info_prices}>
                                    <div className={styles.start_info_price}>Стоимость для вас <em>82&nbsp;890&nbsp;₽</em> <span>4&nbsp;990&nbsp;₽</span></div>
                                </div>
                                <div className={styles.vigoda}>Старт 18&nbsp;мая</div>
                                <div className={styles.vigoda}>Срок доступа&nbsp;&mdash; 1&nbsp;год</div>
                            
                            
                            

                            <div className={styles.start_info_end}>
                                <div className={styles.promo_btn} onClick={()=>myfunc(2)}><p>забрать программы</p></div>
                            </div>
                        </div>
                        
                    </div>
                </div>


                <div className={('scrollstart ') + styles.start_block}>
                    {/* <div className={styles.start_text}>СПЕЦИАЛЬНО ДЛЯ ВАС: ЦЕЛЫЕ КОМПЛЕКТЫ ПРОГРАММ ДЛЯ ПРОРАБОТКИ ВСЕХ ЗОН И&nbsp;ВОССТАНОВЛЕНИЯ ЗДОРОВЬЯ</div>
                    <div className={styles.start_top}>
                        <div className={styles.start_title}>ПОЛУЧИТЕ ГОДОВОЙ ДОСТУП НА&nbsp;ЛЮБОЙ КОМПЛЕКТ</div>
                        <div className={styles.start_top_right}>
                            <div className={styles.start_countdown}>
                                <p>Специальное предложение сгорит через:</p>
                                <div className={styles.start_countdown_timer}>
                                    <Countdown date={Date.now() + 1200000} renderer={renderer} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    
                    
                    <div className="lessons_start">
                        <SalesWidget hook={widget} id={window.lps_settings.forms.upsale_id} />
                    </div>
                    <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>
                    <div className={styles.start_new}>
                        <div className={styles.start_credit}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/inshape/tnkf.svg" alt="" />
                            <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;665&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц</div>
                            <div className={styles.start_credit__text}>
                                <p>Вы можете оформить заказ в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                            </div>
                        </div>
                        <div className={styles.start_parts}>
                            <div className={styles.start_parts_mark}><img src="https://gymteam.kinescopecdn.net/img/l/2023/birthdayparty/mark2.png" alt="" /></div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/pregnancy/start_icon.png" alt="" />
                            <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                            <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части<br/> от 997,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель.</div>
                            <div className={styles.start_parts_block}>
                                <div className={styles.start_parts_item}>
                                    <div className={styles.start_parts_number}><p>1</p></div>
                                    <p>от 997,5&nbsp;<b>₽</b><br/> <span>сегодня</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                    <p>от 997,5&nbsp;<b>₽</b><br/> <span>через 2&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                    <p>от 997,5&nbsp;<b>₽</b><br/> <span>через 4&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                    <p>от 997,5&nbsp;<b>₽</b><br/> <span>через 6&nbsp;недель</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start;