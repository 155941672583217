import styles from './begin.module.css';

const Begin = () => {
    return (
        <div className={styles.begin}>
            <div className="container">
                <div className={styles.begin_title}>ПОРА ТРЕНИРОВАТЬСЯ В ЗАЛЕ<br/> <span>С КАТЕЙ УСМАНОВОЙ</span></div>
                <div className={styles.begin_subtitle}>Вас ждёт:</div>
                <div className={styles.begin_block}>
                    <div className={styles.begin_item}>
                        <div className={styles.begin_info}>
                            <div className={styles.begin_number}>/<span>1</span></div>
                            <div className={styles.begin_text}><b>Отработка правильной техники выполнения</b> на минимальных весах, чтобы заниматься безопасно.</div>
                        </div>
                    </div>
                    <div className={styles.begin_item}>
                        <div className={styles.begin_info}>
                            <div className={styles.begin_number}>/<span>2</span></div>
                            <div className={styles.begin_text}><b>Повторяющийся цикл тренировок с чётким графиком,</b> чтобы постепенно увеличивать нагрузку и гармонично развивать своё тело.</div>
                        </div>
                    </div>
                    <div className={styles.begin_item}>
                        <div className={styles.begin_info}>
                            <div className={styles.begin_number}>/<span>3</span></div>
                            <div className={styles.begin_text}><b>Новая система тренировок,</b> чтобы задействовать медленные и быстрые мышечные волокна.</div>
                        </div>
                    </div>
                    <div className={styles.begin_item}>
                        <div className={styles.begin_info}>
                            <div className={styles.begin_number}>/<span>4</span></div>
                            <div className={styles.begin_text}><b>Продуманная система питания,</b> чтобы добиться результата быстрее и сформировать правильные привычки.</div>
                        </div>
                    </div>
                </div>
                <div className={styles.begin_block2}>
                    <div>КУРС В ФОРМАТЕ<br/> <span>«ПОВТОРЯЙ ЗА КАТЕЙ»</span></div>
                    <ul>
                        <li><b>4,5 месяца</b> вас будет тренировать сама Катя Усманова.</li>
                        <li>Вам надо только включить нужное видео, внимательно смотреть<br/> и слушать, повторять. <b>Соблюдать все рекомендации и график.</b></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Begin;