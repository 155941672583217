import { useState } from 'react';
import styles from './faq.module.css';

const Faq = () => {
    const faqs = [
        {question: 'Инструкция как активировать бесплатный доступ к подписке?', answer: <><p>1. Скопируйте реферальную ссылку для приглашения в GymTeam.</p><p>2. Отправьте её друзьям — после регистрации каждый получит бесплатную неделю подписки.</p><p>3. За каждого друга, который зарегистрировался по вашей ссылке, вы тоже получите бесплатную неделю подписки.</p></>},
        {question: 'Есть ли ограничения на количество друзей, которых я могу пригласить?', answer: <><p>Вы можете пригласить неограниченное количество друзей в GymTeam и каждый из них получит бесплатную неделю подписки. Количество приглашений на данный момент безлимитно.</p></>},
        {question: 'Что если у меня уже есть подписка?', answer: <><p>Если у вас уже есть активная подписка, то приглашая друга в GymTeam и даря бесплатную неделю, ваша подписка продлевается на одну неделю.</p><p>Для пользователей оформивших подписку в iOS или Android есть ограничение: вы можете получить не более 12 недель дважды в течение года.</p></>},
        {question: 'Если друг уже зарегистрирован в GymTeam?', answer: <><p>В этом случае воспользоваться реферальной ссылкой не получится —  программа действует только для новых участников.</p></>},
        {question: 'Как друзья могут воспользоваться своей бесплатной неделей подписки?', answer: <><p>Подписка включается автоматически, можно сразу начинать тренироваться после регистрации на платформе. Все функции и весь фитнес каталог тренировок - будет доступен в бесплатном недельном доступе.</p></>},
        {question: 'Как мне узнать, что бесплатная неделя доступа активирована?', answer: <><p>После регистрации друга по вашей реферальной ссылке вы получите подтверждение по электронной почте, а в личном кабинете автоматически изменится срок доступа подписки.</p><p>Если у вас возникли сложности или появились вопросы, рекомендуем обратиться в службу поддержки GymTeam.</p></>},
    ];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    return(
        <div className={styles.faq}>            
            <div className={styles.faq_title}>Отвечаем на вопросы</div>
            <div className={styles.accordion}>
              {faqs.map((faq, index) => (
                <div key={index} id={`faqnumber${index}`} className={clicked == index + 1 ? styles.active : ''}>
                  <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                  <p className={styles.answer}>{faq.answer}</p>
                </div>
              ))}
            </div>
        </div>
    );
};

export default Faq;