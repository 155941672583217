import './whaitmore.css';

const Whaitmore = () => {
    return (
        <div className="whaitmore">
            <div className="container">
            <div className="whaitmore_block">
                <div className="whaitmore_img">
                <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/whaitmore.png' alt="" />
                </div>
                <div className="whaitmore_description">
                <div className="whaitmore_description-title">Вас ждёт нечто большее, чем просто тренировки</div>
                <div className="whaitmore_description-text">Это возможность немного отвлечься и&nbsp;заняться собой,<br/> чтобы не&nbsp;превращать уход за&nbsp;ребёнком в&nbsp;культ<br/> и&nbsp;гиперопеку.</div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Whaitmore;