import { useState } from 'react';
import './faq.css';

const Faq = ({scroll}) => {
    const faqs = [
        {question: 'Есть ли противопоказания для занятий?', answer: 'Мы собрали список основных противопоказаний, с которым вы можете ознакомиться перед покупкой в отдельном блоке на нашем сайте.'},
        {question: 'Можно купить только тот триместр, который мне сейчас подходит по сроку беременности?', answer: 'Да, у вас есть возможность приобрести триместры по отдельности. Вы приобретаете именно тот формат участия, который соответствует вашему сроку беременности.'},
        {question: 'Какой срок доступа к тренировкам, если покупать программы отдельно по триместрам?', answer: 'Срок доступа к каждому триместру составляет 1 год после старта тренировок.'},
        {question: 'Входят ли в курс рекомендации по питанию?', answer: 'В курсе есть рекомендации по питанию для облегчения токсикоза и других симптомов. Рекомендации будут представлены в формате zoom-сессии от трех специалистов: психолог, нутрициолог и гинеколог-эндокринолог.'},
        {question: 'Сколько времени длится одна тренировка?', answer: 'Длительность одной тренировки составляет от 15 до 45 минут. Тренировки проходят в домашних условиях в удобном для вас режиме.'},
        {question: 'Какой инвентарь нужен для тренировок?', answer: 'Вам потребуется: коврик для фитнеса (по желанию), фитбол 55-60 см, фитнес-резинка или ремень для йоги, гантели 2 шт. (по 1-2 кг каждая), мяч для пилатеса и плед.'},
        {question: 'Могу ли я приступить к тренировке, которая соответствует моей неделе беременности в триместре?', answer: 'Вы можете начать заниматься в любой удобный для вас момент. Для этого нужно будет самостоятельно проставить отметку о выполнении предыдущих тренировок в прошедших неделях, после этого вам откроется доступ к вашей актуальной неделе тренировок в триместре.'},
        {question: 'Тренировки проходят в определенное время?', answer: 'Вы сможете тренироваться в любом удобном для вас месте, дату и время для занятий вы определяете сами. Для прохождения вам потребуется от 15 до 45 минут свободного времени в течение дня.'},
        {question: 'Можно ли заниматься дома?', answer: 'Программа специально разработана для тренировок в домашних условиях.'},
        {question: 'Смогу ли я заниматься дома, если живу не в России?', answer: 'Платформа доступна для всех пользователей, и в любой точке мира. Для этого вам потребуется смартфон, планшет или компьютер со стабильным доступом в интернет. Также, для удобства вы можете использовать SmartTV.'},
        {question: 'Актуален ли курс для тех, кто только планирует беременность?', answer: 'Программа актуальна и для тех, кто планирует беременность. Так как пройдя курс вы сможете правильно подготовиться к физиологическим изменениям своего тела во время беременности.'},
        {question: 'Я оплатила курс, но мне ничего не открылось. Что делать?', answer: <p>Курс доступен на платформе GymTeam. Приобретенная программа будет доступна по тому номеру телефона, который вы указали при покупке. Поэтому, рекомендуем вам проверить актуальность личных данных, указанных при покупке. Если вы найдете несоответствие, сразу напишите об этом в службу поддержки по адресу &mdash; <a href="mailto:support@gymteam.ru">support@gymteam.ru</a>.</p>},
        {question: 'Можно ли «заморозить» программу во время прохождения курса, а потом продолжить?', answer: <p>Доступ можно «заморозить» на период от 1 до 4 недель, при условии наличия медицинской справки от врача. По вопросам заморозки доступа, обращайтесь в службу поддержки по адресу &mdash; <a href="mailto:support@gymteam.ru">support@gymteam.ru</a>.</p>},
        {question: 'У меня возникли технические проблемы. Куда писать?', answer: <p>По техническим вопросам, связанным с работой платформы или доступом к программам, пишите в нашу службу поддержки <a href='mailto:support@gymteam.ru'>support@gymteam.ru</a>.</p>},
        {question: 'Как установить мобильное приложение GymTeam?', answer: <><p>Прямые ссылки для установки:</p><p><a href="https://apps.apple.com/us/app/id1533404428" target='_blank'>IOS App</a></p><p><a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android" target='_blank'>Android App</a></p></>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через web-сайт?', answer: <><p>Чтобы войти на платформу, необходимо пройти регистрацию по номеру мобильного телефона. ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Выполните инструкцию ниже по шагам:</p><p>Шаг 1 - Откройте веб сайт <a href="https://gymteam.ru/" target='_blank'> www.gymteam.ru</a></p><p>Шаг 2 - Укажите номер телефона в международном формате.</p><p>Шаг 3 - Нажмите на кнопку «Получить код».</p><p>Шаг 4 - Получите входящий вызов.</p><p>Шаг 5 - Введите последние 4 цифры входящего номера телефона, и нажмите на кнопку «Войти».</p></>},
        {question: 'Как войти в аккаунт GymTeam по номеру телефона через мобильное приложение?', answer: <><p>ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Шаг 1 - Введите номер телефона в международном формате, затем нажмите «Подтвердить».</p><p>Шаг 2 - Подтвердите входящий вызов, кликнув на кнопку.</p><p>Шаг 3 - Запомните 4 последние цифры входящего номера телефона. ВНИМАНИЕ! Отвечать на звонок не нужно.</p><p>Шаг 4 - Введите 4 цифры в поле для ввода, далее приложение автоматически произведет вход.</p><p>Шаг 5 - Если вам не поступил входящий вызов, то нажмите на кнопку «Отправить SMS сообщение».</p><p>Шаг 6 - Получите входящее SMS сообщение с кодом для входа, и введите цифры.</p></>},
        {question: 'Как купить другие программы Екатерины Усмановой?', answer: <><p>Купить другие программы вы можете самостоятельно на сайте программ или напишите нашим менеджерам <a href='mailto:support@gymteam.ru' target='_blank'>support@gymteam.ru</a></p><p>Купить программы можно по этим ссылкам:</p><p><a href='https://gymteam.ru/l/universalpop/' target='_blank'>Универсальные тренировки на ягодицы</a><br/><a href='https://gymteam.ru/l/marafonstr1' target='_blank'>Марафон Стройность 1.0</a><br/><a href='https://gymteam.ru/l/marafonabs' target='_blank'>Марафон Плоского живота</a><br/><a href='https://gymteam.ru/l/marafonpopa1' target='_blank'>Марафон Упругой Попы 1.0</a><br/><a href='https://gymteam.ru/l/marafonpopa2' target='_blank'>Марафон Упругой Попы 2.0</a><br/><a href='https://gymteam.ru/l/removefat' target='_blank'>Марафон Убираем лишний жир</a><br/><a href='https://gymteam.ru/l/anticellulite' target='_blank'>Антицеллюлитный марафон</a><br/><a href='https://gymteam.ru/l/fatburning' target='_blank'>Жиросжигающий курс</a><br/><a href='https://gymteam.ru/l/coursepop2' target='_blank'>Курс по создания идеальной Попы 2.0</a><br/><a href='https://gymteam.ru/l/coursepop3' target='_blank'>Курс идеальной попы 3.0</a><br/><a href='https://gymteam.ru/l/nutrition' target='_blank'>Курс Питание</a><br/><a href='https://gymteam.ru/l/afterpregnancy' target='_blank'>Курс Восстановление после родов</a><br/><a href='https://gymteam.ru/l/coursegym/' target='_blank'>Курс для зала</a></p></>}
    ];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    return(
        <div className="faq" >
          <div className="container">
            
            <div className="faq_title">ВОПРОСЫ И ОТВЕТЫ</div>
            <div className='accordion'>
              {faqs.map((faq, index) => (
                <div key={faq.answer} className={clicked == index + 1 ? ' active' : ''}>
                  <p onClick={()=>handleToggle(index+1)} className="question">{faq.question} </p>
                  <p className="answer">{faq.answer}</p>
                </div>
              ))}
            </div>
            <button className='main_btn_p' onClick={scroll}>Принять участие</button>
          </div>
        </div>
    );
};

export default Faq;