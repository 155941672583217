import styles from './points.module.css';

const Points = ({scroll}) => {
    return (
        <div className={styles.points}>
            <div className="container">
                <div className={styles.points_date}>7&nbsp;дней бесплатных тренировок</div>
                <div className={styles.points_title}>Вы ставите цель&nbsp;—<br/> мы подбираем программу,<br/> которая приведёт вас к&nbsp;ней</div>
                <div className={styles.points_subtitle}>интенсивно&nbsp;– энергично&nbsp;– эффективно</div>
                <div className={styles.points_block}>
                    <div className={styles.points_item}>
                        <div className={styles.points_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/points_1.svg" alt="" /></div>
                        <div className={styles.points_item_title}>7 тренеров-экспертов будут работать с&nbsp;вами</div>
                        <div className={styles.points_item_text}>чтобы вы&nbsp;смогли достичь своей лучшей формы</div>
                    </div>
                    <div className={styles.points_item}>
                        <div className={styles.points_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/points_2.svg" alt="" /></div>
                        <div className={styles.points_item_title}>Индивидуальная программа для каждого</div>
                        <div className={styles.points_item_text}>об&nbsp;этом позаботятся наши специалисты</div>
                    </div>
                </div>
                <div className={styles.points_title2}>Вам нужно участвовать,<br/> если хотите похудеть или</div>
                <div className={styles.points_block2}>
                    <div className={styles.points_item2}>
                        <div className={styles.points_info}>
                            <div className={styles.points_info_number}>1</div>
                            <div className={styles.points_info_title}>У&nbsp;вас сидячий образ жизни</div>
                            <div className={styles.points_info_text}>Это приводит к&nbsp;боли в&nbsp;шее, пояснице, слабости в&nbsp;теле. Ни&nbsp;на&nbsp;что нет энергии.</div>
                        </div>
                    </div>
                    <div className={styles.points_item2}>
                        <div className={styles.points_info}>
                            <div className={styles.points_info_number}>2</div>
                            <div className={styles.points_info_title}>Не&nbsp;хватает сил и&nbsp;мотивации</div>
                            <div className={styles.points_info_text}>Обещаете себе начать с&nbsp;понедельника, с&nbsp;Нового года, но&nbsp;всегда что-то мешает.</div>
                        </div>
                    </div>
                    <div className={styles.points_item2}>
                        <div className={styles.points_info}>
                            <div className={styles.points_info_number}>3</div>
                            <div className={styles.points_info_title}>Хотите красивое, здоровое и&nbsp;подтянутое тело</div>
                            <div className={styles.points_info_text}>Такое, о&nbsp;котором всегда мечтали</div>
                        </div>
                    </div>
                </div>
                <div className={styles.points_pretitle3}>Только рабочие методы похудения&nbsp;—<br/> и&nbsp;ничего лишнего</div>
                <div className={styles.points_title3}>Готовы принять вызов?</div>
                <div className={styles.points_btn} onClick={scroll}>Принять вызов</div>
            </div>
        </div>
    );
};

export default Points;