import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/universalpop/logo.svg' alt="gymteam" /></div>
                <div className={styles.promo_pretitle}>Онлайн-курс Кати&nbsp;Усмановой</div>
                <div className={styles.promo_title}>ИДЕАЛЬНОЙ<br/> <span>ПОПЫ&nbsp;3.0</span></div>
                <div className={styles.promo_subtitle}>Масса и&nbsp;сушка: та&nbsp;самая методика создания<br/> <strong>эталонных ягодиц за&nbsp;90&nbsp;дней</strong></div>
                <div className={styles.promo_items}>
                    <div className={styles.promo_item1}><img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/icon1.png" alt="" /> <p>Для любого уровня</p></div>
                    <div className={styles.promo_item2}><img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/icon2.png" alt="" /> <p>3 месяца</p></div>
                    <div className={styles.promo_item3}><img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/icon3.png" alt="" /> <p>С инвентарём</p></div>
                </div> 
                <div className={styles.promo_btns}>
                    <div className='l2212_coursepop3_btn' onClick={scroll}><p>Участвовать</p></div>
                    <div className={styles.promo_date}><p>Старт<br/> {window.lps_settings.dates.mainlands}</p></div>
                </div>
            </div>
            <div className={styles.promo_points_block}>
                <div className={styles.promo_point1}>#набрать<span>массу</span></div>
                <div className={styles.promo_point2}>#<span>похудеть</span></div>
                <div className={styles.promo_point3}>#придать<span>рельеф</span></div>
            </div>
        </div>
    )
}

export default Promo;