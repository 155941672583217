import styles from './knowledge.module.css';

const Knowledge = () => {
    return (
        <div className={styles.knowledge}>
            <div className="container">
                <div className={styles.knowledge_block}>
                    <ul>
                        <li>Какие упражнения <strong>на&nbsp;пресс существуют?</strong></li>
                        <li>А какие работают для&nbsp;вас <strong>лучше всего?</strong></li>
                        <li>Сколько нужно выполнять одно упражнение, чтобы&nbsp;<strong>оно было&nbsp;эффективным?</strong></li>
                        <li>Как нужно питаться, чтобы&nbsp;<strong>уходил жирок с&nbsp;живота?</strong></li>
                    </ul>
                    <div className={styles.knowledge_info}><strong>Марафон плоского живота —</strong><br/> это&nbsp;концентрат знаний Кати Усмановой о&nbsp;создании рельефного и&nbsp;красивого пресса</div>
                </div>
            </div>
        </div>
    )
}

export default Knowledge;