import { Helmet } from "react-helmet";

export function DocumentTitle({ value }) {
    return (
      <Helmet defaultTitle="GymTeam">
        <meta property="og:title" content={value}/>
        <title>{value}</title>
      </Helmet>
    );
}

export function DocumentDescription({ value }) {
  return (
    <Helmet>
        <meta name="description" content={value} />
        <meta property="og:description" content={value} />
    </Helmet>
  );
}