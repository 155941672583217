import { RedirectPrograms } from '../components/redirect/RedirectProgram';
import { DocumentDescription, DocumentTitle } from '../components/DocumentMeta';
import Author from './components/Author';
import Faq from './components/Faq';
import Footer from '../components/Footer';
import Inventory from './components/Inventory';
import Marforme from './components/Marforme';
import Promo from './components/Promo';
import Start from './components/Start';
import Studentres from './components/Studentres';
import Whaityou from './components/Whaityou';
import Createbody from './components/Createbody';
import Noinventory from './components/Noinventory';
import Onmarafon from './components/Onmarafon';
import Platform from '../components/platform/Platform';
import './style.css';

export const L2022_12_Coursepop3 = () => {
    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scrollStart = () => {
      const section = document.querySelector( '.scrollstart' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };
    
    return (
      <>
      <RedirectPrograms />
      <DocumentTitle value='Создание идеальной попы 3.0 — курс Екатерины Усмановой в GymTeam'/>
      <DocumentDescription value='Курс «Идеальной попы 3.0» в GymTeam — программа Екатерины Усмановой на 3 месяца тренировок дома или в зале. Цель: накачать ягодицы, сделать попу круглой.' />
      
        <div className='coursepop3'>
            <Promo scroll={scroll} />
            <Createbody />
            <Onmarafon />
            <Whaityou />
            <Marforme />
            <Studentres scrollStart={scrollStart} />
            <Platform />
            <Inventory />
            <Noinventory />
            <Start />
            <Author />
            <Faq scroll={scroll} />
            <Footer />
        </div>
      </>
    );
};