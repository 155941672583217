import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import { useEffect, useState } from 'react';
import Faq from './components/Faq';
import Promo from './components/Promo';
import Header from './components/Header';
import Footer from '../../components/Footer';
import Yourbody from './components/Yourbody';
import Slider from './components/Slider';
import Programs from './components/Programs';
import Newprograms from './components/Newprograms';
import Novelties from './components/Novelties';
import Joinus from './components/Joinus';
import Trainers from './components/Trainers';
import Preims from './components/Preims';
import Subscribe from './components/Subscribe';
import Gtage from './components/Gtage';
import Reviews from './components/Reviews';
import './style.css';
import { MyRedirect } from '../gtv/components/redirec';

export const L2023_01_Gymteamsale = () => {
    const [isActive, setIsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const scroll = (name) => {
        const section = document.querySelector( name ).offsetTop;
        window.scrollTo({top: section - 60, behavior: "smooth"});
        setIsActive(false)
    };

    useEffect(() => {
        const onPageLoad = () => {
            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        };
        
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
      
    }, []);

    return (
        // <>
        // <DocumentTitle value='Подписка GymTeam — безлимитные тренировки экстра-класса'/>
        // <DocumentDescription value='GymTeam — почти 40 направлений тренировок для здорового и красивого тела за 10-50 минут в день. Подписка GymTeam — это безлимитные тренировки экстра-класса с лучшими тренерами, доступ к новым программам по подписке бесплатно.' />
        // {
        // !isLoading ?
        // <div className='gymteamsale_23_01'>
        //     <Header isActive={isActive} setIsActive={setIsActive} scrollh={() => scroll('.sub_anchor')}/>
        //     <Promo scrollg={() => scroll('.programs_anchor')} scroll={() => scroll('.sub_anchor')} />
        //     <Yourbody/>
        //     <Slider/>
        //     <Programs/>
        //     <Newprograms/>
        //     <Novelties/>
        //     <Joinus scroll={() => scroll('.sub_anchor')} />
        //     <Trainers/>
        //     <Preims/>
        //     <Subscribe/>
        //     <Reviews/>
        //     <Gtage scroll={() => scroll('.sub_anchor')} />
        //     <Faq />
        //     <Footer />
        // </div>
        // :
        // <div className='gt_preloader'>
        //     <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preloader_logo.svg" alt="" />
        //     <div className='gt_loader'></div>
        // </div>
        // }
        // </>
        <MyRedirect url='/'/>
    );
};