import Countdown from "react-countdown";
import styles from './promo2.module.css';

const Promo2 = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_pretitle}>ЧЕРНАЯ ПЯТНИЦА</div>
                    <div className={styles.promo_title}>Похудейте<br/> и&nbsp;создайте стройное<br/> тело к&nbsp;новому году<br/> <b>с&nbsp;выгодой&nbsp;89%</b></div>
                    <div className={styles.promo_text}><b>13&nbsp;программ Кати Усмановой</b>,<br/> программа питания,<br/> розыгрыши ценных призов<br/> и&nbsp;множество других бонусов</div>
                    {/* <div className={styles.promo_list}>
                        <p>4&nbsp;бонусных урока по&nbsp;питанию<br/> для максимального эффекта<br/> от&nbsp;тренировок</p>
                        <p>Чат на&nbsp;1,5 месяца с&nbsp;поддержкой кураторов<br/> и&nbsp;другими ученицами</p>
                    </div> */}
                    <div className={styles.mob}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/promo_mob.png" alt="" />
                    </div>
                    <div className={styles.mob_btn}>
                        <div className={styles.promo_btn} onClick={scroll}>Забрать набор</div>
                    </div>
                    <div className={styles.remains}>
                        <div className={styles.remains_place}>Осталось комплектов: <span>{window.lps_settings.other.count}</span>/100</div>
                        <div className={styles.remains_text}>Далее цена повысится</div>
                    </div>
                    {/* <div className={styles.promo_more}> */}
                        {/* <div className={styles.promo_remains_more}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/promo_i1.png" alt="" />
                            <p>Забирайте <b>эксклюзивный<br/> набор на&nbsp;1&nbsp;год</b></p>
                        </div> */}
                        {/* <div className={styles.promo_remains}>
                            <div className={styles.promo_remains_text}>Спецпредложение<br/> сгорит через:</div>
                            <div className={styles.promo_remains_number}>{window.lps_settings.other.count}</div>
                            <div className={styles.timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default Promo2;