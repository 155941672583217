import styles from './onmarafon.module.css';

const Onmarafon = () => {
    return (
        <div className={styles.onmarafon}>
            <div className="container">
                <div className={styles.onmarafon_title}>НА курсе <span>НЕ&nbsp;БУДЕТ:</span></div>
                <div className={styles.onmarafon_block}>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/nutrition/onm_1.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Жёстких диет</strong><br/> и голодовок</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/nutrition/onm_2.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Смузи на завтрак, обед</strong><br/> и ужин</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/nutrition/onm_3.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Чудо-средств</strong> для похудения</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/nutrition/onm_4.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Психологического <strong>давления</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onmarafon;