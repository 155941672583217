import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/logo.svg" alt="" /></div>
                <div className={styles.promo_title}>ЖИРОСЖИГАЮЩАЯ НЕДЕЛЯ<br/> ПО&nbsp;ИНДИВИДУАЛЬНОЙ<br/> ПРОГРАММЕ</div>
                <div className={styles.promo_subtitle}>Тренировки под вашу цель<br/> с учетом последних научных открытий.<br/> Минус лишние киллограмы и сантиметры</div>
                <div className={styles.promo_text}>Готовы сжечь всё лишнее?</div>
                <div className={styles.promo_btn} onClick={scroll}>участвовать бесплатно</div>
            </div>
        </div>
    );
};

export default Promo;