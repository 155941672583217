import './promo.css';
import Countdown from 'react-countdown';

const Promoblock = ({date, renderer, scroll}) => {
    return (
        <div className='promoblock'>
            <div className='container'>
                <div className='promo_logo'><img src='https://gymteam.kinescopecdn.net/img/l/2023/universalpop/logo.svg' alt="gymteam" /></div>
                <h1 className='promoblock_title'>ФИТНЕС-<br/>ВОССТАНОВЛЕНИЕ<br/> ПОСЛЕ РОДОВ</h1>
                <div className="promoblock_subtitle">по методу Кати Усмановой</div>
                <div className="promoblock_info">Всего 2&nbsp;месяца, чтобы вернуть<br/> <span>стройность, здоровье и&nbsp;эмоциональное равновесие</span><br/> ради себя и&nbsp;малыша</div>
                {/* <div className="promoblock_countdown">
                    <p>Предложение сгорит через:</p>
                    <Countdown date={Date.now() + date} renderer={renderer} />
                </div> */}
                <button className='main_btn' onClick={scroll}>Участвовать</button>
            </div>
            <div className="promoblock_block">
                <div className="promoblock_block__title">Чемпионка России по&nbsp;фитнес-бикини</div>
                <div className='promoblock_block__text'>Во время первой беременности <strong>набрала 20&nbsp;кг и&nbsp;вернулась в&nbsp;форму за&nbsp;100 дней&nbsp;после родов</strong></div>
            </div>
        </div>
    )
}

export default Promoblock;