import React, { Fragment, createContext, useRef } from "react";
import { useDisclosure } from "@chakra-ui/react";

import { AuthWidget } from "../AuthWidget";

export const AuthContext = createContext();

export function AuthProvider({ children, onSuccess }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const successCb = useRef(null);

  function login(cb) {
    successCb.current = cb;
    onOpen();
  }

  function _onSuccess() {
    typeof successCb.current === "function" && successCb.current();
    typeof onSuccess === "function" && onSuccess();
    onClose();
  }

  return (
    <AuthContext.Provider
      value={{
        login,
      }}
    >
      <AuthWidget modal isOpen={isOpen} onClose={onClose} onSuccess={_onSuccess} />
      <Fragment>{children}</Fragment>
    </AuthContext.Provider>
  );
}
