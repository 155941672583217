import {
  DocumentTitle,
  DocumentDescription,
} from "../../components/DocumentMeta";
import Footer from "../../components/Footer";

import styles from "./style.module.css";

export const L2024_08_Oferta_metod_c = () => {
  return (
    <>
      <DocumentTitle value="ДОГОВОР (ОФЕРТА) НА УЧАСТИЕ В КОНКУРСЕ" />
      <DocumentDescription value="ДОГОВОР (ОФЕРТА) НА УЧАСТИЕ В КОНКУРСЕ" />
      <div className={styles.oferta}>
        <h1>ДОГОВОР (ОФЕРТА) НА УЧАСТИЕ В КОНКУРСЕ</h1>
        <div>
          <p>г. Москва</p>
          <p>30 июля 2024 года</p>
        </div>
        <p>
          Настоящий договор-оферта (далее по тексту – Договор) адресован любому
          лицу (неопределенному кругу лиц), чья воля будет выражена им лично,
          выразившему готовность принять предложение Оферента, на указанных ниже
          условиях.
        </p>
        <p>
          Настоящий Договор является публичной офертой в соответствии с п. 2 ст.
          437 Гражданского кодекса Российской Федерации, принятием условий
          (акцептом) которой является подача заявки на участие в Конкурсе в
          порядке, предусмотренном настоящим Договором.
        </p>
        <p>
          Настоящий Договор представляет собой оферту ООО «Онлайн Фитнес»
          заключить (принять) договор (оферту) на изложенных условиях
        </p>
        <h2>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ, ПРИМЕНЯЕМЫЕ В ДОГОВОРЕ</h2>
        <p>
          «Конкурс» – открытое и доступное для участия неопределенному кругу
          лиц, отвечающих требованиям к Участникам настоящего Договора,
          стимулирующее мероприятие, условия и порядок проведения которого
          устанавливаются настоящим Договором.&nbsp;
          <br />
          Цель Конкурса: Повышение узнаваемости, стимулирование продаж. Конкурс
          носит исключительно рекламный характер, не является лотереей, не
          основан на риске, не требует внесения платы за участие, проводится для
          выявления кандидатов наиболее соответствующих по мнению Организатора
          критериям, определяемым настоящим Договором.
          <br />
          &nbsp;«Организатор Конкурса» – ООО “Онлайн Фитнес”, ИНН 7734434533,
          КПП 773401001
          <br />
          ОГРН 1207700175209.&nbsp;
          <br />
          Адрес: 123182, г. Москва, ул. Щукинская, д.2,этаж/офис, цокольный/32
          <br />
          Организатор Конкурса:
          <br />· утверждает Договор-оферту, адресованную Участникам Конкурса;
          <br />· размещает информацию о Конкурсе на Сайте и в средствах
          массовой информации;
          <br />· устанавливает порядок проведения и осуществляет регистрацию
          Участников Конкурса;
          <br />· организует опубликование результатов Конкурса и награждение
          победителей Конкурса;
          <br />· осуществляет техническое и иное обеспечение Конкурса;
          <br />
          «Участник» – физическое лицо, достигшее 18 лет, приобретшее участие в
          Мероприятии.
          <br />
          «Сайт» — программно-аппаратный комплекс, на котором размещены все
          официальные и правоустанавливающие документы Конкурса, а также
          документы, посредством которых производится информирование участников
          Конкурсов в сети Интернет о ходе проведения Конкурса и его
          результатах, имеет указатель страницы в сети Интернет:
          https://gymteam.ru/l/gymmarch.
          <br />
          «Акцепт» - согласие Участника с условиями Договора, выраженное и
          предполагаемое при условии выполнения им требований участия в
          Конкурсе, предусмотренных Договором, в полном объеме.
        </p>
        <h2>2. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
        <p>
          2.1. Договор (Оферта) на участие в Конкурсе определяет условия и
          порядок организации и проведения Конкурса: «Лучший результат Метода
          Усмановой 2 поток» (далее по тексту — Конкурс), в том числе сроки и
          место проведения Конкурса, требования, соответствие, соблюдение и
          выполнение которых обязательно для лиц, желающих стать Участниками
          Конкурса, порядок награждения победителей Конкурса, порядок обработки
          персональных данных.
          <br />
          2.2. При совместном упоминании по тексту Договора Организатор и
          Участник именуются «Стороны», а каждый из них по отдельности —
          «Сторона».
          <br />
          2.3. Каждая Сторона гарантирует другой Стороне, что обладает
          необходимой право- и дееспособностью, а равно всеми правами и
          полномочиями, необходимыми и достаточными для заключения и исполнения
          Договора в соответствии с его условиями.
          <br />
          2.4. Действующая редакция Договора обязательна для ознакомления
          Участником до момента совершения акцепта. Акцепт настоящего
          Договора-оферты предполагается Организатором в случае подачи
          Участником заявки на участие в Конкурсе.
          <br />
          2.5. Организатор в одностороннем порядке вправе изменить содержание
          Договора исключительно в целях приведения в соответствие с
          изменившимся законодательством Российской Федерации. Организатор может
          вносить указанные изменения без предварительного уведомления
          Участника. Изменения содержания Договора вступают в законную силу с
          момента их публикации по адресу постоянного размещения Договора в виде
          обновленного текста Договора.
        </p>
        <h2>3. ПРЕДМЕТ ДОГОВОРА</h2>
        <p>
          3.1. Организатор обязуется обеспечить проведение Конкурса «Лучший
          результат Метода Усмановой 2 поток» в период с 30 июля по 08 ноября
          2024 года, а Участник в установленные настоящим Договором сроки
          предоставить все необходимые сведения и документы для участия в
          Конкурсе (сроки могут быть изменены Организатором с обязательным
          уведомлением Участников).
          <br />
          3.2. Участник Конкурса - физическое лицо, достигшее 18 лет.
          <br />
          3.3. Официальным языком Конкурса является официальный язык Российской
          Федерации — русский язык.
          <br />
          3.4. Место проведения Конкурса: официальный сайт https://gymteam.ru/.
          <br />
          3.5. Срок объявления победителей Конкурса — в течение 30 календарных
          дней с момента окончания конкурса (сроки могут быть изменены
          Организатором с обязательным уведомлением Участников) на официальном
          сайте Конкурса.
          <br />
          3.6. Сроки выполнения обязательств Организатора по предоставлению
          победителям Конкурса призов, предусмотренных настоящим Договором — в
          течение 90 календарных дней с момента предоставления всех необходимых
          документов для получения приза победителем Конкурса (сроки могут быть
          изменены Организатором с обязательным уведомлением Участников).
          <br />
          3.7. Акцепт оферты означает, что Участник согласен со всеми
          положениями Договора и обязуется им следовать.
        </p>
        <h2>4. УСЛОВИЯ УЧАСТИЯ В КОНКУРСЕ</h2>
        <p>
          4.1. В целях участия в Конкурсе лица, соответствующие требованиям,
          установленным в п.3.2 настоящего Договора, обязаны выполнить следующие
          действия:
          <br />
          4.1.1. Ознакомиться с правилами конкурса и выразить согласие на
          обработку персональных данных.
          <br />
          4.1.2. Приобрести продукт Организатора в рамках акции «Метод
          Усмановой. 2 поток» в срок с 30 июля по 16 сентября 2024 года.
          <br />
          4.1.3. Приложить свои фотографии в анкете Участника во вкладку "до" в
          период с 17 по 22 сентября, а затем приложить свои фотографии в анкете
          Участника во вкладку "после" в период с 14 по 18 октября.
          <br />
          4.1.4. Выполнить все конкурсные задания в соответствии с правилами и
          сроками указанными в заданиях.
          <br />
          4.1.5. Предоставить свои данные для передачи приза и заполнить
          необходимые документы.
          <br />
          4.1.6. Каждый Участник может стать Победителем и получить Приз только
          один раз.
          <br />
          4.2. Участник Конкурса гарантирует достоверность любой информации,
          содержащейся в заявке на участие в Конкурсе.
        </p>
        <h2>5. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
        <p>
          5.1. Организатор информирует участников обо всех событиях, связанных
          с&nbsp;Конкурсом. Размещение любой официальной информации
          на&nbsp;Сайте является официальным порядком информирования. Каждый
          участник считается информированным надлежащим способом с&nbsp;момента
          публикации информации на&nbsp;Сайте.
          <br />
          5.2. Каждый Участник принимает, что участие в&nbsp;Конкурсе
          добровольно, и&nbsp;Организатор Конкурса не&nbsp;несёт ответственности
          за&nbsp;содержание авторских материалов и&nbsp;не&nbsp;может
          гарантировать и&nbsp;не&nbsp;обещает никаких специфических результатов
          от&nbsp;участия в&nbsp;Конкурсе и/или использования сайта Конкурса
          и/или его сервисов.
          <br />
          5.3. Организатор Конкурса вправе затребовать у&nbsp;любого Участника
          Конкурса или любого лица, подавшего заявку на&nbsp;участие
          в&nbsp;Конкурсе: документ, удостоверяющий личность; адрес регистрации,
          почтовый адрес, другие документы и&nbsp;сведения, необходимые для
          соблюдения законодательства Российской Федерации и/или для организации
          и&nbsp;проведения конкурса, в&nbsp;форме отсканированных копий
          документов.
          <br />
          5.4. Участник обязуется не&nbsp;нарушать авторские права третьих лиц.
          <br />
          5.5. За&nbsp;нарушение установленного порядка участия в&nbsp;Конкурсе,
          устанавливаемого Договором, или несоблюдение действующего
          законодательства, результаты участника в&nbsp;рамках конкурса может
          быть оставлена Организатором без рассмотрения на&nbsp;любой стадии
          Конкурса без предоставления каких-либо мотивированных разъяснений или
          подтверждающих документов.
          <br />
          5.6. В&nbsp;случае непредставления Участником сведений согласно форме
          заявки в&nbsp;установленном Договором порядке или
          их&nbsp;представления в&nbsp;несогласованном с&nbsp;Организатором
          формате, а&nbsp;также предоставления неверных сопроводительных данных
          или реквизитов, такой Участник принимает на&nbsp;себя все риски
          и&nbsp;последствия, связанные с&nbsp;оставлением его заявки без
          рассмотрения или непоступления в&nbsp;его адрес приза в&nbsp;случае
          победы.
          <br />
          5.7. Основания для исключения Участника из&nbsp;Конкурса:
          <br />
          &middot; нарушение правил участия в&nbsp;Конкурсе, изложенных
          в&nbsp;Договоре;
          <br />
          &middot; проявление неуважения к&nbsp;Организатору Конкурса;
          <br />
          &middot; непредставление необходимых для участия в&nbsp;Конкурсе
          сведений в&nbsp;порядке и&nbsp;сроки, указанные в&nbsp;настоящем
          Договоре.
          <br />
          5.8. Обслуживание Участника осуществляется Организатором 24&nbsp;часа
          7&nbsp;дней в&nbsp;неделю. Действия во&nbsp;исполнение Договора,
          выполняемые не&nbsp;в&nbsp;автоматическом режиме, совершаются
          Организатором в&nbsp;рабочие дни (являющиеся таковыми
          в&nbsp;соответствии с&nbsp;законодательством Российской Федерации)
          исключительно с&nbsp;9.00 до&nbsp;18.00 по&nbsp;Московскому времени.
          <br />
          5.9. Права Организатора:
          <br />
          5.9.1. Производить модификацию интерфейсов и&nbsp;программного
          обеспечения, используемого при взаимодействии Сторон в&nbsp;рамках
          Договора;
          <br />
          5.9.2. Приостанавливать работу программных и/или аппаратных средств,
          обеспечивающих техническое взаимодействие Сторон в&nbsp;рамках
          Договора, при обнаружении существенных неисправностей, ошибок
          и&nbsp;сбоев, а&nbsp;также в&nbsp;целях проведения профилактических
          работ и&nbsp;предотвращения случаев несанкционированного доступа;
          <br />
          5.9.3. Обрабатывать любым способом в&nbsp;целях исполнения Договора
          любые персональные данные Участника, предоставленные Участником лично,
          либо через третьих лиц при заключении, либо в&nbsp;период действия
          Договора.
          <br />
          5.10. Обязанности Организатора:
          <br />
          5.10.1. Принимать меры для предотвращения несанкционированного доступа
          иных лиц к&nbsp;информации о&nbsp;персональных данных Участника,
          отличной от&nbsp;опубликованной в&nbsp;открытом доступе
          на&nbsp;официальном сайте Организатора;
          <br />
          5.10.2. Обеспечивает взятые на&nbsp;себя обязательства
        </p>
        <h2>6.4 ОБЯЗАННОСТИ УЧАСТНИКА</h2>
        <p>
          6.4.1. Предоставить Организатору достоверные и актуальные сведения,
          контактные данные и иные требуемые Организатором для участия в
          Конкурсе документы, предусмотренные настоящим Договором;
          <br />
          6.4.2. Своевременно информировать Организатора об изменении
          персональных данных, реквизитов и контактных данных в документах,
          предъявляемых на Конкурс;
          <br />
          6.4.3. Предоставить право использования видео и фотоматериалов, в том
          числе в любых социальных сетях, Организатору любым доступным способом
          и на неограниченный срок, без дополнительного согласования;
          <br />
          6.4.4. Участник извещен, что Выигрыш подлежит налогообложению в
          соответствии с законодательством РФ. Участник самостоятельно подает
          декларацию о доходах в налоговые органы по месту жительства.
          <br />
          6.4.5. Предоставить Организатору адрес и банковские реквизиты счета в
          банке в пределах Российской Федерации для получения приза. Банковский
          счет обязан принадлежать Победителю.
          <br />
          6.4.6. Иные обязанности, предусмотренные настоящим Договором.
        </p>
        <h2>7. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА</h2>
        <p>
          7.1. Акцептом условий Договора является приобретение любого
          Мероприятия на официальном сайте Организатора. Акцепт условий Договора
          является безотзывным.
          <br />
          7.2. Акцепт условий Договора означает полное и безоговорочное принятие
          Участником всех условий Договора без каких-либо изъятий и/или
          ограничений и равносилен заключению двухстороннего письменного
          договора о Конкурсе.
        </p>
        <h2>8. МЕРЫ ПООЩЕРЕНИЯ УЧАСТНИКОВ КОНКУРСА И ВЫБОР ПОБЕДИТЕЛЕЙ</h2>
        <p>
          8.1. Организатор поощряет Участников Конкурса, признанных
          Организатором победителями, призами в установленной настоящим
          Договором форме. Призовой фонд Конкурса формируется за счет средств
          Организатора.
          <br />
          8.2. Меры поощрения (призы), причитающиеся победителям Конкурса:
          “Лучший результат Метода Усмановой 2 поток”:
          <br />
          за 1 место – тур в Объединенные Арабские Эмираты г. Дубай на 2 человек
          на 7 дней (перелет из Российской Федерации из г. Москва + проживание)
          <br />
          за 2 место – 100 000 рублей
          <br />
          за 3 место – 50 000 рублей
        </p>

        <p>
          8.3. Выбор победителя Конкурса осуществляется по следующим критериям:
          <br />— выполнение всех условий Конкурса;&nbsp;
          <br />— метод определения: открытое голосование на сайте Организатора.
          <br />
          Организатор не обязан доводить до Участников информацию об
          обстоятельствах и причинах выбора соответствующих победителей. Решение
          обжалованию не подлежит.
          <br />
          8.4. После определения победителя Организатор Конкурса направляет ему
          соответствующее уведомление по электронной почте, содержащее
          инструкцию для получения Приза (перечень необходимых документов, куда,
          каким образом и в какие сроки их следует направить), а также
          информацию о стоимости выигранного Приза. Отправка Приза
          осуществляется Оператором акции в течение 6 (шести) календарных
          месяцев после направления Победителю указанного выше уведомления.
          <br />
          Для получения Приза Победителю необходимо предоставить следующие
          документы (в совокупности):
          <br />- фамилия, имя, отчество;
          <br />
          &nbsp;- дата рождения;
          <br />- копию паспорта или иного документа, удостоверяющего личность
          (страницы, содержание паспортные данные, сведения об имени, дате
          рождения, адресе места жительства);
          <br />- копию свидетельства о постановке на учет в налоговом органе
          физического лица по месту жительства на территории РФ / ИНН;
          <br />- согласие на получения Приза.
          <br />
          8.5. Обязательным условием для получения Победителем акции Приза
          является подписание Акта приема-передачи (Приложение № 1 к настоящему
          Договору) между Победителем и Организатором. Акт Победителем
          подписывается до начала тура, а именно после предоставления всех
          данных Победителем и получением им подтверждения по туру от
          Организатора
          <br />
          8.6. При не предоставлении всех необходимых документов в течение 14
          календарных дней с даты направления уведомления Победителю, последний
          считается отказавшимся от получения Приза, а Приз признается
          невостребованным. В таком случае Организатор вправе разыграть его
          снова или распорядиться иным способом по своему усмотрению.
          <br />
          8.7. Доставка Приза осуществляется Организатором Конкурса только по
          территории Российской Федерации и за его счет.
          <br />
          8.8. Организатор при выдаче победителям Призов, совокупная стоимость
          которых превышает 4 000 (четыре тысячи рублей), в соответствии со ст.
          226 НК РФ выступает в качестве налогового агента победителей в целях
          обложения НДФЛ и обязуется исчислить, удержать из денежной части Приза
          и перечислить в бюджет НДФЛ по ставке 35% от общей стоимости приза,
          превышающей сумму 4 000 руб. Факт участия в Конкурсе участника
          означает полное согласие участника на удержание и перечисление
          Организатором НДФЛ из денежной части Приза, в сумме, превышающей 50
          (пятьдесят) процентов от суммы денежной части Приза.
          <br />
          8.9. В случае невозможности Организатора удержать и уплатить в бюджет
          НДФЛ победителя в полном объеме, в том числе в случае превышения в
          налоговом периоде размера облагаемого НДФЛ дохода налогоплательщика в
          виде подарков/призов от организаций (в том числе, но не ограничиваясь
          Организатором Конкурса), установленного законодательством РФ (свыше 4
          000 рублей), участник Конкурса несет обязанность по расчету и уплате
          НДФЛ самостоятельно, по ставке установленной НК РФ (35%, со стоимости,
          превышающей 4000 рублей). При этом Организатор Конкурса, выполняя
          функции налогового агента в соответствии с требованиями действующего
          законодательства РФ о налогах и сборах, уведомляет налоговые органы о
          совокупном доходе победителя в рамках Конкурса и невозможности
          Организатора удержать и уплатить в бюджет НДФЛ.
          <br />
          8.10. Копии документов, предоставляемых Участником Конкурса, должны
          соответствовать следующим техническим требованиям: Файл-изображение в
          одном из форматов: JPG, JPEG, GIF, PNG, TIF, TIFF с разрешением не
          менее 100 DPI, размером не более 5 мегабайт. Информация, отраженная в
          представляемой копии документа, в том числе его реквизиты, должна быть
          читаема и различима без дополнительной обработки и иных манипуляций со
          стороны Организатора.
          <br />
          8.11. В случае если победитель Конкурса не соблюдает и не выполняет
          требования раздела 8 Договора, по истечении 14 календарных дней с
          момента объявления результатов Конкурса, организатор вправе исключить
          данного участника из числа победителей и распорядится призом на свое
          усмотрение. выбрать другого победителя из числа Участников.
        </p>
        <h2>9. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
        <p>
          9.1. Участник дает Организатору согласие на обработку любым способом в
          целях исполнения Договора любых персональных данных Участника,
          предоставленных Участником лично, при заключении, либо в период
          действия Договора.
          <br />
          9.2. Заключая Договор, Участник своей волей и в своем интересе дает
          согласие Организатору на запись, систематизацию, накопление, хранение,
          уточнение, извлечение, использование своих персональных данных,
          указанных им при заполнении регистрационной формы на Сайте или
          становящихся известными Организатору в связи с исполнением Договора, в
          частности, фамилии, имени, отчества, адреса регистрации, постоянного
          проживания, даты и места рождения, номера мобильного телефона, личного
          электронного адреса, электронного адреса аккаунтов в социальных сетях,
          личных фотографий (фотоизображений), в том числе путем
          автоматизированной обработки таких данных, в целях осуществления
          Организатором основных видов деятельности в соответствии с Уставом и
          осуществления Организатором действий, предусмотренных условиями
          настоящего договора, а также сбор и анализ материалов относительно
          востребованности Конкурса.
          <br />
          9.3. Организатор вправе использовать предоставленные Участником
          персональные данные в целях обеспечения соблюдения требований,
          действующих законодательных и иных нормативных правовых актов
          Российской Федерации (в том числе в целях предупреждения и пресечения
          незаконных и/или противоправных действий других пользователей Сайта).
          Раскрытие предоставленной Участником информации может быть произведено
          лишь в соответствии с действующим законодательством Российской
          Федерации по требованию суда, правоохранительных органов, а равно в
          иных предусмотренных законодательством Российской Федерации случаях.
          <br />
          9.6. Согласие Участника на обработку персональных данных действует с
          даты заключения Договора, истекает спустя 5 (пять) лет с даты
          окончания проведения Конкурса, за исключением случаев обработки таких
          данных в целях, где срок обработки составляет 75 (семьдесят пять) лет
          с даты расторжения Договора. Согласие на обработку персональных данных
          не может быть отозвано Участником.
          <br />
          9.7. Организатор вправе осуществлять хранение (архивное хранение) и
          комплектование документов и персональных данных, в том числе в форме
          электронных (цифровых) документов (оригиналов и копий), в электронных
          базах данных включительно.
          <br />
          9.8. Участник выражает согласие на опубликование своих персональных
          данных, а именно фамилии, имени, отчества, города проживания, при
          опубликовании Организатором результатов Конкурса, а также при
          опубликовании интервью с Участником, в случае его избрания победителем
          Конкурса.
        </p>
        <h2>10. СРОК ДЕЙСТВИЯ ДОГОВОРА, ПОРЯДОК ЕГО ИЗМЕНЕНИЯ И РАСТОРЖЕНИЯ</h2>
        <p>
          10.1. Договор считается заключенным и вступает в силу с даты акцепта
          Участником оферты Организатора. При невыполнении Участником хотя бы
          одного из указанных в настоящем Договоре условий и требований участия
          в Конкурсе, Договор не считается заключенным, соответственно Участник
          не допускается до участия в Конкурсе.
          <br />
          10.2. Договор действует до исполнения Сторонами своих обязательств по
          нему в полном объеме, если не будет расторгнут досрочно одной из
          Сторон или обеими Сторонами по соглашению друг с другом.
          <br />
          10.3. Договор может быть изменен или расторгнут по основаниям и в
          порядке, предусмотренным законодательством Российской Федерации и
          Договором.
          <br />
          10.4. В соответствии с пунктом 1 ст. 450 Гражданского кодекса
          Российской Федерации Стороны договорились, что Организатор имеет право
          вносить изменения в условия Договора. Изменения, внесенные
          Организатором, становятся обязательными для Сторон с момента их
          размещения на Сайте, если иное не предусмотрено Организатором.
          <br />
          10.5. В случае несогласия с изменением условий Договора, Участник
          имеет право в течение трех календарных дней с момента размещения новой
          редакции Договора на официальном сайте Организатора в одностороннем
          порядке отказаться от исполнения Договора путем передачи Организатору
          оригинального экземпляра подписанного заявления на бумажном носителе.
          <br />
          10.6. В случае неполучения Организатором письменного уведомления от
          Участника об одностороннем отказе от исполнения Договора в срок,
          установленный пунктом
          <br />
          10.5 Договора, Участник считается выразившим согласие с изменениями
          условий Договора.
          <br />
          10.7. Отказ от исполнения Договора, расторжение Договора, прекращение
          действия Договора по иным причинам влечет прекращение участие
          Участника в Конкурсе.
          <br />
          10.8. Участник вправе в любое время отказаться от исполнения Договора
          путем направления соответствующего уведомления Организатору способами,
          предусмотренными Договором. В данном случае Договор считается
          расторгнутым по истечении 3 (трех) рабочих дней после получения
          Организатором соответствующего уведомления.
        </p>
        <h2>11. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
        <p>
          11.1. Стороны несут ответственность за ненадлежащее исполнение своих
          обязанностей в соответствии с законодательством Российской Федерации и
          условиями Договора.
          <br />
          11.2. Организатор не несет ответственности:
          <br />
          11.2.1. За сбои в работе почты, Интернета, сетей связи, возникшие по
          независящим от Организатора причинам и повлекшие за собой
          несвоевременное получение или неполучение Участником уведомлений
          Организатора;
          <br />
          11.2.2. В случае технических сбоев (отключение/повреждение
          электропитания и сетей связи, сбои программного обеспечения
          процессингового центра и технические сбои в платежных системах),
          повлекших за собой невыполнение Организатора условий Договора;
          <br />
          11.2.3. Если информация о персональных данных Участника станет
          известной иным лицам в результате прослушивания или перехвата каналов
          связи во время их использования, а также в результате иных
          противоправных действий третьих лиц, направленных на получение
          несанкционированного доступа к персональным данным Участника против
          воли Организатора и Участника Конкурса;
          <br />
          11.2.4. Если информация о персональных данных Участника станет
          известной иным лицам в результате несоблюдения Участником условий
          хранения и использования персональных данных;
          <br />
          11.2.5. За временное отсутствие у Участника доступа к средствам связи,
          обеспечивающим взаимодействие с Организатором в рамках Договора, а
          также связанные с этим убытки Участника;
          <br />
          11.2.6. За убытки, возникшие в результате предоставления Участником
          недостоверных, некорректных, ошибочных данных для направления
          уведомлений;
          <br />
          11.2.7. За убытки, возникшие в результате предоставления Участником
          недостоверных идентификационных данных;
          <br />
          11.2.8. За убытки, возникшие в результате не обновления Участником
          данных для направления уведомлений.
          <br />
          11.3. Стороны освобождаются от имущественной ответственности за
          неисполнение или ненадлежащее исполнение обязательств по Договору,
          если оно вызвано действиями непреодолимой силы, т.е. чрезвычайными и
          непредотвратимыми обстоятельствами, в том числе стихийными явлениями,
          военными действиями, актами органов власти. При возникновении
          обстоятельств непреодолимой силы Сторона должна в течение 3 (трех)
          рабочих дней проинформировать другую Сторону в письменной форме о
          невозможности исполнения обязательств.
        </p>
        <h2>12. ТЕХНИЧЕСКАЯ ПОДДЕРЖКА УЧАСТНИКОВ КОНКУРСА</h2>
        <p>
          12.1. Организатор конкурса согласен проводить техническую поддержку
          Участника Конкурса, касающуюся пользования официальным сайтом
          Организатора, до истечения срока проведения Конкурса, установленного
          настоящим Договором.
          <br />
          12.2. Техническая поддержка Участников Конкурса, указанная в п. 12.1
          Договора, осуществляется по электронным каналам связи (электронной
          почте) и/или путем размещения определенной информации на Сайте
          Организатора.
        </p>
        <h2>13. ПРОЧИЕ ПОЛОЖЕНИЯ</h2>
        <p>
          13.1. Участник гарантирует, что все условия Договора ему понятны, и он
          принимает их безусловно и в полном объеме.
          <br />
          13.2. Участник гарантирует, что не будет использовать Конкурсы в
          противоправных целях, а также в иных целях, нежели указанные в
          Договоре.
          <br />
          13.3. Временем совершения всех операций с Участником является
          московское время, определяемое на основании данных сервера
          Организатора, обрабатывающего информацию о ходе Конкурса, если иной
          порядок определения времени не был оговорен дополнительно.
          <br />
          13.4. К отношениям Сторон в рамках Договора применяется материальное и
          процессуальное право Российской Федерации независимо от гражданства и
          места жительства Участника.
        </p>

        <p style={{ textAlign: "right", marginTop: 200 }}>
          Приложение № 1<br />К Договору (Оферте) на участие в Конкурсе
        </p>
        <p style={{ textAlign: "center" }}>
          АКТ № &nbsp; &nbsp; приемки – передачи Приза
        </p>

        <p>
          <strong>
            г. Москва&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;«__»
            ____________ 2024г.
          </strong>
          <br />
        </p>
        <p>
          <br />
        </p>

        <p>
          созданное и действующее в соответствии с законодательством Российской
          Федерации, ИНН 7734434553, ОГРН 1207700175209, в лице генерального
          директора Голубцовой Ю.Д., действующего на основании Устава, с одной
          стороны, и гр. _______________________, паспорт серия _____ номер
          _____, выдан ________________________________________, дата выдачи
          ________, код подразделения ______, дата рождения _________ г., ИНН
          ___________________, зарегистрированный по адресу:
          _________________________________________ (далее – «Победитель
          Конкурса»), с другой стороны, составили настоящий Акт приемки-передачи
          приза (далее – Акт) о нижеследующем:
          <br />
          1. Организатор Конкурса «Лучший результат Метода Усмановой 2 поток»,
          проводимого в период с 30 июля по 08 ноября 2024 года (далее –
          Конкурс) передал, а Победитель Конкурса принял следующий приз (далее –
          Приз) на общую сумму _______(____________________) рублей, состоящий
          из:
          <br />
          1.1. ___________________ (наименование приза) стоимостью ____________
          (____________________) рублей с учетом всех применимых налогов.
          <br />
          1.2. Денежной части Приза в сумме: _____________
          (____________________) рублей.
        </p>
        <p>
          2. Победитель Конкурса подтверждает, что Организатор Конкурса выполнил
          свои обязательства по передаче Приза в полном объеме, и претензий к
          Организатору он не имеет. Победитель Конкурса соглашается с тем, что
          Организатор Конкурса выполнит функцию налогового агента, а именно -
          произведет удержание денежной части Приза (от общей стоимости,
          превышающей 4000 руб.) в сумме ______(________________) рублей
          _______копеек и перечислит её в налоговые органы как НДФЛ с
          полученного Победителем Конкурса Приза в соответствии с п. 2 ст. 224 и
          п. 1, 4 ст. 226 Налогового кодекса Российской Федерации. – данный
          пункт заполняется в случае получения денежного приза и/или денежного и
          приза в натуральной форме.
          <br />
          2. Победитель Конкурса подтверждает, что Организатор Конкурса выполнил
          свои обязательства по передаче Приза в полном объеме, и претензий к
          Организатору он не имеет. Победитель подтверждает, что осведомлен о
          необходимости самостоятельного декларирования полученных доходов в
          размере полной стоимости Приза, самостоятельного исчисления и уплаты
          суммы налога на доходы физических лиц в соответствии с п. 2 ст. 224 НК
          (35% со стоимости, превышающей 4000 рублей). – данный пункт
          заполняется в случае получения не денежного приза.
          <br />
          3. Победитель Конкурса ознакомлен с тем, что согласно п. 2 ст. 230
          Налогового кодекса РФ сведения о стоимости полученного им Приза, а
          также о сумме начисленного и удержанного НДФЛ или невозможности
          Организатора удержания и уплаты НДФЛ в бюджет, будут переданы
          налоговым органам.
          <br />
          4. Победитель Конкурса подтверждает получение Справки о доходах
          физического лица (Форма 2-НДФЛ).
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>ПОДПИСИ СТОРОН:</p>

        <p>
          Организатор Конкурса ООО «Онлайн Фитнес»&nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; Победитель Конкурса
          <br />
          Генеральный директор&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; ФИО
          <br />
          &nbsp;
          <br />
          &nbsp;
          <br />
          &nbsp;
          <br />
          ___________________/Голубцова Ю.Д.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;_______________/
          ____________
        </p>
      </div>
      <Footer />
    </>
  );
};
