import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Марафон точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li><strong>Хотите изменить качество тела:</strong> меньше жира — больше мышц</li>
                            <li>Мечтаете о&nbsp;красивой и&nbsp;<strong>упругой попе</strong></li>
                            <li><strong>Избавились от&nbsp;лишнего веса</strong>, осталось изменить пропорции фигуры</li>
                            <li>Тренируетесь <strong>давно или только&nbsp;начали</strong></li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Давно пытаетесь <strong>избавиться от&nbsp;галифе</strong> и&nbsp;дряблости на&nbsp;бёдрах</li>
                            <li><strong>Нуждаетесь в&nbsp;поддержке</strong> и&nbsp;мотивации на&nbsp;пути к&nbsp;цели</li>
                            <li><strong>Не&nbsp;можете найти время на&nbsp;фитнес-клуб</strong>, не&nbsp;планируете покупать абонемент</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;