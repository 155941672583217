import { SalesWidget } from "@com.marathonium/web2-ui";
import { useState } from "react";

export const TestWidget = () => {
  const [form, setForm] = useState(false);

    return (
      <div>
          <div style={{margin: 'auto', padding: 12, background: '#FB4F54', cursor: 'pointer', color: '#fff', width: 300, textAlign: 'center'}} onClick={() => setForm(true)}>Форма</div>
          {form && <SalesWidget id={window.lps_settings.forms.test_id} buttonText="ОФОРМИТЬ ЗАКАЗ" />}
      </div>
    );
};