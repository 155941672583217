// import Countdown from 'react-countdown';
import styles from './programs.module.css';

const Programs = ({scroll, renderer, date}) => {
    return (
        <div className={('scrollhere2 ') + styles.programs}>
            
            <div className="container">
                
                {/* <div className={styles.t1}>НЕ&nbsp;УПУСТИТЕ САМУЮ БОЛЬШУЮ<br/> <span>СКИДКУ 78% НА&nbsp;КОМПЛЕКТ ИЗ&nbsp;13&nbsp;ПРОГРАММ<br/> КАТИ УСМАНОВОЙ</span></div> */}
                <div className={styles.t1}>12&nbsp;ПРОГРАММ С&nbsp;ДОМАШНИМИ<br/> ТРЕНИРОВКАМИ И&nbsp;КУРС ДЛЯ ЗАЛА</div>
                <div className={styles.st1}>Только сейчас помимо комплекса домашних тренировок мы&nbsp;даём курс для зала,<br/> чтобы вы&nbsp;могли <b>улучшить фигуру и&nbsp;разнообразить тренировки</b></div>
                <div className={styles.top}>
                    <div className={styles.dts}>
                        <div className={styles.dts_item}>
                            <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts1.svg" alt="" /></div>
                            <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 1&nbsp;год</div>
                        </div>
                        <div className={styles.dts_item}>
                            <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts2.svg" alt="" /></div>
                            <div className={styles.dts_item_text}>Старт тренировок&nbsp;&mdash; 25&nbsp;ноября</div>
                        </div>
                        {/* <div className={styles.dts_item}>
                            <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts3.svg" alt="" /></div>
                            <div className={styles.dts_item_text}>Чат с&nbsp;куратором&nbsp;&mdash; с&nbsp;18&nbsp;ноября</div>
                        </div> */}
                    </div>
                </div>
                {/* <div className={styles.h_mob}>
                    <div className={styles.second}>
                            <div className={styles.block}>
                                <div className={styles.left}>
                                    <div className={styles.title}>Забирай комплект программ<br/> на&nbsp;всё тело <span>с&nbsp;выгодой&nbsp;78%</span></div>
                                    <div className={styles.subtitle}>Проработай всё тело в&nbsp;своём темпе, чтобы больше<br/> никогда не&nbsp;пришлось худеть к&nbsp;лету&nbsp;&mdash;<br/> у&nbsp;новой тебя будет стройная фигура круглый год</div>
                                </div>
                            </div>      
                    </div>
                </div> */}
            </div>
            <div className={styles.programs_block}>
            
                    <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <div className={styles.programs_img_bg}></div>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_1.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Похудеть</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>МАРАФОН <span>СТРОЙНОСТЬ 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 21&nbsp;день</li>
                                        <li><b>Без инвентаря</b></li>
                                        <li><b>График тренировок:</b> ежедневно</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p1" name="p1" />
                                        <label htmlFor="p1">Для кого подойдёт <p>Марафон для&nbsp;новичков и&nbsp;тех, кто уже обладает некоторым опытом. <b>Программа поможет быстро похудеть</b> и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p></label>
                                    </div>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn2" name="pn2" />
                                        <label htmlFor="pn2">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Похудеете минимум на&nbsp;1&nbsp;размер</li>
                                                <li>Подтянете руки, живот, попу и&nbsp;ноги</li>
                                                <li>Улучшите самочувствие и&nbsp;повысите уровень энергии</li>
                                                <li>Разберетесь в&nbsp;системе сбалансированного питания</li>
                                                <li>Сделаете фитнес своей привычкой</li>
                                            </ul>
                                            <p>Марафон предназначен для того, чтобы достичь конкретных результатов в&nbsp;короткие сроки, следуя структурированной программе тренировок и&nbsp;рационального питания.</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_2.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Подтянуть тело</div>
                                        <div className={styles.programs_tag}>Быстро похудеть</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Убираем<br/> лишний жир</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 1&nbsp;месяц</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по 1,5-3&nbsp;кг</li>
                                    <li><b>График тренировок:</b> через&nbsp;день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p3" name="p3" />
                                    <label htmlFor="p3">Для кого подойдёт <p>Марафон подойдёт тем, кто хочет <b>быстро сбросить лишний вес, сделать тело более подтянутым</b> и&nbsp;рельефным. В&nbsp;программе также&nbsp;— рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn4" name="pn4" />
                                        <label htmlFor="pn4">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>В максимально короткие сроки избавитесь от&nbsp;лишнего веса без&nbsp;ущерба для здоровья</li>
                                                <li>Сформируете более подтянутое, стройное и&nbsp;рельефное тело</li>
                                                <li>Ускорите метаболизм и&nbsp;общее состояние здоровья</li>
                                                <li>Начнете правильно питаться и&nbsp;научитесь поддерживать вес</li>
                                                <li>Изучите техники самомассажа для активации обменных процессов и&nbsp;ускорения сжигания жира</li>
                                            </ul>
                                            <p>Марафон предназначен для тех, кто стремится к&nbsp;быстрым и&nbsp;заметным результатам в&nbsp;снижении веса и&nbsp;формировании подтянутого тела. Программа включает в&nbsp;себя не&nbsp;только эффективные тренировки, но&nbsp;и&nbsp;рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа. Это делает его комплексным и&nbsp;эффективным инструментом для достижения фитнес-целей.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_3.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 1.0</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p5" name="p5" />
                                    <label htmlFor="p5">Для кого подойдёт <p>Марафон подойдет для тех, кто хочет добавить объём и&nbsp;тонус ягодицам, заметно улучшить форму в&nbsp;короткий срок.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn6" name="pn6" />
                                        <label htmlFor="pn6">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавитесь от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_3.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 2.0</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 30&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт.&nbsp;по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p7" name="p7" />
                                    <label htmlFor="p7">Для кого подойдёт <p>Марафон для тех, кто хочет добавить объём ягодицам, качественно проработать зону попу, заметно улучшить форму и&nbsp;тонус ягодичных мышц.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn8" name="pn8" />
                                        <label htmlFor="pn8">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Увеличите объем и&nbsp;форму ягодиц</li>
                                                <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавитесь от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                    

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_5.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Избавиться от дряблости</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Антицеллюлитный</span></div>
                                    <div className={styles.programs_img__info_price}>7 500 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 30&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг. Для массажа потребуются вакуумная банка, скребок гуаша или обычная столовая ложка</li>
                                    <li><b>График тренировок:</b> через день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p11" name="p11" />
                                    <label htmlFor="p11">Для кого подойдёт <p>Марафон для тех, кто хочет <b>избавиться от&nbsp;целлюлита и&nbsp;дряблости кожи</b>, изменить качество тела. Внутри кроме тренировок техники самомассажа.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn12" name="pn12" />
                                        <label htmlFor="pn12">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Избавитесь от&nbsp;целлюлита и&nbsp;улучшите текстуру кожи</li>
                                                <li>Повысите тонус и&nbsp;упругость кожи</li>
                                                <li>Подчеркнете контуры тела и&nbsp;сформируете более стройную и&nbsp;подтянутую фигуру</li>
                                                <li>Освоите техники самомассажа для улучшения кровообращения и&nbsp;лимфодренажа </li>
                                                <li>Повысите уверенность в&nbsp;себе и&nbsp;улучшите самочувствие</li>
                                            </ul>
                                            <p>Марафон создан для того, чтобы избавиться от&nbsp;целлюлита и&nbsp;повысить качество тела. Внутри программы&nbsp;&mdash; не&nbsp;только тренировки, но&nbsp;и&nbsp;обучение эффективным техникам самомассажа. Это делает марафон комплексным и&nbsp;эффективным средством для изменения качества тела.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_10.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Укрепить пресс</div>
                                        <div className={styles.programs_tag}>Избавиться от животика</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Плоского живота</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Без инвентаря</b></li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p9" name="p9" />
                                    <label htmlFor="p9">Для кого подойдёт <p>Марафон направлен на&nbsp;глубокую проработку мышц пресса <b>для создания красивого рельефа на&nbsp;животике</b>.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn10" name="pn10" />
                                        <label htmlFor="pn10">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Уменьшите объемы талии и&nbsp;живота</li>
                                                <li>Повысите мышечный тонус всего тела и&nbsp;подтянете мышцы пресса</li>
                                                <li>Снизите процент жира в&nbsp;области живота</li>
                                                <li>Улучшите осанку и&nbsp;усилите поддержку спины</li>
                                                <li>Повысите общую выносливость организма и&nbsp;уровень энергии</li>
                                                <li>Приобретете знания в&nbsp;области питания и&nbsp;тренировок, которые будут полезны всегда</li>
                                            </ul>
                                            <p>Этот марафон подходит как начинающим, так и&nbsp;опытным спортсменам, так как программы могут быть адаптированы под разные уровни физической подготовки.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/pr_cfat.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Придать телу рельеф</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>Жиросжигающий</span></div>
                                    <div className={styles.programs_img__info_price}>7 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 3&nbsp;месяца</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                    <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p13" name="p13" />
                                    <label htmlFor="p13">Для кого подойдёт <p>Курс подойдет для тех, кто устал от&nbsp;неэффективных диет и&nbsp;экспериментов над своим телом и&nbsp;мечтает избавиться от&nbsp;лишнего жира в&nbsp;проблемных зонах.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn14" name="pn14" />
                                        <label htmlFor="pn14">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Снизите процент жира в&nbsp;организме за&nbsp;счет ускорения метаболизма </li>
                                                <li>Станете выносливее и&nbsp;улучшите физическую форму благодаря интенсивным тренировкам.</li>
                                                <li>Усилите мышечный корсет и&nbsp;осанку</li>
                                                <li>Уменьшите объемы талии, бедер и&nbsp;других проблемных зон за&nbsp;счет активного жиросжигания</li>
                                                <li>Нормализуете питание</li>
                                            </ul>
                                            <p>Курс поможет избавиться от&nbsp;лишнего веса, запустить процессы активного жиросжигания и&nbsp;обмена веществ, поможет нормализовать питание и&nbsp;сделать физическую нагрузку нормой.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/pr_cpopa2.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Для продвинутых</div>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>идеальной попы 2.0</span></div>
                                    <div className={styles.programs_img__info_price}>6 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 45&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, штанга/бодибар от&nbsp;8&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт.&nbsp;по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p15" name="p15" />
                                    <label htmlFor="p15">Для кого подойдёт <p>Курс подойдет для тех, кто хочет заметно улучшить форму ягодиц, не&nbsp;считая калории и&nbsp;качественно проработать ягодичные мышцы.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn16" name="pn16" />
                                        <label htmlFor="pn16">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Увеличите объем, округлость и&nbsp;форму ягодиц</li>
                                                <li>Подтянете и&nbsp;укрепите мышцы ног</li>
                                                <li>Избавитесь от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Этот курс предназначен для тех, кто хочет комплексно проработать зону ягодиц и&nbsp;ног, чтобы заметно улучшить форму и&nbsp;нарастить объем.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_7.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Для продвинутых</div>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>идеальной попы 3.0</span></div>
                                    <div className={styles.programs_img__info_price}>12 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 90&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> бодибар или штанга весом от&nbsp;8&nbsp;кг, гантели разборные до&nbsp;15&nbsp;кг, утяжелители по&nbsp;1,5-3&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p17" name="p17" />
                                    <label htmlFor="p17">Для кого подойдёт <p>Вы&nbsp;хотите добавить объём и&nbsp;изменить форму ягодиц, ищете комплексный подход к&nbsp;формированию идеальной попы.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn18" name="pn18" />
                                        <label htmlFor="pn18">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Увеличите объем и&nbsp;округлость ягодиц</li>
                                                <li>Улучшите форму и&nbsp;симметрию ягодиц</li>
                                                <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                <li>Уменьшите проблемные зоны и&nbsp;несовершенства</li>
                                                <li>Повысите уверенность в&nbsp;себе и&nbsp;самооценку</li>
                                            </ul>
                                            <p>Этот курс предназначен для тех, кто хочет преобразить свои ягодицы и&nbsp;достичь идеальной формы и&nbsp;объема. Благодаря точечным упражнениям и&nbsp;индивидуальному подходу к&nbsp;тренировкам, вы&nbsp;сможете достичь желаемых результатов и&nbsp;получить попу, о&nbsp;которой мечтали.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_8.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Наладить питание</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>ПИТАНИЕ</span></div>
                                    <div className={styles.programs_img__info_price}>10 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 45&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> без инвентаря</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p19" name="p19" />
                                    <label htmlFor="p19">Для кого подойдёт <p>Курс подойдет для тех, кто хочет научиться самостоятельно составлять сбалансированный рацион питания под свои цели, привить себе привычку всегда питаться правильно.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn20" name="pn20" />
                                        <label htmlFor="pn20">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Научитесь самостоятельно составлять сбалансированный рацион питания, который соответствует вашим потребностям и&nbsp;целям</li>
                                                <li>Научитесь правильно распределять белки, жиры и&nbsp;углеводы в&nbsp;рационе</li>
                                                <li>Улучшите общее состояния здоровья, уровень энергии и&nbsp;физическую форму</li>
                                                <li>Избавитесь от&nbsp;необходимости обращаться к&nbsp;диетологу или нутрициологу благодаря полученным знаниям и&nbsp;навыкам</li>
                                                <li>Повысите самодисциплину и&nbsp;контроль над питанием, научитесь постоянно поддерживать вес</li>
                                            </ul>
                                            <p>Курс поможет самостоятельно создать здоровый и&nbsp;сбалансированный рацион питания, который будет соответствовать вашим целям и&nbsp;потребностям. Полученные знания и&nbsp;навыки помогут контролировать свое питание и&nbsp;поддерживать здоровый образ жизни на&nbsp;долгосрочной основе.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_11.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Восстановление</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>курс <span>Восстановление после родов</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 60&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, гантели 2&nbsp;кг и&nbsp;фитнес-резинки с&nbsp;сопротивлением до&nbsp;5&nbsp;кг, утяжелители 1,5-3&nbsp;кг&nbsp;&mdash; по&nbsp;желанию</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> через день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p99" name="p99" />
                                    <label htmlFor="p99">Для кого подойдёт <p>Вы&nbsp;недавно стали мамой и&nbsp;стремитесь вернуться к&nbsp;своей прежней форме. Хотите избавиться от&nbsp;лишних килограммов и&nbsp;восстановить тонус мышц после беременности.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn99" name="pn99" />
                                        <label htmlFor="pn99">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Похудеете и&nbsp;восстановите форму</li>
                                                <li>Укрепите мышцы брюшного пресса и&nbsp;тазового дна</li>
                                                <li>Подтянете тело</li>
                                            </ul>
                                            <p>Курс поможет вернуться после родов в&nbsp;прежнюю форму: сбросить лишние килограммы, подтянуть тело и&nbsp;укрепить мышцы тазового дна. Включает рекомендации по&nbsp;питанию для кормящих мам.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_9.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}><span>Универсальные тренировки на&nbsp;ягодицы</span></div>
                                    <div className={styles.programs_img__info_price}>4 790 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 10&nbsp;тренировок</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p21" name="p21" />
                                    <label htmlFor="p21">Для кого подойдёт <p>Курс представляет собой конструктор из&nbsp;10&nbsp;тренировок, которые вы можете комбинировать под физическую подготовку, самочувствие и&nbsp;даже настроение.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn22" name="pn22" />
                                        <label htmlFor="pn22">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Укрепите и&nbsp;подтянете мышцы ягодиц</li>
                                                <li>Улучшите форму и&nbsp;контуры ягодиц</li>
                                                <li>Повысите выносливость и&nbsp;уровень энергии</li>
                                                <li>Улучшите самочувствие и&nbsp;повысите энергию</li>
                                            </ul>
                                            <p>Марафон представляет собой уникальный конструктор из&nbsp;10&nbsp;тренировок на&nbsp;ягодицы, которые можно комбинировать в&nbsp;соответствии с&nbsp;вашими потребностями, физической подготовкой и&nbsp;настроением. Он&nbsp;разработан для того, чтобы обеспечить максимальную эффективность и&nbsp;удобство тренировок.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item_full}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/pr_full.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Наработка техники</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>КУРС <span>ДЛЯ ЗАЛА. ЦИКЛ 1</span></div>
                                        {/* <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div> */}
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 6&nbsp;недель</li>
                                        <li><b>График тренировок:</b> 3&nbsp;раза в&nbsp;неделю</li>
                                        <li>пн&nbsp;— ягодицы, база</li>
                                        <li>ср&nbsp;— верх тела</li>
                                        <li>пт&nbsp;— ягодицы, изоляция</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pz" name="pz" />
                                        <label htmlFor="pz">Что внутри <p>В&nbsp;цикл входит блок из&nbsp;6 тренировок. Вы&nbsp;делаете их&nbsp;друг за&nbsp;другом впервые 2&nbsp;недели по&nbsp;графику, а&nbsp;потом повторяете цикл на&nbsp;3-й и&nbsp;4-й, а&nbsp;потомна 5-й и&nbsp;6-й неделях.</p></label>
                                    </div>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pnz" name="pnz" />
                                        <label htmlFor="pnz">Результаты после курса 
                                            <div>
                                                <p>Поставлена правильная техника выполнения базовых упражнений на&nbsp;минимальных весах. Тело готово к&nbsp;увеличению нагрузки для дальнейшей работы на&nbsp;силу, выносливость и&nbsp;рельеф.</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                {/* <div className={styles.pr1}>
                    <div className={styles.pr1_1}>7&nbsp;990&nbsp;₽</div>
                    <div className={styles.pr1_2}>85&nbsp;990&nbsp;₽</div>
                </div>
                <div className={styles.pr11}>
                    <div className={styles.pr1_4}>(от&nbsp;1 331,5&nbsp;₽/мес.)</div>
                    <div className={styles.pr1_3}><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/blank.png" alt="" /><p>-91%</p></div>
                </div> */}
                
                <div className={styles.pr2}>
                    <div className={styles.promo_btn} onClick={scroll}>
                        <p>ЗАБРАТЬ ВЕСЬ НАБОР</p>
                    </div>
                    <div className={styles.pr2_perc}>&mdash;&nbsp;90%</div>
                </div>
                {/* <div className={styles.promo_countdown}>
                    <p>Выгода сгорит через:</p>
                    <div className={styles.promo_countdown_timer}>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                </div> */}
                {/* <div className={styles.remains}>Осталось мест: <span>{window.lps_settings.other.count}</span></div> */}
        </div>
    )
}

export default Programs;