import './program.css';

const Program = () => {
    return (
        <div className="program">
          <div className="container">
            <div className="program_title">ЭТО <span>УНИКАЛЬНАЯ</span><br /> КОМПЛЕКСНАЯ ПРОГРАММА</div>
            <div className="program_info">
              <div className="program_info__left">
                <p>ДЛЯ БЕРЕМЕННЫХ НА&nbsp;ЛЮБОМ СРОКЕ И ПЛАНИРУЮЩИХ БЕРЕМЕННОСТЬ</p>
              </div>
              <div className="program_info__right">
                <p>👉 <strong>100% победы над токсикозом</strong> с&nbsp;помощью специальной диеты и&nbsp;авторских техник</p>
                <p>👉 <strong>минимум растяжек</strong> после родов</p>
                <p>👉 <strong>−10&nbsp;кг после родов</strong> до&nbsp;начала тренировок</p>
                <p>👉 <strong>9 месяцев без «тревожности беременных»</strong></p>
                <p>👉 <strong>280 дней беременности с&nbsp;радостью</strong> от&nbsp;собственного отражения в&nbsp;зеркале</p>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Program;