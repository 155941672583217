import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Марафон точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li><strong>Готовы изменить качество тела:</strong> меньше жира&nbsp;— больше мышц</li>
                            <li><strong>Мечтаете подтянуть попу</strong>, не&nbsp;набирая вес</li>
                            <li><strong>Хотите набрать мышечную массу и&nbsp;сделать попу более объемной</strong></li>
                            <li><strong>Новичок или&nbsp;профессионал</strong></li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Похудели, но&nbsp;<strong>все&nbsp;ещё выглядите не&nbsp;так, как&nbsp;представляли</strong></li>
                            <li><strong>Нуждаетесь в&nbsp;поддержке</strong> и&nbsp;мотивации на&nbsp;пути к&nbsp;цели</li>
                            <li>Занимаетесь дома, в&nbsp;зале с&nbsp;тренером, без&nbsp;тренера <strong>или&nbsp;не&nbsp;занимаетесь вообще</strong></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;