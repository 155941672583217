import './nowyoumama.css';

const Nowyoumama = () => {
    return (
        <div className="nowyoumama">
            <div className="container">
            <div className="nowyoumama_title">Теперь вы мама, поэтому<br/> <span>должны думать о себе</span></div>
            <div className="nowyoumama_subtitle">За 2&nbsp;месяца программы вы решите множество приятных задач:</div>
            <div className="nowyoumama_block">
                <div className="nowyoumama_item">
                <ul>
                    <li>Избавиться от лишнего веса и&nbsp;подтянуть всё тело <strong>без риска для&nbsp;лактации</strong></li>
                    <li><strong>Свести к&nbsp;нулю растяжки, целлюлит</strong> и&nbsp;дряблость кожи</li>
                    <li>Вернуть красивую осанку, <strong>забыть о&nbsp;постоянных болях в&nbsp;спине и&nbsp;шее</strong></li>
                    <li><strong>Меньше уставать</strong> от&nbsp;укачивания, сна в&nbsp;неудобных позах и&nbsp;постоянных наклонов к&nbsp;малышу</li>
                </ul>
                </div>
                <div className="nowyoumama_item">
                <ul>
                    <li><strong>Улучшить самочувствие и&nbsp;настроение,</strong> даже если не&nbsp;удалось выспаться</li>
                    <li><strong>Научиться питаться с&nbsp;пользой</strong> для фигуры и своего малыша, если он на&nbsp;грудном вскармливании</li>
                    <li><strong>Быстрее справиться с&nbsp;послеродовыми проблемами</strong> — физическими и&nbsp;психологическими</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Nowyoumama;