import React, { useEffect } from 'react';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import styles from './thanks.module.css';
import { RedirectPrograms } from '../../components/redirect/RedirectProgram';

export const L2024_10_Vitrinathanks = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])
    
    return (
        <>
            <RedirectPrograms />
            <DocumentTitle value='Все онлайн-тренировки Екатерины Усмановой в GymTeam'/>
            <DocumentDescription value='Все курсы и марафоны Кати Усмановой в GymTeam — программы онлайн-фитнеса для дома и зала. Онлайн-тренировки, чтобы быстро похудеть, накачать ягодицы и пресс, сжечь жир и избавиться от целлюлита.' />
            <div className={styles.thanks}>
                <div className={styles.thanks_block}>
                    <div className={styles.thanks_block_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/thx2.png" alt="" />
                    </div>
                    <div className={styles.thanks_block_title}>Поздравляем,<br/> мы&nbsp;уже направили гайд<br/> на&nbsp;твою почту!</div>
                    <div className={styles.thanks_block_subtitle}>Проверь свою почту, чтобы скачать гайд<br/> <b>&laquo;8&nbsp;вещей, которые нужно знать новичку перед<br/> тренировкой&raquo;</b></div>
                    <div className={styles.thanks_block_text}>А&nbsp;если хочешь еще больше полезного контента о&nbsp;тренировках<br/> и&nbsp;питании, подписывайся на&nbsp;наш телеграм-канал</div>
                    <div className={styles.thanks_block_btn}>
                        <a href="https://t.me/+vfnJPSkIOZEzMjky" target='_blank'>Подписаться на&nbsp;ТГ</a>
                    </div>
                </div>
            </div>
        </>
    );
};