import { useEffect, useState, useCallback } from "react";

import { callWebRpc, fetchWithN8n } from "../helpers/jrpc";

export function useApi(exec = () => {}, deps = []) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState();

  const fetchData = useCallback(async () => {
    setLoading(true);

    return exec()
      .then(data => {
        setData(data);
        setError(null);
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, deps);

  const refetch = async () => {
    return fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { loading, error, data, setData, refetch };
}

export function useN8n(method, params = {}, deps = [], options = {}, n8nVersion = 1) {
  return useApi(() => {
    return fetchWithN8n(method, params, options, n8nVersion);
  }, deps);
}

export function useN8n2(method, params = {}, deps = [], options = {}) {
  return useN8n(method, params, deps, options, 2);
}

export function useWebRpc(method, params = {}, deps = [], options = {}) {
  return useApi(() => {
    return callWebRpc(method, params, options);
  }, deps);
}

export function useManyJrpc(...requests) {
  const result = {};

  for (let request of requests) {
    const fields = Object.keys(request);

    for (let field of fields) {
      if (!result[field]) {
        result[field] = [];
      }

      result[field].push(request[field]);
    }
  }

  return {
    ...result,
    loading: result.loading.some(loading => loading === true),
    error: result.error.find(error => error),
  };
}
