import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Second from './components/Second';
import Tabs from './components/Tabs';
import Tabs2 from './components/Tabs2';
import Reviews from './components/Reviews';
import Form from './components/Form';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_10_BirthdayUsm = ({version}) => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
        const section = document.querySelector( '.birthday_usm_form' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    // let nextMidnight = new Date();
    // nextMidnight.setHours(24, 0, 0, 0);
    let nextMidnight
    {window.lps_settings.other.active_land == 1 ? nextMidnight = new Date(2024,10,1,0,0,0) : nextMidnight = new Date(2024,10,2,0,0,0) }
    // let nextMidnight = new Date(2024,10,1,0,0,0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        let dayszero = '0';
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                {days > 0 && <div>{dayszero}{days} <span>:</span></div>}
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value=''/>
        <DocumentDescription value='' />
        <div className='BirthdayUsm'>
            <Promo scroll={scroll} date={date} renderer={renderer} version={version} />
            <Second />
            <Tabs />
            <Tabs2 />
            <Form date={date} renderer={renderer} version={version} />
            <Reviews />
            {/* <Footer /> */}
        </div>
      </>
    );
};