import { addUserToGroup, LoginWidget } from "@com.marathonium/web2-ui";
import { fetchWithN8n2, setJWT } from "@com.marathonium/web2-utils";
import styles from "./start.module.css";

const Auth = () => {
	const loginByAnonimous = (e) => {
		e.preventDefault();

		let usp = new URLSearchParams(window.location.search);

		fetchWithN8n2("web.user.login_anonymous.v1", {
			platform: window["97e74e0a-d6d2-4052-a387-cd4a619f7b94"].platform,
			gateway: window["97e74e0a-d6d2-4052-a387-cd4a619f7b94"].gateway,
			utm_source: usp.get("utm_source"),
			utm_medium: usp.get("utm_medium"),
			utm_content: usp.get("utm_content"),
		}).then((j) => {
			if (j.success === true) {
				setJWT(j.token);

				var _tmr = window._tmr || (window._tmr = []);
				_tmr.push({
					id: "3396944",
					type: "pageView",
					start: new Date().getTime(),
				});
				(function (d, w, id) {
					if (d.getElementById(id)) return;
					var ts = d.createElement("script");
					ts.type = "text/javascript";
					ts.async = true;
					ts.id = id;
					ts.src = "https://top-fwz1.mail.ru/js/code.js";
					var f = function () {
						var s = d.getElementsByTagName("script")[0];
						s.parentNode.insertBefore(ts, s);
					};
					if (w.opera == "[object Opera]") {
						d.addEventListener("DOMContentLoaded", f, false);
					} else {
						f();
					}
				})(document, window, "tmr-code");
				_tmr.push({ type: "reachGoal", id: 3396944, goal: "reg-fatlost" });
				window._tmr.push({
					type: "reachGoal",
					id: 3396944,
					goal: "reg-fatlost",
				});

				addFunction();
			}
		});

		return false;
	};

	const addFunction = () => {
		addUserToGroup(window.lps_settings.user_groups.fatlost);
		window.location.href =
			"https://gymteam.ru/trainings/c191bde9-d914-435a-a6fd-3b81056d9ecb";
	};

	return (
		<div className={`${styles.start} ${styles.auth_main} scrollhere`}>
			<div className="container">
				<div className={styles.auth}>
					<div className={styles.auth_title}>
						НАЧАТЬ ЖИРОСЖИГАЮЩУЮ
						<br /> НЕДЕЛЮ
					</div>
					<div className={styles.auth_block}>
						{/* <div className={styles.auth_left}>
							<div className={styles.auth_box2}>
								<p>
									<b>Создать гостевой аккаунт</b>
									<br /> Начните пользоваться платформой без&nbsp;ввода телефона
									и&nbsp;укажите его позже в&nbsp;настройках
								</p>
								<a href="/login_by_anonymous" onClick={loginByAnonimous}>
									Войти как гость
								</a>
							</div>
						</div> */}
						<div className={styles.auth_right}>
							<div className={styles.auth_box}>
								<p>
									<b>Зарегистрируйтесь или авторизуйтесь в&nbsp;свой аккаунт</b>
								</p>
								<div className="fatlost_login">
									<LoginWidget onSuccess={addFunction} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.start_img}>
				<img
					src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/start_img.jpg"
					alt=""
				/>
			</div>
		</div>
	);
};

export default Auth;
