import './whobirth.css';

const Whobirth = ({scroll}) => {
    return (
        <div className="whobirth">
            <div className="container">
            <div className="whobirth_title wbt_1"><span>Для всех, кто рожал:</span><br/> самостоятельно или через<br/> кесарево сечение</div>
            <div className="whobirth_block">
                <div className="whobirth_item">
                <div className="whobirth_item-number">01</div>
                <div className="whobirth_item-title">Лёгкий старт</div>
                <p>Тренировки начинаются в&nbsp;максимально щадящем режиме, чтобы исключить любой стресс и&nbsp;вред здоровью.</p>
                </div>
                <div className="whobirth_item">
                <div className="whobirth_item-number">02</div>
                <div className="whobirth_item-title">Через 6 месяцев</div>
                <p>После рождения малыша вы можете начинать занятия, так ваше тело точно будет готово к&nbsp;полноценному восстановлению.</p>
                </div>
                <div className="whobirth_item">
                <div className="whobirth_item-number">03</div>
                <div className="whobirth_item-title">Можно и позже</div>
                <p>Не поздно заниматься по&nbsp;программе и&nbsp;через год после родов. Главное — начать, а&nbsp;тренировки идеально подойдут, даже если вы никогда не&nbsp;занимались фитнесом.</p>
                </div>
            </div>
            <div className="whobirth_title wbt_2">Если не&nbsp;сейчас,<br/> то когда?</div>
            <div className="whobirth_title"><span>Скорее всего никогда</span></div>
            <div className="whobirth_subtitle">Посмотрите как Катя пришла в&nbsp;форму</div>
            <div className="whobirth_img">
                <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/whobirth_1_2.jpg' alt="" />
            </div>
            <button className='main_btn' onClick={scroll}>Забрать курс по&nbsp;спеццене</button>
            </div>
        </div>
    )
}

export default Whobirth;