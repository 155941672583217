import { useEffect, useState } from "react";
import {
    HideForSegment,
	LoginWidget,
    ShowForSegment,
    checkUserInGroup,
} from "@com.marathonium/web2-ui";
import {
	Anonymous,
	Authorized,
} from "@com.marathonium/web2-utils";

import {
	DocumentTitle,
	DocumentDescription,
} from "../../../components/DocumentMeta";

import styles from './style.module.css'
import Land from "./land";
import Footer from "../../../components/Footer";

export const L2023_09_Fatlost5 = () => {
    const [notInGroup, setNotInGroup] = useState(false)
    const [timeLeft, setTimeLeft] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		document.body.id = "rasprozarka_2305";
		document.body.parentElement.id = "htmlrasprozarka_2305";

        checkUserInGroup(window.lps_settings.user_groups.fatlost_n5).then(res => {
            if(res) {
                setTimeLeft(res)
                setTimeout(()=>{
                    setIsLoading(false)
                }, 1000)
            } else {
                setNotInGroup(true)
                setTimeout(()=>{
                    setIsLoading(false)
                }, 1000)
            }
        })
	}, []);

    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    // let nextMidnight = new Date();
    // nextMidnight.setHours(24, 0, 0, 0);
    // const now = new Date();
    // const date = nextMidnight.getTime() - now.getTime();
    // const renderer = ({hours, minutes, seconds, completed}) => {
    //     let hourzero = '';
    //     let minuteszero = '';
    //     let secondszero = '';
    //     if(hours<10) {hourzero = '0'}
    //     if(minutes<10) {minuteszero = '0'}
    //     if(seconds<10) {secondszero = '0'}
    //     return (
    //         <div>
    //             <div>{hourzero}{hours} <span>:</span></div>
    //             <div>{minuteszero}{minutes} <span>:</span></div>
    //             <div>{secondszero}{seconds}</div>
    //         </div>
    //     );
    // };

    const now = new Date();    
    let expiredTime = new Date()
    expiredTime.setHours(48, 0, 0, 0);

    const date = expiredTime.getTime() - now.getTime();
    const renderer = ({days, hours, minutes, seconds, completed}) => {
            let hourzero = '';
            let minuteszero = '';
            let secondszero = '';
            if(hours<10 && days<1) {hourzero = '0'}
            if(minutes<10) {minuteszero = '0'}
            if(seconds<10) {secondszero = '0'}
            return (
                <div>
                    
                    <div>{hourzero}{days > 0 ? hours+24 : hours} <span>:</span></div>
                    <div>{minuteszero}{minutes} <span>:</span></div>
                    <div>{secondszero}{seconds}</div>
                </div>
            );        
    };

    function LoginForm() {
		return (
			<div className={styles.main}>             
                <Authorized>
                    {isLoading ? 
                        <div className={styles.block3}>
                            <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/logo.svg" alt="" /></div>	
                            <div className={styles.block3_title}>ждите, загружаем данные…</div>
                        </div> 
                    : 
                    // notInGroup ? 
                    //     <div className={styles.block3}>
                    //         <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/logo.svg" alt="" /></div>	
                    //         <div className={styles.block3_title}>страница недоступна</div>
                    //         <div className={styles.block3_subtitle}>Вы не являетесь участником данной акции</div>
                    //         <a href="/" className={styles.btn3}>перейти в личный кабинет</a>
                    //     </div>
                    // : date > 0 ?
                     <>
                     <ShowForSegment id={window.lps_settings.segments.fatlost_n5_1}>
                        <div className={styles.block3}>
                            <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/logo.svg" alt="" /></div>	
                            <div className={styles.block3_title}>страница недоступна</div>
                            <div className={styles.block3_subtitle}>Вы уже воспользовались предложением данной акции</div>
                            <a href="/" className={styles.btn3}>перейти в личный кабинет</a>
                        </div>
                    </ShowForSegment>
                    <HideForSegment id={window.lps_settings.segments.fatlost_n5_1}>
                        <ShowForSegment id={window.lps_settings.segments.fatlost_n5_5}>
                            <Land hide={false} date={date} renderer={renderer} version={5} scroll={scroll} />
                        </ShowForSegment>
                        <HideForSegment id={window.lps_settings.segments.fatlost_n5_5}>
                            <ShowForSegment id={window.lps_settings.segments.fatlost_n5_4}>
                                <Land hide={false} date={date} renderer={renderer} version={4} scroll={scroll} />
                            </ShowForSegment>
                            <HideForSegment id={window.lps_settings.segments.fatlost_n5_4}>
                                <ShowForSegment id={window.lps_settings.segments.fatlost_n5_3}>
                                    <Land hide={false} date={date} renderer={renderer} version={3} scroll={scroll} />
                                </ShowForSegment>
                                <HideForSegment id={window.lps_settings.segments.fatlost_n5_3}>
                                    {/* <ShowForSegment id={window.lps_settings.segments.fatlost_n5_2}> */}
                                        <Land hide={true} date={date} renderer={renderer} version={2} scroll={scroll} />
                                    {/* </ShowForSegment> */}
                                </HideForSegment>
                            </HideForSegment>
                        </HideForSegment>
                    </HideForSegment>
                    </>
                    //  : 
                    //     <div className={styles.block3}>
                    //         <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/logo.svg" alt="" /></div>	
                    //         <div className={styles.block3_title}>страница недоступна</div>
                    //         <div className={styles.block3_subtitle}>Время действия данной акции истекло</div>
                    //         <a href="/" className={styles.btn3}>перейти в личный кабинет</a>
                    //     </div>
                    }
                    
                </Authorized>
                <Anonymous>
                    <Land auth={true} hide={true} version={1} scroll={scroll} />
                    {/* <div className={`scrollhere year1_login ${styles.land_main}`}>
                        <div className="year1_login_title">Войдите, чтобы потратить баллы и&nbsp;получить дополнительную скидку</div>
                        <LoginWidget />
                    </div> */}
                </Anonymous>
			</div>
		);
	}

	return (
		<>
			<DocumentTitle value="Спец предложение на 1 месяц" />
			<DocumentDescription value="" />
            <LoginForm />
            <Footer />
		</>
	);
};
