import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_title}>ТРЕНИРОВКИ БЕЗ&nbsp;ОГРАНИЧЕНИЙ,<br/> чтобы&nbsp;быть в&nbsp;форме всегда</div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>10&nbsp;тренировок<br/> разного уровня‍‍</div>
                            <div className={styles.whaityou_info__text}>Можно выбрать ту, которая подходит вам именно сегодня. Например, если вы хотите восстановиться и&nbsp;размять мышцы после изматывающего тренинга. Или наоборот чувствуете, что вы готовы к&nbsp;серьёзным тренировкам и&nbsp;новым упражнениям.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ЗАНЯТИЯ В&nbsp;ФОРМАТЕ<br/> «ПОВТОРЯЙ&nbsp;ЗА&nbsp;КАТЕЙ»‍‍</div>
                            <div className={styles.whaityou_info__text}>На целый месяц вы получите доступ к&nbsp;универсальным тренировкам Кати Усмановой. Не&nbsp;нужно менять свой график&nbsp;— занимайтесь, когда вам удобно. Просто включайте видео и&nbsp;повторяйте за&nbsp;Катей.</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>МЕНЮ НА&nbsp;2&nbsp;НЕДЕЛИ‍</div>
                            <div className={styles.whaityou_info__text}>Тренировки&nbsp;— это только одна составляющая, необходимая для создания идеальных ягодиц. Вторая&nbsp;— это сбалансированное питание. В&nbsp;наборе предусмотрено меню из&nbsp;3-х блюд на&nbsp;каждый день на&nbsp;2&nbsp;недели.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;