import './aboutcource.css';

const Aboutcourse = () => {
    return (
        <div className="aboutcourse">
          <div className="container">
            <div className="aboutcourse_title">НА КУРСЕ ВАС БУДУТ ЖДАТЬ:</div>
            <div className="aboutcourse_block">
              <div className="aboutcourse_item">
                <div className="aboutcourse_item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/about_icon_1.png' alt="" />
                </div>
                <div className="aboutcourse_item-title">Фитнес-программа</div>
                <p>пилатес, йога, МФР и&nbsp;растяжка, функциональные и силовые тренировки с&nbsp;Екатериной Усмановой и&nbsp;лучшими тренерами UsmanovaTeam & GymTeam</p>
              </div>
              <div className="aboutcourse_item">
                <div className="aboutcourse_item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/about_icon_2.png' alt="" />
                </div>
                <div className="aboutcourse_item-title">Методики, давно доказавшие свою эффективность</div>
                <p>адаптированные для беременных тренировки с&nbsp;Екатериной Усмановой в&nbsp;формате «повторяй за мной» и&nbsp;рекомендации по&nbsp;питанию</p>
              </div>
              <div className="aboutcourse_item">
                <div className="aboutcourse_item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/about_icon_3.png' alt="" />
                </div>
                <div className="aboutcourse_item-title">СОВЕТЫ гинеколога, нутрициолога и психолога</div>
                <p>видео с&nbsp;рекомендациями специалистов</p>
              </div>
              <div className="aboutcourse_item">
                <div className="aboutcourse_item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/about_icon_4.png' alt="" />
                </div>
                <div className="aboutcourse_item-title">Бонусные видео Екатерины Усмановой</div>
                <p>для беременных и&nbsp;планирующих стать мамой</p>
              </div>
              <div className="aboutcourse_item">
                <div className="aboutcourse_item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/about_icon_5.png' alt="" />
                </div>
                <div className="aboutcourse_item-title">Трекер «Дневник беременности»</div>
                <p>и&nbsp;эксклюзивные методические материалы</p>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Aboutcourse;