import './gifts.css';

const Gifts = () => {
    return (
        <div className="gifts">
          <div className="container">
            <div className="gifts_block">
              <div className="gifts_block__left">
                <div className="gifts_block-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/gifts.jpg' alt="" />
                </div>
              </div>
              <div className="gifts_block__right">
                <div className="gifts_block-title"><span>ПОДАРКИ</span> ЗА ПОКУПКУ</div>
                <p><strong>Полезные материалы</strong> для будущих мам</p>
                <ul>
                  <li><strong>Бонусные видео</strong> Екатерины Усмановой для беременных и&nbsp;планирующих стать мамой.</li>
                  <li><strong>Дневник беременности.</strong> Удобный трекер для мониторинга ведения беременности, который вы будете заполнять самостоятельно.</li>
                  <li><strong>Чек-лист</strong> «Что брать с&nbsp;собой в&nbsp;роддом»</li>
                  <li><strong>Чек-лист</strong> «Какая музыка хорошо влияет на&nbsp;плод»</li>
                  <li><strong>Гайд с&nbsp;подборкой книг</strong> «Ребенок слышит или что почитать животику»</li>
                  <li><strong>Чек-лист</strong> «5 экспресс-способов победить токсикоз»</li>
                  <li><strong>Таблица</strong> прибавления веса во&nbsp;время беременности</li>
                  <li><strong>Пособие</strong> «Как спастись от&nbsp;ринита беременных»</li>
                  <li><strong>Чек-лист</strong> «Как справляться с&nbsp;тревожностью»</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Gifts;