import styles from './onmarafon.module.css';

const Onmarafon = () => {
    return (
        <div className={styles.onmarafon}>
            <div className="container">
                <div className={styles.onmarafon_title}>НА курсе <span>НЕ&nbsp;БУДЕТ:</span></div>
                <div className={styles.onmarafon_block}>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/onm_1.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Бесконечных приседаний</strong><br/> и&nbsp;выпадов</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/onm_2.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Сомнительных методов</strong><br/> и упражнений</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/onm_3.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Жёстких ограничений</strong> по питанию</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/coursepop2/onm_4.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Психологического <strong>давления</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onmarafon;