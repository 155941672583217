import { useState } from 'react';
import styles from './programs.module.css';

const lessons1 = [
    {
        name: 'Основные растяжки',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/1.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/1a.jpg',
        time: '34 минуты',
        desc: <p>Знакомимся с&nbsp;основами растяжки. Делаем разминку и&nbsp;базовые упражнения в&nbsp;статике и&nbsp;динамике</p>
    },
    {
        name: 'Внимание к осанке и спине',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/2.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/2.jpg',
        time: '31 минута',
        desc: <p>Улучшаем осанку, минимизируем боли в&nbsp;спине. Избавляемся от&nbsp;напряжения в бедрах</p>
    },
    {
        name: 'Расслабление для груди',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/3.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/3.jpg',
        time: '33 минуты',
        desc: <p>Закрепляем технику выполнения упражнений. Растягиваем и&nbsp;расслабляем спину, грудной отдел</p>
    },
    {
        name: 'Повторяем растягивания',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/4.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/4.jpg',
        time: '27 минут',
        desc: <p>Оцениваем первые результаты, разминаемся и&nbsp;плавно начинаем неделю. Делаем новые упражнения</p>
    },
    {
        name: 'Учимся держать осанку',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/5.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/5.jpg',
        time: '37 минут',
        desc: <p>Снимаем напряжение с&nbsp;ног и&nbsp;ягодиц, чтобы избавиться от&nbsp;тяжести и&nbsp;держать осанку вне тренировок</p>
    },
    {
        name: 'Основы для расслабления',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/6.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/6.jpg',
        time: '29 минут',
        desc: <p>Завершаем неделю базовыми упражнениями. Расслабляем мышцы после нагрузок</p>
    },
    {
        name: 'Растяжка для эластичности',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/7.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/7.jpg',
        time: '35 минут',
        desc: <p>Растягиваем конечности и&nbsp;спину. Снимаем напряжение, повышаем эластичность мышц</p>
    },
    {
        name: 'Прочувствуем глубинные зажимы',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/8.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/8.jpg',
        time: '29 минут',
        desc: <p>Снижаем уровень кортизола в&nbsp;крови, и&nbsp;расслабляемся</p>
    },
    {
        name: 'Плавные упражнения',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/9.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/9.jpg',
        time: '32 минуты',
        desc: <p>Формируем красивую осанку. Разгоняем кровоток в&nbsp;ногах, прессе и&nbsp;мышцах поясницы</p>
    },
    {
        name: 'Растяжка на рельеф',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/10.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/10.jpg',
        time: '33 минуты',
        desc: <p>Повышаем силу и&nbsp;выносливость мышц. Увеличиваем их&nbsp;объем и&nbsp;улучшаем рельефность</p>
    },
    {
        name: 'Убираем дискомфорт',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/11.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/11.jpg',
        time: '29 минут',
        desc: <p>Медленно растягиваемся. Больше не&nbsp;придется пить обезболивающие при&nbsp;дискомфорте в&nbsp;мышцах</p>
    },
    {
        name: 'Убираем внутренние зажимы',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/12.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/12.jpg',
        time: '32 минуты',
        desc: <p>Раскрепощаем таз и&nbsp;поясницу. Заряжаемся энергией</p>
    },
    {
        name: 'Профилактика высокого давления',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/13.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/13.jpg',
        time: '35 минут',
        desc: <p>Минимизируем вред от&nbsp;сидячего образа жизни. Расслабляем тело, очищаем разум</p>
    },
    {
        name: 'Королевская осанка',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/14.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/14.jpg',
        time: '30 минут',
        desc: <p>Раскрепощаем мышцы бедер и&nbsp;спины. Радуемся королевской осанке. Нормализуем сон</p>
    },
    {
        name: 'Растягиваемся и заряжаемся',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/15.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/15.jpg',
        time: '32 минуты',
        desc: <p>Повышаем внутренние резервы. Заряжаемся энергией и&nbsp;становимся более работоспособными</p>
    },
    {
        name: 'Растяжка для суставов',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/16.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/16.jpg',
        time: '34 минуты',
        desc: <p>Улучшаем мобильность тазобедренных суставов, чтобы повысить сексуальную активность</p>
    },
    {
        name: 'Растяжка при сутулости',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/17.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/17.jpg',
        time: '41 минута',
        desc: <p>Растягиваем спину, чтобы держать красивую осанку в&nbsp;повседневности</p>
    },
    {
        name: 'Тренируем шпагат',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs1/18.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons1/18.jpg',
        time: '33 минуты',
        desc: <p>Шпагат&nbsp;— не&nbsp;просто красиво, но и&nbsp;способ снять зажимы в&nbsp;области таза</p>
    }
]
const lessons2 = [
    {
        name: 'Раскрытие ТБС',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/1.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/1.jpg',
        time: '23 минуты',
        desc: <p>Упражнения на&nbsp;раскрытие тазобедренного сустава и&nbsp;эластичность мышц.</p>
    },
    {
        name: 'Подвижность ТБС',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/2.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/2.jpg',
        time: '27 минут',
        desc: <p>Работаем над улучшением подвижности ТБС и&nbsp;растяжением связок.</p>
    },
    {
        name: 'Поперечный шпагат',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/3.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/3.jpg',
        time: '23 минуты',
        desc: <p>Плавно и&nbsp;аккуратно готовим мышцы и&nbsp;связки к&nbsp;поперечному шпагату.</p>
    },
    {
        name: 'Интенсивная растяжка',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/4.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/4.jpg',
        time: '25 минут',
        desc: <p>Интенсивная динамическая растяжка для мышц ног и&nbsp;глубоких связок.</p>
    },
    {
        name: 'Поперечный шпагат, продолжение',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/5.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/5.jpg',
        time: '17 минут',
        desc: <p>Продолжаем идти к&nbsp;поперечному шпагату, увеличивая амплитуду и&nbsp;нагрузку.</p>
    },
    {
        name: 'Подвижность мышц ног',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/6.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/6.jpg',
        time: '20 минут',
        desc: <p>Улучшение гибкости и&nbsp;подвижности мышц ног и&nbsp;спины, а&nbsp;также ягодичных мышц.</p>
    },
    {
        name: 'Безопасная растяжка',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/7.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/7.jpg',
        time: '19 минут',
        desc: <p>Эффективная и&nbsp;безопасная растяжка для расслабления всего тела и&nbsp;восстановления мышц.</p>
    },
    {
        name: 'Базовые растяжки',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/8.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/8.jpg',
        time: '25 минут',
        desc: <p>Эффективная расслабляющая растяжка на&nbsp;основные группы мышц.</p>
    },
    {
        name: 'Грудной отдел',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/9.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/9.jpg',
        time: '26 минут',
        desc: <p>Раскрываем и&nbsp;улучшаем подвижность грудного отдела, снимаем напряжение.</p>
    },
    {
        name: 'Комплекс на растяжение',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/10.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/10.jpg',
        time: '25 минут',
        desc: <p>Комплекс эффективных упражнений на&nbsp;растяжение мышц ног и&nbsp;подготовку к&nbsp;шпагатам.</p>
    },
    {
        name: 'Растяжка для энергии',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/11.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/11.jpg',
        time: '22 минуты',
        desc: <p>Расслабляющая тренировка на&nbsp;всё тело, позволяющая как следует зарядиться.</p>
    },
    {
        name: 'Гибкость рук и ног',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs2/12.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons2/12.jpg',
        time: '24 минуты',
        desc: <p>Постепенно улучшаем гибкость и&nbsp;подвижность в&nbsp;верхних и&nbsp;нижних конечностях.</p>
    },
]
const lessons3 = [
    {
        name: 'Растяжка до тренировки',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/1.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/1.jpg',
        time: '13 минут',
        desc: <p>Комплекс упражнений для разминки, подготовки мышц к&nbsp;нагрузкам и&nbsp;профилактики травм.</p>
    },
    {
        name: 'После тренировки',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/2.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/2.jpg',
        time: '13 минут',
        desc: <p>Техники, которые повысят эффект от&nbsp;любой тренировки и&nbsp;избавят от&nbsp;боли в&nbsp;мышцах после занятий.</p>
    },
    {
        name: 'Легкие ноги',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/3.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/3.jpg',
        time: '24 минуты',
        desc: <p>Повышаем эластичность мышц и&nbsp;подвижность тканей. Улучшаем кровообращение и&nbsp;отток лимфы</p>
    },
    {
        name: 'Продольный шпагат',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/4.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/4.jpg',
        time: '15 минут',
        desc: <p>Безопасные техники для девушек с&nbsp;любым уровнем подготовки</p>
    },
    {
        name: 'Гибкость и тонус для тела',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/5.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/5.jpg',
        time: '22 минуты',
        desc: <p>Комплекс упражнений для гибкости всего тела и&nbsp;тонуса мышц</p>
    },
    {
        name: 'Поперечный шпагат',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/6.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/6.jpg',
        time: '16 минут',
        desc: <p>Садимся на&nbsp;шпагат без страха и&nbsp;боли. Инструкции для новичков и&nbsp;правила безопасности</p>
    },
    {
        name: 'На каждый день',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/7.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/7.jpg',
        time: '22 минуты',
        desc: <p>Комплекс упражнений на&nbsp;каждый день для гибкости тела и&nbsp;прекрасного самочувствия</p>
    },
    {
        name: 'Летящая походка',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/8.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/8.jpg',
        time: '18 минут',
        desc: <p>Комплекс, который улучшит осанку, сделает походку грациозной и&nbsp;позволит ощутить лёгкость в&nbsp;движении.</p>
    },
    {
        name: 'Утренняя растяжка',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/9.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/9.jpg',
        time: '20 минут',
        desc: <p>Мягкая разминка для всех групп мышц и&nbsp;пробуждение тела</p>
    },
    {
        name: 'Вечерняя растяжка',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/101.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/10.jpg',
        time: '13 минут',
        desc: <p>Снимаем усталость, убираем зажимы и&nbsp;продлеваем молодость тканей.</p>
    },
    {
        name: 'Здоровая спина',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/11.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/11.jpg',
        time: '24 минуты',
        desc: <p>Простые техники, которые помогут снять зажимы и&nbsp;избавиться от&nbsp;боли в&nbsp;спине. Продлевают молодость.</p>
    },
    {
        name: 'Легкость в теле',
        img: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/progs3/12.jpg',
        poster: 'https://gymteam.kinescopecdn.net/img/l/2023/stretch/lessons3/12.jpg',
        time: '24 минуты',
        desc: <p>Простые техники для гибкости тела и&nbsp;отличного самочувствия</p>
    }
]

const Programs = ({scroll}) => {
    const [lesson1, setLesson1] = useState(lessons1[0])
    const [lesson2, setLesson2] = useState(lessons2[0])
    const [lesson3, setLesson3] = useState(lessons3[0])

    const showProgs = (somearr, setLesson) => {
        return somearr.map((item) => (
            <div className={styles.item2} onClick={()=>setLesson(item)}>
                <div><img src={item.poster} alt="" /></div>
                <div>
                    <p>{item.name} <span>{item.time}</span></p>
                </div>
            </div>
        ))
    }

    return (
        <div className={styles.programs}>
            <div className="stretch_container">
                <div className={styles.title}><span>Растяжка</span> Формирует красивую осанку и&nbsp;нормализует давление</div>
                <div className={styles.item}>
                    <div className={styles.left}>
                        <div className={styles.text}>Стретчинг в&nbsp;статике и&nbsp;динамике: начиная с&nbsp;базы и&nbsp;заканчивая сложными упражнениями. Улучшаем осанку, минимизируем боли в&nbsp;спине, избавляемся от&nbsp;напряжения в&nbsp;бедрах, расслабляем спину и&nbsp;грудной отдел</div>
                        <div className={styles.lessons}>
                            {showProgs(lessons1, setLesson1)}
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.right_img}>
                            <img src={lesson1.poster} alt="" />
                            <p>{lesson1.time}</p>
                        </div>
                        <div className={styles.right_info}>
                            <div className={styles.right_title}>{lesson1.name}</div>
                            <div className={styles.right_text}>{lesson1.desc}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.title}><span>Растяжка</span> Улучшает эластичность<br/> мышц и&nbsp;связок</div>
                <div className={styles.item}>
                    <div className={styles.left}>
                        <div className={styles.text}>Упражнения на&nbsp;раскрытие тазобедренного сустава, эластичность и&nbsp;подвижность мышц ног и&nbsp;спины, интенсивное динамическое растяжение связок и&nbsp;расслабляющая растяжка на&nbsp;основные группы мышц.</div>
                        <div className={styles.lessons}>
                            {showProgs(lessons2, setLesson2)}
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.right_img}>
                            <img src={lesson2.poster} alt="" />
                            <p>{lesson2.time}</p>
                        </div>
                        <div className={styles.right_info}>
                            <div className={styles.right_title}>{lesson2.name}</div>
                            <div className={styles.right_text}>{lesson2.desc}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.title}><span>Растяжка</span> Готовит мышцы к&nbsp;нагрузкам и&nbsp;улучшает кровообращение</div>
                <div className={styles.item}>
                    <div className={styles.left}>
                        <div className={styles.text}>Техники, которые повысят эффект от&nbsp;любой тренировки, снимут усталость, уберут зажимы, улучшат самочувствие и&nbsp;продлят молодость тканей.</div>
                        <div className={styles.lessons}>
                            {showProgs(lessons3, setLesson3)}
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.right_img}>
                            <img src={lesson3.poster} alt="" />
                            <p>{lesson3.time}</p>
                        </div>
                        <div className={styles.right_info}>
                            <div className={styles.right_title}>{lesson3.name}</div>
                            <div className={styles.right_text}>{lesson3.desc}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.btn} onClick={scroll}>Получить неделю растяжки</div>
            </div>
        </div>
    )
}

export default Programs;