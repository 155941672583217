import React, { forwardRef } from "react";
import { PinInput as CUPinInput, PinInputField as CUPinInputField, HStack, useColorModeValue } from "@chakra-ui/react";

import {
  BORDER_RADIUS_10,
  BORDER_RADIUS_20,
  COLOR_BLACK,
  COLOR_BLACK_OPACITY_05,
  COLOR_WHITE_OPACITY_05,
  COLOR_PINK,
  COLOR_WHITE,
  FONT_SIZE_20,
  FONT_SIZE_36,
  SPACING_50,
  SPACING_65,
} from "../../constants/styles";

export const PinInput = forwardRef((props, ref) => <CUPinInput ref={ref} {...props} />);

const PinInputFieldBase = forwardRef((props, ref) => (
  <CUPinInputField
    ref={ref}
    backgroundColor={useColorModeValue(COLOR_WHITE_OPACITY_05, COLOR_BLACK_OPACITY_05)}
    border="none"
    _focus={{
      outline: `2px solid ${COLOR_PINK}`,
      outlineOffset: "0",
    }}
    {...props}
  />
));

export const PinInputFieldSmall = forwardRef((props, ref) => (
  <PinInputFieldBase
    ref={ref}
    borderRadius={BORDER_RADIUS_10}
    fontWeight="500"
    fintSize={FONT_SIZE_20}
    height={SPACING_50}
    width={SPACING_50}
    minW={SPACING_50}
    {...props}
  />
));

export const PinInputField = forwardRef(({ colorMode, ...props }, ref) => (
  <PinInputFieldBase
    ref={ref}
    borderRadius={BORDER_RADIUS_20}
    fontWeight="400"
    fontSize={FONT_SIZE_36}
    height={SPACING_65}
    width={SPACING_65}
    minW={SPACING_65}
    color={colorMode === "light" ? COLOR_BLACK : COLOR_WHITE}
    backgroundColor={colorMode === "light" ? COLOR_WHITE : COLOR_BLACK_OPACITY_05}
    border={colorMode === "light" ? null : "none"}
    _focus={{
      outline: `1px solid ${colorMode === "light" ? "var(--chakra-colors-chakra-border-color)" : COLOR_WHITE}`,
      outlineOffset: "0",
    }}
    _focusVisible={{
      outline: "none",
    }}
    {...props}
  />
));

const InputPinField = forwardRef((props, ref) => (
  <PinInputFieldBase
    ref={ref}
    _focus={{
      outline: `1px solid ${COLOR_PINK}`,
      outlineOffset: "0",
    }}
    _focusVisible={{
      outline: "none",
    }}
    {...props}
  />
));

export function InputPin({
  spacing,
  justifyContent = "flex-start",
  placeholder = "",
  autoFocus = true,
  otp = true,
  onChange,
  onComplete,
  isDisabled,
  ...props
}) {
  return (
    <HStack spacing={spacing} justifyContent={justifyContent}>
      <CUPinInput
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autoFocus}
        otp={otp}
        onComplete={onComplete}
        isDisabled={isDisabled}
      >
        <InputPinField {...props} />
        <InputPinField {...props} />
        <InputPinField {...props} />
        <InputPinField {...props} />
      </CUPinInput>
    </HStack>
  );
}
