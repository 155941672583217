import { useEffect, useState } from "react";
import { AuthWidget, checkUserInGroup, addUserToGroup, getUserCountByGroup, ShowForSegment, HideForSegment, checkUserInSegment, getUser } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";

import './styles.css';
import styles from './prizes.module.css';
import Hidden from "./hidden";
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Already from "./already";
import Footer from "../../components/Footer";


const prizes = [
    {
        id: '447379f8-baa7-472c-9312-5cf5934cbb61',
        name: <>Apple iPhone&nbsp;16&nbsp;Pro</>,
        description: <><p>Вы&nbsp;выиграли Apple iPhone 15&nbsp;Pro. Мечта всех девушек&nbsp;&mdash; и&nbsp;вот теперь она ваша! Ведь это не&nbsp;просто смартфон&nbsp;&mdash; это ваша личная фотостудия. Арт-фотографии, селфи, семейные снимки&nbsp;&mdash; каждый кадр будет настоящим произведением искусства.</p></>,
        limit: window.lps_settings.limits.prize1,
    },
    {
        id: 'f0b6b33d-345f-48e2-b5e2-a227118eda8b',
        name: <>Apple Watch SE 2024</>,
        description: <><p>Вы&nbsp;выиграли Apple Watch SE&nbsp;2024. Это мощное устройство для здорового образа жизни. Когда вы&nbsp;надеваете эти часы, они становятся настоящим партнёром по&nbsp;фитнесу, который учитывает каждое ваше движение и&nbsp;сообщает полезные данные о&nbsp;вашем здоровье.</p></>,
        limit: window.lps_settings.limits.prize2,
    },
    {
        id: 'c9d24890-c595-4fcb-ad79-192804debc8e',
        name: <>Яндекс Станция 2</>,
        description: <><p>Вы&nbsp;выиграли Яндекс Станцию&nbsp;2. Теперь ваша жизнь станет ещё удобнее! Умный девайс поможет с&nbsp;музыкой, рецептами, планированием дня и&nbsp;даже развлечет детей. Лаконичный дизайн и&nbsp;мощный звук&nbsp;&mdash; это идеальный гаджет для любого дома.</p></>,
        limit: window.lps_settings.limits.prize3,
    },
    {
        id: '63176caf-9d89-4716-952c-18ffcde0ccb8',
        name: <>Сертификат в&nbsp;&laquo;Золотое Яблоко&raquo; на&nbsp;10&nbsp;000 рублей</>,
        description: <><p>Вы&nbsp;выиграли сертификат на&nbsp;10&nbsp;000 рублей в&nbsp;&laquo;Золотое Яблоко&raquo;. Теперь можете побаловать себя любимой косметикой, парфюмом или новыми средствами ухода. Ощутите роскошь заботы о&nbsp;себе!</p></>,
        limit: window.lps_settings.limits.prize4,
    },
    {
        id: 'e0668b6e-b679-4436-bb06-dce19885ef81',
        name: <>Доступ к&nbsp;6&nbsp;мастер-классам с&nbsp;Катей Усмановой</>,
        description: <><p>Вы&nbsp;выиграли доступ к&nbsp;6&nbsp;мастер-классам с&nbsp;Катей Усмановой, которые пройдут в&nbsp;течение года. Эти встречи дадут вам мощную мотивацию, эксклюзивные советы по&nbsp;тренировкам, питанию и&nbsp;уверенности в&nbsp;себе. Вдохновляйтесь и&nbsp;достигайте новых вершин!</p></>,
        limit: window.lps_settings.limits.prize5,
    },
    {
        id: 'e367fcc7-728e-4a74-ada5-28c1a4b429f6',
        name: <>Доступ к&nbsp;записи FMD-марафона</>,
        description: <><p>Вы&nbsp;выиграли доступ к&nbsp;записи FMD-марафона. Это интенсивная программа, которая поможет вам не&nbsp;только избавиться от&nbsp;лишнего веса, но&nbsp;и&nbsp;наладить здоровое питание. Готовьтесь к&nbsp;лёгкости и&nbsp;обновлению!</p></>,
        limit: window.lps_settings.limits.prize6,
    },
    {
        id: 'f6c0f287-7d68-4bb0-947c-dd2653feecbd',
        name: <>Доступ к&nbsp;12&nbsp;прямым эфирам с&nbsp;нутрициологом</>,
        description: <><p>Вы&nbsp;выиграли доступ к&nbsp;12&nbsp;прямым эфирам с&nbsp;нутрициологом, которые пройдут в&nbsp;течение года. Это уникальная возможность задавать свои вопросы, получать профессиональные советы и&nbsp;наладить своё питание с&nbsp;помощью эксперта.</p></>,
        limit: window.lps_settings.limits.prize7,
    },
    {
        id: '5c6d8b0c-1ad2-4169-9864-47489f2e45cb',
        name: <>Книга рецептов Кати Усмановой</>,
        description: <><p>Вы&nbsp;выиграли книгу рецептов Кати Усмановой. Это сборник выверенной информации с&nbsp;примерами рационов для разного диапазона калорий, подробными рецептами блюд, описанием бжу и&nbsp;калоража каждого блюда, лайфхаками по&nbsp;приготовлению еды и&nbsp;другой полезной информацией.</p></>,
        limit: window.lps_settings.limits.prize8,
    },
    {
        id: 'b2a6567c-ff58-4cb5-b1b0-e56c82570727',
        name: <>Бонус &laquo;Детокс&raquo; с&nbsp;Катей Усмановой</>,
        description: <><p>Вы&nbsp;выиграли марафон &laquo;Детокс&raquo; с&nbsp;Катей Усмановой. Информация поможет пересмотреть свой рацион, понять, какие продукты вызывают тяжесть и&nbsp;как быстро очистить организм. Добавьте себе лёгкости и&nbsp;воздушности в&nbsp;самоощущении!</p></>,
        limit: window.lps_settings.limits.prize9,
    },
]

const prizes2 = [
    {
        id: '447379f8-baa7-472c-9312-5cf5934cbb61',
        name: <>Apple iPhone&nbsp;16&nbsp;Pro</>,
        description: <><p>Вы&nbsp;выиграли Apple iPhone 15&nbsp;Pro. Мечта всех девушек&nbsp;&mdash; и&nbsp;вот теперь она ваша! Ведь это не&nbsp;просто смартфон&nbsp;&mdash; это ваша личная фотостудия. Арт-фотографии, селфи, семейные снимки&nbsp;&mdash; каждый кадр будет настоящим произведением искусства.</p></>,
        limit: window.lps_settings.limits.prize1,
    },
    {
        id: 'f0b6b33d-345f-48e2-b5e2-a227118eda8b',
        name: <>Apple Watch SE 2024</>,
        description: <><p>Вы&nbsp;выиграли Apple Watch SE&nbsp;2024. Это мощное устройство для здорового образа жизни. Когда вы&nbsp;надеваете эти часы, они становятся настоящим партнёром по&nbsp;фитнесу, который учитывает каждое ваше движение и&nbsp;сообщает полезные данные о&nbsp;вашем здоровье.</p></>,
        limit: window.lps_settings.limits.prize2,
    },
    {
        id: 'c9d24890-c595-4fcb-ad79-192804debc8e',
        name: <>Яндекс Станция 2</>,
        description: <><p>Вы&nbsp;выиграли Яндекс Станцию&nbsp;2. Теперь ваша жизнь станет ещё удобнее! Умный девайс поможет с&nbsp;музыкой, рецептами, планированием дня и&nbsp;даже развлечет детей. Лаконичный дизайн и&nbsp;мощный звук&nbsp;&mdash; это идеальный гаджет для любого дома.</p></>,
        limit: window.lps_settings.limits.prize3,
    },
    {
        id: '63176caf-9d89-4716-952c-18ffcde0ccb8',
        name: <>Сертификат в&nbsp;&laquo;Золотое Яблоко&raquo; на&nbsp;10&nbsp;000 рублей</>,
        description: <><p>Вы&nbsp;выиграли сертификат на&nbsp;10&nbsp;000 рублей в&nbsp;&laquo;Золотое Яблоко&raquo;. Теперь можете побаловать себя любимой косметикой, парфюмом или новыми средствами ухода. Ощутите роскошь заботы о&nbsp;себе!</p></>,
        limit: window.lps_settings.limits.prize4,
    },
    {
        id: 'e0668b6e-b679-4436-bb06-dce19885ef81',
        name: <>Доступ к&nbsp;6&nbsp;мастер-классам с&nbsp;Катей Усмановой</>,
        description: <><p>Вы&nbsp;выиграли доступ к&nbsp;6&nbsp;мастер-классам с&nbsp;Катей Усмановой, которые пройдут в&nbsp;течение года. Эти встречи дадут вам мощную мотивацию, эксклюзивные советы по&nbsp;тренировкам, питанию и&nbsp;уверенности в&nbsp;себе. Вдохновляйтесь и&nbsp;достигайте новых вершин!</p></>,
        limit: window.lps_settings.limits.prize5,
    },
    {
        id: 'e367fcc7-728e-4a74-ada5-28c1a4b429f6',
        name: <>Доступ к&nbsp;записи FMD-марафона</>,
        description: <><p>Вы&nbsp;выиграли доступ к&nbsp;записи FMD-марафона. Это интенсивная программа, которая поможет вам не&nbsp;только избавиться от&nbsp;лишнего веса, но&nbsp;и&nbsp;наладить здоровое питание. Готовьтесь к&nbsp;лёгкости и&nbsp;обновлению!</p></>,
        limit: window.lps_settings.limits.prize6,
    },
    {
        id: 'f6c0f287-7d68-4bb0-947c-dd2653feecbd',
        name: <>Доступ к&nbsp;12&nbsp;прямым эфирам с&nbsp;нутрициологом</>,
        description: <><p>Вы&nbsp;выиграли доступ к&nbsp;12&nbsp;прямым эфирам с&nbsp;нутрициологом, которые пройдут в&nbsp;течение года. Это уникальная возможность задавать свои вопросы, получать профессиональные советы и&nbsp;наладить своё питание с&nbsp;помощью эксперта.</p></>,
        limit: window.lps_settings.limits.prize7,
    },
    {
        id: '5c6d8b0c-1ad2-4169-9864-47489f2e45cb',
        name: <>Книга рецептов Кати Усмановой</>,
        description: <><p>Вы&nbsp;выиграли книгу рецептов Кати Усмановой. Это сборник выверенной информации с&nbsp;примерами рационов для разного диапазона калорий, подробными рецептами блюд, описанием бжу и&nbsp;калоража каждого блюда, лайфхаками по&nbsp;приготовлению еды и&nbsp;другой полезной информацией.</p></>,
        limit: window.lps_settings.limits.prize8,
    },
    {
        id: 'b2a6567c-ff58-4cb5-b1b0-e56c82570727',
        name: <>Бонус &laquo;Детокс&raquo; с&nbsp;Катей Усмановой</>,
        description: <><p>Вы&nbsp;выиграли марафон &laquo;Детокс&raquo; с&nbsp;Катей Усмановой. Информация поможет пересмотреть свой рацион, понять, какие продукты вызывают тяжесть и&nbsp;как быстро очистить организм. Добавьте себе лёгкости и&nbsp;воздушности в&nbsp;самоощущении!</p></>,
        limit: window.lps_settings.limits.prize9,
    },
]

export const L2024_11_bfbox = () => {
    const [modalActive, setModalActive] = useState(false)
    const [activePrize, setActivePrize] = useState({})
    const [isSelected, setIsSelected] = useState(false)
    const [alreadyShow, setAlreadyShow] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isRusPhone, setIsRusPhone] = useState(false)

    function randomNumber(min, max) { 
        return Math.round(Math.random() * (max - min) + min);
    }

    useEffect(()=>{
        getUser().then((res) => {
            if(res.phone.substr(0,2) == '+7' && res.phone.length == 12) {
                setIsRusPhone(true)
            }
        })
    }, [])

    useEffect(()=> {
        {prizes.map((i, idx)=>{
            checkUserInGroup(i.id).then(res => {
                // console.log(res == null)
                if(res !== null) {
                    // console.log(res)
                    setAlreadyShow(true)
                }
            })
            if(idx + 1 == prizes.length) {
                setTimeout(()=>{
                    setIsLoading(false)
                }, 2000)
            }
        })}
        
    }, [])
    
    function givePrize() {
        setIsSelected(true)
        
        checkUserInSegment(window.lps_settings.segments.segment_bfboxes).then(res => {
        if (!res) {
            return false;
        }

            if(isRusPhone) {
                Promise.all(prizes.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {
                    const availablePrizes = prizes.filter((i, idx) => {
                        return values[idx] < i.limit;
                    });
                    
                    if (availablePrizes.length === 0) {
                        return false;
                    }
                    
                    const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                    const prize = availablePrizes[randomPriceIdx];
                    
                    addUserToGroup(prize.id).then((res)=>{
                        setModalActive(true)
                        setActivePrize(prize) 
                    }) 
          
                    return prize;
                  })
                //   .then((prize) => console.log(prize));
            } else {
                Promise.all(prizes2.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {
                    const availablePrizes = prizes2.filter((i, idx) => {
                        return values[idx] < i.limit;
                    });
                    
                    if (availablePrizes.length === 0) {
                        return false;
                    }
                    
                    const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                    const prize = availablePrizes[randomPriceIdx];
                    
                    addUserToGroup(prize.id).then((res)=>{
                        setModalActive(true)
                        setActivePrize(prize) 
                    }) 
          
                    return prize;
                  })
                //   .then((prize) => console.log(prize));
            }
      });
    }

    function Boxes() {
        return (
            <>
                {!modalActive ?
                                <>
                                    <div className={styles.prizes_hide_top}>
                                        <div className={styles.pretitle}>Черная пятница</div>
                                        <div className={styles.title2}>ПРИЗОВЫЕ<br/> БОКСЫ</div>
                                        <div className={styles.title3}>Вас ждёт гарантированный приз!<br/> Скорее выбирайте один из&nbsp;боксов<br/> и&nbsp;узнайте, что достанется<br/> именно вам.</div>
                                    </div>
                                    <div className={`${styles.prizes_block} ${isSelected && styles.prizes_block__active}`}>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prize.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prize.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prize.png" alt="" />
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className={styles.win}>
                                        {/* <div className={styles.win_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/bant.png" alt="" /></div> */}
                                        <div className={styles.win_name}>
                                            {activePrize.name}
                                        </div>
                                        <div className={styles.win_desc}>
                                            <p style={{marginBottom: 10}}><b>Поздравляем!</b></p>
                                            {activePrize.description}
                                        </div>
                                        <div className={styles.win_more}>Подробная информация о&nbsp;получении подарка у&nbsp;вас&nbsp;на&nbsp;почте.</div>
                                    </div>
                                    </>
                            }
            </>
        )
    }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <HideForSegment id={window.lps_settings.segments.segment_bfboxes}>
                    <Hidden/>
                </HideForSegment>

                <ShowForSegment id={window.lps_settings.segments.segment_bfboxes}>
                
                {isLoading
                ? <div className={styles.loader_block}><div className={styles.loader}><div></div><div></div><div></div><div></div></div></div>
                : <>
                    {alreadyShow
                        ? <Already />
                        : <Boxes/>
                    }
                  </>
                }
                                
                </ShowForSegment>
            
            </Authorized>
            <Anonymous>
                <div className={styles.prizes_ghost_title}>Чтобы получить доступ к&nbsp;боксам<br/> нужно авторизоваться</div>
                <div className="velvet_prizes_login"><AuthWidget /></div>
            </Anonymous>
          </>
        );
      }

    return (
        <div className={styles.cursor}>
        <DocumentTitle value='Призовые боксы'/>
        <DocumentDescription value='' />
        <div className={styles.prizes}>
            <div className={styles.prizes_main}>
               <LoginForm />
            </div>
            
        </div>
        <Footer />
        </div>
    );
};