import { DocumentDescription, DocumentTitle } from '../components/DocumentMeta';
import Author from './components/Author';
import Faq from './components/Faq';
import Footer from '../components/Footer';
import Inventory from './components/Inventory';
import Marforme from './components/Marforme';
import Promo from './components/Promo';
import Start from './components/Start';
import Studentres from './components/Studentres';
import Whaityou from './components/Whaityou';
import Createbody from './components/Createbody';
import Onmarafon from './components/Onmarafon';
import Platform from '../components/platform/Platform';
import './style.css';

export const L2022_12_Nutrition = () => {
    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scrollStart = () => {
      const section = document.querySelector( '.scrollstart' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };
    return (
      <>
      <DocumentTitle value='Питание — курс Екатерины Усмановой в GymTeam'/>
      <DocumentDescription value='Курс «Питание» в GymTeam — программа Екатерины Усмановой на 45 дней. Цель: похудеть без строгих диет, перейти на правильное питание, научиться составлять рацион без диетолога.' />
      
        <div className='nutrition'>
          <Promo scroll={scroll} />
          <Createbody />
          <Onmarafon />
          <Whaityou />
          <Platform />
          <Marforme />
          <Studentres scrollStart={scrollStart} />
          <Inventory />
          <Start />
          <Author />
          <Faq scroll={scroll} />
          <Footer />
        </div>
      </>
    );
};