import './App.css';
import {DocumentTitle, DocumentDescription} from '../components/DocumentMeta';

import Footer from '../components/Footer';
import Faq from './components/Faq';
import Start from './components/Start';
import Gifts from './components/Gifts';
import Physical from './components/Physical';
import Inventory from './components/Inventory';
import Aboutcourse from './components/Aboutcourse';
import Youget from './components/Youget';
import Signup from './components/Signup';
import Program from './components/Program';
import Author from './components/Author';
import Promo from './components/Promo';
import Insta from './components/Insta';
import Gallery from './components/Gallery';
import Platform from '../components/platform/Platform';
import { useEffect } from 'react';
import { RedirectPrograms } from '../components/redirect/RedirectProgram';

export const L2022_11_Pregnancy = () => {

    const scroll = () => {
        const section = document.querySelector( '.startsection' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scrollStart = () => {
      const section = document.querySelector( '.scrollstart' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    useEffect(() => {
      document.body.id = 'rasprozarka_2305'
      document.body.parentElement.id = 'htmlrasprozarka_2305'
    }, [])

    return (
        <>
        <RedirectPrograms />
        <DocumentTitle value='Курс для беременных — программа Екатерины Усмановой в GymTeam'/>
        <DocumentDescription value='«Курс для беременных» в GymTeam — программа Екатерины Усмановой с первого триместра до родов. Включает тренировки для беременных, рекомендации по питанию и советы психолога — всё для осознанной подготовки к родам.' />
            <div className='pregnancyupd'>
                <Promo scroll={scroll} />
                <Author />
                <Insta />
                <Program />
                <Signup />
                <Youget scroll={scroll} />
                <Aboutcourse />
                <Platform />
                <Inventory />
                <Physical />
                <Gifts />
                <Start />
                <Gallery scrollStart={scrollStart} />
                <Faq scroll={scroll} />
                <Footer />
            </div>
        </>
    );
};