import { useEffect, useState } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Author from './components/Author';
import Faq from './components/Faq';
import Footer from '../../components/Footer';
import Inventory from './components/Inventory';
import Marforme from './components/Marforme';
import Promo from './components/Promo';
import Start from './components/Start';
import Studentres from './components/Studentres';
import Whaityou from './components/Whaityou';
import Createbody from './components/Createbody';
import Noinventory from './components/Noinventory';
import Onmarafon from './components/Onmarafon';
import Platform from '../../components/platform/Platform';
import './style.css';

export const L2023_06_Universalpop = () => {
    useEffect(() => {
      document.body.id = 'rasprozarka_2305'
      document.body.parentElement.id = 'htmlrasprozarka_2305'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    
    return (
      <>
        <DocumentTitle value='«Универсальные тренировки на ягодицы» в GymTeam'/>
        <DocumentDescription value='Программа «Универсальные тренировки на ягодицы» в GymTeam — это конструктор из 10 тренировок от Кати Усмановой. Цель: накачать ягодицы, сделать попу круглой в домашних условиях.' />
        <div className='universalpop'>
            <Promo scroll={scroll} />
            <Createbody />
            <Onmarafon />
            <Whaityou />
            <Marforme />
            <Platform />
            <Studentres scroll={scroll} />
            <Inventory />
            <Noinventory />
            <Start />
            <Author />
            <Faq scroll={scroll} />
            <Footer />
        </div>
      </>
    );
};