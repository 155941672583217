import styles from './inventory.module.css';

const Inventory = () => {
    return (
        <div className={styles.inventory}>
            <div className="container">
                <div className={styles.inventory_title}>КАКОЙ ИНВЕНТАРЬ НУЖЕН<br/> ДЛЯ&nbsp;ТРЕНИРОВОК?</div>
                <div className={styles.inventory_subtitle}>Этого набора хватит на&nbsp;всю&nbsp;жизнь!</div>
                <div className={styles.inventory_block}>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/inv_1.png" alt="" />
                        <p>Спортивная резиновая<br/> лента с&nbsp;сопротивлением<br/> 20&nbsp;кг</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/inv_2.png" alt="" />
                        <p>Разборные гантели<br/> от&nbsp;2&nbsp;до&nbsp;15&nbsp;кг</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/inv_3.png" alt="" />
                        <p>2&nbsp;утяжелителя<br/> по&nbsp;1,5-3&nbsp;кг</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inventory;