import Countdown from "react-countdown";
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from "./start.module.css";
import { useInstance } from "@com.marathonium/web2-utils";
import { useState } from "react";

const Start = ({ renderer, date }) => {
	const [widget] = useInstance();
	const [form, setForm] = useState(false);

	const fbqfunc = () => {
		if (window.fbq && typeof window.fbq === 'function') {
			window.fbq('trackCustom', 'order-bf2024');
		} else {
			console.warn('Facebook Pixel не инициализирован.');
		}
	}
	
	return (
		<div className={styles.oh}>
			{/* <div className={styles.line}>
				<div className={styles.line_text}>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>ЧЕРНАЯ ПЯТНИЦА</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
					<p>выгода 90%</p>
					<img
						src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg"
						alt=""
					/>
				</div>
			</div> */}

			<div className={"scrollhere " + styles.start}>
				<div className="container">
					<div className={styles.block}>
						<div className={styles.pretitle}>
							Такое предложение бывает только раз в&nbsp;году!
						</div>
						<div className={styles.title}>Успейте забрать<br/> коллекцию программ<br/> <span>Кати с&nbsp;выгодой&nbsp;89%</span></div>
						<div className={styles.subtitle}>12&nbsp;программ с&nbsp;домашними тренировками, курс для зала, розыгрыш<br/> призов и&nbsp;множество бонусов!</div>
						<div className={styles.prices}>9&nbsp;990&nbsp;<i>₽</i> <em>89&nbsp;480&nbsp;<i>₽</i></em>
							<span>(от&nbsp;1 665&nbsp;₽/мес.)</span>
						</div>
						<div className={styles.remains_n}>
							<div className={styles.remains_n_place}>Осталось комплектов: <span>{window.lps_settings.other.count}</span>/100</div>
						</div>
						{/* <div className={styles.remains}>
							<div className={styles.remains_text}>Спецпредложение <b>с&nbsp;выгодой&nbsp;90%</b> сгорит через:</div>
							<div className={styles.remains_number}>
								{window.lps_settings.other.count}
							</div>
							<div className={styles.timer2}>
								<Countdown date={Date.now() + date} renderer={renderer} />
							</div>
						</div> */}
						{/* <div className={styles.pretitle}>Такое предложение бывает <b>только раз в&nbsp;году!</b></div> */}
						<div className={styles.dts}>
							<div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/dts1.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Доступ&nbsp;&mdash; 1&nbsp;год
								</div>
							</div>
							{/* <div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/dts4.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Старт FMD-марафона&nbsp;&mdash; 18&nbsp;ноября
								</div>
							</div> */}
							<div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/dts2.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Старт тренировок&nbsp;&mdash; 25&nbsp;ноября
								</div>
							</div>
							{/* <div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/dts3.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Чат с&nbsp;куратором&nbsp;&mdash; с&nbsp;15&nbsp;ноября
									по&nbsp;15&nbsp;февраля
								</div>
							</div> */}
						</div>
						{/* <div className={styles.info_all}>
							<div className={styles.info_all_name}>
								Набор "Черная пятница 2024"
							</div>
							<div className={styles.info_all_prices}>
								19&nbsp;990&nbsp;₽ <em>199&nbsp;480&nbsp;₽</em>{" "}
								<span>от&nbsp;1 658&nbsp;₽/мес.</span>
							</div>
						</div> */}
						<div className={styles.info_all}>
							<div className={styles.info_all_name}>
							Набор из&nbsp;13&nbsp;программ Кати Усмановой
							</div>
							{/* <div className={styles.info_all_prices}>
								<em>89&nbsp;990&nbsp;₽</em>{" "}9&nbsp;990&nbsp;₽
								<span>от&nbsp;1 658&nbsp;₽/мес.</span>
							</div> */}
							<div className={styles.info_all_prices}>
								<em>89&nbsp;480&nbsp;₽</em>
							</div>
						</div>
						<div className={styles.info}>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Стройность 1.0&raquo;
								</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Упругой попы 1.0&raquo;
								</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Упругой попы 2.0&raquo;
								</p>
								<span>7&nbsp;900&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Плоского живота&raquo;
								</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Антицеллюлитный&raquo;
								</p>
								<span>7&nbsp;500&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Убираем лишний жир&raquo;
								</p>
								<span>7&nbsp;900&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Восстановление после родов&raquo;
								</p>
								<span>7&nbsp;900&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Питание&raquo;
								</p>
								<span>10&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Идеальной попы 2.0&raquo;
								</p>
								<span>6&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Идеальной попы 3.0&raquo;
								</p>
								<span>12&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Жиросжигающий&raquo;
								</p>
								<span>7&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Универсальные тренировки на&nbsp;ягодицы</b>
								</p>
								<span>4&nbsp;790&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Курс для зала. Цикл&nbsp;1</b>
								</p>
								<span>3&nbsp;490&nbsp;₽</span>
							</div>
						</div>

						<div className={styles.info_all}>
							<div className={styles.info_all_name}>Подарки<br/> и&nbsp;розыгрыши призов</div>
							<div className={styles.info_all_prices}>
								<em>21&nbsp;687&nbsp;₽</em>{" "}Бесплатно
							</div>
						</div>
						<div className={styles.info}>
							<div className={styles.info_item}>
								<p>
									<b>+ новая программа питания</b>
								</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>+ чат с&nbsp;куратором на&nbsp;1&nbsp;месяц</b>
								</p>
								<span>5&nbsp;000&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>
										+ подписка на&nbsp;тренировки GymTeam на&nbsp;3&nbsp;месяца
									</b>
								</p>
								<span>3&nbsp;697&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>+ 10&nbsp;полезных гайдов</b>
								</p>
								<span>7&nbsp;990&nbsp;₽</span>
							</div>
						</div>

						<div className={styles.info_all}>
							<div className={styles.info_all_name}>Участие в&nbsp;розыгрыше поездки в&nbsp;Дубай</div>
						</div>
						<div className={styles.info_all}>
							<div className={styles.info_all_name}>Участие в&nbsp;розыгрыше Iphone 16&nbsp;pro и&nbsp;других призов</div>
						</div>

						{/* <div className={styles.timer}>
							<Countdown date={Date.now() + 3600000} renderer={renderer} />
						</div> */}

						{!form && (
							<div className={styles.btn} onClick={() => setForm(true)}>
								Забрать программы
							</div>
						)}

						{form && (
							<div className="blackfriday_3_start">
								<SalesWidget
									hook={widget}
									initValue={window.lps_settings.forms.blackfriday2024_3_offer_id}
									id={window.lps_settings.forms.blackfriday2024_3_id}
									buttonText="Забрать программы"
									onPaymentSelect={fbqfunc}
								/>
							</div>
						)}

						<div className={styles.pay}>
							<div className={styles.pay_item}>
								<img
									src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank1.png"
									alt=""
								/>
								<p>Рассрочка без процентов от&nbsp;1 665&nbsp;₽ в&nbsp;месяц</p>
								<span>
									Вы&nbsp;можете оформить заказ в&nbsp;рассрочку:
									на&nbsp;6&nbsp;месяцев без процентов
									<br /> и&nbsp;предварительного взноса. Только для
									граждан&nbsp;РФ.
								</span>
							</div>
							<div className={styles.pay_item}>
								<img
									src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank2.png"
									alt=""
								/>
								<p>Оплати Долями, без банка, переплат и&nbsp;рисков</p>
								<span>
									Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; разделите
									платёж на&nbsp;4&nbsp;части от&nbsp;2 497,5&nbsp;₽.
									<br /> Всего на&nbsp;6&nbsp;недель.
									<br /> Только для граждан РФ
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Start;
