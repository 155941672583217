import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from './results.module.css';

const Results = () => {
    return (
        <div className={styles.oh}>
            <div className={styles.line}>
                <div className={styles.line_text}>
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>ЧЕРНАЯ ПЯТНИЦА</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                    <p>выгода 91%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/marker.svg" alt="" />
                </div>
            </div>

            <div className={styles.results}>
                <div className="container">
                    <div className={styles.results_title}>Твои результаты после<br/> прохождения программ</div>
                    <div className={styles.results_block}>
                        <div className={styles.results_block_item}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/res1.png" alt="" />
                            <p>Избавишься от&nbsp;лишнего веса</p>
                        </div>
                        <div className={styles.results_block_item}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/res2.png" alt="" />
                            <p>Подтянешь животик и&nbsp;сделаешь его более плоским</p>
                        </div>
                        <div className={styles.results_block_item}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/res3.png" alt="" />
                            <p>Создашь стройную и&nbsp;подтянутую фигуру</p>
                        </div>
                        <div className={styles.results_block_item}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/res4.png" alt="" />
                            <p>Накачаешь округлую и&nbsp;соблазнительную попу</p>
                        </div>
                        <div className={styles.results_block_item}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/res5.png" alt="" />
                            <p>Появится больше энергии и&nbsp;сил</p>
                        </div>
                        <div className={styles.results_block_item}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/res6.png" alt="" />
                            <p>Будешь нравиться себе в&nbsp;зеркале и&nbsp;сможешь носить любимую одежду</p>
                        </div>
                    </div>

                    <div className={styles.results_title}>Забирайте 12&nbsp;программ прямо<br/> сейчас, чтобы получить<br/> <span>все бонусы</span></div>
                    <div className={styles.block}>
                        <div className={styles.block_title}>Самое выгодное предложение года с&nbsp;эксклюзивными<br/> бонусами <b>для участниц предпродаж</b></div>
                        <div className="presale_blackfriday_start">
                            <SalesWidget id={window.lps_settings.forms.presale_blackfriday_id} buttonText="ОФОРМИТЬ ЗАКАЗ" />
                        </div>
                        <div className={styles.block_remains}>
                            <div className={styles.block_remains_text}>Осталось мест<br/> по&nbsp;спецпредложению:</div>
                            <div className={styles.block_remains_number}>{window.lps_settings.other.count}</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Results;