import styles from './inventory.module.css';

const Inventory = () => {
    return (
        <div className={styles.inventory}>
            <div className="container">
                <div className={styles.inventory_title}>КАКОЙ ИНВЕНТАРЬ НУЖЕН<br/> ДЛЯ&nbsp;ТРЕНИРОВОК?</div>
                <div className={styles.inventory_subtitle}>Этого набора хватит на&nbsp;всю&nbsp;жизнь!</div>
                <div className={styles.inventory_block}>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/inv_1.png" alt="" />
                        <p>Коврик<br/> (или&nbsp;аналог)</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/inv_2.png" alt="" />
                        <p>Вакуумная банка</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/inv_3.png" alt="" />
                        <p>Разборные гантели<br/> до&nbsp;15&nbsp;кг</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/inv_4.png" alt="" />
                        <p>Фитнес-резинки<br/> с&nbsp;сопротивлением<br/> 10-22&nbsp;кг</p>
                    </div>
                    <div className={styles.inventory_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/inv_5.png" alt="" />
                        <p>Скребок гуаша<br/> или&nbsp;обычная столовая<br/> ложка</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inventory;