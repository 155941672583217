import { SalesWidget, addUserToGroup } from '@com.marathonium/web2-ui';
import Countdown from 'react-countdown';
import styles from './land2.module.css'
import { useEffect } from 'react';
import Reviews from "./reviews";

const Land2 = ({date, renderer, hide, version, scroll, auth}) => {
    // useEffect(()=>{
    //     if(hide !== true) {
    //         addUserToGroup(window.lps_settings.user_groups.fatlost_n5_watched)
    //     }
    // }, [])

    return (
        <div className={styles.land2}>
            <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/logo.svg" alt="" /></div>
            <div className={styles.wrapper}>
                <div className={styles.block}>
                    <div className={styles.block_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/frame1.jpg" alt="" />
                    </div>
                    <div className={styles.block_info}>
                        <div className={styles.block_title}>ДЛЯ УЧАСТНИЦ<br/> ЖИРОСЖИГАЮЩЕЙ НЕДЕЛИ</div>
                        <div className={styles.block_subtitle}><span>Получите годовую подписку GymTeam со&nbsp;скидкой 15%!</span><br/> Стоимость для вас <em>9&nbsp;590</em> 8&nbsp;150&nbsp;₽</div>
                        <div className={styles.block_countdown}>
                            <p>Предложение действительно:</p>
                            <div className={styles.block_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                        <div className={styles.block_btn} onClick={scroll}>Оформить подписку</div>
                    </div>
                </div>

                <div className={styles.title2}>ЧТО ТАКОЕ GYMTEAM:</div>
                <div className={styles.block2}>
                    <div className={styles.block2_item}>
                        <div className={styles.block2_info}>
                            <div className={styles.block2_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/icon1.png" alt="" /></div>
                            <p>Сегодня уже более&nbsp;50&nbsp;программ и&nbsp;каждый месяц выходят обновления</p>
                        </div>
                    </div>
                    <div className={styles.block2_item}>
                        <div className={styles.block2_info}>
                            <div className={styles.block2_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/icon2.png" alt="" /></div>
                            <p>Рейтинг приложения 5,0 на&nbsp;основании более 2&nbsp;400 отзывов.</p>
                        </div>
                    </div>
                    <div className={styles.block2_item}>
                        <div className={styles.block2_info}>
                            <div className={styles.block2_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/icon3.png" alt="" /></div>
                            <p>Доступ к&nbsp;тренировкам через браузер, приложение на&nbsp;iPhone или&nbsp;Android, а&nbsp;так&nbsp;же на&nbsp;телевизорах SmartTV</p>
                        </div>
                    </div>
                    <div className={styles.block2_item}>
                        <div className={styles.block2_info}>
                            <div className={styles.block2_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/icon4.png" alt="" /></div>
                            <p>Действующие тренеры-эксперты с&nbsp;опытом работы более&nbsp;10&nbsp;лет</p>
                        </div>
                    </div>
                    <div className={styles.block2_item}>
                        <div className={styles.block2_info}>
                            <div className={styles.block2_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/icon5.png" alt="" /></div>
                            <p>Наборы тренировок на&nbsp;похудение, растяжку, ягодицы, пресс, расслабление и&nbsp;выносливость для возраста от&nbsp;18 до&nbsp;60&nbsp;лет</p>
                        </div>
                    </div>
                </div>

                <div className={('scrollhere ') + styles.block3}> 
                        <div className={styles.block3_title}>Подписка на&nbsp;все<br/> программы GymTeam на&nbsp;год<br/> включая обновления </div>
                        <div className={styles.block3_subtitle}>Стоимость <span>9&nbsp;590</span> 8&nbsp;150&nbsp;₽</div>
                        <div className={styles.block3_countdown}>
                            <p>Предложение действительно:</p>
                            <div className={styles.block3_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                        <div className="year115_start">
                            <SalesWidget id={window.lps_settings.forms.fatlost_n6_id} />
                        </div>                    
                </div>
            </div>
        </div>
    )
}

export default Land2;