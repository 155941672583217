import { useIsAuthorized } from "../../helpers";

export function Anonymous({ children }) {
  const isAnonymous = !useIsAuthorized();
  return isAnonymous ? children : null;
}

export function Authorized({ children }) {
  const isAuthorized = useIsAuthorized();
  return isAuthorized ? children : null;
}