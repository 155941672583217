import styles from './onmarafon.module.css';

const Onmarafon = () => {
    return (
        <div className={styles.onmarafon}>
            <div className="container">
                <div className={styles.onmarafon_title}>НА КУРСЕ <span>НЕ&nbsp;БУДЕТ:</span></div>
                <div className={styles.onmarafon_block}>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/onm_1.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Кардио до&nbsp;<strong>потери пульса</strong></div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/onm_2.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Непроверенных</strong> методов<br/> и&nbsp;упражнений</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/onm_3.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>100 приседаний за&nbsp;раз</strong><br/> и&nbsp;бёрпи по&nbsp;часу</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/onm_4.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Психологического <strong>давления</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onmarafon;