import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/universalpop/logo.svg' alt="gymteam" /></div>
                <div className={styles.promo_pretitle}><span>Премьера 2023 года</span> от Кати Усмановой</div>
                <div className={styles.promo_title}>УНИВЕРСАЛЬНЫЕ<br/> ТРЕНИРОВКИ<br/> <span>НА&nbsp;ЯГОДИЦЫ</span></div>
                <div className={styles.promo_subtitle}><b>Первый конструктор тренировок,</b> которые<br/> можно комбинировать как хочется</div>
                <div className={styles.promo_items}>
                    <div className={styles.promo_item}><img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/icon1.png" alt="" /> <p>Для любого<br/> уровня</p></div>
                    <div className={styles.promo_item}><img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/icon2.png" alt="" /> <p>10 тренировок<br/> для&nbsp;дома</p></div>
                    <div className={styles.promo_item}><img src="https://gymteam.kinescopecdn.net/img/l/2023/universalpop/icon3.png" alt="" /> <p>С инвентарём<br/> и&nbsp;без</p></div>
                </div> 
                <div className={styles.promo_btns}>
                    <div className={styles.promo_btn} onClick={scroll}><p>Участвовать</p></div>
                    <div className={styles.promo_date}><p>Старт сразу<br/> после оплаты</p></div>
                </div>
            </div>
            <div className={styles.promo_points_block}>
                <div className={styles.promo_point1}>#избавиться<span>от целлюлита</span></div>
                <div className={styles.promo_point2}>#создать<span>форму</span></div>
                <div className={styles.promo_point3}>#придать<span>рельеф</span></div>
            </div>
        </div>
    )
}

export default Promo;