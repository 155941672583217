import { useEffect } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';


import styles from './style.module.css';
import './style.css'
import Promo from './components/Promo';
import Platform from './components/Platform';
import Preims from './components/Preims';
import Programs from './components/Programs';
import Trainings from './components/Trainings';
import Start from './components/Start';
import Faq from './components/Faq';
import Footer from '../../components/Footer';

export const L2023_06_Stretchweek = () => {

    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'
    }, [])

    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    return (
        <>
        <DocumentTitle value='С 19 по 25 июня — неделя растяжки в GymTeam!'/>
        <DocumentDescription value='Принимайте участие в акции и бесплатно занимайтесь растяжкой на платформе для самостоятельного фитнеса GymTeam!' />
        <div className={styles.stretchweek}>
            <Promo scroll={scroll} />
            <Platform />
            <Preims />
            <Programs scroll={scroll} />
            <Trainings scroll={scroll} />
            <Start />
            <Faq />
            <Footer />
        </div>
        </>
    );
};