import styles from './block2.module.css';

const Block2 = ({scroll}) => {
    return (
        <div className={styles.block2}>
            <div className="container">
                <div className={styles.img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/block2.png" alt="" />
                </div>
                <div className={styles.pt}>Новая программа питания!</div>
                <div className={styles.t}>Питайтесь 4&nbsp;раза в&nbsp;день <span>и&nbsp;худейте</span></div>
                <div className={styles.b}>
                    <div className={styles.b_item}>
                        <div className={styles.b_item_num}>1</div>
                        <div className={styles.b_item_text}>
                            <div>Пройдите 7-дневный марафон &laquo;Неделя чистого питания&raquo;</div>
                            <p>Наш нутрициолог разработал программу, которая поможет легко и&nbsp;без стресса адаптировать организм к&nbsp;сбалансированному питанию за&nbsp;7&nbsp;дней. Каждый день вы&nbsp;получаете рекомендации по&nbsp;питанию и&nbsp;быстрые рецепты вкусных блюд. В&nbsp;итоге вы&nbsp;плавно перестраиваете свою систему питания и&nbsp;вырабатываете привычку постоянно питаться правильно.</p>
                        </div>
                    </div>
                    <div className={styles.b_item}>
                        <div className={styles.b_item_num}>2</div>
                        <div className={styles.b_item_text}>
                            <div>Питайтесь по&nbsp;простому правилу &laquo;здоровой тарелки&raquo; 4&nbsp;раза в&nbsp;день без жёстких ограничений</div>
                            <p>Наша система питания основывается на&nbsp;правиле &laquo;здоровой тарелки&raquo;&nbsp;&mdash; это простой принцип питания, который позволяет питаться 4&nbsp;раза в&nbsp;день без диет, жёстких ограничений, подсчёта КБЖУ и&nbsp;дорогих продуктов. Такая программа помогает легко сбросить лишние килограммы и&nbsp;поддерживать крутое качество тела.</p>
                        </div>
                    </div>
                </div>
                <div className={styles.btn} onClick={scroll}>Хочу новую программу питания</div>
            </div>
        </div>
        
    )
}

export default Block2;
