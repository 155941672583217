import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_title}>Что вы должны знать<br/> о&nbsp;курсе «Питание»</div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>УРОКИ ОТ&nbsp;ПРОСТОГО К&nbsp;СЛОЖНОМУ‍‍</div>
                            <div className={styles.whaityou_info__text}>Курс начинается с&nbsp;самых основ, чтобы&nbsp;у&nbsp;вас было понимание о&nbsp;том, как&nbsp;работает пищеварительная система и&nbsp;зачем&nbsp;мы едим. Далее вы познакомитесь со&nbsp;всеми составляющими правильного питания: от&nbsp;расчёта КБЖУ до&nbsp;различных способов коррекции веса посредством рациона.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>НЕ ТОЛЬКО ТЕОРИЯ, НО&nbsp;И&nbsp;ПРАКТИКА‍‍</div>
                            <div className={styles.whaityou_info__text}>После каждого урока вам надо будет выполнить задание, чтобы закрепить материал и&nbsp;внедрить новые знания в&nbsp;жизнь. Так, вы научитесь анализировать свой рацион и&nbsp;рассчитывать калораж на&nbsp;день в&nbsp;зависимости от&nbsp;целей.</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>СИСТЕМА, ЧТОБЫ СФОРМИРОВАТЬ НАВЫК‍</div>
                            <div className={styles.whaityou_info__text}>Задача курса&nbsp;— дать вам базу, необходимую для&nbsp;формирования навыка. Только так можно сделать правильное питание&nbsp;— частью жизни, а&nbsp;не&nbsp;«обязаловкой», которая вызывает только дискомфорт и&nbsp;становится причиной стресса.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;