import { extendTheme as extendChakraTheme } from "@chakra-ui/react";
import _merge from "lodash/merge";
import { DEFAULT_COLOR_MODE } from "@com.marathonium/web2-utils";

import { COLOR_WHITE_OPACITY_025, DESKTOP_MIN_WIDTH, DESKTOP_LARGE_MIN_WIDTH } from "./constants";

const config = {
  initialColorMode: DEFAULT_COLOR_MODE,
  useSystemColorMode: false,
};

const shadows = {
  outline: `0 0 0 3px ${COLOR_WHITE_OPACITY_025}`,
};

const semanticTokens = {
  colors: {
    "chakra-body-bg": {
      _dark: "gray.700",
      _light: "white",
    },
  },
};

const theme = extendChakraTheme({ config, shadows, semanticTokens });

theme.breakpoints = {
  md: `${DESKTOP_MIN_WIDTH}px`,
  lg: `${DESKTOP_LARGE_MIN_WIDTH}px`,
};

function extendTheme(obj = {}) {
  return _merge(theme, obj);
}

export { theme, extendTheme };
