export function getCookie(name) {
  let cookie = {};

  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = decodeURIComponent(value);
  });

  return cookie[name];
}

export function setCookie(name, value, options = {}) {
  const _options = {
    path: "/",
    ...options,
  };

  if (_options.expires instanceof Date) {
    _options.expires = _options.expires.toUTCString();
  }

  let cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let key in _options) {
    cookie += "; " + key;
    let val = _options[key];
    if (val !== true) {
      cookie += "=" + val;
    }
  }

  document.cookie = cookie;
}
