import React, { Fragment, useState, useEffect, useCallback, useRef } from "react";
import {
  VStack,
  HStack,
  Icon,
  Button,
  Skeleton,
  Alert,
  AlertIcon,
  AlertDescription,
  Image,
  Modal,
  ModalContent,
  ModalBody,
  Input,
  Center,
  Flex,
  useFormControlContext,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import Papa from "papaparse";
import InputMask from "react-input-mask";
import { X, MagnifyingGlass } from "phosphor-react";
import { AutoSizer, List } from "react-virtualized";
import { fitHost } from "@com.marathonium/web2-utils";

import { TextAccent } from "../Text";
import { Desktop, DesktopLarge, Mobile, MobileOrDesktopMedium } from "../MediaQueries";
import { ArrowSmall } from "../../icons/ArrowSmall";
import {
  BLUR_50,
  BORDER_RADIUS_2,
  BORDER_RADIUS_20,
  BORDER_RADIUS_30,
  BORDER_RADIUS_5,
  COLOR_BLACK,
  COLOR_BLACK_OPACITY_01,
  COLOR_BLACK_OPACITY_025,
  COLOR_WHITE,
  COLOR_WHITE_OPACITY_01,
  COLOR_WHITE_OPACITY_025,
  FONT_SIZE_18,
  FONT_SIZE_20,
  FONT_SIZE_24,
  FONT_SIZE_32,
  SPACING_10,
  SPACING_20,
  SPACING_45,
  SPACING_65,
  SPACING_72,
  LOGIN_FORM_CONTAINER_WIDTH,
  COLOR_BG_PRIMARY_LIGHT_025,
  COLOR_BG_PRIMARY_DARK_025,
  COLOR_WHITE_OPACITY_075,
  COLOR_BLACK_OPACITY_075,
} from "../../constants/styles";

function CountrySearchInput({ onSearchChanged, clearInput, searchText, closeModal }) {
  const inputRef = useRef();
  function onClick() {
    inputRef.current.focus();
  }

  function onXClick() {
    if (searchText) {
      clearInput();
    } else {
      closeModal();
    }
  }

  return (
    <HStack
      height={SPACING_72}
      background={useColorModeValue(COLOR_WHITE_OPACITY_075, COLOR_BLACK_OPACITY_075)}
      borderRadius={BORDER_RADIUS_20}
      padding={SPACING_20}
      spacing={0}
      onClick={onClick}
    >
      <Icon as={MagnifyingGlass} color={useColorModeValue(COLOR_BLACK, COLOR_WHITE)} opacity={0.25} fontSize={FONT_SIZE_32} />
      <Input
        ref={inputRef}
        autoComplete="off"
        placeholder="Поиск"
        px={0}
        value={searchText}
        color={useColorModeValue(COLOR_BLACK, COLOR_WHITE)}
        fontSize={FONT_SIZE_20}
        paddingLeft={SPACING_20}
        background="transparent"
        onChange={onSearchChanged}
        border="none"
        _focus={{
          outline: "none",
        }}
        _focusVisible={{
          outline: "none",
        }}
        _invalid={{
          border: "none",
        }}
        _placeholder={{ color: useColorModeValue(COLOR_BLACK_OPACITY_025, COLOR_WHITE_OPACITY_025) }}
      />
      <Icon
        as={X}
        color={useColorModeValue(COLOR_BLACK, COLOR_WHITE)}
        cursor="pointer"
        fontSize={FONT_SIZE_32}
        opacity={0.25}
        _hover={{ opacity: 0.5 }}
        onClick={onXClick}
      />
    </HStack>
  );
}

function CountryListRowRenderer({ style, country, onSelect }) {
  const [countryCode = "", flagImage, countryName] = country;

  return (
    <HStack
      px={SPACING_20}
      spacing={SPACING_20}
      cursor="pointer"
      border="2px solid transparent"
      _hover={{
        background: useColorModeValue(COLOR_WHITE_OPACITY_025, COLOR_BLACK_OPACITY_025),
        border: `2px solid ${useColorModeValue(COLOR_BLACK, COLOR_WHITE)}`,
        borderRadius: BORDER_RADIUS_20,
      }}
      onClick={() => {
        onSelect(country);
      }}
      {...style}
    >
      <Image src={flagImage} alt="" borderRadius={BORDER_RADIUS_5} width={SPACING_45} flexShrink={0} />
      <TextAccent fontWeight="500" lineHeight="shorter" color={useColorModeValue(COLOR_BLACK, COLOR_WHITE)}>
        {countryName} (+{countryCode})
      </TextAccent>
    </HStack>
  );
}

function makeCountryListRowRenderer(items, onSelect) {
  return ({ index, key, style }) => <CountryListRowRenderer key={key} style={style} country={items[index]} onSelect={onSelect} />;
}

function CountryListRenderer({ width, height, items, onSelect }) {
  const listProps = {
    width,
    height,
    rowCount: items.length,
    rowRenderer: makeCountryListRowRenderer(items, onSelect),
  };

  return (
    <Fragment>
      <DesktopLarge>
        <List {...listProps} rowHeight={73} />
      </DesktopLarge>
      <MobileOrDesktopMedium>
        <List {...listProps} rowHeight={57} />
      </MobileOrDesktopMedium>
    </Fragment>
  );
}

function CountryList({ items, onSelect }) {
  if (!items.length) {
    return (
      <Center height="100%">
        <TextAccent color={COLOR_WHITE}>Не найдено</TextAccent>
      </Center>
    );
  }

  return (
    <Flex basis="auto" grow="1" shrink="1">
      <AutoSizer>{sizes => <CountryListRenderer {...sizes} items={items} onSelect={onSelect} />}</AutoSizer>
    </Flex>
  );
}

function SelectCountryModalContent({ children }) {
  return (
    <Fragment>
      <Desktop>
        <ModalContent
          className="input-phone__select-country-modal"
          borderRadius={BORDER_RADIUS_30}
          w={LOGIN_FORM_CONTAINER_WIDTH}
          minW={LOGIN_FORM_CONTAINER_WIDTH}
          background={useColorModeValue(COLOR_BG_PRIMARY_LIGHT_025, COLOR_BG_PRIMARY_DARK_025)}
          backdropFilter={BLUR_50}
          boxShadow="none"
          my={0}
        >
          <ModalBody p={0}>{children}</ModalBody>
        </ModalContent>
      </Desktop>
      <Mobile>
        <ModalContent
          className="input-phone__select-country-modal"
          w="100%"
          background={useColorModeValue(COLOR_WHITE_OPACITY_025, COLOR_BLACK_OPACITY_025)}
          backdropFilter={BLUR_50}
          boxShadow="none"
          m={0}
        >
          <ModalBody p={0}>{children}</ModalBody>
        </ModalContent>
      </Mobile>
    </Fragment>
  );
}

function SelectCountryModal({ isOpen, onClose, countries, onSelect }) {
  const [searchText, setSearchText] = useState("");
  const onSearchChanged = e => {
    setSearchText(e.target.value);
  };
  const onSearchClear = () => {
    setSearchText("");
  };

  const countryItems = countries
    .filter(country => {
      const [countryCode] = country;
      return !!countryCode;
    })
    .filter(country => {
      if (!searchText) {
        return true;
      }

      const [, , countryName] = country;
      return countryName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SelectCountryModalContent>
        <VStack align="stretch" spacing={SPACING_10} h="100vh" my={0} p="16px">
          <CountrySearchInput onSearchChanged={onSearchChanged} clearInput={onSearchClear} closeModal={onClose} searchText={searchText} />

          <VStack
            h="100%"
            align="stretch"
            overflow="auto"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <CountryList items={countryItems} onSelect={onSelect} />
          </VStack>
        </VStack>
      </SelectCountryModalContent>
    </Modal>
  );
}

function normalizePhone(phone) {
  return phone.replace(/\D+/g, "");
}

function detectCountry(phone, countries) {
  if (!phone) {
    return countries[0];
  }

  const p = normalizePhone(phone);

  let lastSuccess;
  let countryCode = "";
  for (let i = 0; i < p.length; i++) {
    countryCode += p[i];

    // eslint-disable-next-line
    const country = countries.find(c => {
      return c[0] === countryCode;
    });

    if (country) {
      lastSuccess = country;
    } else if (!country && lastSuccess) {
      return lastSuccess;
    }
  }

  return countries[0];
}

function processCountryData(country) {
  const [countryCode = "", flagImage, , inputMask = ""] = country;

  let mask = inputMask;
  for (let i = 0; i < countryCode.length; i++) {
    mask = mask.replace("_", `\\${countryCode[i]}`);
  }

  mask = mask.trim();
  mask = mask.replace(/_/g, "9");
  mask = mask.replace(/\(/g, "");
  mask = mask.replace(/\)/g, "");
  mask = mask.replace(/-/g, " ");
  mask += "9999";

  return [countryCode, flagImage, mask];
}

function getCursorChangeHandler(inputRef) {
  return function () {
    const cursorPosition = inputRef.current.getCursorPosition() || 0;
    const inputValue = inputRef.current.getInputValue() || "";
    const rightPart = inputValue.slice(cursorPosition);
    if (rightPart.trim() === "") {
      inputRef.current.setCursorToEnd();
    }
  };
}

function fetchCountries() {
  return fetch(`${fitHost}/countries_short.csv`)
    .then(r => r.text())
    .then(csv => {
      const { data } = Papa.parse(csv);
      return data;
    });
}

function InputLoading() {
  return (
    <Skeleton
      height={SPACING_65}
      borderRadius={BORDER_RADIUS_20}
      startColor={useColorModeValue(COLOR_BLACK_OPACITY_025, COLOR_WHITE_OPACITY_025)}
      endColor={useColorModeValue(COLOR_BLACK_OPACITY_01, COLOR_WHITE_OPACITY_01)}
      w="100%"
    />
  );
}

function InputError({ error, onClick }) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <Alert status="error" height={SPACING_65} borderRadius={BORDER_RADIUS_20}>
      <AlertIcon />
      <AlertDescription w="100%">
        <HStack justify="space-between">
          <TextAccent>Ошибка :(</TextAccent>
          <Button onClick={onClick} size="sm">
            Обновить
          </Button>
        </HStack>
      </AlertDescription>
    </Alert>
  );
}

export function InputPhone(props) {
  const { inputProps, formikProps, onReady, ...boxProps } = props;
  const { setFieldValue } = formikProps;
  const { isDisabled } = useFormControlContext() || {};

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [country, setCountry] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputRef = useRef();

  const loadData = useCallback(() => {
    setLoading(true);
    setError(null);

    fetchCountries()
      .then(data => {
        setData(data);
        setCountry(detectCountry(inputProps.value, data));
        setFieldValue(inputProps.name, normalizePhone(inputProps.value));
        typeof onReady === "function" && onReady();
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onContainerClick = () => {
    inputRef.current.getInputDOMNode().focus();
  };

  const onCountrySelectorClick = e => {
    e.stopPropagation();
    onOpen();
  };

  const onCountrySelect = country => {
    setCountry(country);
    onClose();
    setFieldValue(inputProps.name, "");

    setTimeout(() => {
      inputRef.current.getInputDOMNode().focus();
    }, 300); // Cause: https://github.com/formium/formik/issues/529
  };

  if (loading) {
    return <InputLoading />;
  }

  if (error) {
    return <InputError error={error} onClick={loadData} />;
  }

  const [countryCode, flagImage, mask] = processCountryData(country);

  const handleCursorChange = getCursorChangeHandler(inputRef);

  function _onChange(e) {
    if (normalizePhone(e.target.value) === countryCode) {
      return setFieldValue(inputProps.name, "");
    }

    return inputProps.onChange(e);
  }

  return (
    <Fragment>
      <SelectCountryModal isOpen={isOpen} onClose={onClose} countries={data} onSelect={onCountrySelect} />

      <HStack
        spacing={0}
        height={SPACING_65}
        px={SPACING_20}
        borderWidth="1px"
        borderColor="var(--chakra-colors-chakra-border-color)"
        borderRadius={BORDER_RADIUS_20}
        onClick={onContainerClick}
        opacity={isDisabled ? 0.4 : 1}
        w="100%"
        {...boxProps}
      >
        <HStack spacing={SPACING_10} cursor={isDisabled ? "not-allowed" : "pointer"} onClick={isDisabled ? null : onCountrySelectorClick}>
          <Image src={flagImage} width={SPACING_20} borderRadius={BORDER_RADIUS_2} alt="" />

          <ArrowSmall fontSize={FONT_SIZE_24} />
        </HStack>

        <Input
          fontFamily="mono"
          fontSize={FONT_SIZE_18}
          background="transparent"
          as={InputMask}
          mask={mask}
          maskChar={" "}
          alwaysShowMask
          onKeyUp={handleCursorChange}
          onMouseUp={handleCursorChange}
          border="none"
          textAlign="center"
          _focus={{ border: "none" }}
          _focusVisible={{
            outline: "none",
          }}
          _invalid={{ border: "none" }}
          _placeholder={{ color: "#666666" }}
          ref={inputRef}
          {...inputProps}
          onChange={_onChange}
        />
      </HStack>
    </Fragment>
  );
}

export function InputPhone2({ name, value = "", onChange, onReady, ...props }) {
  const { isDisabled } = useFormControlContext() || {};
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [country, setCountry] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [val, setVal] = useState(value);

  const inputRef = useRef();

  const loadData = useCallback(() => {
    setLoading(true);
    setError(null);

    fetchCountries()
      .then(data => {
        setData(data);
        setCountry(detectCountry(value, data));
        setVal(normalizePhone(value));
        typeof onReady === "function" && onReady();
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onContainerClick = () => {
    inputRef.current.getInputDOMNode().focus();
  };

  const onCountrySelectorClick = e => {
    e.stopPropagation();
    onOpen();
  };

  const onCountrySelect = country => {
    setCountry(country);
    onClose();
    setVal("");

    setTimeout(() => {
      inputRef.current.getInputDOMNode().focus();
    }, 300); // Cause: https://github.com/formium/formik/issues/529
  };

  if (loading) {
    return <InputLoading />;
  }

  if (error) {
    return <InputError error={error} onClick={loadData} />;
  }

  const [countryCode, flagImage, mask] = processCountryData(country);

  const handleCursorChange = getCursorChangeHandler(inputRef);

  function _onChange(e) {
    const newVal = normalizePhone(e.target.value) === countryCode ? "" : e.target.value;
    setVal(newVal);
    e.target.value = newVal;
    return typeof onChange === "function" && onChange(e);
  }

  return (
    <Fragment>
      <SelectCountryModal isOpen={isOpen} onClose={onClose} countries={data} onSelect={onCountrySelect} />

      <HStack
        spacing={0}
        height={SPACING_65}
        px={SPACING_20}
        borderRadius={BORDER_RADIUS_20}
        onClick={onContainerClick}
        opacity={isDisabled ? 0.4 : 1}
        fontFamily="mono"
        fontSize={FONT_SIZE_18}
        {...props}
      >
        <HStack spacing={SPACING_10} cursor={isDisabled ? "not-allowed" : "pointer"} onClick={isDisabled ? null : onCountrySelectorClick}>
          <Image src={flagImage} width={SPACING_20} borderRadius={BORDER_RADIUS_2} alt="" />
          <ArrowSmall fontSize={FONT_SIZE_24} />
        </HStack>

        <Input
          ref={inputRef}
          as={InputMask}
          mask={mask}
          maskChar={" "}
          autoFocus
          alwaysShowMask
          fontFamily="inherit"
          fontSize="inherit"
          background="transparent"
          border="none"
          textAlign="center"
          name={name}
          value={val}
          onChange={_onChange}
          onKeyUp={handleCursorChange}
          onMouseUp={handleCursorChange}
          _focus={{ border: "none" }}
          _focusVisible={{
            outline: "none",
          }}
          _invalid={{ border: "none" }}
        />
      </HStack>
    </Fragment>
  );
}
