import styles from './onmarafon.module.css';

const Onmarafon = () => {
    return (
        <div className={styles.onmarafon}>
            <div className="container">
                <div className={styles.onmarafon_title}>НА МАРАФОНЕ <span>НЕ&nbsp;БУДЕТ:</span></div>
                <div className={styles.onmarafon_block}>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/onm_1.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Непроверенных</strong> методов</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/onm_2.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Кардио <strong>до&nbsp;потери пульса</strong></div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/onm_3.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Жёстких диет и&nbsp;<strong>голодовок</strong></div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_removefat/onm_4.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Психологического <strong>давления</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onmarafon;