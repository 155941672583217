import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Марафон точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Новичок и не знаете, <strong>с чего начать,</strong><br/> но&nbsp;мечтаете <strong>похудеть и&nbsp;подтянуть всё тело</strong></li>
                            <li>Профессионал и хотите <strong>подсушиться<br/> или&nbsp;подкачаться</strong></li>
                            <li>Много раз пробовали худеть, <strong>но&nbsp;вес<br/> всегда возвращался</strong></li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Не можете найти время <strong>для похода<br/> в&nbsp;спортзал</strong></li>
                            <li>Пока не собираетесь <strong>покупать<br/> инвентарь</strong></li>
                            <li>Не знаете, где найти мотивацию,<br/> <strong>чтобы начать и&nbsp;не&nbsp;бросить</strong></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;