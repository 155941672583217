import { RedirectPrograms } from '../components/redirect/RedirectProgram';
import { DocumentDescription, DocumentTitle } from '../components/DocumentMeta';
import Author from './components/Author';
import Faq from './components/Faq';
import Footer from '../components/Footer';
import Inventory from './components/Inventory';
import Marforme from './components/Marforme';
import Onmarafon from './components/Onmarafon';
import Points from './components/Points';
import Promo from './components/Promo';
import Start from './components/Start';
import Studentres from './components/Studentres';
import Whaityou from './components/Whaityou';
import Createbody from './components/Createbody';
import Noinventory from './components/Noinventory';
import Platform from '../components/platform/Platform';
import './style.css';

export const L2022_12_Marafon_removefat = () => {
    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scrollStart = () => {
      const section = document.querySelector( '.scrollstart' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    return (
      <>
      <RedirectPrograms />
      <DocumentTitle value='Убираем лишний жир — марафон Екатерины Усмановой в GymTeam'/>
      <DocumentDescription value='Марафон «Убираем лишний жир» в GymTeam — программа Екатерины Усмановой на 30 дней тренировок дома или в зале. Цель: похудеть, запустить процесс жиросжигания.' />
      
        <div className='removefat'>
          <Promo scroll={scroll} />
          <Createbody />
          <Onmarafon />
          <Whaityou />
          <Marforme />
          <Platform />
          <Studentres scrollStart={scrollStart} />
          <Points />
          <Inventory />
          <Noinventory />
          <Start />
          <Author />
          <Faq scroll={scroll} />
          <Footer />
        </div>
      </>
    );
};