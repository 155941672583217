import React, { useEffect } from "react";

import styles from "./style.module.css";
import {
	DocumentDescription,
	DocumentTitle,
} from "../../components/DocumentMeta";

import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import { SubscriptionWidget } from "@com.marathonium/web2-ui";
import { Redirect } from "../../components/redirect/Redirect";
import "./styles.css";

export const L2024_03_8march = () => {
	const redirectURL =
		"https://gymteam.ru/collections/de912219-cba2-403e-b6ab-15ce3d1a33f0";

	useEffect(() => {
		document.body.id = "reset";
		document.body.parentElement.id = "html_reset";
	}, []);

	const widgetClick = () => {
		document.querySelector('.gt-subsription__offer-list .chakra-stack').click()
	}

	function LoginForm() {
		return (
			<>
				<Authorized>
					<Redirect url={redirectURL} />
				</Authorized>
				<Anonymous>
					<>
						<DocumentTitle value="" />
						<DocumentDescription value="" />
						<div className={`${styles.page} march8sb`}>
							<div className={styles.block}>
								<div className={styles.img}></div>
								<div className={styles.block2}>
									<div className={styles.title}>
										Три месяца тренировок
										<br /> за&nbsp;1900&nbsp;руб!
									</div>
									<div className={styles.subtitle}>
										В&nbsp;честь праздника открываем <a onClick={widgetClick}>бесплатную коллекцию тренировок</a>, чтобы вы&nbsp;могли попробовать все направления, и&nbsp;даем скидку на&nbsp;первые три месяца фитнеса!
									</div>
									<SubscriptionWidget
										onLogin={() => {
											window.document.write("");
											window.location = redirectURL;
										}}
									/>
								</div>
							</div>
						</div>
					</>
				</Anonymous>
			</>
		);
	}

	return <LoginForm />;
};
