import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Марафон точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li><strong>Перепробовали самые популярные диеты,</strong><br/> но&nbsp;не&nbsp;получили результат</li>
                            <li>Хотите получить результат, но&nbsp;<strong>не&nbsp;знаете, с&nbsp;чего начать</strong></li>
                            <li>Похудели, но&nbsp;в&nbsp;самых <strong>проблемных местах жир<br/> остался</strong></li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Не знаете, где&nbsp;найти мотивацию, <strong>чтобы начать<br/> и&nbsp;не&nbsp;бросить</strong></li>
                            <li>Не понимаете, как&nbsp;тренироваться, <strong>чтобы сжигать жир</strong></li>
                            <li>Профессионал, хотите <strong>быстро вернуть рельеф</strong></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;