import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Second from './components/Second';
import Programs from './components/Programs';
import Block4 from './components/Block4';
import Block1 from './components/Block1';
import Localplatform from './components/Localplatform';
import Students from './components/Students';
import Author from './components/Author';
import Start from './components/Start';
import Results from './components/Results';
import Faq from './components/Faq';
import Promo2 from './components/Promo2';
import What from './components/What';
import Bonus from './components/Bonus';
import Prize from './components/Prize';
import Block2 from './components/Block2';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_11_bf3 = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    const scroll2 = () => {
      const section = document.querySelector( '.scrollhere2' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10 && days<1) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{days > 0 ? `${hourzero}${hours+24}` : `${hourzero}${hours}`} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };

    const rendererm = ({hours, minutes, seconds}) => {
      let minuteszero = '';
      let secondszero = '';
      if(minutes<10) {minuteszero = '0'}
      if(seconds<10) {secondszero = '0'}
      return (
          <div>
              <div>{minuteszero}{minutes} <span>:</span></div>
              <div>{secondszero}{seconds}</div>
          </div>
      );
    };
    
    return (
      <>
        <DocumentTitle value='Чёрная пятница от Кати Усмановой - 13 фитнес-программ с выгодой до 89% и розыгрыш путёвки в Дубай.'/>
        <DocumentDescription value='Чёрная пятница от Кати Усмановой! 13 фитнес-программ с выгодой до 89%, чат с куратором, конкурс до/после, розыгрыш призов и путёвки в Дубай' />
        <div className='presale_blackfriday2'>
            <Promo2 scroll={scroll} renderer={renderer} date={date}/>
            <Second />
            <What scroll2={scroll2} />
            <Programs scroll={scroll}/>
            <Block2 scroll={scroll} />
            <Block1 renderer={renderer} />
            {/* <Bonus scroll={scroll}/> */}
            <Prize scroll={scroll}/>
            <Students scroll={scroll} />
            <Block4 />
            <Localplatform renderer={renderer} scroll={scroll} />
            
            <Author />
            <Start renderer={renderer} date={date} />
            {/* <Results /> */}
            <Faq scroll={scroll} />
            <Footer isColor={'#FDFDFD'} />
        </div>
      </>
    );
};