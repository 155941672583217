import React, { useState, useEffect, createContext, useContext } from "react";
import { FormControl, FormErrorMessage, VStack, HStack, Icon, useColorMode, Box, useColorModeValue } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { ArrowRight } from "phosphor-react";
import { URLSearchParamsService, setJWT, fetchWithN8n2, GLOBAL_CONFIG_KEY } from "@com.marathonium/web2-utils";
import _get from "lodash/get";

import { InputPhone } from "../InputPhone";
import { Desktop, Mobile } from "../MediaQueries";
import { Heading1 } from "../Heading";
import { PinInput, PinInputField } from "../PinInput";
import { TextAccent, TextSmall } from "../Text";
import { ButtonPrimary, ButtonText } from "../Button";

import {
  BORDER_RADIUS_30,
  COLOR_PINK,
  FONT_SIZE_16,
  FONT_SIZE_24,
  SPACING_10,
  SPACING_30,
  SPACING_50,
  LOGIN_FORM_CONTAINER_WIDTH,
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_BLACK_OPACITY_05,
  COLOR_BG_PRIMARY_DARK,
  COLOR_BG_PRIMARY_LIGHT,
} from "../../constants/styles";

const TOTAL_STEPS = 2;

function loginBySms(p) {
  const phone = p.replace(/\D+/g, "");

  if (!phone) {
    return Promise.reject(new Error("Введите корректный номер телефона"));
  }

  return fetchWithN8n2("web.user.login_by_sms_request.v1", { phone })
    .then(resp => ({ ...resp, phone }))
    .catch(() => {
      throw new Error("Произошла ошибка. Попробуйте снова");
    });
}

const LoginWidgetContext = createContext();

const LoginWidgetContextProvider = ({ children, onStepChange, colorMode }) => {
  const [confirmState, setConfirmState] = useState(null);
  const { colorMode: themeColorMode } = useColorMode();

  function changeStep(step) {
    typeof onStepChange === "function" &&
      onStepChange({
        total: TOTAL_STEPS,
        current: step,
      });
  }

  useEffect(() => {
    changeStep(1);
  }, []); // eslint-disable-line

  function loginByPhone(phone) {
    return loginBySms(phone).then(resp => {
      setConfirmState(resp);
      changeStep(2);
    });
  }

  function resendCode() {
    return loginBySms(confirmState.phone).then(setConfirmState);
  }

  function confirmCode(code) {
    const extra = URLSearchParamsService.load();

    return fetchWithN8n2("web.user.login_by_sms_confirm.v1", {
      id: confirmState.id,
      code,
      gateway: _get(window, `["${GLOBAL_CONFIG_KEY}"].gateway`),
      ...extra,
    }).then(resp => {
      if (!resp.token) {
        throw new Error("Произошла ошибка. Попробуйте снова");
      }

      return resp;
    });
  }

  function cancelConfirm() {
    setConfirmState(null);
    changeStep(1);
  }

  return (
    <LoginWidgetContext.Provider
      value={{
        confirmState,

        loginByPhone,
        resendCode,
        confirmCode,
        cancelConfirm,

        colorMode: colorMode || themeColorMode,
      }}
    >
      {children}
    </LoginWidgetContext.Provider>
  );
};

export function ConfirmCodeInput({ renderID, onComplete, onChange, isDisabled, colorMode }) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(false);

    const timeout = setTimeout(() => {
      setIsReady(true);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [renderID]);

  if (!isReady) {
    return <Box height={SPACING_50} />;
  }

  return (
    <HStack spacing={["10px", "24px", "30px"]}>
      <PinInput placeholder="" onChange={onChange} autoFocus={true} onComplete={onComplete} isDisabled={isDisabled}>
        <PinInputField colorMode={colorMode} />
        <PinInputField colorMode={colorMode} />
        <PinInputField colorMode={colorMode} />
        <PinInputField colorMode={colorMode} />
      </PinInput>
    </HStack>
  );
}

function ExpiredCodeControl({ repeatButtonText, expiredAt, onRetry, retryButtonProps = {}, isDisabled }) {
  const [expiredAtSeconds, setExpiredAtSeconds] = useState();

  useEffect(() => {
    setExpiredAtSeconds(); // reset expiredAtSeconds when expiredAt is changed
  }, [expiredAt]);

  useEffect(() => {
    if (!expiredAt || expiredAtSeconds <= 0) {
      return;
    }

    const result = Math.floor((new Date(expiredAt).getTime() - new Date().getTime()) / 1000);

    if (!expiredAtSeconds && result) {
      setExpiredAtSeconds(result);
    }

    const timeout = setTimeout(() => {
      setExpiredAtSeconds(result - 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [expiredAt, expiredAtSeconds]);

  if (!expiredAt || expiredAtSeconds <= 0) {
    return (
      <ButtonPrimary onClick={onRetry} disabled={isDisabled} {...retryButtonProps}>
        <TextAccent>{repeatButtonText}</TextAccent>
      </ButtonPrimary>
    );
  }

  return (
    <ButtonPrimary {...retryButtonProps} opacity={0.5} disabled>
      <TextAccent whiteSpace="nowrap">{`${repeatButtonText}: ${expiredAtSeconds}`}</TextAccent>
    </ButtonPrimary>
  );
}

export function ConfirmCodeFormTitle({ title }) {
  return (
    <TextAccent lineHeight="shorter" textAlign="center">
      {title}
    </TextAccent>
  );
}

export function ConfirmCodeError({ error, onClick }) {
  if (!error) {
    return null;
  }

  return (
    <HStack color={COLOR_PINK} spacing={0}>
      <TextSmall>Код не верный, </TextSmall>
      <TextSmall textDecoration="underline" cursor="pointer" onClick={onClick}>
        очистить
      </TextSmall>
    </HStack>
  );
}

export function ConfirmFormButtons({ pageSettings, expiredAt, onRetry, isDisabled, onCancel }) {
  return (
    <HStack w="100%" align="center" spacing={[0, "24px", "30px"]} maxW={LOGIN_FORM_CONTAINER_WIDTH}>
      <ButtonText flexGrow={0} flexShrink={0} onClick={onCancel}>
        <TextAccent>Назад</TextAccent>
      </ButtonText>

      <ExpiredCodeControl
        repeatMessageText={_get(pageSettings, "repeat_message")}
        repeatButtonText={_get(pageSettings, "repeat_button")}
        expiredAt={expiredAt}
        onRetry={onRetry}
        retryButtonProps={{ flexGrow: 1 }}
        isDisabled={isDisabled}
      />
    </HStack>
  );
}

function ConfirmCodeFormContainer({ children, noBg }) {
  const desktopBg = useColorModeValue(COLOR_BG_PRIMARY_LIGHT, COLOR_BG_PRIMARY_DARK);

  return (
    <React.Fragment>
      <Desktop>
        <VStack
          align="center"
          w={LOGIN_FORM_CONTAINER_WIDTH}
          background={noBg ? null : desktopBg}
          borderRadius={BORDER_RADIUS_30}
          p={noBg ? 0 : SPACING_30}
          spacing={SPACING_50}
        >
          {children}
        </VStack>
      </Desktop>
      <Mobile>
        <VStack alignSelf="center" spacing="30px">
          {children}
        </VStack>
      </Mobile>
    </React.Fragment>
  );
}

function ConfirmCodeStep({ onSuccess, noBg }) {
  const { confirmState, resendCode, confirmCode, cancelConfirm, colorMode } = useContext(LoginWidgetContext);
  const { expired_at, page_settings } = confirmState;

  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(null);

  function onSubmit(code) {
    setIsDisabled(true);

    confirmCode(code)
      .then(r => {
        setJWT(r.token);
        return r;
      })
      .then(onSuccess)
      .catch(e => {
        setError(e.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  }

  function onRetry() {
    return resendCode().catch(() => {
      setError(`Не удалось отправить проверочный код. Попробуйте ещё раз`);
    });
  }

  const [pinTimestamp, setPinTimestamp] = useState(new Date().getTime());
  function clearPinInput() {
    setPinTimestamp(new Date().getTime());
    setError(null);
  }

  return (
    <ConfirmCodeFormContainer noBg={noBg}>
      <Mobile>
        <VStack spacing={SPACING_10}>
          <Heading1 textAlign="center" lineHeight="shorter">
            Подтверждение номера
          </Heading1>
          <ConfirmCodeFormTitle title={_get(page_settings, "title")} />
        </VStack>
      </Mobile>
      <Desktop>
        <ConfirmCodeFormTitle title={_get(page_settings, "title")} />
      </Desktop>

      <VStack spacing={SPACING_10}>
        <ConfirmCodeInput
          renderID={pinTimestamp}
          isDisabled={isDisabled}
          onComplete={onSubmit}
          onChange={() => setError(null)}
          colorMode={colorMode}
        />
        <ConfirmCodeError error={error} onClick={clearPinInput} />
      </VStack>

      <ConfirmFormButtons
        pageSettings={page_settings}
        expiredAt={expired_at}
        onRetry={onRetry}
        isDisabled={isDisabled}
        onCancel={cancelConfirm}
      />
    </ConfirmCodeFormContainer>
  );
}

function LoginByPhoneForm({ noBg }) {
  const { loginByPhone, colorMode } = useContext(LoginWidgetContext);

  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);

  function onReady() {
    setDisabled(false);
  }

  function onSubmit({ phone }) {
    setDisabled(true);

    loginByPhone(phone)
      .catch(e => {
        setError(e.message);
      })
      .finally(() => {
        setDisabled(false);
      });
  }

  return (
    <VStack spacing={SPACING_10} align="stretch" w="100%">
      <TextAccent textAlign="center" color={noBg ? COLOR_BLACK : null}>
        По номеру телефона:
      </TextAccent>
      <Formik
        initialValues={{
          phone: "",
        }}
        onSubmit={onSubmit}
      >
        <Form>
          <VStack align="stretch" spacing={SPACING_30} w="100%">
            <Field name="phone">
              {({ field, form }) => (
                <FormControl isRequired isInvalid={error} isDisabled={disabled}>
                  <VStack w="100%">
                    <InputPhone
                      inputProps={field}
                      formikProps={form}
                      onReady={onReady}
                      color={colorMode === "light" ? COLOR_BLACK : COLOR_WHITE}
                      background={colorMode === "light" ? COLOR_WHITE : COLOR_BLACK_OPACITY_05}
                      border={colorMode === "light" ? null : "none"}
                    />

                    <FormErrorMessage color={COLOR_PINK} fontSize={FONT_SIZE_16}>
                      {error}
                    </FormErrorMessage>
                  </VStack>
                </FormControl>
              )}
            </Field>

            <ButtonPrimary type="submit" disabled={disabled}>
              <HStack>
                <TextAccent>Подтвердить</TextAccent>
                <Icon as={ArrowRight} fontSize={FONT_SIZE_24} />
              </HStack>
            </ButtonPrimary>
          </VStack>
        </Form>
      </Formik>
    </VStack>
  );
}

function LoginByPhoneStep({ header, footer, noBg }) {
  const desktopBg = useColorModeValue(COLOR_BG_PRIMARY_LIGHT, COLOR_BG_PRIMARY_DARK);

  return (
    <React.Fragment>
      <Mobile>
        <VStack alignSelf="center">
          {header}

          <VStack spacing="20px" w="100%" maxW="400px">
            <LoginByPhoneForm noBg={noBg} />
            {footer}
          </VStack>
        </VStack>
      </Mobile>

      <Desktop>
        <VStack
          align="stretch"
          w={LOGIN_FORM_CONTAINER_WIDTH}
          background={noBg ? null : desktopBg}
          borderRadius={BORDER_RADIUS_30}
          p={noBg ? 0 : SPACING_30}
          spacing={SPACING_50}
        >
          <VStack spacing={SPACING_30}>
            {header}

            <VStack spacing={SPACING_10} w="100%">
              <LoginByPhoneForm noBg={noBg} />
              {footer}
            </VStack>
          </VStack>
        </VStack>
      </Desktop>
    </React.Fragment>
  );
}

function LoginWidgetView({ onSuccess, header, footer, noBg }) {
  const { confirmState } = useContext(LoginWidgetContext);

  if (confirmState) {
    return <ConfirmCodeStep onSuccess={onSuccess} noBg={noBg} />;
  }

  return <LoginByPhoneStep header={header} footer={footer} noBg={noBg} />;
}

export function LoginWidget({ onSuccess, onStepChange, header, footer, noBg, colorMode }) {
  return (
    <LoginWidgetContextProvider onStepChange={onStepChange} colorMode={colorMode}>
      <LoginWidgetView onSuccess={onSuccess} header={header} footer={footer} noBg={noBg} />
    </LoginWidgetContextProvider>
  );
}
