import { useEffect } from "react";

import { URLSearchParamsService } from "../../services/URLSearchParamsService";

export function URLSearchParamsCatcher() {
  useEffect(() => {
    URLSearchParamsService.save();
  }, []); // eslint-disable-line

  return null;
}
