import styles from './start.module.css';
import { LoginWidget, SubscriptionWidget, addUserToGroup, ShowForSegment, HideForSegment, ShowForGroup, HideForGroup } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";

const Start = () => {
    function LoginForm() {
        return (
          <>
            <Authorized>
                <ShowForSegment id={window.lps_settings.segments.stretch_segment}>
                <div className={styles.subs}>
                    <div className={styles.subs_left}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/subs.jpg" alt="" />
                    </div>
                    <div className={styles.subs_right}>
                        <div className={styles.subs_title}>НЕДЕЛЯ РАСТЯЖКИ</div>
                        <div className={styles.subs_text}>Так как у&nbsp;вас уже есть активная подписка, вы имеете доступ ко&nbsp;всем программам каталога GymTeam, в&nbsp;том числе и&nbsp;в&nbsp;растяжке.</div>
                        <a href="https://gymteam.ru/my" target='_blank' className={styles.subs_link}>Перейти в каталог</a>
                    </div>
                </div>
                </ShowForSegment>
                <HideForSegment id={window.lps_settings.segments.stretch_segment}>
                <ShowForGroup id={window.lps_settings.user_groups.stretch}>
                    <div className={styles.download}>
                    <div className={styles.download_left}>
                        <div className={styles.download_title}>Скачайте приложение</div>
                        <div className={styles.download_subtitle}>и&nbsp;получите доступ к&nbsp;49-ти программам</div>
                        <div className={styles.download_info}>
                            <div className={styles.download_item}>
                                <p>1. Скачивайте приложение:</p>
                                <div>
                                    <a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android&utm_source=gymteam-site&utm_campaign=bange&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pli=1" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/andmob.png" alt="" /></a>
                                    <a href="https://apps.apple.com/ru/app/id1533404428" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/appmob.png" alt="" /></a>
                                </div>
                            </div>
                            <div className={styles.download_item}>
                                <p>2. Войдите, используя ваш номер телефона</p>
                            </div>
                            <div className={styles.download_item}>
                                <p>3. Выбирайте программу и&nbsp;тренируйтесь</p>
                            </div>
                            <div className={styles.download_item}>
                                <p>4. Оформляйте подписку на&nbsp;полный каталог</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.download_right}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/download.jpg" alt="" />
                    </div>
                </div>
                <div className={styles.subs}>
                    <div className={styles.subs_left}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/subs.jpg" alt="" />
                    </div>
                    <div className={styles.subs_right}>
                        <div className={styles.subs_title}>ОФОРМИТЕ ПОДПИСКУ</div>
                        <div className={styles.subs_text}>и&nbsp;получите доступ к&nbsp;49-ти программам</div>
                        <div className={`${styles.subs_form} stretch_subs_form`}>
                            <SubscriptionWidget onLogin={()=>{window.yaCounter70253455.reachGoal('stretching-reg')}} />
                        </div>
                    </div>
                </div>
                </ShowForGroup>
                <HideForGroup id={window.lps_settings.user_groups.stretch}>
                    <div className={styles.reg}>
                        <div className={styles.reg_left}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/str1.jpg" alt="" />
                        </div>
                        <div className={styles.reg_right}>
                            <div className={styles.reg_right_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/gtv/gtv_logo.svg' alt="" /></div>
                            <div className={styles.reg_right_title}>Принять участие</div>
                            <div className={styles.reg_right_text}>Неделя растяжки прошла с 19 по 25 июня 2023 года. <a href='https://gymteam.ru/'>Перейти в фитнес-каталог</a></div>
                            <div className={styles.reg_right_form}>
                                <div className={styles.reg_right_btn} >ЗАБРАТЬ НЕДЕЛЮ РАСТЯЖКИ</div>
                            </div>
                        </div>
                    </div>
                </HideForGroup>
                </HideForSegment>
            </Authorized>
            <Anonymous>
                <div className={styles.reg}>
                    <div className={styles.reg_left}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/str1.jpg" alt="" />
                    </div>
                    <div className={styles.reg_right}>
                        <div className={styles.reg_right_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/gtv/gtv_logo.svg' alt="" /></div>
                        <div className={styles.reg_right_title}>ЗАРЕГИСТРИРУЙТЕСЬ</div>
                        <div className={styles.reg_right_text}>Введите свой номер телефона и&nbsp;нажмите кнопку «Зарегистрироваться»</div>
                        <div className={`${styles.reg_right_form} stretch_reg_form`}>
                            <LoginWidget />
                        </div>
                        <a href="https://gymteam.ru/legal/public" rel="noreferrer" target='_blank'>Правила пользования</a>
                        <p>Вам придёт звонок для&nbsp;подтверждения номера телефона</p>
                    </div>
                </div>
            </Anonymous>
          </>
        );
      }

    return (
        <div className={`scrollhere ${styles.start}`}>
            <div className="stretch_container">
                <LoginForm />
            </div>
            <div className={styles.trainers}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2023/stretch/trainers.jpg" alt="" />
            </div>
        </div>
    )
}

export default Start;