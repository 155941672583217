import styles from './preims.module.css';

const Preims = () => {
    return (
        <div className={styles.preims}>
            <div className="stretch_container">
                <div className={styles.block}>
                    <div className={styles.title}>19&nbsp;—&nbsp;25&nbsp;июня<br/> БЕСПЛАТНая НЕДЕЛЯ РАСТЯЖКИ</div>
                    <div className={styles.subtitle}>Принимайте участие в&nbsp;акции, скачивайте<br/> приложение и&nbsp;занимайтесь бесплатно</div>
                    <ul>
                        <li>Регистрируйтесь на&nbsp;этой странице</li>
                        <li>Скачайте мобильное приложение</li>
                        <li>Получите десятки тренировок по&nbsp;растяжке</li>
                        <li>Занимайтесь!</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Preims;