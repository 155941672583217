import React from "react";
import { IF, THEN, ELSE } from "@com.marathonium/web2-utils";

import { checkUserInGroup, checkUserInSegment, useUserGroupsChangedLastKey } from "../../helpers/user";

export function ShowForGroup({ id, children }) {
  const key = `${id}_${useUserGroupsChangedLastKey()}`;
  return (
    <IF condition={checkUserInGroup(id)} key={key}>
      <THEN>{children}</THEN>
    </IF>
  );
}

export function HideForGroup({ id, children }) {
  const key = `${id}_${useUserGroupsChangedLastKey()}`;
  return (
    <IF condition={checkUserInGroup(id)} key={key}>
      <ELSE>{children}</ELSE>
    </IF>
  );
}

export function ShowForSegment({ id, children }) {
  return (
    <IF condition={checkUserInSegment(id)}>
      <THEN>{children}</THEN>
    </IF>
  );
}

export function HideForSegment({ id, children }) {
  return (
    <IF condition={checkUserInSegment(id)}>
      <ELSE>{children}</ELSE>
    </IF>
  );
}
