import React, { useEffect, useState } from "react";
import { Box, Link } from "@chakra-ui/react";
import { getReferralCode, getReferralLink } from "../../helpers/user";

export function ReferralCode({ placeholder, ...props }) {
  const [result, setResult] = useState();

  useEffect(() => {
    getReferralCode().then(r => {
      setResult(r.code);
    });
  }, []);

  return <Box {...props}>{result || placeholder}</Box>;
}

export function ReferralLink({ children, placeholder, ...props }) {
  const [link, setLink] = useState();

  useEffect(() => {
    getReferralLink().then(r => {
      setLink(r.link);
    });
  }, []);

  return (
    <Link href={link} {...props}>
      {link ? children || link : placeholder || children}
    </Link>
  );
}
