import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_title}>ЖИРОСЖИГАЮЩИЙ КУРС —<br/> <span>программа, которая подстраивается под&nbsp;вас</span></div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>3&nbsp;КЛАССА ТРЕНИРОВОК</div>
                            <div className={styles.whaityou_info__text}>Силовой, умеренный и&nbsp;интенсивный классы — вы&nbsp;выбираете тот, который больше подходит под&nbsp;ваши цели и&nbsp;уровень подготовки. Например, новичкам подойдёт умеренный класс, чтобы&nbsp;быстро похудеть и&nbsp;подтянуть всё тело.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ЗАНИМАЕМСЯ В&nbsp;ФОРМАТЕ «ПОВТОРЯЙ ЗА&nbsp;КАТЕЙ» 🤸‍♀️</div>
                            <div className={styles.whaityou_info__text}>Ежедневно в&nbsp;личном кабинете будет открываться новая тренировка. Вам не&nbsp;придётся менять свой график — на&nbsp;выполнение тренировки есть целые сутки. Просто включайте видео, слушайте комментарии по&nbsp;технике от&nbsp;Кати Усмановой и&nbsp;повторяйте за&nbsp;моделью. Мы дадим вам много крутых связок упражнений — скучно не&nbsp;будет!</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ВСЕГО 20-30 МИНУТ НА&nbsp;ТРЕНИРОВКУ</div>
                            <div className={styles.whaityou_info__text}>Такие занятия точно впишутся в&nbsp;ваш распорядок дня, ведь&nbsp;вам не&nbsp;потребуется тратить время на&nbsp;дорогу. Вы сможете тренироваться и&nbsp;дома, и&nbsp;в&nbsp;любом другом подходящем месте.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;