import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Курс точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Хотите как можно скорее привести попу в&nbsp;порядок, <strong>избавиться от&nbsp;целлюлита</strong></li>
                            <li>Готовы тренироваться интенсивно, <strong>соблюдая все рекомендации</strong></li>
                            <li>Понимаете, что это другой уровень подхода к&nbsp;тренировкам и&nbsp;<strong>готовы выкладываться на&nbsp;каждом этапе</strong></li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Хотите добавить объёмов, чтобы <strong>сделать фигуру спортивной и&nbsp;соблазнительной</strong></li>
                            <li>Испытываете трудности <strong>в&nbsp;наборе мышечной массы или&nbsp;похудении</strong></li>
                            <li>Не обладаете <strong>большим избыточным весом</strong> (более&nbsp;10&nbsp;кг)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;