import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Марафон точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Хотите получить результат, <strong>но&nbsp;не&nbsp;знаете,&nbsp;с&nbsp;чего&nbsp;начать</strong></li>
                            <li><strong>Профессионал</strong>, хотите быстро вернуть рельеф</li>
                            <li>Давно мечтаете похудеть, но&nbsp;никак не&nbsp;<strong>можете дойти до&nbsp;цели</strong></li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Хотите добавить объёмов, чтобы&nbsp;сделать фигуру <strong>спортивной и&nbsp;соблазнительной</strong></li>
                            <li><strong>Готовы сделать</strong> животик плоским, попу упругой и&nbsp;накаченной, талию тонкой</li>
                            <li>Не знаете, <strong>где найти мотивацию</strong>, чтобы&nbsp;начать и&nbsp;не&nbsp;бросить</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;