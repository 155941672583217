import './aboutyou.css';

const Aboutyou = () => {
    return (
        <div className="aboutyou">
            <div className="container">
            <div className="aboutyou_title">Вы создали новую жизнь,<br/> пришло <span>время подумать о&nbsp;себе</span></div>
            <div className="aboutyou_block">
                <div className="aboutyou_item">
                <div className="aboutyou_item__img">
                    <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/aboutyou_1.png' alt="" />
                </div>
                <div className="aboutyou_item__title">20&nbsp;минут только<br/> для себя</div>
                <p>Тренировки в удобном режиме, возможность отвлечься от&nbsp;быта и&nbsp;рутины</p>
                </div>
                <div className="aboutyou_item">
                <div className="aboutyou_item__img">
                    <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/aboutyou_2.png' alt="" />
                </div>
                <div className="aboutyou_item__title">Рекомендации по&nbsp;фитнес-питанию</div>
                <p>Вкусно и&nbsp;полезно для вас, безопасно для малыша на&nbsp;грудном вскармливании. Одобрено профессионалами</p>
                </div>
                <div className="aboutyou_item">
                <div className="aboutyou_item__img">
                    <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/aboutyou_3.png' alt="" />
                </div>
                <div className="aboutyou_item__title">С учётом всех особенностей</div>
                <p>Всё, чтобы избавиться от&nbsp;лишних&nbsp;кг и&nbsp;подтянуть тело, предотвратить проблемы со&nbsp;здоровьем</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Aboutyou;