import React, { useEffect } from 'react';

import { Center } from "@chakra-ui/react";
import { LoginWidget,  ReferralCode, ReferralLink, SalesWidget, getReferralLink, getReferralCode } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized, useInstance } from "@com.marathonium/web2-utils";

export const Refregpage = () => {
    
    function LoginForm() {
        return (
          <>
            <Authorized>
                <div style={{width: 1400, margin: '0 auto', paddingTop: 60}}>Вы авторизованы</div>
            </Authorized>
            <Anonymous>
                <div style={{width: 470, margin: '0 auto', paddingTop: 60}}>
                    <div style={{marginBottom: 40, textAlign: 'center', fontSize: 24}}>Страница для друзей</div>
                    <LoginWidget />
                </div>
            </Anonymous>
          </>
        );
      }

    return (
      <LoginForm/>
    );
};