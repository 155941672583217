import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className="container">
                <div className='l2211_str1_title l2211_str1_title_create'>СОЗДАЙТЕ ТЕЛО С НУЛЯ<br/> <span>ПО СИСТЕМЕ КАТИ УСМАНОВОЙ</span></div>
                <div className='l2211_str1_subtitle'>Если вы когда-то пытались похудеть, то&nbsp;знакомы с формулой:<br/> <strong>полтора часа кардио + пищевая плёнка на&nbsp;талии + гречка с&nbsp;кефиром</strong></div>
                <div className={styles.create_blue}>Такие методы похудения всегда заканчиваются перетренированностью и срывом. <strong>Но как ещё похудеть в&nbsp;домашних условиях?</strong></div>
                <div className={styles.create_green}><strong>Стройность 1.0&nbsp;—</strong> идеальный способ подтянуть всё тело. <strong>Дома, за&nbsp;20&nbsp;минут в&nbsp;день, без инвентаря!</strong></div>
            </div>
        </div>
    )
}

export default Createbody;