import { SalesWidget, addUserToGroup } from '@com.marathonium/web2-ui';
import Countdown from 'react-countdown';
import styles from './style.module.css'
import { useEffect } from 'react';
import Reviews from "./reviews";

const Land = ({date, renderer, hide, version, scroll, auth}) => {
    useEffect(()=>{
        if(hide !== true) {
            addUserToGroup(window.lps_settings.user_groups.fatlost_n5_watched)
        }
    }, [])

    return (
        <div className={styles.land_main}>
        <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/logo.svg" alt="" /></div>
            <div className={styles.title}>Подпишитесь на&nbsp;все программы GymTeam со&nbsp;скидкой до&nbsp;65% c&nbsp;учетом баллов! <span>Стоимость для вас без учета заработанных баллов <i>1&nbsp;690</i> 1&nbsp;190&nbsp;₽</span></div>
            {!auth &&
                <div className={styles.block1}>
                {hide != true &&
                <>
                    <div className={styles.block1_title}>САМОЕ ВРЕМЯ ПОТРАТИТЬ ЗАРАБОТАННЫЕ БАЛЛЫ, ПОКА ОНИ НЕ&nbsp;СГОРЕЛИ!</div>
                    {version == 2 && 
                    <div className={styles.block1_subtitle}>Вы набрали <span>0</span> баллов. Поэтому цена подписки для вас <span>1&nbsp;190&nbsp;₽</span> в&nbsp;месяц!</div>
                    }
                    {version == 3 && 
                    <div className={styles.block1_subtitle}>Вы набрали <span>100</span> баллов. Поэтому цена подписки для вас всего <span>1&nbsp;090&nbsp;₽</span> за&nbsp;1&nbsp;месяц!</div>
                    }
                    {version == 4 && 
                    <div className={styles.block1_subtitle}>Вы набрали <span>300</span> баллов. Поэтому цена подписки для вас всего <span>890&nbsp;₽</span> за&nbsp;1&nbsp;месяц!</div>
                    }
                    {version == 5 && 
                    <div className={styles.block1_subtitle}>Вы набрали <span>600</span> баллов. Поэтому цена подписки для вас всего  <span>590&nbsp;₽</span> за&nbsp;1&nbsp;месяц!</div>
                    }
                    </>
                }
                
                    <div className={styles.block1_countdown}>
                        <p>Предложение действительно:</p>
                        <div className={styles.block1_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                
                </div>}
            
                <div className={styles.subtitle}>Стоимость сохранится для вас навсегда, включая новые программы!<br/> Отменить подписку можно в&nbsp;любой момент.</div>
                <div className={styles.btn1} onClick={scroll}>Оформить подписку</div>
                <div className={styles.list}>
                    <p>GymTeam подходит для тех, кто:</p>
                    <ul>
                        <li>хочет быстро стать стройным не&nbsp;в&nbsp;ущерб здоровью</li>
                        <li>много работает и у&nbsp;него почти не&nbsp;остается времени на&nbsp;спорт</li>
                        <li>считает, что и&nbsp;в&nbsp;молодости, да и&nbsp;после 50&nbsp;физическая активность еще более важна&nbsp;– и&nbsp;мы в&nbsp;GymTeam уделили этому много внимания</li>
                        <li>хочет заниматься там, где ему удобно&nbsp;– на&nbsp;работе (у нас есть программы для офиса!), дома, в&nbsp;отпуске, на&nbsp;улице или в&nbsp;спортзале</li>
                        <li>понимает, что занимается в&nbsp;первую очередь для&nbsp;себя любимой</li>
                    </ul>
                </div>
                <Reviews scroll={scroll} />
                {/* {version != 1 &&  */}
                <div className={('scrollhere ') + styles.block2}>
                    
                    
                        <div className={styles.block2_title}>Подписка на&nbsp;все<br/> программы GymTeam</div>
                        {version == 1 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>1&nbsp;690</span> 1&nbsp;190&nbsp;₽</div>
                        }
                        {version == 2 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>1&nbsp;690</span> 1&nbsp;190&nbsp;₽</div>
                        }
                        {version == 3 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>1&nbsp;690</span> 1&nbsp;090&nbsp;₽</div>
                        }
                        {version == 4 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>1&nbsp;690</span> 890&nbsp;₽</div>
                        }
                        {version == 5 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>1&nbsp;690</span> 590&nbsp;₽</div>
                        }
                        {version != 1 &&
                        <div className={styles.block2_countdown}>
                            <p>Предложение действительно:</p>
                            <div className={styles.block2_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                        }
                        <div className="year1_start">
                            {version == 1 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n5_1_id} />
                            }
                            {version == 2 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n5_1_id} />
                            }
                            {version == 3 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n5_2_id} />
                            }
                            {version == 4 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n5_3_id} />
                            }
                            {version == 5 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n5_4_id} />
                            }
                            
                        </div>                    
                </div>
                {/* } */}
        </div>
    )
}

export default Land;