import React from "react";
import { Heading as CUHeading } from "@chakra-ui/react";
import { FONT_SIZE_28, FONT_SIZE_36, FONT_SIZE_48 } from "../../constants/styles";

function Heading(props) {
  return <CUHeading whiteSpace="break-spaces" {...props} />;
}

export function Heading1(props) {
  return <Heading fontSize={FONT_SIZE_48} fontWeight={["500", "700"]} {...props} />;
}

export function Heading2(props) {
  return <Heading fontSize={FONT_SIZE_36} fontWeight="400" {...props} />;
}

export function Heading3(props) {
  return <Heading fontSize={FONT_SIZE_28} fontWeight="500" {...props} />;
}
