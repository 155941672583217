import './signup.css';

const Signup = () => {
    return (
        <div className="signup">
          <div className="container">
            <div className="signup_title"><span>ЗАПИСЫВАЙТЕСЬ,</span> ЕСЛИ&nbsp;ВЫ:</div>
            <div className="signup_block">
              <div className="signup_block__item">
                <div className="signup_block__item-info">
                  <div className="signup_block__item-img">
                    <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/sign_1.png' alt="" />
                  </div>
                  <div className="signup_block__item-description"><strong>Хотите сохранить<br/> красоту тела</strong> во&nbsp;время беременности</div>
                </div>
              </div>
              <div className="signup_block__item">
                <div className="signup_block__item-info">
                <div className="signup_block__item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/sign_2.png' alt="" />
                </div>
                
                <div className="signup_block__item-description"><strong>Мечтаете быстро вернуть прежнюю форму</strong> после родов</div>
                </div>
              </div>
              <div className="signup_block__item">
                <div className="signup_block__item-info">
                <div className="signup_block__item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/sign_3.png' alt="" />
                </div>
                
                <div className="signup_block__item-description"><strong>Желаете оставаться привлекательной</strong><br/> и&nbsp;сексуальной</div>
                </div>
              </div>
              <div className="signup_block__item">
                <div className="signup_block__item-info">
                <div className="signup_block__item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/sign_4.png' alt="" />
                </div>
                <div className="signup_block__item-description"><strong>Боитесь проблем<br/> с&nbsp;диастазом</strong><br/> и&nbsp;растяжками</div>
                </div>
              </div>
              <div className="signup_block__item">
                <div className="signup_block__item-info">
                <div className="signup_block__item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/sign_5.png' alt="" />
                </div>
                <div className="signup_block__item-description"><strong>Уже знаете, как много<br/> сил и&nbsp;здоровья</strong> нужно молодой маме</div>
                </div>
              </div>
              <div className="signup_block__item">
                <div className="signup_block__item-info">
                <div className="signup_block__item-img">
                  <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/sign_6.png' alt="" />
                </div>
                <div className="signup_block__item-description"><strong>Запутались в&nbsp;чужих советах</strong> — что можно,<br/> а&nbsp;что&nbsp;нельзя</div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Signup;