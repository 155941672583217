import { useState } from 'react';
import './howcover.css';

const Howcover = ({scroll}) => {
    const [play, setPlay] = useState(false);
    const url = play ? "?autoplay=1" : '';
    
    return (
        <div className="howrecover">
            <div className="container">
            <div className="howrecover_title">Как Катя восстановилась<br/> после родов <span>за&nbsp;100&nbsp;дней?</span></div>
            <div className="howrecover_block">
                <div className="howrecover_item">
                <p><strong>Она прошла через это сама,<br/></strong> поэтому точно знает, как женский организм приходит в&nbsp;себя после рождения ребёнка.</p>
                </div>
                <div className="howrecover_item">
                <p>Создала программу, <strong>чтобы каждая мама могла вернуться в&nbsp;дородовую форму,</strong> избежать проблем со здоровьем и&nbsp;вновь полюбить своё тело.</p>
                </div>
            </div>
            <div className='howrecover_video'>
                <div className={'author_block__video-preview' + (play ? ' hide' : '')} onClick={() => setPlay(true)}></div>
                <iframe src={'https://kinescope.io/embed/202239656'+url} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowFullScreen></iframe>
                
            </div>
            <button className='main_btn' onClick={scroll}>Записаться</button>
            </div>
        </div>
    )
}

export default Howcover;