import styles from './onmarafon.module.css';

const Onmarafon = () => {
    return (
        <div className={styles.onmarafon}>
            <div className="container">
                <div className={styles.onmarafon_title}>СОЗДАТЬ КРАСИВЫЙ<br/> И РЕЛЬЕФНЫЙ ПРЕСС&nbsp;—<br/> <span>НЕСЛОЖНО</span></div>
                <div className={styles.onmarafon_subtitle}>Но часто девушки совершают одни&nbsp;и&nbsp;те&nbsp;же&nbsp;ошибки:</div>
                <div className={styles.onmarafon_block}>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/onm_1.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Не&nbsp;пробуют новые упражнения,</strong><br/> а&nbsp;чередуют скручивания<br/> с&nbsp;подъемом ног</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/onm_4.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Забывают о&nbsp;систематичности</strong><br/> и&nbsp;качают пресс только&nbsp;когда<br/> «остаются силы»</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str1/onm_3.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Бросают упражнение</strong><br/> сразу после&nbsp;появления жжения<br/> в&nbsp;мышцах</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/onm_2.svg" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Не следят за питанием</strong><br/> и&nbsp;потребляют больше или&nbsp;столько<br/> же&nbsp;калорий, сколько тратят</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onmarafon;