import React from "react";
import { Icon } from "@chakra-ui/react";

export function WorldCard(props) {
  return (
    <Icon viewBox="0 0 48 48" {...props}>
      <g clipPath="url(#clip0_8593_18065)">
        <path
          d="M26 39C26.5523 39 27 38.5523 27 38C27 37.4477 26.5523 37 26 37V39ZM26 37H10V39H26V37ZM7 34V14H5V34H7ZM10 11H38V9H10V11ZM41 14V23.5H43V14H41ZM38 11C39.6569 11 41 12.3431 41 14H43C43 11.2386 40.7614 9 38 9V11ZM7 14C7 12.3431 8.34315 11 10 11V9C7.23858 9 5 11.2386 5 14H7ZM10 37C8.34315 37 7 35.6569 7 34H5C5 36.7614 7.23858 39 10 39V37Z"
          fill="black"
        />
        <path d="M6 23.5H42V18.5H6V23.5Z" fill="black" />
        <path
          d="M47.5309 38.7077L47.554 38.6308C48.149 36.9274 48.149 35.0726 47.554 33.3692L47.5309 33.2923C46.9695 31.7446 45.946 30.4068 44.599 29.4602C43.2519 28.5136 41.6465 28.0038 40.0001 28C38.3532 28.0016 36.7468 28.5105 35.3994 29.4574C34.0519 30.4044 33.0289 31.7434 32.4694 33.2923L32.4463 33.3692C31.8512 35.0726 31.8512 36.9274 32.4463 38.6308L32.4694 38.7077C33.0289 40.2566 34.0519 41.5956 35.3994 42.5426C36.7468 43.4895 38.3532 43.9984 40.0001 44C41.647 43.9984 43.2535 43.4895 44.6009 42.5426C45.9484 41.5956 46.9714 40.2566 47.5309 38.7077ZM38.0386 39.0769H41.9617C41.5675 40.3005 40.8962 41.4167 40.0001 42.3385C39.1041 41.4167 38.4328 40.3005 38.0386 39.0769ZM37.7155 37.8462C37.4797 36.6267 37.4797 35.3733 37.7155 34.1538H42.2848C42.4019 34.7623 42.4611 35.3804 42.4617 36C42.4615 36.6196 42.4022 37.2378 42.2848 37.8462H37.7155ZM33.2309 36C33.2322 35.376 33.3176 34.755 33.4848 34.1538H36.4617C36.2566 35.3761 36.2566 36.6239 36.4617 37.8462H33.4848C33.3176 37.245 33.2322 36.624 33.2309 36ZM41.9617 32.9231H38.0386C38.4328 31.6995 39.1041 30.5833 40.0001 29.6615C40.8962 30.5833 41.5675 31.6995 41.9617 32.9231ZM43.5386 34.1538H46.5155C46.8541 35.3613 46.8541 36.6387 46.5155 37.8462H43.5386C43.7437 36.6239 43.7437 35.3761 43.5386 34.1538ZM46.0309 32.9231H43.2617C42.8916 31.6272 42.2607 30.4205 41.4078 29.3769C42.3926 29.5896 43.318 30.0179 44.1174 30.6311C44.9168 31.2442 45.5703 32.027 46.0309 32.9231ZM38.5924 29.3769C37.7396 30.4205 37.1087 31.6272 36.7386 32.9231H33.9694C34.43 32.027 35.0835 31.2442 35.8829 30.6311C36.6823 30.0179 37.6077 29.5896 38.5924 29.3769ZM33.9694 39.0769H36.7386C37.1087 40.3728 37.7396 41.5795 38.5924 42.6231C37.6071 42.412 36.681 41.9842 35.8814 41.3709C35.0817 40.7575 34.4286 39.9739 33.9694 39.0769ZM41.4078 42.6231C42.2607 41.5795 42.8916 40.3728 43.2617 39.0769H46.0309C45.5716 39.9739 44.9185 40.7575 44.1189 41.3709C43.3193 41.9842 42.3932 42.412 41.4078 42.6231Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_8593_18065">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
