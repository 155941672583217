import './inventory.css';

const Inventory = () => {
    return (
        <div className="inventory">
          <div className="container">
            <div className="inventory_title">Какой инвентарь нужен? 👇</div>
            <ul>
              <li>Коврик</li>
              <li>Фитбол 55-60 см</li>
              <li>Фитнес-резинка или ремень для йоги</li>
              <li>2 гантели весом 1-2 кг</li>
              <li>Мяч для пилатеса</li>
              <li>Ролл для пилатеса</li>
              <li>Шипованный мяч</li>
              <li>Блоки для йоги (можно заменить толстыми книгами)</li>
              <li>Плед</li>
            </ul>
          </div>
        </div>
    );
};

export default Inventory;