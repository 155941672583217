import React from "react";
import { Button as CUButton } from "@chakra-ui/react";

import {
  BORDER_RADIUS_10,
  BORDER_RADIUS_20,
  COLOR_BLACK_OPACITY_025,
  COLOR_BLACK_OPACITY_05,
  COLOR_PINK,
  COLOR_PINK_HOVER,
  COLOR_WHITE,
  COLOR_WHITE_OPACITY_01,
  COLOR_WHITE_OPACITY_025,
  FONT_SIZE_16,
  FONT_SIZE_25,
  SPACING_20,
  SPACING_25,
  SPACING_40,
  SPACING_65,
} from "../../constants";

function ButtonBase(props) {
  return (
    <CUButton
      h={SPACING_65}
      fontSize={FONT_SIZE_25}
      px={SPACING_25}
      fontWeight="500"
      borderRadius={BORDER_RADIUS_20}
      _focus={{ outline: "none" }}
      {...props}
    />
  );
}

function ButtonSmall(props) {
  return (
    <CUButton
      h={SPACING_40}
      fontSize={FONT_SIZE_16}
      px={SPACING_20}
      fontWeight="400"
      borderRadius={BORDER_RADIUS_10}
      _focus={{ outline: "none" }}
      {...props}
    />
  );
}

export function ButtonPrimary({ Component = ButtonBase, ...props }) {
  return (
    <Component
      bgColor={COLOR_PINK}
      color={COLOR_WHITE}
      _active={{
        backgroundColor: COLOR_PINK,
      }}
      _hover={{
        backgroundColor: COLOR_PINK_HOVER,
      }}
      {...props}
    />
  );
}

export function ButtonPrimarySmall(props) {
  return <ButtonPrimary Component={ButtonSmall} {...props} />;
}

export function ButtonSecondary({ Component = ButtonBase, ...props }) {
  return (
    <Component
      bgColor={COLOR_BLACK_OPACITY_025}
      _active={{
        backgroundColor: COLOR_BLACK_OPACITY_025,
      }}
      _hover={{
        backgroundColor: COLOR_BLACK_OPACITY_05,
      }}
      color={COLOR_WHITE}
      {...props}
    />
  );
}

export function ButtonSecondarySmall(props) {
  return <ButtonSecondary Component={ButtonSmall} {...props} />;
}

export function ButtonText({ Component = ButtonBase, ...props }) {
  return (
    <Component
      bgColor="transparent"
      _active={{
        backgroundColor: COLOR_WHITE_OPACITY_01,
      }}
      _hover={{
        backgroundColor: COLOR_WHITE_OPACITY_025,
      }}
      {...props}
    />
  );
}

export function ButtonTextSmall(props) {
  return <ButtonText Component={ButtonSmall} {...props} />;
}

export function Button({ primary = true, secondary = false, text = false, small = false, ...props }) {
  const baseComponent = small ? ButtonSmall : ButtonBase;

  switch (true) {
    case text:
      return <ButtonText Component={baseComponent} {...props} />;
    case secondary:
      return <ButtonSecondary Component={baseComponent} {...props} />;
    case primary:
    default:
      return <ButtonPrimary Component={baseComponent} {...props} />;
  }
}
