import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import styles from "./students.module.css";

const Students = ({scroll}) => {
  const items = [
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/5.jpg",
      name: "Екатерина",
      weight: "65 → 60",
      chest: "90 → 86",
      waist: "76 → 68",
      hips: "106 → 96",
      id: "fdc6416e-1b64-4ea1-8786-9e80ec1fca20",
      point: 970,
      place: 1,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/20.jpg",
      name: "Алина",
      weight: "67,5 → 64",
      chest: "96 → 101",
      waist: "92 → 80",
      hips: "104 → 91",
      id: "3db28979-4150-40e5-b6ab-9c7b1c29f8da",
      point: 577,
      place: 2,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/3.jpg",
      name: "Анастасия",
      weight: "61,8 → 57,4",
      chest: "85 → 82",
      waist: "74 → 63",
      hips: "55 → 50",
      id: "24e225d0-c41b-46db-b6df-0014292c1a78",
      point: 253,
      place: 3,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/18.jpg",
      name: "Екатерина",
      weight: "76 → 71",
      chest: "99 → 96",
      waist: "90 → 82",
      hips: "112 → 105",
      id: "ef46ffb4-16fe-4228-8938-33ad932d263e",
      point: 144,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/1.jpg",
      name: "Елена",
      weight: "62 → 55,4",
      chest: "89 → 86",
      waist: "76 → 66",
      hips: "96 → 94",
      id: "690800ee-e34f-4f22-bc0e-cf2d1e9d61ef",
      point: 141,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/7.jpg",
      name: "Юлия",
      weight: "63,7 → 62,3",
      chest: "92 → 92",
      waist: "79 → 71",
      hips: "100 → 98",
      id: "a7a9ce33-92d2-475f-b05e-2c596623e3ba",
      point: 109,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/6.jpg",
      name: "Влада",
      weight: "60,4 → 57,1",
      chest: "91 → 86",
      waist: "78 → 74",
      hips: "98 → 95",
      id: "73756424-7e6b-45e2-9d58-4d47cf163e8f",
      point: 64,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/12.jpg",
      name: "Светлана",
      weight: "95 → 89",
      chest: "111 → 106",
      waist: "92 → 83",
      hips: "120 → 116",
      id: "418997d4-de16-40fb-b3e1-528c7186f188",
      point: 63,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/9.jpg",
      name: "Екатерина",
      weight: "70,3 → 69",
      chest: "89 → 94",
      waist: "85 → 80",
      hips: "108 → 105",
      id: "ebee274a-ee54-4fb5-bcb7-bbd9d38c2667",
      point: 54,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/2.jpg",
      name: "Наталья",
      weight: "80,5 → 75",
      chest: "104 → 97",
      waist: "82 → 75",
      hips: "115 → 109",
      id: "f3a643f7-cdd6-4603-a68c-ce2f95d44ec2",
      point: 38,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/15.jpg",
      name: "Валерия",
      weight: "64 → 62",
      chest: "85 → 86",
      waist: "74 → 71",
      hips: "100 → 100",
      id: "04fae863-fb20-4ef1-8501-b98ace02ca01",
      point: 37,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/17.jpg",
      name: "Екатерина",
      weight: "62 → 58",
      chest: "90 → 88",
      waist: "78 → 75",
      hips: "98 → 94",
      id: "9fc0a0e7-fc25-4e94-83db-7f1350253db4",
      point: 31,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/4.jpg",
      name: "Анастасия",
      weight: "75 → 70",
      chest: "96 → 90",
      waist: "86 → 80",
      hips: "106 → 102",
      id: "4623ada0-76a8-4432-a2e8-23c9ac1021ae",
      point: 29,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/10.jpg",
      name: "Альбина",
      weight: "63,7 → 61,3",
      chest: "90 → 87",
      waist: "72 → 70",
      hips: "101 → 98",
      id: "1b437b1d-b1eb-4679-8683-9977058cb6dd",
      point: 25,
    },

    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/13.jpg",
      name: "Ольга",
      weight: "70 → 63",
      chest: "100 → 98",
      waist: "91 → 83",
      hips: "100 → 98",
      id: "a2838023-47f4-491a-941d-4ef086a01d96",
      point: 21,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/19.jpg",
      name: "Вероника",
      weight: "57,4 → 53,2",
      chest: "88 → 86",
      waist: "78 → 73",
      hips: "102 → 98",
      id: "d6a2de06-813f-4c8b-abcd-63a368f3330d",
      point: 16,
    },

    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/11.jpg",
      name: "Екатерина",
      weight: "87 → 85,1",
      chest: "104 → 101",
      waist: "86 → 82",
      hips: "114 → 112",
      id: "542b2c5e-a34c-4602-9a99-f57168269a67",
      point: 11,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/16.jpg",
      name: "Юлия",
      weight: "63,1 → 61",
      chest: "87 → 84",
      waist: "78 → 75",
      hips: "96 → 96",
      id: "5a00f959-b446-479c-bc0d-9fecaa35b1ab",
      point: 10,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/8.jpg",
      name: "Алена",
      weight: "53 → 51",
      chest: "86 → 78",
      waist: "71 → 67",
      hips: "91 → 89",
      id: "020c7224-7715-4534-be58-b2f52e063639",
      point: 6,
    },
    {
      image:
        "https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/14.jpg",
      name: "Валерия",
      weight: "74,7 → 72,9",
      chest: "100 → 95",
      waist: "85 → 82",
      hips: "109 → 108",
      id: "8a2d27f1-80c7-4187-aeac-4ebfa26b2783",
      point: 2,
    },
  ];

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div className={styles.studentres}>
        <div className="container">
            {/* <div className={styles.pretitle}><b>Лучше 1&nbsp;раз увидеть</b> результаты учениц,</div> */}
            <div className={styles.title}>ОБЫЧНЫЕ РЕЗУЛЬТАТЫ<br/> НАШИХ УЧЕНИЦ</div>
        </div>

      <div className={styles.slider_block}>
      <Swiper
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        loop
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;

            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
        slidesPerView={1}
        spaceBetween={0}
        modules={[Navigation]}
        className={styles.slider}
        breakpoints={{
          576: {
            slidesPerView: 4,
            spaceBetween: 18
          },
          1440: {
            slidesPerView: 4.5,
            spaceBetween: 18
          },
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.slide_img} className={styles.slide}>
            <div className={styles.slide_img}>
              <img src={item.image} alt="" />
              <p>{item.name}</p>
              <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/stars.svg" alt="" /></div>
            </div>
            <div className={styles.slide_info}>
              <div>
                <img
                  src="https://gymteam.kinescopecdn.net/img/l/2024/mu/i1.png"
                  alt=""
                />{" "}
                <b>Вес:</b>&nbsp;{item.weight}
              </div>
              <div>
                <img
                  src="https://gymteam.kinescopecdn.net/img/l/2024/mu/i2.png"
                  alt=""
                />{" "}
                <b>Талия:</b>&nbsp;{item.waist}
              </div>
              <div>
                <img
                  src="https://gymteam.kinescopecdn.net/img/l/2024/mu/i3.png"
                  alt=""
                />{" "}
                <b>Грудь:</b>&nbsp;{item.chest}
              </div>
              <div>
                <img
                  src="https://gymteam.kinescopecdn.net/img/l/2024/mu/i4.png"
                  alt=""
                />{" "}
                <b>Бедра:</b>&nbsp;{item.hips}
              </div>
            </div>
          </SwiperSlide>
        ))}

        
      </Swiper>
            <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/arrow.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/arrow.svg" alt="" />
                        </div>
            </div>
        

            <div className={styles.btn} onClick={scroll}>ХОЧУ ПОЛУЧИТЬ РЕЗУЛЬТАТ</div>
        </div>
    </div>
  );
};

export default Students;
