import Countdown from 'react-countdown';
import { SalesWidget, AuthWidget, ShowForSegment, HideForSegment } from "@com.marathonium/web2-ui";
import { useInstance, Anonymous, Authorized } from "@com.marathonium/web2-utils";
import styles from './form.module.css';
import { useState } from 'react';

const Form = ({date, renderer, version}) => {
    const [chose, setChose] = useState(0)
    const [widget] = useInstance();

    const [play, setPlay] = useState(false);
    const url = play ? "?autoplay=1" : '';

    return (
        <div className={'birthday_usm_form ' + styles.form}>
            <div className="container">
                {version == 1 ? 
                    <div className={styles.form_block}>
                    <div className={styles.form_title}>Присоединяйтесь!</div>
                    <div className={styles.form_block2}>
                        <div className={styles.form_block2_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/form.png" alt="" />
                        </div>
                        <div className={styles.form_block2_info}>
                            <div className={styles.form_block2_title}>Для меня этот день особенный, и&nbsp;именно поэтому<br/> мне особенно приятно делать подарки.</div>
                            <div className={styles.form_block2_text}>{window.lps_settings.other.active_land == 1 ? <>Только 31&nbsp;октября</> : <>Только 01&nbsp;ноября</>} мы&nbsp;предлагаем уникальные скидки на&nbsp;GymTeam. Присоединяйтесь и&nbsp;приглашайте друзей.</div>
                            <div className={styles.form_block2_text}>Физические упражнения могут заменить множество лекарств, но&nbsp;ни&nbsp;одно лекарство в&nbsp;мире не&nbsp;может заменить физические упражнения.</div>
                        </div>
                    </div>
                    <div className={styles.countdown}>
                        <p>{window.lps_settings.other.active_land == 1 ? <>Скидка доступна только 31&nbsp;октября</> : <>Акцию продлили по&nbsp;вашим просьбам!<br/> Скидка доступна только 01&nbsp;ноября</>}</p>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                    <div className={styles.form_hook}>
                        <div className={`${styles.form_hook_item} ${chose == 0 && styles.form_hook_item_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(0); setChose(0) }}>
                            <div className={styles.form_hook_title}>3&nbsp;месяца</div>
                            <div className={styles.form_hook_prices}>
                                <p>2&nbsp;697&nbsp;₽</p>
                            </div>
                        </div>
                        <div className={`${styles.form_hook_item} ${chose == 1 && styles.form_hook_item_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(1); setChose(1) }}>
                            <div className={styles.form_hook_title}>6&nbsp;месяцев <p><span>-30%</span></p></div>
                            <div className={styles.form_hook_prices}>
                                <span>5&nbsp;394&nbsp;₽</span>
                                <p>3&nbsp;771&nbsp;₽</p>
                            </div>
                        </div>
                        <div className={`${styles.form_hook_item} ${chose == 2 && styles.form_hook_item_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(2); setChose(2) }}>
                            <div className={styles.form_hook_title}>12&nbsp;месяцев <p><span>-50%</span></p></div>
                            <div className={styles.form_hook_prices}>
                                <span>10&nbsp;788&nbsp;₽</span>
                                <p>5&nbsp;311&nbsp;₽</p>
                            </div>
                        </div>
                    </div>
                    <SalesWidget id={window.lps_settings.forms.birthday_usm_id} hook={widget} />
                </div>
                :
                <>
                    {/* <Authorized> */}

                    {/* <ShowForSegment id={window.lps_settings.segments.birthday_usm}> */}
                    <div className={styles.form_block} style={{backgroundColor: 'transparent', paddingBottom: 20}}>
                        <div className={styles.form_title}>Подарок от GymTeam</div>
                        <div className={styles.form_block2}>
                            <div className={styles.form_block2_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/form.png" alt="" />
                            </div>
                            <div className={styles.form_block2_info}>
                                <div className={styles.form_block2_title}>Для меня этот день особенный, и&nbsp;именно поэтому мне особенно приятно делать подарки.</div>
                                <div className={styles.form_block2_text}>Сегодня мы&nbsp;открываем для вас доступ к&nbsp;вебинару Кати Усмановой и&nbsp;Кристины Коныжевой &laquo;Как избавиться от&nbsp;жира в&nbsp;проблемных зонах и&nbsp;укрепить женское здоровье&raquo;.</div>
                            </div>
                        </div>
                        <div className={styles.form_block2}>
                            <div className={styles.form_block2_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/form2.png" alt="" />
                            </div>
                            <div className={styles.form_block2_info}>
                                <div className={styles.form_block2_title}>Кристина&nbsp;&mdash; методист тренировок GymTeam, мастер спорта по&nbsp;легкой атлетике и&nbsp;специалист по&nbsp;движению.</div>
                                <div className={styles.form_block2_text}>Она следит, чтобы каждая тренировка в&nbsp;GymTeam соответствовала высочайшему уровню качества, а&nbsp;программа была составлена с&nbsp;учётом последних научных открытий в&nbsp;анатомии, физиологии, биомеханики движения человека.</div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.video_block}>
                        <div className={'birthday_usm_preview' + (play ? ' hide' : '')} onClick={() => setPlay(true)}></div>
                        <iframe src={'https://kinescope.io/embed/5WEgtdFj7aJ3HWBypotB3f'+url} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" allowFullScreen></iframe>
                    </div>
                    {/* </ShowForSegment> */}

                    {/* <HideForSegment id={window.lps_settings.segments.birthday_usm}>
                    <div className={styles.form_block}>
                        <div className={styles.form_title}>Присоединяйтесь!</div>
                        <div className={styles.form_block2}>
                            <div className={styles.form_block2_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/form.png" alt="" />
                            </div>
                            <div className={styles.form_block2_info}>
                                <div className={styles.form_block2_title}>Для меня этот день особенный, и&nbsp;именно поэтому<br/> мне особенно приятно делать подарки.</div>
                                <div className={styles.form_block2_text}>{window.lps_settings.other.active_land == 1 ? <>Только 31&nbsp;октября</> : <>Только 01&nbsp;ноября</>} мы&nbsp;предлагаем уникальные скидки на&nbsp;GymTeam. Присоединяйтесь и&nbsp;приглашайте друзей.</div>
                                <div className={styles.form_block2_text}>Физические упражнения могут заменить множество лекарств, но&nbsp;ни&nbsp;одно лекарство в&nbsp;мире не&nbsp;может заменить физические упражнения.</div>
                            </div>
                        </div>
                        <div className={styles.countdown}>
                            <p>{window.lps_settings.other.active_land == 1 ? <>Скидка доступна только 31&nbsp;октября</> : <>Акцию продлили по&nbsp;вашим просьбам!<br/> Скидка доступна только 01&nbsp;ноября</>}</p>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                        <div className={styles.form_hook}>
                            <div className={`${styles.form_hook_item} ${chose == 0 && styles.form_hook_item_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(0); setChose(0) }}>
                                <div className={styles.form_hook_title}>3&nbsp;месяца</div>
                                <div className={styles.form_hook_prices}>
                                    <p>2&nbsp;697&nbsp;₽</p>
                                </div>
                            </div>
                            <div className={`${styles.form_hook_item} ${chose == 1 && styles.form_hook_item_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(1); setChose(1) }}>
                                <div className={styles.form_hook_title}>6&nbsp;месяцев <p><span>-30%</span></p></div>
                                <div className={styles.form_hook_prices}>
                                    <span>5&nbsp;394&nbsp;₽</span>
                                    <p>3&nbsp;771&nbsp;₽</p>
                                </div>
                            </div>
                            <div className={`${styles.form_hook_item} ${chose == 2 && styles.form_hook_item_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(2); setChose(2) }}>
                                <div className={styles.form_hook_title}>12&nbsp;месяцев <p><span>-50%</span></p></div>
                                <div className={styles.form_hook_prices}>
                                    <span>10&nbsp;788&nbsp;₽</span>
                                    <p>5&nbsp;311&nbsp;₽</p>
                                </div>
                            </div>
                        </div>
                        <SalesWidget id={window.lps_settings.forms.birthday_usm_id} hook={widget} />
                    </div>
                    </HideForSegment> */}
                {/* </Authorized> */}
                {/* <Anonymous> */}
                    {/* <div className={styles.form_block}>
                        <div className={styles.form_title}>Подарок от&nbsp;GymTeam</div>
                            <div className={styles.form_block2}>
                                <div className={styles.form_block2_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/form.png" alt="" />
                                </div>
                                <div className={styles.form_block2_info}>
                                    <div className={styles.form_block2_title}>Для меня этот день особенный, и&nbsp;именно поэтому<br/> мне особенно приятно делать подарки.</div>
                                    <div className={styles.form_block2_text}>Авторизуйтесь, чтобы забрать подарок.</div>
                                </div>
                            </div>
                        <AuthWidget />
                    </div> */}
                {/* </Anonymous> */}
                </>
                }
                
            </div>
        </div>
    )
}

export default Form;