import { isIOS, isSafari } from "./navigator";

export function openNewTab(url) {
  if (isIOS() || isSafari()) {
    window.location.assign(url);
  } else {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
  }
}
