import { useLayoutEffect, useRef, useState } from 'react';
import styles from './tabs.module.css';

import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, Pagination} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";

const items = [
    {name: <>Тазовое дно и&nbsp;диастаз</>},
    {name: <>Сутулость</>},
    {name: <>Колени без боли</>},
    {name: <>Здоровая спина</>},
    {name: <>Грыжи и&nbsp;протрузии</>},
    {name: <>Ноги без варикоза</>},
    {name: <>Антицеллюлит</>},
];
// const items2 = [
//     'Стройная фигура',
//     'Зарядиться энергией',
//     'Гибкость и грация',
//     'Выглядеть моложе',
//     'Стать выносливее',
//     'Свобода движений',
//     'Антистресс фитнес'
// ];

const Tabs2 = () => {
    const [active, setActive] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const openTab = e => setActive(e.target.dataset.index);

    useLayoutEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // const pagination = {
    //     clickable: true,

    //     renderBullet: function (index, className) {            
    //         return '<span class="' + className + '">' + items2[index] + '</span>';
    //     },
    // };

    return (
        <div className={styles.tabs}>
            <div className="container">
                <div className={styles.t1}>GymTeam для здоровья</div>
                <div className={styles.tabs_items}>
                    {items.map((n, i) => (
                    <div
                        className={`${styles.tabs_item} ${i == active && `${styles.tabs_item_active}`}`}
                        onClick={openTab}
                        data-index={i}
                    >{n.name}</div>
                    ))}
                </div>
                {width > 576 ?
                <>
                    {active == 0 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_1.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Тренировки на&nbsp;укрепление тазового дна</div>
                                    <div className={styles.content_item_text}>Тренировки укрепляют мышцы тазового дна и&nbsp;поясницы, улучшают осанку и&nbsp;дыхание, оптимизируют нагрузку на&nbsp;тело. Особенно полезны при недержании, болях в&nbsp;пояснице, крестце и&nbsp;нижней части живота, а&nbsp;также после операций на&nbsp;брюшной полости и&nbsp;в&nbsp;послеродовом периоде.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_2.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Тренировки при диастазе</div>
                                    <div className={styles.content_item_text}>Тренировки укрепляют мышцы живота и&nbsp;восстанавливают правильное давление в&nbsp;брюшной полости, что помогает снизить нагрузку на&nbsp;спину и&nbsp;суставы, улучшая общую подвижность. Рекомендуется при диастазе, выпирающем животике, болях в&nbsp;пояснице, грыжах, а&nbsp;также для профилактики и&nbsp;облегчения симптомов варикоза в&nbsp;области таза и&nbsp;ног.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 1 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_3.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Здоровая спина</div>
                                    <div className={styles.content_item_text}>Эта программа помогает вернуть гибкость и&nbsp;легкость в&nbsp;теле, снять напряжение в&nbsp;спине и&nbsp;грудной клетке, убрать боли в&nbsp;пояснице. Повышает выносливость, подвижность суставов, укрепляет мышцы кора и&nbsp;предотвращает травмы&nbsp;&mdash; для свободного и&nbsp;комфортного движения без ограничений.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_4.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Мягкие тренировки для спины</div>
                                    <div className={styles.content_item_text}>Тренировка мягко укрепляет мышцы спины и&nbsp;кора, снимает боли и&nbsp;защемления, улучшает осанку и&nbsp;походку, подтягивает живот и&nbsp;ягодицы. Спина получает надежную поддержку, а&nbsp;движение&nbsp;&mdash; легкость и&nbsp;свободу без дискомфорта.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 2 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_5.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Здоровые стопы</div>
                                    <div className={styles.content_item_text}>Программа тренировок помогает снизить боли и&nbsp;дискомфорт в&nbsp;стопах, убирает усталость и&nbsp;отечность, корректирует плоскостопие и&nbsp;улучшает положение стоп. Правильная нагрузка на&nbsp;стопы поддерживает здоровье суставов и&nbsp;повышает эффективность упражнений на&nbsp;ягодицы &mdash; для легкости и&nbsp;уверенности в&nbsp;каждом шаге.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_6.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Восстановительный микс</div>
                                    <div className={styles.content_item_text}>Программа восстанавливает функциональность мышц, улучшает кровообращение и&nbsp;лимфоток, дает легкость и&nbsp;свободу движений. Поддержка для ягодиц, кора, плеч&nbsp;&mdash; каждое занятие помогает укрепить опорно-двигательный аппарат и&nbsp;ощутить комфорт в&nbsp;движении уже с&nbsp;первых тренировок.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 3 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_7.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Здоровая спина</div>
                                    <div className={styles.content_item_text}>Эта программа помогает вернуть гибкость и&nbsp;легкость в&nbsp;теле, снять напряжение в&nbsp;спине и&nbsp;грудной клетке, убрать боли в&nbsp;пояснице. Повышает выносливость, подвижность суставов, укрепляет мышцы кора и&nbsp;предотвращает травмы&nbsp;&mdash; для свободного и&nbsp;комфортного движения без ограничений.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_8.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Фельденкрайз</div>
                                    <div className={styles.content_item_text}>Эта программа по&nbsp;методу Фельденкрайза снимает мышечное напряжение, возвращает гибкость и&nbsp;улучшает координацию. Деликатные движения нормализуют тонус, активируют &laquo;спящие&raquo; мышцы, восстанавливают осанку и&nbsp;дыхание. Метод усиливает контроль над телом, помогает почувствовать легкость и&nbsp;ясность в&nbsp;каждом движении.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 4 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_9.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Восстановительный микс</div>
                                    <div className={styles.content_item_text}>Программа восстанавливает функциональность мышц, улучшает кровообращение и&nbsp;лимфоток, дает легкость и&nbsp;свободу движений. Поддержка для ягодиц, кора, плеч&nbsp;&mdash; каждое занятие помогает укрепить опорно-двигательный аппарат и&nbsp;ощутить комфорт в&nbsp;движении уже с&nbsp;первых тренировок.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_10.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Силовые при ограничениях</div>
                                    <div className={styles.content_item_text}>Эта программа помогает укрепить мышцы и&nbsp;подтянуть тело даже при травмах или проблемах со&nbsp;спиной и&nbsp;суставами. Постепенное увеличение нагрузки поддерживает адаптацию организма, улучшая стабильность и&nbsp;подвижность суставов, снижает вес и&nbsp;укрепляет сердечно-сосудистую систему&nbsp;&mdash; без риска для здоровья.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 5 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_11.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Силовые при ограничениях</div>
                                    <div className={styles.content_item_text}>Эта программа помогает укрепить мышцы и&nbsp;подтянуть тело даже при травмах или проблемах со&nbsp;спиной и&nbsp;суставами. Постепенное увеличение нагрузки поддерживает адаптацию организма, улучшая стабильность и&nbsp;подвижность суставов, снижает вес и&nbsp;укрепляет сердечно-сосудистую систему&nbsp;&mdash; без риска для здоровья.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_12.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Лимфодренажные тренировки</div>
                                    <div className={styles.content_item_text}>Программа улучшает лимфоток, убирает отеки и&nbsp;признаки целлюлита, выравнивает цвет кожи и&nbsp;снижает высыпания. Лимфодренажные упражнения добавляют энергии, помогают справиться с&nbsp;усталостью и&nbsp;повышают тонус. Отличный способ поддержать здоровье кожи и&nbsp;лёгкость в&nbsp;теле, а&nbsp;также ускорить восстановление.</div>
                                </div>
                            </div>
                        </div>
                    }
                    {active == 6 && 
                        <div className={styles.content}>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_13.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Лимфодренажный комплекс</div>
                                    <div className={styles.content_item_text}>Лимфодренажный комплекс на&nbsp;основе массажа и&nbsp;тейпирования помогает избавиться от&nbsp;отеков и&nbsp;улучшить общее самочувствие. Уже после курса заметно уменьшение объема тела, кожа приобретает упругость, ощущается легкость. Тейпирование можно проводить как по&nbsp;зонам, так и&nbsp;комплексно.</div>
                                </div>
                            </div>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_14.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Лимфодренажные тренировки</div>
                                    <div className={styles.content_item_text}>Программа улучшает лимфоток, убирает отеки и&nbsp;признаки целлюлита, выравнивает цвет кожи и&nbsp;снижает высыпания. Лимфодренажные упражнения добавляют энергии, помогают справиться с&nbsp;усталостью и&nbsp;повышают тонус. Отличный способ поддержать здоровье кожи и&nbsp;лёгкость в&nbsp;теле, а&nbsp;также ускорить восстановление.</div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={styles.content_more}>И&nbsp;ещё десятки программ для красоты и&nbsp;здоровья...</div>
                </>
                
                :

                <>
                    <Swiper
                        // pagination={pagination}
                        modules={[Pagination, Navigation]}
                        // autoHeight={true}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        loop
                        onSwiper={(swiper) => {
                            setTimeout(() => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current
                            
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }}
                        slidesPerView={1.15}
                        spaceBetween={20}
                        className={styles.slider}
                    >
                        {active == 0 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_1.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Тренировки на&nbsp;укрепление тазового дна</div>
                                    <div className={styles.content_item_text}>Тренировки укрепляют мышцы тазового дна и&nbsp;поясницы, улучшают осанку и&nbsp;дыхание, оптимизируют нагрузку на&nbsp;тело. Особенно полезны при недержании, болях в&nbsp;пояснице, крестце и&nbsp;нижней части живота, а&nbsp;также после операций на&nbsp;брюшной полости и&nbsp;в&nbsp;послеродовом периоде.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 0 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_2.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Тренировки при диастазе</div>
                                    <div className={styles.content_item_text}>Тренировки укрепляют мышцы живота и&nbsp;восстанавливают правильное давление в&nbsp;брюшной полости, что помогает снизить нагрузку на&nbsp;спину и&nbsp;суставы, улучшая общую подвижность. Рекомендуется при диастазе, выпирающем животике, болях в&nbsp;пояснице, грыжах, а&nbsp;также для профилактики и&nbsp;облегчения симптомов варикоза в&nbsp;области таза и&nbsp;ног.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 1 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_3.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Здоровая спина</div>
                                    <div className={styles.content_item_text}>Эта программа помогает вернуть гибкость и&nbsp;легкость в&nbsp;теле, снять напряжение в&nbsp;спине и&nbsp;грудной клетке, убрать боли в&nbsp;пояснице. Повышает выносливость, подвижность суставов, укрепляет мышцы кора и&nbsp;предотвращает травмы&nbsp;&mdash; для свободного и&nbsp;комфортного движения без ограничений.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 1 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_4.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Мягкие тренировки для спины</div>
                                    <div className={styles.content_item_text}>Тренировка мягко укрепляет мышцы спины и&nbsp;кора, снимает боли и&nbsp;защемления, улучшает осанку и&nbsp;походку, подтягивает живот и&nbsp;ягодицы. Спина получает надежную поддержку, а&nbsp;движение&nbsp;&mdash; легкость и&nbsp;свободу без дискомфорта.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 2 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_5.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Здоровые стопы</div>
                                    <div className={styles.content_item_text}>Программа тренировок помогает снизить боли и&nbsp;дискомфорт в&nbsp;стопах, убирает усталость и&nbsp;отечность, корректирует плоскостопие и&nbsp;улучшает положение стоп. Правильная нагрузка на&nbsp;стопы поддерживает здоровье суставов и&nbsp;повышает эффективность упражнений на&nbsp;ягодицы &mdash; для легкости и&nbsp;уверенности в&nbsp;каждом шаге.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 2 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_6.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Восстановительный микс</div>
                                    <div className={styles.content_item_text}>Программа восстанавливает функциональность мышц, улучшает кровообращение и&nbsp;лимфоток, дает легкость и&nbsp;свободу движений. Поддержка для ягодиц, кора, плеч&nbsp;&mdash; каждое занятие помогает укрепить опорно-двигательный аппарат и&nbsp;ощутить комфорт в&nbsp;движении уже с&nbsp;первых тренировок.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 3 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_3.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Здоровая спина</div>
                                    <div className={styles.content_item_text}>Эта программа помогает вернуть гибкость и&nbsp;легкость в&nbsp;теле, снять напряжение в&nbsp;спине и&nbsp;грудной клетке, убрать боли в&nbsp;пояснице. Повышает выносливость, подвижность суставов, укрепляет мышцы кора и&nbsp;предотвращает травмы&nbsp;&mdash; для свободного и&nbsp;комфортного движения без ограничений.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 3 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_8.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Фельденкрайз</div>
                                    <div className={styles.content_item_text}>Эта программа по&nbsp;методу Фельденкрайза снимает мышечное напряжение, возвращает гибкость и&nbsp;улучшает координацию. Деликатные движения нормализуют тонус, активируют &laquo;спящие&raquo; мышцы, восстанавливают осанку и&nbsp;дыхание. Метод усиливает контроль над телом, помогает почувствовать легкость и&nbsp;ясность в&nbsp;каждом движении.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 4 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_6.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Восстановительный микс</div>
                                    <div className={styles.content_item_text}>Программа восстанавливает функциональность мышц, улучшает кровообращение и&nbsp;лимфоток, дает легкость и&nbsp;свободу движений. Поддержка для ягодиц, кора, плеч&nbsp;&mdash; каждое занятие помогает укрепить опорно-двигательный аппарат и&nbsp;ощутить комфорт в&nbsp;движении уже с&nbsp;первых тренировок.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 4 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_10.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Силовые при ограничениях</div>
                                    <div className={styles.content_item_text}>Эта программа помогает укрепить мышцы и&nbsp;подтянуть тело даже при травмах или проблемах со&nbsp;спиной и&nbsp;суставами. Постепенное увеличение нагрузки поддерживает адаптацию организма, улучшая стабильность и&nbsp;подвижность суставов, снижает вес и&nbsp;укрепляет сердечно-сосудистую систему&nbsp;&mdash; без риска для здоровья.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 5 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_10.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Силовые при ограничениях</div>
                                    <div className={styles.content_item_text}>Эта программа помогает укрепить мышцы и&nbsp;подтянуть тело даже при травмах или проблемах со&nbsp;спиной и&nbsp;суставами. Постепенное увеличение нагрузки поддерживает адаптацию организма, улучшая стабильность и&nbsp;подвижность суставов, снижает вес и&nbsp;укрепляет сердечно-сосудистую систему&nbsp;&mdash; без риска для здоровья.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 5 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_12.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Лимфодренажные тренировки</div>
                                    <div className={styles.content_item_text}>Программа улучшает лимфоток, убирает отеки и&nbsp;признаки целлюлита, выравнивает цвет кожи и&nbsp;снижает высыпания. Лимфодренажные упражнения добавляют энергии, помогают справиться с&nbsp;усталостью и&nbsp;повышают тонус. Отличный способ поддержать здоровье кожи и&nbsp;лёгкость в&nbsp;теле, а&nbsp;также ускорить восстановление.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 6 &&
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_13.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Лимфодренажный комплекс</div>
                                    <div className={styles.content_item_text}>Лимфодренажный комплекс на&nbsp;основе массажа и&nbsp;тейпирования помогает избавиться от&nbsp;отеков и&nbsp;улучшить общее самочувствие. Уже после курса заметно уменьшение объема тела, кожа приобретает упругость, ощущается легкость. Тейпирование можно проводить как по&nbsp;зонам, так и&nbsp;комплексно.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                        {active == 6 && 
                        <SwiperSlide>
                            <div className={styles.content_item}>
                                <div className={styles.content_item_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/birthdayusm/tab2_12.jpg" alt="" />
                                </div>
                                <div className={styles.content_item_info}>
                                    <div className={styles.content_item_title}>Лимфодренажные тренировки</div>
                                    <div className={styles.content_item_text}>Программа улучшает лимфоток, убирает отеки и&nbsp;признаки целлюлита, выравнивает цвет кожи и&nbsp;снижает высыпания. Лимфодренажные упражнения добавляют энергии, помогают справиться с&nbsp;усталостью и&nbsp;повышают тонус. Отличный способ поддержать здоровье кожи и&nbsp;лёгкость в&nbsp;теле, а&nbsp;также ускорить восстановление.</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        }
                    </Swiper>
                    
                    <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}></div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}></div>
                    </div>
                </>

                }
            </div>
        </div>
    )
}

export default Tabs2;