import styles from './whaityou.module.css';

const Whaityou = () => {
    return (
        <div className={styles.whaityou}>
            <div className="container">
                <div className={styles.whaityou_title}>МАРАФОН СТРОЙНОСТИ&nbsp;1.0&nbsp;— это…</div>
                <div className={styles.whaityou_block}>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>21&nbsp;ТРЕНИРОВКА В&nbsp;ФОРМАТЕ «ПОВТОРЯЙ&nbsp;ЗА&nbsp;КАТЕЙ» 🤸‍♀️</div>
                            <div className={styles.whaityou_info__text}>Ежедневно вам будет открываться новая тренировка с&nbsp;Катей Усмановой. Не&nbsp;нужно менять свой график&nbsp;— на&nbsp;выполнение есть целые сутки. Просто включайте видео и&nbsp;повторяйте за&nbsp;Катей.</div>
                            <div className={styles.whaityou_info__number}>1</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>ВСЕГО 20-30&nbsp;МИНУТ НА&nbsp;ТРЕНИРОВКУ</div>
                            <div className={styles.whaityou_info__text}>Такие занятия точно впишутся в&nbsp;ваш распорядок дня, ведь&nbsp;вам не&nbsp;потребуется тратить время на&nbsp;дорогу. Вы&nbsp;сможете тренироваться и&nbsp;дома, и&nbsp;в&nbsp;любом другом подходящем месте.</div>
                            <div className={styles.whaityou_info__number}>2</div>
                        </div>
                    </div>
                    <div className={styles.whaityou_block_item}>
                        <div className={styles.whaityou_info}>
                            <div className={styles.whaityou_info__title}>МОРАЛЬНАЯ ПОДДЕРЖКА и&nbsp;мотивация 🦸‍♀️</div>
                            <div className={styles.whaityou_info__text}>Ощущение, что&nbsp;ты&nbsp;не&nbsp;одна&nbsp;— это&nbsp;очень&nbsp;важно. Записавшись на&nbsp;марафон, вы&nbsp;попадёте в&nbsp;фитнес-комьюнити, где&nbsp;вас со&nbsp;всех&nbsp;сторон готовы поддержать единомышленницы и&nbsp;Катя Усманова.</div>
                            <div className={styles.whaityou_info__number}>3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whaityou;