import { useRef } from "react";

export function useInstance() {
  const ref = useRef();

  if (!ref.current) {
    const instance = {
      __inject: fields => {
        for (let key in fields) {
          instance[key] = fields[key];
        }
      },
    };

    ref.current = instance;
  }

  return [ref.current];
}
