import styles from './marforme.module.css';

const Marforme = () => {
    return (
        <div className={styles.marforme}>
            <div className="container">
                <div className={styles.marforme_title}>Курс точно<br/> подойдёт мне?</div>
                <div className={styles.marforme_subtitle}>Да, если вы…</div>
                <div className={styles.marforme_block}>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Хотите не&nbsp;просто подтянуть ягодицы, а&nbsp;создать попу, <strong>достойную сцены фитнес-бикини</strong></li>
                            <li>Профессионал, вам надо <strong>быстро вернуть рельеф</strong></li>
                            <li>Понимаете, что&nbsp;это <strong>другой уровень подхода к&nbsp;тренировкам</strong> и&nbsp;готовы выкладываться на&nbsp;каждом этапе</li>
                        </ul>
                    </div>
                    <div className={styles.marforme_item}>
                        <ul>
                            <li>Хотите добавить объёмов, чтобы&nbsp;<strong>сделать фигуру спортивной и&nbsp;соблазнительной</strong></li>
                            <li>Испытываете трудности <strong>в&nbsp;наборе мышечной массы или&nbsp;похудении</strong></li>
                            <li><strong>Не знаете, где&nbsp;найти мотивацию</strong>, чтобы&nbsp;начать и&nbsp;не&nbsp;бросить</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marforme;