import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from './start.module.css';
import { useState } from "react";

const Start3 = () => {
    const [active, setActive] = useState(1);

    return (
        <div className={('scrollhere ') + styles.start}>
            <div className="container">
                <div className={styles.withbtns}>
                    <div className={styles.btns}>
                        <div className={`${styles.btn} ${active==1 && styles.active}`} onClick={()=>setActive(1)}>Продления</div>
                        <div className={`${styles.btn} ${active==2 && styles.active}`} onClick={()=>setActive(2)}>Офферы ОП</div>
                        <div className={`${styles.btn} ${active==3 && styles.active}`} onClick={()=>setActive(3)}>Текущие активности</div>
                        <div className={`${styles.btn} ${active==4 && styles.active}`} onClick={()=>setActive(4)}>Витрина продуктов</div>
                        <div className={`${styles.btn} ${active==5 && styles.active}`} onClick={()=>setActive(5)}>Витрина от ОП</div>
                    </div>

                    <div className={styles.start_block}>
                        <div className="velvet_start3">
                            {active == 1 ?
                            <SalesWidget id={window.lps_settings.forms.prolong_id} />
                            : active == 2 ?
                            <SalesWidget id={window.lps_settings.forms.prolong_2_id} />
                            : active == 3 ?
                            <SalesWidget id={window.lps_settings.forms.prolong_3_id} />
                            : active == 4 ?
                            <SalesWidget id={window.lps_settings.forms.prolong_4_id} />
                            :
                            <SalesWidget id={window.lps_settings.forms.prolong_5_id} />
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Start3;