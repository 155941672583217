import styles from './onmarafon.module.css';

const Onmarafon = () => {
    return (
        <div className={styles.onmarafon}>
            <div className="container">
                <div className={styles.onmarafon_title}>НА МАРАФОНЕ&nbsp;ВЫ <span>НЕ&nbsp;БУДЕТЕ:</span></div>
                <div className={styles.onmarafon_block}>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/onm_1.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Просто приседать <strong>по&nbsp;100&nbsp;повторений</strong><br/> в&nbsp;течение подхода</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/onm_2.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Сидеть у&nbsp;стены <strong>в&nbsp;«стульчике» по&nbsp;2&nbsp;минуты</strong><br/> до&nbsp;мышечного отказа</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/onm_3.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}><strong>Надрываться со&nbsp;штангой весом 50&nbsp;кг,</strong><br/> без&nbsp;которой «мышцы не&nbsp;растут»</div>
                        </div>
                    </div>
                    <div className={styles.onmarafon_item}>
                        <div className={styles.onmarafon_item_info}>
                        <div className={styles.onmarafon_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/onm_4.png" alt="" />
                        </div>
                        <div className={styles.onmarafon_item_title}>Раз за&nbsp;разом делать <strong>одни&nbsp;и&nbsp;те&nbsp;же<br/> тренировки</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Onmarafon;