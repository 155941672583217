import { useRef } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import './insta.css';

const Insta = () => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    const slides = [
        {image: 'https://gymteam.kinescopecdn.net/img/l/pregnancy/insta/insta_1_c.jpg', text: '#худеемпослеродов'},
        {image: 'https://gymteam.kinescopecdn.net/img/l/pregnancy/insta/insta_2_c.jpg', text: '#инстамама'},
        {image: 'https://gymteam.kinescopecdn.net/img/l/pregnancy/insta/insta_3_c.jpg', text: '#инстамама'},
        {image: 'https://gymteam.kinescopecdn.net/img/l/pregnancy/insta/insta_4_c.jpg', text: '#худеемпослеродов'},
        {image: 'https://gymteam.kinescopecdn.net/img/l/pregnancy/insta/insta_5_c.jpg', text: '#фигурамечты'},
        {image: 'https://gymteam.kinescopecdn.net/img/l/pregnancy/insta/insta_6_c.jpg', text: '#какуусмановой'},
        {image: 'https://gymteam.kinescopecdn.net/img/l/pregnancy/insta/insta_7_c.jpg', text: '#инстамама'},
        {image: 'https://gymteam.kinescopecdn.net/img/l/pregnancy/insta/insta_8_c.jpg', text: '#инстамама'},
    ]

    return (
        <div className="insta">
          <div className="container">
            <div className="insta_title">ИНСТАМАМА</div>
            <div className="insta_subtitle">+20&nbsp;кг за время первой беременности. Пришла в&nbsp;форму за&nbsp;100 дней!</div>
            <div className="insta_slider">
              <div className="insta_slider__item">
                <div className="insta_slider__item-img"></div>
                <div className="insta_slider__item-text"></div>
              </div>
            </div>
            <Swiper 
              slidesPerView={1}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onSwiper={(swiper) => {
                setTimeout(() => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current
                  swiper.params.navigation.nextEl = navigationNextRef.current
        
                  swiper.navigation.destroy()
                  swiper.navigation.init()
                  swiper.navigation.update()
                })
              }}
              modules={[Navigation]}
              breakpoints={{
                1200: {
                  slidesPerView: 8,
                }
              }}
              >
                <div className="slider_nav">
                  <div ref={navigationPrevRef} className='slider_btn_prev'></div>
                  <div ref={navigationNextRef} className='slider_btn_next'></div>
                </div>
                {slides.map(slide => 
                    <SwiperSlide key={slide.image}>
                        <div className='slide' style={{backgroundImage: `url('${slide.image}')`}}>
                        <p>{slide.text}</p>
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
          </div>
        </div>
    );
};

export default Insta;