import { SalesWidget, addUserToGroup } from '@com.marathonium/web2-ui';
import Countdown from 'react-countdown';
import styles from './style.module.css'
import { useEffect } from 'react';

const Land = ({date,renderer, hide, version, scroll, auth}) => {
    useEffect(()=>{
        if(hide !== true) {
            addUserToGroup(window.lps_settings.user_groups.fatlost_n4_watched)
        }
    }, [])

    return (
        <>
        <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/logo.svg" alt="" /></div>
            <div className={styles.title}>Получите 3&nbsp;месяца подписки на&nbsp;50+ программ GymTeam со&nbsp;скидкой до&nbsp;55%! <span>Стоимость для вас <i>2&nbsp;690</i> 1&nbsp;790&nbsp;₽</span></div>
            {!auth &&
                <div className={styles.block1}>
                {hide != true &&
                <>
                    <div className={styles.block1_title}>НО ЭТО ЕЩЕ НЕ&nbsp;ВСЕ!</div>
                    {version == 2 && 
                    <div className={styles.block1_subtitle}>За завершенные тренировки вам начислялись баллы.<br/> Вы набрали <span>0</span> баллов, которые можете потратить на оплату подписки.<br/> Поэтому цена подписки для вас <span>1&nbsp;790&nbsp;₽</span> за&nbsp;3&nbsp;месяца!</div>
                    }
                    {version == 3 && 
                    <div className={styles.block1_subtitle}>За завершенные тренировки вам начислялись баллы.<br/> Вы набрали <span>100</span> баллов, которые можете потратить на оплату подписки.<br/> Поэтому цена подписки для вас  <span>1&nbsp;690&nbsp;₽</span> за&nbsp;3&nbsp;месяца!</div>
                    }
                    {version == 4 && 
                    <div className={styles.block1_subtitle}>За завершенные тренировки вам начислялись баллы.<br/> Вы набрали <span>300</span> баллов, которые можете потратить на оплату подписки.<br/> Поэтому цена подписки для вас  <span>1&nbsp;490&nbsp;₽</span> за&nbsp;3&nbsp;месяца!</div>
                    }
                    {version == 5 && 
                    <div className={styles.block1_subtitle}>За завершенные тренировки вам начислялись баллы.<br/> Вы набрали <span>600</span> баллов, которые можете потратить на оплату подписки.<br/> Поэтому цена подписки для вас  <span>1&nbsp;190&nbsp;₽</span> за&nbsp;3&nbsp;месяца!</div>
                    }
                </>
                }
                    <div className={styles.block1_countdown}>
                        <p>Предложение действительно:</p>
                        <div className={styles.block1_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                </div>}
            
                <div className={styles.subtitle}>При оплате сейчас стоимость сохранится для вас навсегда!<br/> Отменить подписку можно в&nbsp;любой момент.</div>
                <div className={styles.btn1} onClick={scroll}>Оформить подписку</div>
                <div className={styles.list}>
                    <p>Что можно успеть за три месяца, имея подписку и занимаясь в своем графике:</p>
                    <ul>
                        <li>скинуть 5-15&nbsp;кг под руководством опытных экспертов</li>
                        <li>избавиться от&nbsp;болей в&nbsp;спине с&nbsp;помощью тренировок</li>
                        <li>привести мышцы всего тела в&nbsp;тонус, тем самым увеличив жизненную энергию</li>
                        <li>укрепить стопы – потому что от них идет более&nbsp;80% проблем с&nbsp;опорно-двигательным аппаратом</li>
                        <li>улучшить качество жизни, выполняя короткие адекватные тренировки для тех, кто проводит много времени в&nbsp;офисе</li>
                        <li>научиться расслабляться и&nbsp;снимать стресс через работу с&nbsp;восточными практиками</li>
                        <li>улучшить растяжку и&nbsp;придать телу молодость</li>
                    </ul>
                    <p>Программы в&nbsp;GymTeam можно чередовать и&nbsp;совмещать.<br/> Главное&nbsp;– заниматься в&nbsp;свое удовольствие, так как получается, без&nbsp;надрывов. И&nbsp;уже через 3&nbsp;недели вы взглянете на&nbsp;себя по-новому!</p>
                </div>
                
                {/* {version != 1 &&  */}
                <div className={('scrollhere ') + styles.block2}>
                    
                    
                        <div className={styles.block2_title}>Подписка на&nbsp;все<br/> программы GymTeam</div>
                        {version == 1 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>2&nbsp;690</span> 1&nbsp;790&nbsp;₽</div>
                        }
                        {version == 2 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>2&nbsp;690</span> 1&nbsp;790&nbsp;₽</div>
                        }
                        {version == 3 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>2&nbsp;690</span> 1&nbsp;690&nbsp;₽</div>
                        }
                        {version == 4 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>2&nbsp;690</span> 1&nbsp;490&nbsp;₽</div>
                        }
                        {version == 5 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>2&nbsp;690</span> 1&nbsp;190&nbsp;₽</div>
                        }
                        {version != 1 &&
                        <div className={styles.block2_countdown}>
                            <p>Предложение действительно:</p>
                            <div className={styles.block2_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                        }
                        <div className="year1_start">
                            {version == 1 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n4_1_id} />
                            }
                            {version == 2 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n4_1_id} />
                            }
                            {version == 3 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n4_2_id} />
                            }
                            {version == 4 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n4_3_id} />
                            }
                            {version == 5 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost_n4_4_id} />
                            }
                            
                        </div>
                        
                    
                </div>
                {/* } */}
        </>
    )
}

export default Land;