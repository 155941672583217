import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className={styles.g1}></div>
            <div className={styles.g2}></div>
            <div className={styles.g3}></div>
            <div className="container">
                <div className={styles.createbody_title}>ИЗМЕНИТЕ КАЧЕСТВО ТЕЛА<br/> <span>ПО&nbsp;СИСТЕМЕ КАТИ УСМАНОВОЙ</span></div>
                <div className={styles.create_pink}><b>Вы сами решаете, какой будет ваша тренировка сегодня.</b> Ориентируйтесь на&nbsp;свои цели и&nbsp;самочувствие, чтобы подобрать оптимальную нарузку и&nbsp;упражнения</div>
                <div className={styles.create_yellow}><b>Внутри 10 универсальных тренировок,</b> чтобы создать идеальные ягодицы не&nbsp;выходя из&nbsp;дома.</div>
            </div>
        </div>
    )
}

export default Createbody;