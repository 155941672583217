import { useEffect, useState } from "react";
import { fetchWithN8n2, callWebRpc } from "@com.marathonium/web2-utils";
import _uniqueId from "lodash/uniqueId";

const USER_GROUPS_CHANGED_EVENT = "user-groups-changed";
const userGroupsChangedEvent = new Event(USER_GROUPS_CHANGED_EVENT);

const dispatchUserGroupsChangedEvent = () => {
  window.dispatchEvent(userGroupsChangedEvent);
};

export function useUserGroupsChangedLastKey() {
  const [key, setKey] = useState(_uniqueId());

  useEffect(() => {
    function onUserGroupChange() {
      setKey(_uniqueId());
    }

    window.addEventListener(USER_GROUPS_CHANGED_EVENT, onUserGroupChange);

    return () => {
      window.removeEventListener(USER_GROUPS_CHANGED_EVENT, onUserGroupChange);
    };
  });

  return key;
}

export async function checkUserInGroup(id) {
  return fetchWithN8n2("web.user.check_group.v1", { id });
}

export async function addUserToGroup(id) {
  return fetchWithN8n2("web.user.add_group.v1", { id }).then(dispatchUserGroupsChangedEvent);
}

export async function removeUserFromGroup(id) {
  return fetchWithN8n2("web.user.remove_group.v1", { id }).then(dispatchUserGroupsChangedEvent);
}

export async function checkUserInSegment(id) {
  return fetchWithN8n2("web.user.check_segment.v1", { id });
}

export async function getUser() {
  return callWebRpc("user_get_v1");
}

export async function getReferralCode() {
  return fetchWithN8n2("web.user.refferal_code.v1");
}

export async function getReferralLink() {
  return fetchWithN8n2("web.user.refferal_link.v1");
}

export async function getUserCountByGroup(id_user_group) {
  return callWebRpc("user_count_by_group_v1", { id_user_group });
}
