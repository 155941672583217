import styles from './createbody.module.css';

const Createbody = () => {
    return (
        <div className={styles.createbody}>
            <div className={styles.createbody_weight}></div>
            <div className="container">
                <div className={styles.createbody_title}>ЦЕЛЛЮЛИТ ЕСТЬ<br/> <span>У&nbsp;39 ИЗ&nbsp;40&nbsp;ЖЕНЩИН</span></div>
                <div className={styles.createbody_subtitle}>Несмотря на&nbsp;то, что&nbsp;целлюлит считается признаком нездоровья<br/> и&nbsp;непривлекательности, <strong>с&nbsp;ним сталкиваются даже стройные или&nbsp;очень<br/> худые девушки.</strong></div>
                <div className={styles.create_pink}><span>Но&nbsp;разве от&nbsp;этого легче?</span> Сколько ни&nbsp;говори о&nbsp;целлюлите как&nbsp;о&nbsp;распространенном явлении, все девушки мечтают быть одной из&nbsp;сорока женщин, свободных от&nbsp;ряблости кожи.</div>
                <div className={styles.create_yellow}><span>Целлюлит&nbsp;= психологический дискомфорт</span> Он&nbsp;вынуждает кутаться в&nbsp;полотенце на&nbsp;пляже, носить летом шорты подлиннее или&nbsp;джинсы, стесняться своего тела… Самый распространенный способ самостоятельного избавления от&nbsp;«апельсиновой корки» — похудение. Но&nbsp;резкое снижение веса лишь сделает бугристость кожи ярче и&nbsp;заметнее.</div>
            </div>
        </div>
    )
}

export default Createbody;