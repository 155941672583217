import React from 'react';
import { DocumentTitle } from '../components/DocumentMeta';
import './style.css';

export const TransferPage = () => {
    return (
        <>
        <DocumentTitle value='Перенос доступов'/>
        
        <div className='transfer'>
            <div className="transfer_logo">
                <img src="https://gymteam.kinescopecdn.net/img/l/transfer_page/logo.svg" alt="" />
            </div>
            <div className="transfer_block">
                <div className="transfer_block__title">ПЕРЕНОС ДОСТУПОВ ИЗ USMANOVATEAM</div>
                <div className="transfer_block__info"><strong>Проект «UsmanovaTeam» становится частью GymTeam.</strong><br/> Чтобы продолжить тренироваться по&nbsp;тренировкам Екатерины Усмановой,<br/> вам необходимо осуществить перенос доступов.</div>
                <a href="" target="_blank">Перейти на&nbsp;сайт UsmanovaTeam</a>
                <div className="transfer_block__note">Перейдите на&nbsp;сайт usmanovateam.ru и&nbsp;начните процесс переноса.</div>
            </div>
            <div className="transfer_footer">
                <a href="" target="_blank">Правила сервиса</a> и <a href="" target="_blank">Политика конфиденциальности</a> <span>2020&nbsp;–&nbsp;2022&nbsp;гг. ООО "Онлайн Фитнес"</span>
            </div>
        </div>
        </>
    );
};