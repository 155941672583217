import { SalesWidget, addUserToGroup } from '@com.marathonium/web2-ui';
import Countdown from 'react-countdown';
import styles from './style.module.css'
import { useEffect } from 'react';

const Land = ({date,renderer, hide, version, scroll, auth}) => {
    useEffect(()=>{
        if(hide !== true) {
            addUserToGroup(window.lps_settings.user_groups.fatlost_watched)
        }
    }, [])

    return (
        <>
        <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/fatburning/logo.svg" alt="" /></div>
        <div className={styles.title}>Получите годовую подписку GymTeam<br/> со&nbsp;скидкой&nbsp;45%! <span>Стоимость для вас <i>9&nbsp;590</i> 5&nbsp;290&nbsp;₽</span></div>
            {!auth &&
                <div className={styles.block1}>
                {hide != true &&
                <>
                    <div className={styles.block1_title}>НО ЭТО ЕЩЕ НЕ&nbsp;ВСЕ!</div>
                    {version == 2 && 
                    <div className={styles.block1_subtitle}>За завершенные тренировки вам начислялись баллы.<br/> Вы набрали <span>0</span> баллов, которые можете потратить на оплату подписки.<br/> Поэтому цена подписки для вас <span>5&nbsp;290&nbsp;₽</span> за&nbsp;год!</div>
                    }
                    {version == 3 && 
                    <div className={styles.block1_subtitle}>За завершенные тренировки вам начислялись баллы.<br/> Вы набрали <span>100</span> баллов, которые можете потратить на оплату подписки.<br/> Поэтому цена подписки для вас  <span>5&nbsp;190&nbsp;₽</span> за&nbsp;год!</div>
                    }
                    {version == 4 && 
                    <div className={styles.block1_subtitle}>За завершенные тренировки вам начислялись баллы.<br/> Вы набрали <span>300</span> баллов, которые можете потратить на оплату подписки.<br/> Поэтому цена подписки для вас  <span>4&nbsp;990&nbsp;₽</span> за&nbsp;год!</div>
                    }
                    {version == 5 && 
                    <div className={styles.block1_subtitle}>За завершенные тренировки вам начислялись баллы.<br/> Вы набрали <span>600</span> баллов, которые можете потратить на оплату подписки.<br/> Поэтому цена подписки для вас  <span>4&nbsp;690&nbsp;₽</span> за&nbsp;год!</div>
                    }
                </>
                }
                    <div className={styles.block1_countdown}>
                        <p>Предложение действительно:</p>
                        <div className={styles.block1_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                </div>}
            
                <div className={styles.subtitle}>При оплате сейчас стоимость сохранится для вас навсегда!<br/> Можно в&nbsp;рассрочку!</div>
                <div className={styles.btn1} onClick={scroll}>Оформить подписку</div>
                <div className={styles.list}>
                    <p>Какие задачи успешно решает GymTeam:</p>
                    <ul>
                        <li>Cделать тело стройным</li>
                        <li>Накачать упругие ягодицы и&nbsp;получить плоский живот</li>
                        <li>Укрепить глубокие мышцы и&nbsp;избавиться от&nbsp;болей в&nbsp;спине, пояснице и&nbsp;шее</li>
                        <li>Убрать второй подбородок и&nbsp;сделать лицо помолодевшим</li>
                        <li>Улучшить осанку</li>
                        <li>Поддержать тело в&nbsp;тонусе и&nbsp;повысить уровень энергии</li>
                        <li>Улучшить здоровье в&nbsp;работе над&nbsp;стопами, тазовым дном и&nbsp;через проработку дыхания</li>
                        <li>Расслабиться с&nbsp;помощью восточных практик или&nbsp;миофасциального релиза</li>
                    </ul>
                </div>
                <div className={styles.list}>
                    <p>Что внутри подписки:</p>
                    <ul>
                        <li>50+ фитнес-программ для всех возрастов, более 1&nbsp;500 тренировок&nbsp;– чередуйте стили как угодно и найдите свой</li>
                        <li>Занятия от&nbsp;15 до&nbsp;50&nbsp;минут</li>
                        <li>Только действующие тренеры-эксперты с&nbsp;опытом работы более&nbsp;10&nbsp;лет</li>
                        <li>Ежемесячно добавляются новые тренировки и&nbsp;новые программы</li>
                        <li>Возможность заниматься с&nbsp;компьютера, телефона, планшета или&nbsp;SmartTV в&nbsp;любое удобное для вас время</li>
                    </ul>
                </div>
                {/* {version != 1 &&  */}
                <div className={('scrollhere ') + styles.block2}>
                    
                    
                        <div className={styles.block2_title}>Подписка на&nbsp;все<br/> программы GymTeam на&nbsp;год.</div>
                        {version == 1 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>9&nbsp;590</span> 5&nbsp;290&nbsp;₽</div>
                        }
                        {version == 2 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>9&nbsp;590</span> 5&nbsp;290&nbsp;₽</div>
                        }
                        {version == 3 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>9&nbsp;590</span> 5&nbsp;190&nbsp;₽</div>
                        }
                        {version == 4 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>9&nbsp;590</span> 4&nbsp;990&nbsp;₽</div>
                        }
                        {version == 5 && 
                        <div className={styles.block2_subtitle}>Стоимость <span>9&nbsp;590</span> 4&nbsp;690&nbsp;₽</div>
                        }
                        {version != 1 && 
                        <div className={styles.block2_countdown}>
                            <p>Предложение действительно:</p>
                            <div className={styles.block2_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                        }
                        <div className="year1_start">
                            {version == 1 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost1_id} />
                            }
                            {version == 2 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost1_id} />
                            }
                            {version == 3 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost2_id} />
                            }
                            {version == 4 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost3_id} />
                            }
                            {version == 5 && 
                            <SalesWidget id={window.lps_settings.forms.fatlost4_id} />
                            }
                            
                        </div>
                        
                    
                </div>
                {/* } */}
        </>
    )
}

export default Land;