import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2023/universalpop/logo.svg' alt="gymteam" /></div>
                <div className={styles.promo_title}>МАРАФОН КРАСИВОЙ<br/> И&nbsp;УПРУГОЙ <span>ПОПЫ&nbsp;1.0</span></div>
                <div className={styles.promo_subtitle}><strong>Подтянутые ягодицы за&nbsp;21&nbsp;день</strong> домашних<br/> тренировок по&nbsp;методу Кати Усмановой</div>
                <div className={styles.promo_items}>
                    <div className={styles.promo_item1}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa1/icon1.png" alt="" /> <p>Для новичков</p></div>
                    <div className={styles.promo_item2}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa1/icon2.png" alt="" /> <p>21 тренировка</p></div>
                    <div className={styles.promo_item3}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa1/icon3.png" alt="" /> <p>С инвентарём</p></div>
                </div> 
                <div className={styles.promo_btns}>
                    <div className='l2212_popa1_btn' onClick={scroll}><p>Участвовать</p></div>
                    <div className={styles.promo_date}><p>Старт<br/> {window.lps_settings.dates.mainlands}</p></div>
                </div>
            </div>
            <div className={styles.promo_points_block}>
                <div className={styles.promo_point1}>#избавиться<span>отгалифе</span></div>
                <div className={styles.promo_point2}>#подтянуть<span>тело</span></div>
                <div className={styles.promo_point3}>#накачать<span>ягодицы</span></div>
            </div>
        </div>
    )
}

export default Promo;