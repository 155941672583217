import { slideFadeConfig } from "@chakra-ui/transition";

export const modalSlideFade = {
  ...slideFadeConfig,
  custom: {
    offsetY: "25vh",
    reverse: true,
    delay: 0,
    transition: {
      enter: {
        duration: 0.3,
      },
      exit: {
        duration: 0.1,
      },
    },
  },
};
