import React, { useEffect, useState } from 'react';
import { AuthWidget, addUserToGroup, checkUserInGroup } from "@com.marathonium/web2-ui";
import { useDisclosure } from "@chakra-ui/react";

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Preims from './components/Preims';
import Students from './components/Students';
import Reserve from './components/Reserve';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_07_Mu = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const [user, setUser] = useState(false)
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
      document.body.classList.add('removephoneauth')

      checkUserInGroup(window.lps_settings.user_groups.metod2_add).then(res => {
        // console.log(res, 'res tut')
        if(res) {
          setUser(true)
        }
      })
    }, [])

    const func = () => {
      addUserToGroup(window.lps_settings.user_groups.metod2_add);
      // window.location.replace('/l/presale_metod2');
   }
    
    return (
      <>
        <DocumentTitle value='Предзапись на 2 поток Метода Усмановой закончилась'/>
        <DocumentDescription value='Предзапись на 2 поток Метода Усмановой закончилась' />
        <div className='mu'>
              <Promo user={user} onOpen={onOpen} />
              <Preims user={user} onOpen={onOpen} />
              <Students />
              <Reserve user={user} onOpen={onOpen} />
              <Footer isColor={'#DEE5EF'} />
        </div>

        <AuthWidget isOpen={isOpen} onClose={onClose} modal={true} title="Анкета предзаписи" subtitle="Оставь свой емейл, чтобы попасть в анкету предзаписи и получить самые выгодные условия на 2 поток" onSuccess={func} />
      </>
    );
};