import styles from './prize.module.css';

const Prize = ({scroll}) => {
    return (
        <div className={styles.prize}>
            {/* <div className={styles.forlines} style={{marginBottom: 80}}>
                <div className={styles.line}>
                    <div className={styles.line_text}>
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>РОЗЫГРЫШ СУПЕРПРИЗОВ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    </div>
                </div>
            </div> */}

            <div className="container">
                <div className={styles.b1}>
                    <div className={styles.b1_info}>
                        <div className={styles.b1_info_title}><span>Выиграйте поездку<br/> в&nbsp;Дубай на&nbsp;двоих</span><br/> и&nbsp;встречу с&nbsp;Катей</div>
                        <div className={styles.b1_info_subtitle}>При покупке комплекта программ вы&nbsp;автоматически попадаете в&nbsp;список участниц главного розыгрыша. Вы&nbsp;сможете отправиться в&nbsp;отпуск в&nbsp;жаркий Дубай, как одна из&nbsp;наших победительниц, и&nbsp;встретиться с&nbsp;Катей, которая проведёт вам персональную тренировку. Именно вы&nbsp;можете быть на&nbsp;её&nbsp;месте!</div>
                        <div className={styles.b1_info_text}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/prize1.svg" alt="" />
                            <p><b>15&nbsp;декабря в&nbsp;12:00 мск</b><br/> разыграем путёвку среди всех<br/> участниц Чёрной пятницы</p>
                        </div>
                    </div>
                    <div className={styles.b1_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/prize_img_2.png" alt="" />
                    </div>
                </div>

                <div className={styles.t2}><span>Подарки только для купивших</span><br/> полный набор программ Кати</div>
                <div className={styles.st2}>Для тех, кто купит пакет программ, 20&nbsp;ноября будет<br/> проведён розыгрыш призов. Получите возможность<br/> выиграть новый Iphone&nbsp;16 Pro и&nbsp;множество других призов</div>
                <div className={styles.b2}>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/prize_win_1.jpg" alt="" />
                        <p>Apple Iphone&nbsp;16 Pro</p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/prize_win_2.jpg" alt="" />
                        <p>Apple AirPods <span>(4-го поколения, 2024)</span></p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/prize_win_3.jpg" alt="" />
                        <p>Tefal Optigrill+ <span>Умный гриль</span></p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/prize_win_4.jpg" alt="" />
                        <p>Яндекс Станция&nbsp;2 <span>с&nbsp;Алисой на&nbsp;YaGPT</span></p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/prize_win_5.jpg" alt="" />
                        <p>Сертификат 10&nbsp;000&nbsp;₽ х2 <span>Золотое Яблоко</span></p>
                    </div>
                </div>

                <div className={styles.btn} onClick={scroll}>Забрать программы<br/> и&nbsp;участвовать в&nbsp;розыгрыше</div>

                {/* <div className={styles.pt3}>Ещё больше подарков.<br/> Мы&nbsp;объявляем конкурсный поток!</div> */}
                <div className={styles.t3}>ПОКАЖИТЕ ЛУЧШИЙ РЕЗУЛЬТАТ<br/> И&nbsp;ВЫИГРАЙТЕ <span>100&nbsp;000&nbsp;<em>₽</em></span></div>
                <div className={styles.st3}>Через три месяца мы&nbsp;вместе выберем 3-х победительниц<br/> с&nbsp;лучшими до/после и&nbsp;вручим денежные призы</div>
                <div className={styles.b3}>
                    <div className={styles.b3_item}>
                        <div className={styles.b3_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/place_1.png" alt="" />
                        </div>
                        <div className={styles.b3_item_place}>1&nbsp;место</div>
                        <div className={styles.b3_item_prize}>100&nbsp;000&nbsp;₽</div>
                    </div>
                    <div className={styles.b3_item}>
                        <div className={styles.b3_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/place_2.png" alt="" />
                        </div>
                        <div className={styles.b3_item_place}>2&nbsp;место</div>
                        <div className={styles.b3_item_prize}>75&nbsp;000&nbsp;₽</div>
                    </div>
                    <div className={styles.b3_item}>
                        <div className={styles.b3_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/place_3.png" alt="" />
                        </div>
                        <div className={styles.b3_item_place}>3&nbsp;место</div>
                        <div className={styles.b3_item_prize}>50&nbsp;000&nbsp;₽</div>
                    </div>
                </div>

                <div className={styles.b4}>
                    <div className={styles.b4_left}>
                        <p>Принять участие в&nbsp;конкурсе легко:</p>
                    </div>
                    <div className={styles.b4_right}>
                        <div className={styles.b4_right_item}>
                            <div className={styles.b4_right_item_num}>01</div>
                            <div className={styles.b4_right_item_text}>Приобретите набор из&nbsp;13&nbsp;фитнес-программ<br/> от&nbsp;Кати Усмановой</div>
                            <div className={styles.b4_right_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/usl1.jpg" alt="" />
                            </div>
                        </div>
                        <div className={styles.b4_right_item}>
                            <div className={styles.b4_right_item_num}>02</div>
                            <div className={styles.b4_right_item_text}>Подайте заявку для участия с&nbsp;период с&nbsp;15&nbsp;по&nbsp;29&nbsp;ноября, заполнив анкету и&nbsp;приложив фотографии &laquo;до&raquo;. Предложение об&nbsp;участии и&nbsp;ссылку на&nbsp;анкету вы&nbsp;получите на&nbsp;свой email после покупки программ.</div>
                            <div className={styles.b4_right_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/usl2.jpg" alt="" />
                            </div>
                        </div>
                        <div className={styles.b4_right_item}>
                            <div className={styles.b4_right_item_num}>03</div>
                            <div className={styles.b4_right_item_text}>Тренируйтесь по&nbsp;выбранным программам&nbsp;&mdash; у&nbsp;вас будет 3&nbsp;месяца для получения результата!</div>
                            <div className={styles.b4_right_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/usl3.jpg" alt="" />
                            </div>
                        </div>
                        <div className={styles.b4_right_item}>
                            <div className={styles.b4_right_item_num}>04</div>
                            <div className={styles.b4_right_item_text}>Заполните анкету и&nbsp;приложите фотографии<br/> &laquo;после&raquo; в&nbsp;период с&nbsp;24&nbsp;февраля по&nbsp;2&nbsp;марта</div>
                            <div className={styles.b4_right_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/usl4.jpg" alt="" />
                            </div>
                        </div>
                        <div className={styles.b4_right_item}>
                            <div className={styles.b4_right_item_num}>05</div>
                            <div className={styles.b4_right_item_text}>Следите за&nbsp;ходом открытого голосования</div>
                            <div className={styles.b4_right_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/usl5.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className={styles.forlines} style={{marginTop: 80}}>
                <div className={styles.line2}>
                    <div className={styles.line_text}>
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                        <p>БОНУСЫ ПРИ&nbsp;ОПЛАТЕ</p>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/2/marker.svg" alt="" />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Prize;